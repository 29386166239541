import 'moment/locale/pt-br';

import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';

@Component({
    selector: 'my-portal-my-searches',
    templateUrl: './my-searches.component.html',
    styleUrls: ['./my-searches.component.scss'],
})
export class MySearchesComponent implements OnInit {

    @Input() veritifactionVert: any;

    searchList: any = this.documentService.getWindow()?.localStorage?.getItem('searches')
        ? JSON.parse(this.documentService.getWindow()?.localStorage?.getItem('searches') || '')
        : [];

    momentDay: any = moment().format('YYYY-MM-DD');
    loading: boolean = true;


    constructor(
        private documentService: DocumentService,
        private route: Router
    ) {}

    ngOnInit(): void {
        if (!this.searchList?.length) {
            this.filterSearchListByAtualDate();
        }
        this.loading = false;
    }

    async filterSearchListByAtualDate(): Promise<void> {
        const filteredSearchesByDate = this.searchList?.filter(
            (item: any) => item.dates.startDate >= this.momentDay
        );

        this.documentService.getWindow()?.localStorage?.setItem('searches', JSON.stringify(await filteredSearchesByDate));
    }

    clearSearch(index: number): void {
        this.searchList.splice(index, 1);
        this.documentService.getWindow()?.localStorage?.setItem('searches', JSON.stringify(this.searchList));
    }
}
