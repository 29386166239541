import { AppDataService } from './../../../../../services/app-data.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import {DocumentService} from '../../../../../services/document.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'my-portal-search-local',
    templateUrl: './local.component.html',
    styleUrls: ['./local.component.scss'],

    animations: [
        trigger('slideUpAndDown', [
            transition(
                ':enter', [
                style({
                    transform: 'translateY(100%)'
                }),
                animate(200)]
            ),
            transition(':leave', [
                animate(200,
                    style({
                        transform: 'translateY(100%)'
                    })
                )
            ]),
        ]),
    ],
})
export class LocalComponent implements OnInit {

    @Output() destination: any = new EventEmitter();
    @Input() roundedSearch: any = false;
    @Input() squaredSearch: any = false;
    @Input() lineSearch: any = false;
    @Input() mobileSearch: any = false;

    isMobile: boolean = this.initializerService.isMobileDevice();

    cities: any = [];
    local: any = {
        hidden: true,
        model: null,
    };
    hotelInfo: any
    selectedLocal: any;

    constructor(
        private appData: AppDataService,
        private initializerService: InitializerService,
        private documentService: DocumentService,
        private activatedRouter: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getDestination();

        this.initializerService.currentControlFormSearch.subscribe(
            (value: any) => {
                if (value) {
                    if (value.type === 'localBack') {
                        this.openModal();
                    }

                    if (value.hotelInfo){
                        this.hotelInfo = value.hotelInfo
                        const localModel = {
                            id: value.hotelInfo.id,
                            name: value.hotelInfo.name,
                            type: 'hotel',
                        }

                        this.local.model = localModel;
                        this.selectLocal('hotel', localModel)
                        this.destination.emit(localModel)
                    }
                }
            }
        );

        this.activatedRouter.paramMap.subscribe((params) => {
            const initUrl = this.router.url;
            const url = initUrl.split('/');
            const idLocal = url[url.length - 1];
            const searches: any = this.documentService.getWindow()?.localStorage.getItem('searches');

            if (searches) {
                const searchesArr: any = JSON.parse(searches);
                for (const search of searchesArr) {
                    if (!search.destination?.id) {
                        this.local.model = null;
                    }

                    if (search.destination?.id === idLocal) {
                        setTimeout(() => {
                            this.selectedLocal = search.destination;
                            this.local.model = search.destination.name;
                            this.destination.emit(search.destination);
                            this.initializerService.changeControlLocalSelected(search.destination);
                        }, 100);
                    }
                }
            }
        });

        this.initializerService.currentControlLocalSelected.subscribe(
            (value: any) => {
                if (value) {
                    setTimeout(() => {
                        this.selectedLocal = value;
                        this.local.model = value.name;
                        this.destination.emit(value);
                    }, 100);
                }
            }
        );
    }

    selectLocal(type: string, local?: any): void {
        this.local.model = local?.name ?? 'Todos';

        const localObj = {
            id: local?.id ?? null,
            name: local?.name ?? 'Todos',
            type: local ? type : null,
        };

        if(local.hotels && local.hotels.length === 1) {
            const firstDestinationHotel = local.hotels[0];

            const FDHlocalObj = {
                id: firstDestinationHotel?.id ?? null,
                name: firstDestinationHotel?.name ?? 'Todos',
                type: firstDestinationHotel ? 'localHotel' : null,
            };

            this.selectedLocal = FDHlocalObj;
            this.destination.emit(FDHlocalObj);
            this.initializerService.changeControlLocalSelected(FDHlocalObj);
        } else {
            this.selectedLocal = localObj;
            this.destination.emit(localObj);
            this.initializerService.changeControlLocalSelected(localObj);
        }

        if(this.hotelInfo) {
            this.destination.emit(localObj);
        }
    }

    getDestination(): void {
        this.appData.getDestination().subscribe((destination: any) => {
            this.cities = destination.data.destination;
        });
    }

    handleSelectDates(): void {
        setTimeout(() => {
            this.initializerService.changeControlFormSearch({
                type: 'local'
            });

            this.initializerService.changeControlLocalSelected(this.selectedLocal);
        }, 100);


        this.openModal();
    }

    openModal(): void {
        const body: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.mat-drawer-content');
        const gallery: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.mat-tab-body.mat-tab-body-active');
        this.local.hidden = !this.local.hidden;

        if (this.local.hidden) {
            this.appData.showAskSuites(true);
            body.style = 'overflow: auto';
            if (gallery) {
                gallery.style.zIndex = 1;
            }
        } else {
            this.appData.showAskSuites(false);
            const element: HTMLElement | any = this.documentService.getWindow()?.document.getElementById('search');
            (element as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
            body.style = 'overflow: hidden';

            if (gallery) {
                gallery.style.zIndex = 0;
            }
        }
    }

    handleBackButton(): void {
        this.openModal();
    }

    handleClearLocal(): void {
        this.local.model = null;
        this.destination.emit(null);
        this.selectedLocal = null;
        this.initializerService.changeControlFormSearch(null);
        this.initializerService.changeControlLocalSelected(null);
    }
}
