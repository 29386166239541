<section class="mt-5 mb-5" *ngIf="this.packages?.length">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
        </div>
    </div>
    <my-portal-packages-default *ngIf="this.packages?.length <= 2 && typeEstablishment < 6" [packages]="packages" [load]="load" [hotelId]="personId"></my-portal-packages-default>
    <my-portal-packages-carousel *ngIf="this.packages?.length > 2 && typeEstablishment < 6" [packages]="packages" [load]="load" [initialize]="initialize" [hotelId]="personId"></my-portal-packages-carousel>

    <!-- COM TABS -->
    <div *ngIf="typeEstablishment >= 6">
        <mat-tab-group mat-stretch-tabsBo (selectedTabChange)="onTabChange()">
            <ng-template #listHotelPackages [ngTemplateOutlet]="listHotelPackages" *ngFor="let hotel of this.packages; let i = index">
                <mat-tab *ngIf="hotel.promotions" [label]="hotel.hotel | uppercase">
                    <div class="container-fluid mb-5 mt-5">
                        <my-portal-packages-default *ngIf="hotel.promotions?.length <= 2" [packages]="hotel.promotions" [load]="load" [hotelId]="hotel.id"></my-portal-packages-default>
                        <my-portal-packages-carousel *ngIf="hotel.promotions?.length > 2" [packages]="hotel.promotions" [load]="load" [hotelId]="hotel.id" [initialize]="initialize"></my-portal-packages-carousel>
                    </div>
                </mat-tab>
            </ng-template>
        </mat-tab-group>
    </div>

    <my-portal-show-all *ngIf="moduleData.landing_page_categoria_id" [route]="'categoria/' + moduleData.slug + '/' + moduleData.landing_page_categoria_id"></my-portal-show-all>

</section>
