<div class="datepicker" [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch, 'line': lineSearch}">
    <div (click)="openCalendar()">
        <label *ngIf="(!mobileSearch && !otherForm) || (isMobile && !mobileSearch) || lineSearch">Reserve online!</label>
        <div class="contain" [ngClass]="{'mobile-search': mobileSearch}">
            <figure>
                <img alt="" class="svg-icon"
                     src="assets/images/ico-buscador-calendario.svg">
            </figure>
            <button [ngClass]="{'portal': typeSearch}" class="form">
                <div>
                    <span translate="no">{{ objectDate.checkin | date: (mobileSearch || otherForm ? 'dd' : 'dd') }} </span> <span translate="no"> {{ objectDate.checkin | date: (mobileSearch || otherForm ? 'MMM' : 'MM/yy') }} </span> - <span translate="no"> {{ objectDate.checkout | date: (mobileSearch || otherForm ? 'dd' : 'dd') }} </span> <span translate="no">{{ objectDate.checkout | date: (mobileSearch || otherForm ? 'MMM' : 'MM/yy') }}</span>
                </div>
<!--                <div *ngIf="otherForm" class="squared">-->
<!--                    <div class="first">{{ objectDate.checkin | date: (mobileSearch ? 'dd MMM' : 'dd-MM-yyyy') }}</div>-->
<!--                    <div>{{ objectDate.checkout | date: (mobileSearch ? 'dd MMM' : 'dd-MM-yyyy') }}</div>-->
<!--                </div>-->
                <span *ngIf="!mobileSearch" class="material-icons notranslate">{{ openDatePicker ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
            </button>
        </div>
    </div>

    <div [@.disabled]="!isMobile" [hidden]="!openDatePicker" @slideUpAndDown [ngClass]="{'open': openDatePicker, 'line': lineSearch, 'rounded': roundedSearch, 'squared': squaredSearch, 'squared-portal': typeSearch && squaredSearch}" class="container-dates">
        <div class="box">
            <div *ngIf="load || arePricesLoading" class="load-calendar">
                <div *ngIf="load || arePricesLoading" class="spinner-border" role="status"></div>
            </div>

            <div class="back-button-container">
                <button matRipple class="back-button" (click)="handleBackButton()">
                    <span class="material-icons-outlined notranslate">chevron_left</span>
                </button>

                <div class="selected-local">
                    <span>{{ selectedLocal?.name }}</span>
                    <span class="date" *ngIf="selectedLocalAndDates?.selectedDates">
                        {{ selectedLocalAndDates?.selectedDates?.checkin | date: 'dd MMM' }} à {{ selectedLocalAndDates?.selectedDates?.checkout | date: 'dd MMM' }}
                    </span>
                </div>
            </div>

            <div *ngIf="!load && !arePricesLoading" class="wrapper-month">
                <ng-template #listDatePickerMonth *ngFor="let month of calendarWeek"
                             [ngTemplateOutlet]="listDatePickerMonth">
                    <div class="month">
                        <div class="title">{{month.name | date: 'MMMM | yyyy'}}</div>
                        <div class="week">
                            <ul>
                                <li *ngFor="let dayWeek of week">
                                    {{dayWeek}}
                                </li>
                            </ul>
                        </div>
                        <div class="container-days" id="wrapper">
                            <ng-template #listDatePickerWeeks *ngFor="let week of month?.weeks"
                                         [ngTemplateOutlet]="listDatePickerWeeks">
                                <ul class="days">

                                    <ng-template #listDatePickerDays *ngFor="let day of week"
                                                 [ngTemplateOutlet]="listDatePickerDays"
                                                 >

                                        <li>
                                            <!-- [matTooltip]="(calendar && calendar[day?.date]?.restriction?.observations && calendar[day?.date]?.restriction?.observations[2]) ? 'Mínimo de ' + calendar[day?.date]?.restriction?.observations[2].min_nights + ' noites' : ''" -->
                                            <div class="touch-action-day"
                                                [matTooltip]="observations[day?.date]"
                                                [matTooltipPosition]="'above'"
                                                [matTooltipClass]="'my-tooltip-min-days'">
                                                <button translate="no" (click)="selectDate(day?.date, $event)"
                                                        (mouseover)="onMouseHoverDate($event, day?.date)"
                                                        (mouseleave)="onMouseLeaveDate($event, day?.date)"
                                                        [ngClass]="{'disabled-date': day?.date < month.startDate || day?.date > month.endDate }"
                                                        [class]="'date-'+day?.date"
                                                        [disabled]="
                                                            day?.date < month.startDate ||
                                                            day?.date < today ||
                                                            day?.date > month.endDate ||
                                                            (calendar && calendar[day?.date]?.restriction?.closed) && !objectRestriction.closedCheckOut ||
                                                            !day"
                                                        matRipple>
                                                    {{ day?.date | date: 'd' }}
                                                    <span *ngIf="calendar && !calendar[day?.date]?.restriction?.closed"
                                                        class="price">{{ calendar[day?.date]?.price | currency:'':'' }}
                                                    </span>

                                                    <span translate="yes" class="restriction" *ngIf="calendar && calendar[day?.date]?.restriction?.id === 2 || calendar && calendar[day?.date]?.restriction?.id === 3">
                                                        {{calendar[day?.date]?.restriction?.message}}
                                                    </span>

                                                    <span translate="yes" class="restriction" *ngIf="day?.date >= today && (calendar && calendar[day?.date]?.restriction?.restrictions[3] && objectRestriction.closedCheckIn)">
                                                        {{calendar[day?.date]?.restriction?.restrictions[3].message}}
                                                    </span>

                                                    <span translate="yes" class="restriction" *ngIf="day?.date >= today && (calendar && calendar[day?.date]?.restriction?.restrictions[4] && objectRestriction.closedCheckOut)">
                                                        {{calendar[day?.date]?.restriction?.restrictions[4].message}}
                                                    </span>

                                                    <span
                                                        class="observation search"
                                                        [matTooltip]="'Mínimo de ' + calendar[day?.date]?.restriction?.observations[2].min_nights + ' noites'"
                                                        [matTooltipPosition]="'above'"
                                                        [matTooltipClass]="'my-tooltip-min-days'"
                                                        *ngIf="day?.date >= today && (calendar && calendar[day?.date]?.restriction?.observations && calendar[day?.date]?.restriction?.observations[2])">
                                                        {{calendar[day?.date]?.restriction?.observations[2].min_nights}}
                                                    </span>

                                                    <span
                                                        class="observation closed"
                                                        *ngIf="day?.date >= today && (calendar && calendar[day?.date]?.restriction?.closed)"
                                                        [matTooltip]="'Esgotado ou Indisponível'"
                                                        [matTooltipPosition]="'above'"
                                                        [matTooltipClass]="'my-tooltip-min-days'">
                                                        x
                                                    </span>
                                                </button>
                                            </div>
<!--                                            <button (click)="selectDate(day?.date, $event)"-->
<!--                                                    (mouseover)="hoverDates($event)"-->
<!--                                                    [class]="'date-'+day?.date"-->
<!--                                                    [disabled]="-->
<!--                                                        day?.date < month.startDate ||-->
<!--                                                        day?.date < today ||-->
<!--                                                        day?.date > month.endDate ||-->
<!--                                                        !day?.price && !typeSearch ||-->
<!--                                                        (day?.restriction?.id === 1 && objectRestriction.unavailable) ||-->
<!--                                                        (day?.restriction?.id === 3 && objectRestriction.closedCheckIn) ||-->
<!--                                                        (day?.restriction?.id === 4 && objectRestriction.closedCheckOut) ||-->
<!--                                                        (day?.restriction?.id === 5)"-->
<!--                                                    matRipple>-->
<!--                                                {{ day?.date | date: 'd' }}-->
<!--                                                <span *ngIf="day?.price"-->
<!--                                                      class="price">{{ day?.price | currency:'':'' }}</span>-->
<!--                                                <span class="restriction" *ngIf="day?.restriction?.id === 2 || day?.restriction?.id === 3">{{day?.restriction?.message}}</span>-->
<!--                                            </button>-->
                                        </li>
                                    </ng-template>
                                </ul>
                            </ng-template>
                            <div class="vertical-line"></div>
                        </div>

                    </div>
                </ng-template>

                <button (click)="changeMonth('mobile', true)" class="dates" matRipple>carregar mais datas</button>
            </div>
            <button (click)="changeMonth('prev')" *ngIf="!load && !arePricesLoading" [disabled]="navigation.next.disabled"
                    [hidden]="navigation.prev.hidden"
                    class="prev"
                    matRipple>
                <span class="material-icons notranslate">
                    arrow_back_ios
                </span>
            </button>
            <button (click)="changeMonth('next')" *ngIf="!load && !arePricesLoading" [disabled]="navigation.next.disabled"
                    [hidden]="navigation.next.hidden"
                    class="next"
                    matRipple>
                <span class="material-icons notranslate">arrow_forward_ios</span>
            </button>
            <div class="footer">
                <button (click)="openCalendar(true)" class="close-button" matRipple>fechar</button>
                <button (click)="openConfig(false)" class="confirm" matRipple>confirmar datas</button>
            </div>
        </div>
    </div>
    <div (click)="openCalendar(true)"
         *ngIf="openDatePicker" class="backdrop"></div>
</div>
