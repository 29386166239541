<div mat-dialog-content>
    <h6>Enviaremos as instruções para recuperar a sua senha.</h6>
    <form (ngSubmit)="onSubmit(passwordForm)" [formGroup]="passwordForm" class="form-container">
      <mat-form-field appearance="outline">
        <mat-label>Digite seu e-mail</mat-label>
        <input
          matInput name="email"
          formControlName="email"
        >
      </mat-form-field>
    </form>

    <div class="success" *ngIf="successMessage">
      <h6>E-mail enviado com sucesso!</h6>
    
      <p>
        As instruções para recuperar a sua senha foram enviadas para o email: <strong>{{ email }}</strong>.
      </p>
    </div>
    
    <div class="error" *ngIf="errorMessage && !successMessage">
      <p>
        Houve um erro ao enviar o e-mail.
      </p>
    </div>
  </div>
  
  <div mat-dialog-actions class="actions-container">
    <button mat-button (click)="onNoClick()">fechar</button>
    <button mat-button cdkFocusInitial
      (click)="onSubmit(passwordForm)"
      [disabled]="!passwordForm.valid"
      class="rescue">
      <span *ngIf="!loading">enviar</span>
            <div *ngIf="loading" class="spinner-border">
                <span class="sr-only">Carregando...</span>
            </div>
    </button>
  </div>
  