<div *ngIf="!load && slide" class="row">
    <div class="col-md-12">
        <swiper [config]="config" [initialize]="!load">
            <div class="swiper-wrapper">
                <div *ngFor="let depoiment of testimony; let i = index" class="swiper-slide" 
                    [ngClass]="
                    {'activated-scroll': depoiment.testimony.length >= 300 && showDescriptionTestimony[depoiment.id],'activated-scroll-mobile': depoiment.testimony.length >= 100 && showDescriptionTestimony[depoiment.id]} ">
                    <div class="testimony">
                        <div class="box">
                            <p [ngClass]="{'loading': load, 'all-description': showDescriptionTestimony[depoiment.id]}">{{depoiment.testimony}}</p>
                            <button *ngIf="depoiment.testimony.length >= 300 && !getScreenWidth" type="button" (click)="showDescriptionTestimony[depoiment.id] = !showDescriptionTestimony[depoiment.id]">{{showDescriptionTestimony[depoiment.id] ? 'ver menos' : 'ver mais' }}</button>
                            <button *ngIf="depoiment.testimony.length >= 100 && getScreenWidth" type="button" (click)="showDescriptionTestimony[depoiment.id] = !showDescriptionTestimony[depoiment.id]">{{showDescriptionTestimony[depoiment.id] ? 'ver menos' : 'ver mais' }}</button>
                        </div>
                        <div class="media">
                            <!--              <img src="assets/images/img-depoiment.png" class="mr-3 rounded-circle" alt="...">-->
                            <div class="media-body align-self-center">
                                <h6 [ngClass]="{'loading': load }" class="mt-0 mb-1 text-right">{{depoiment.name}}</h6>
                                <div *ngIf="depoiment?.hotel" class="hotel">{{ depoiment?.hotel.name }}</div>
                                <!-- <i class="material-icons notranslate" *ngIf="!load">star</i>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination pagination-accommodation"></div>
        </swiper>
    </div>
</div>

<div *ngIf="!load && !slide" class="row">
    <div *ngFor="let depoiment of testimony; let i = index" class="col-md-6 col-sm-12">
        <div class="testimony">
            <div class="box">
                <p [ngClass]="{'loading': load, 'all-description': showDescriptionTestimony[depoiment.id]}">{{depoiment.testimony}}</p>
                <button *ngIf="depoiment.testimony.length >= 300 && !getScreenWidth" type="button" (click)="showDescriptionTestimony[depoiment.id] = !showDescriptionTestimony[depoiment.id]">{{showDescriptionTestimony[depoiment.id] ? 'ver menos' : 'ver mais' }}</button>
                <button *ngIf="depoiment.testimony.length >= 100 && getScreenWidth" type="button" (click)="showDescriptionTestimony[depoiment.id] = !showDescriptionTestimony[depoiment.id]">{{showDescriptionTestimony[depoiment.id] ? 'ver menos' : 'ver mais' }}</button>
            </div>
            <div class="media">
                <!--              <img src="assets/images/img-depoiment.png" class="mr-3 rounded-circle" alt="...">-->
                <div class="media-body align-self-center">
                    <h6 [ngClass]="{'loading': load }" class="mt-0 mb-1 text-right">{{depoiment.name}}</h6>
                    <div *ngIf="depoiment?.hotel" class="hotel">{{ depoiment?.hotel.name }}</div>
                    <!--                <i class="material-icons notranslate" *ngIf="!load">star</i>-->
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="load" class="row">
    <div *ngFor="let depoiment of testimony; let i = index" class="col-md-6">
        <div *ngIf="i <= 1" class="testimony">
            <div class="box">
                <p [ngClass]="{'loading': load, 'all-description': showDescriptionTestimony[depoiment.id]}">{{depoiment.testimony}}</p>
                <button *ngIf="depoiment.testimony.length >= 300 && !getScreenWidth" type="button" (click)="showDescriptionTestimony[depoiment.id] = !showDescriptionTestimony[depoiment.id]">{{showDescriptionTestimony[depoiment.id] ? 'ver menos' : 'ver mais' }}</button>
                <button *ngIf="depoiment.testimony.length >= 100 && getScreenWidth" type="button" (click)="showDescriptionTestimony[depoiment.id] = !showDescriptionTestimony[depoiment.id]">{{showDescriptionTestimony[depoiment.id] ? 'ver menos' : 'ver mais' }}</button>
            </div>
            <div class="media">
                <!--        <img src="assets/images/img-depoiment.png" class="mr-3 rounded-circle" alt="...">-->
                <div class="media-body align-self-center">
                    <h6 [ngClass]="{'loading': load }" class="mt-0 mb-1 text-right">{{depoiment.named}}</h6>
                    <div class="hotel">{{ depoiment?.hotel.name }}</div>
                    <!--          <i class="material-icons notranslate" *ngIf="!load">star</i>-->
                </div>
            </div>
        </div>
    </div>
</div>
