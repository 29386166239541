<mat-dialog-content class="modal-restricted-area" [ngClass]="{'visible': visibility }">
    <div class="content">
        <div class="header">
            <div class="title">
                <h4>{{ loginType === 'client' ? 'Acesso cliente' : 'Acesso corporativo'}}</h4>
            </div>
            <div class="close">
                <button translate="no" class="close-button" (click)="closeDialog()">
                    <i class="material-icons-outlined notranslate">close</i>
                </button>
            </div>
        </div>

        <form #formLogin="ngForm" (ngSubmit)="onSubmit(formLogin, loginType)" class="form">
            <div class="input-area">
                <label>E-mail</label>
                <input [email]="true" type="email" name="mail" [(ngModel)]="user.mail" placeholder="Digite o seu e-mail" required>
            </div>
            <div class="input-area">
                <label>Senha</label>
                <input type="password" name="password" [(ngModel)]="user.password" placeholder="Digite a sua senha" required>
            </div>
            <div *ngIf="loginError" class="alert alert-danger mt-3" role="alert">
                Usuário ou senha incorretos!
              </div>
            <div class="forgot-password">
                <a (click)="handleForgotPassword()">Esqueci a minha senha</a>
            </div>

            <div class="recaptcha">
                <my-portal-recaptcha (recaptchaEvent)="recaptcha($event)"></my-portal-recaptcha>
            </div>

            <div class="button">
                <button [disabled]="!formLogin.valid || !disabledRecaptcha" type="submit">
                    <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status"></div>
                    <span *ngIf="!loading">entrar</span>
                </button>
            </div>
        </form>
    </div>
</mat-dialog-content>
