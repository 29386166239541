<div class="load">
    <!--  <mat-spinner></mat-spinner>-->
    <div class="toolbar"></div>
    <div class="container mt-5">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3">
            <div class="col mb-4" *ngFor="let box of [0,1,2]" >
                <div class="box"></div>
                <h6>Title Load</h6>
                <p>loren impsun</p>
                <div class="button"></div>
            </div>
        </div>
    </div>
    <div class="footer"></div>
</div>

