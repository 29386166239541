<div class="pt-3 pb-3 gap">
    <ul class="mb-0" [ngClass]="{'hidden-coupon-establishment':dataHeader.hide_coupon, 'hidden-coupon-portal': typeSearch >= 6 && dataHeader.hide_coupon}">
        <li *ngIf="searchType == 6">
            <my-portal-search-local (destination)="changeLocal($event)" [roundedSearch]="true"></my-portal-search-local>
        </li>
        <li>
            <my-portal-datepicker (dates)="changeDates($event)" (panel)="openPanel($event)" [destination]="destination" [roundedSearch]="true" [otherForm]="true"></my-portal-datepicker>
        </li>
        <li>
            <my-portal-search-guests [resetTypeAcomodation]="clearAccommodation" [dataHeader]="dataHeader" (acomodationType)="changeAcomodation($event)" (guests)="changeGuests($event)" [roundedSearch]="true" [otherForm]="true" [searchType]="searchType"></my-portal-search-guests>
        </li>
        <li *ngIf="!dataHeader.hide_coupon">
            <my-portal-search-cupom [resetTypeAcomodation]="clearAccommodation" (couponData)="changeCoupon($event)" (open)="onSearch()" [roundedSearch]="true"></my-portal-search-cupom>
        </li>
        <li (click)="onSearch()" class="search" *ngIf="searchType < 6 || isMobile">
            <button>
                Pesquisar
            </button>
        </li>
    </ul>
    <button (click)="onSearch()" *ngIf="searchType >= 6 && !isMobile" class="search">
        <i  class="material-icons-outlined notranslate">search</i>
        <span>Pesquisar</span>
    </button>
</div>
