<section class="mt-5 mb-5 tariffs" *ngIf="stamps?.length">
    <my-portal-highlighter [module]="{title: 'Prêmios e certificações', description: 'Colhendo os frutos do nosso trabalho e dedicação'}"
                           class="mt-5"></my-portal-highlighter>

    <swiper [config]="config" class="mb-5">
        <div class="swiper-wrapper">
            <div *ngFor="let stamp of stamps" class="swiper-slide pl-5 pr-5 ">
                <div class="row m-0 p-0 stamp-container">
                    <a [href]="stamp.url" [target]="stamp.url ? '_target' : '_self'">
                        <figure>
                            <img [alt]="stamp.description" class="img-fluid" [src]="imgServer + 'width=' + 269 + imgServerUrlEnd +  stamp.image">
                        </figure>
                    </a>
                </div>
            </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
    </swiper>
</section>




