<section class="mt-5 mb-5 accommodations-list" *ngIf="accommodations?.length">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
        </div>
    </div>

    <ng-template #accommodationsList [ngTemplateOutlet]="accommodationsList" *ngIf="establishmentType !== 6">
        <my-portal-default *ngIf="accommodations && accommodations.length <= 2" [accommodations]="accommodations" [load]="load" [hotelId]="personId">
        </my-portal-default>
        <my-portal-carousel *ngIf="accommodations && accommodations.length > 2" [accommodations]="accommodations" [initialize]="initialize"
            [load]="load" [quantity]="quantity" [hotelId]="personId">
        </my-portal-carousel>
    </ng-template>

    <ng-template #accommodationsListPortal [ngTemplateOutlet]="accommodationsListPortal" *ngIf="establishmentType >= 6">
        <mat-tab-group mat-stretch-tabsBo (focusChange)="myTabFocusChange($event)">
            <ng-template #listDestinationsHotels *ngFor="let accommodation of accommodations; let i = index"
                [ngTemplateOutlet]="listDestinationsHotels">
                <mat-tab #tab [label]="accommodation.hotel | uppercase">
                    <div class="mt-4">
                        <my-portal-default *ngIf="accommodation && accommodation.rooms.length <= 2" [accommodations]="accommodation.rooms"
                            [load]="load" [hotelId]="accommodation.id" >
                        </my-portal-default>
                        <my-portal-carousel *ngIf="accommodation && accommodation.rooms.length > 2 && tab.isActive" [accommodations]="accommodation.rooms"
                            [initialize]="tab.isActive" [load]="tab.isActive && load" [quantity]="quantity" [hotelId]="accommodation.id">
                        </my-portal-carousel>
                    </div>
                </mat-tab>
            </ng-template>
        </mat-tab-group>
    </ng-template>
</section>
