import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppDataService } from '../../services/app-data.service';
import { InitializerService } from '../../services/initializer.service';
import { DocumentService } from '../../services/document.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DefaultSnackBarComponent } from '../../shared/default-snack-bar/default-snack-bar.component';
import { SeoService } from '../../services/seo.service';
import { Router } from '@angular/router';


@Component({
    selector: 'my-portal-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    load: boolean = true;
    loading: boolean = false;
    displayErrorMessage: boolean = true
    data: any;
    dataHotels: any;
    telefone: any = {sigla: 'br', ddi: '55'};
    urlMap: any;
    checkinOut: any;
    clientColor: any;
    contactForm: FormGroup;
    disabledButton: boolean = false;
    disabledRecaptcha: boolean = false;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    dataVerification: any;
    hiddenAdress: boolean = false;
    tollFree: boolean = false;
    phoneMsgWhatsApp: any;
    idPortal: any;
    typeSearch: number = Number(this.documentService.getWindow()?.sessionStorage.getItem('typeSearch'));
    selectedTabIndex: number = 0;


    constructor(
        private sanitize: DomSanitizer,
        private appData: AppDataService,
        private initializerService: InitializerService,
        private documentService: DocumentService,
        private formBuilder: FormBuilder,
        private snackbar: MatSnackBar,
        private seoService: SeoService,
        private router: Router) {
        this.contactForm = this.formBuilder.group({
            name: ['', Validators.required],
            mail: ['', Validators.required],
            phone: ['', Validators.required],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });

        // recaptcha: ['', Validators.required]
    }

    ngOnInit(): void {
        setTimeout(() => {   
            if (!this.data) {
                this.data = JSON.parse(this.documentService.getWindow()?.sessionStorage.getItem('footer') as string)
                this.urlMap = this.sanitizeUrl(this.data.location_map);
                this.initializerService.currentGetFooterData.subscribe((response: any) => { 
                    if (response) {
                        this.dataHotels = response;
                    }
                });

                this.displayErrorMessage = false

            let novoDiscagem = [];

            for (const phone of this.data.phone) {
                let numberTollFree = phone.substring(0, 4).includes('0800');

                let number;
                let type;

                number = phone;

                if (!numberTollFree) {
                    number = number.replace(/\D+/g, '').replace(/^0+/, '');
                }

                if (numberTollFree) {
                    type = 'tollFree'
                } else if (!numberTollFree && number.length > 10) {
                    type = 'mobilePhone'
                } else if (!numberTollFree && number.length <= 10) {
                    type = 'landlinePhone'
                }

                novoDiscagem.push({
                    number,
                    type
                })

            this.data.phone = novoDiscagem;
        }

        if (!this.data.whatsapp.number) {
            this.data.whatsapp.number = ''
        }

        const whatsNewNumber = this.data.whatsapp?.number.replace(/\D+/g, '').replace(/^0+/, '');
        const whatsVerification = whatsNewNumber.substring(0, 3).includes('55');
        this.data.whatsapp.number = whatsNewNumber;
        if (!whatsVerification && this.data.whatsapp.number.length <= 11) {
            const newWhats = `55${this.data.whatsapp.number}`;
            this.data.whatsapp.number = newWhats;
        }
    }
}, 2000);

        this.initializerService.currentData.subscribe((response: any) => {
            const { only_motor, only_site } = response;

            if (only_motor && !only_site) {
                this.router.navigateByUrl('/');
            }
        }).unsubscribe();

        const dataFooter: any = this.documentService.getWindow()?.sessionStorage.getItem('footer');
        this.clientColor = this.documentService.getWindow()?.sessionStorage.getItem('color');
        this.data = JSON.parse(dataFooter);
        this.load = false;

        this.urlMap = this.sanitizeUrl(this.data?.location_map);

        this.initializerService.currentData
            .subscribe((data: any) => {
                if (this.typeSearch >= 6) {
                    const clientsArr: any[] = Object.values(data.whatsapp.message);
                    const firstClient: any = clientsArr[0];
                    this.phoneMsgWhatsApp = firstClient.text;
                } else {
                    this.phoneMsgWhatsApp = data.whatsapp.message;
                }

                this.load = false;
                if (data) {
                    if (data.checkin && data.checkout) {
                        this.checkinOut = {
                            checkin: data.checkin,
                            checkout: data.checkout
                        };
                    }
                    this.hiddenAdress = data.hide_address;
                }
                this.loading = false;
            })
            .unsubscribe();

        const objSeo = {
            seo: {
                title: 'Fale conosco',
                description: 'Entre em contato conosco!',
                keyWords: null
            }
        };
        this.seoService.updateSeo(objSeo);

        this.initializerService.currentGetFooterData.subscribe((response: any) => {
            if (response) {
                this.dataHotels = response;
                this.dataHotels.forEach((item:any) => {
                    if (!item.whatsapp.number) {
                        item.whatsapp.number = ''
                    }

                    const whatsNewNumber = item.whatsapp.number.replace(/\D+/g, '').replace(/^0+/, '');
                    const whatsVerification = whatsNewNumber.substring(0, 3).includes('55');
                    item.whatsapp.number = whatsNewNumber;
                    if (!whatsVerification && item.whatsapp.number.length <= 11) {
                        const newWhats = `55${item.whatsapp.number}`;
                        item.whatsapp.number = newWhats;
                    }
                })
                this.loading = true
            }
        });
    }

    tabSelecionada(event: any): void {
        this.selectedTabIndex = event;
    }
    
    onTabSelected(event: any) {
        // this.tabSelecionada.emit(event);
    }

    onSubmit(form: any): void {
        this.loading = true;
        const contactData: any = {
            ...this.contactForm.value,
            phone: this.telefone.ddi + this.contactForm.value.phone,
            hotel_id: this.dataHotels[this.selectedTabIndex].id
        }

        this.appData.postContactForm(contactData).subscribe(
            (contact: any) => {
                if (contact.data === 'success') {
                    this.loading = false;

                    this.contactForm.reset();
                    for (const control in this.contactForm.controls) {
                        this.contactForm.controls[control].setErrors(null);
                    }

                    this.openSnackBar(
                        'Formulário enviado com sucesso!',
                        false
                    );
                }
            },

            (error: any) => {
                this.loading = false;
                this.openSnackBar(
                    'Erro ao enviar formulário!',
                    true
                );
            }
        );
    }

    sanitizeUrl(url: string): SafeUrl {
        return this.sanitize.bypassSecurityTrustResourceUrl(url);
    }

    privacyPolicy($event: any): void {
        this.disabledButton = $event;
    }

    recaptcha($event: any): void {
        this.disabledRecaptcha = $event;
    }

    openSnackBar(msg: string, flag?: boolean | undefined): void {
        this.snackbar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg
            },
            horizontalPosition: 'end',
            verticalPosition: this.getScreenWidth ? 'top' : 'bottom',
            panelClass: (flag) ? 'error-snack-bar' : 'success-snack-bar',
            duration: 5000
        });
    }

    onCountryChange(country: any) {
        this.telefone.sigla = country.iso2;
        this.telefone.ddi = country.dialCode;
    }

}


