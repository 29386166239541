<div class="packages-header-swiper d-none d-md-block model-two" *ngIf="hidden" [ngClass]="{'model-one': dataHeader?.header?.searchType === 1, 'model-two': dataHeader?.header?.searchType === 2 || dataHeader?.header?.searchType === 3}">
    <swiper [config]="configInterna" [initialize]="loading">
        <div class="swiper-wrapper">
            <div *ngFor="let package of packagesHeader" class="swiper-slide">
                <div class="close-btn p-0 position-absolute">
                    <div class="icon-close-packages d-flex justify-content-center align-items-center">
                        <a (click)="hiddenComponent()">
                            <i class="material-icons-outlined notranslate">close</i>
                        </a>
                    </div>
                </div>
                <div class="packages-header right">
                    <div class="content">
                        <figure>
                            <img [src]="imgServer + 'width=' + 560 + imgServerUrlEnd + package.image" alt="" width="560" height="auto" class="img-fluid">
                        </figure>

                        <div class="content-info">
                            <h5 class="mb-0">{{ package.title }}</h5>
                            <P class="mb-1">Apartamento 01 quarto (vista mar)</P>
                            <div class="prices">
                                <p class="lower-price m-0" >
                                    <span class="lower-price" *ngIf="package?.discount > 0">De:<strong> {{ package.lowest_rate.old_price | currency}}</strong> </span>
                                </p>
                                <p class="price m-0">
                                    <span class="price" *ngIf="package?.discount > 0">Por: <strong>{{ package.lowest_rate.price | currency }}</strong></span>
                                    <span class="price" *ngIf="!package?.discount">A partir de: <strong>{{ package?.lowest_rate.price | currency }} </strong></span>
                                </p>
                            </div>
                            <div class="btn btn-detail-packages w-100 p-0">
                                <a [routerLink]="hotelId ? ['promocao', package.slug, package.id, hotelId] : ['promocao', package.slug, package.id]" class="d-flex justify-content-center align-items-center text-center p-0 text-decoration-none ">ver detalhes</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-button-next-cart">
            <mat-icon>arrow_forward_ios</mat-icon>
        </div>
        <div class="swiper-button-prev-cart">
            <mat-icon>arrow_back_ios</mat-icon>
        </div>
    </swiper>
</div>
