<div class="counter" [ngClass]="{'type' : type}">
  <i class="material-icons client-theme-color notranslate">query_builder</i>
  <div class="step">
    <div class="count">{{daysToDday}}</div>
    <div class="description">dia(s)</div>
  </div>
  <div class="step">
    <div class="count">{{hoursToDday}}</div>
    <div class="description">hrs</div>
  </div>
  <div class="step">
    <div class="count">{{minutesToDday}}</div>
    <div class="description">min</div>
  </div>
  <div class="step">
    <div class="count">{{secondsToDday}}</div>
    <div class="description">seg</div>
  </div>
</div>
