import {Component, Input, OnInit} from '@angular/core';
import {AppDataService} from '../../services/app-data.service';
import Swiper, {SwiperOptions} from 'swiper';
import {DocumentService} from '../../services/document.service';

@Component({
    selector: 'my-portal-packages',
    templateUrl: './packages.component.html',
    styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

    @Input() moduleData: any;
    @Input() withTabs: any | null;
    @Input() personId: any | null;
    @Input() landingPageId: any | null;

    config: SwiperOptions = {
        spaceBetween: 30,
        slidesPerView: 3,
        autoHeight: true,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: true
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 30,
                centeredSlides: true,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        }
    };

    load: boolean = true;
    initialize: boolean = false;
    typeEstablishment: any = this.documentService.getWindow()?.sessionStorage.getItem('typeSearch');

    packages: any;

    constructor(private appService: AppDataService,
                private documentService: DocumentService) {
    }

    ngOnInit(): void {
        if (!this.moduleData) {
            this.moduleData = {
                title: 'Nossos Pacotes'
            };
        }
        if (this.personId) {
            this.typeEstablishment = 1;
        }

        this.moduleData.slug = this.appService.createSlugUrl(
            this.moduleData.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

        if (!this.landingPageId) {
            this.appService.getDataModule('promotion', this.personId)
                .subscribe((response: any) => {
                    this.initPackages(response.data);
                });
        } else {
            this.appService.getGenericPagesList(this.landingPageId)
                .subscribe((response: any) => {
                    this.initPackages(response.data.items);
                });
        }
    }


    initPackages(response: any): void {
        this.packages = response;
        for (const item of this.packages) {
            if (item.promotions) {
                for (const promotion of item.promotions) {
                    promotion.slug = this.appService.createSlugUrl(promotion.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                }
            } else {
                item.slug = this.appService.createSlugUrl(item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
            }
        }
        this.load = false;
        this.initialize = true;
    }

    onTabChange(): void {
        this.initSwiper();
    }

    initSwiper(): void {
        new Swiper('.swiper-wrapper', this.config);
    }

    createUrlPackages(title: string): string {
        return this.appService.createSlugUrl(title);
    }

}
