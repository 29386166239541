import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DestinationsRoutingModule } from './destinations-routing.module';
import { DetailDestinationsComponent } from './detail/detail.component';
import { DestinyComponent } from './destiny/destiny.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
      DetailDestinationsComponent,
      DestinyComponent,
  ],
  imports: [
    CommonModule,
    DestinationsRoutingModule,
    NgxUsefulSwiperModule,
    SharedModule
  ],
    exports: [
        DestinyComponent
    ]
})
export class DestinationsModule { }
