import {Component, OnInit} from '@angular/core';
import {InitializerService} from '../../../services/initializer.service';
import {AppDataService} from '../../../services/app-data.service';
import {DocumentService} from '../../../services/document.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'my-portal-hotels-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailHotelComponent implements OnInit {

    hotelId: any | null;
    data: any | null;
    load = true;
    constructor(private initializerService: InitializerService,
                private dataService: AppDataService,
                private documentService: DocumentService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.hotelId = Number(this.activatedRoute.snapshot.params.id);
        this.documentService.getWindow()?.sessionStorage.setItem(this.hotelId, 'hotelId');
        this.initializerService.getInitializer(this.hotelId)
            .subscribe((response: any) => {
                this.data = response.data;
                this.load = false;
                setTimeout(() => {
                    this.dataService.scroll('hotels-detail');
                }, 30);
            });
    }

    _updateData(data: []): void {
        this.initializerService.changeInitializerData(data);
    }

}
