import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'my-portal-cashback',
    templateUrl: './cashback.component.html',
    styleUrls: ['./cashback.component.scss']
})
export class CashbackComponent implements OnInit {

    @Input() moduleData: any;

    constructor() {
    }

    ngOnInit(): void {
    }

}
