<div class="search-squared-form">
    <ul [ngClass]="{'hidden-coupon-establishment':dataHeader.hide_coupon, 'hidden-coupon-portal': typeSearch >= 6 && dataHeader.hide_coupon}">
        <li *ngIf="searchType == 6">
            <my-portal-search-local (destination)="changeLocal($event)" [squaredSearch]="true"></my-portal-search-local>
        </li>
        <li>
            <my-portal-datepicker (dates)="changeDates($event)" (panel)="openPanel($event)" [destination]="destination"  [squaredSearch]="true" [otherForm]="true"></my-portal-datepicker>
        </li>

        <li>
            <my-portal-search-guests [dataHeader]="dataHeader" (acomodationType)="changeAcomodation($event)" (guests)="changeGuests($event)" [squaredSearch]="true" [otherForm]="true"></my-portal-search-guests>
        </li>

        <li *ngIf="!dataHeader.hide_coupon">
            <my-portal-search-cupom (couponData)="changeCoupon($event)" (open)="onSearch()" [squaredSearch]="true"></my-portal-search-cupom>
        </li>

        <div (click)="onSearch()" class="search" class="content w-100 btn-group">
            <button class="btn btn-pesquisar">Pesquisar</button>
        </div>
    </ul>
</div>

