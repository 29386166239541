import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'my-portal-room-events',
  templateUrl: './room-events.component.html',
  styleUrls: ['./room-events.component.scss']
})
export class RoomEventsComponent implements OnInit {


  @Input() roomEvents: any;

  constructor() { }

  ngOnInit(): void {
  }

}
