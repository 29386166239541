<section class="mt-5 mb-5 cashback" *ngIf="moduleData.discount">
    <div *ngIf="moduleData" class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
        </div>
    </div>

    <ul class="box-cash">
        <li>
            <div class="box-rounded">
                <figure>
                    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-reserve-cashback-1{fill:none;stroke:#FFF;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.75px;}</style></defs><title>ico-hospedagem</title><path class="cls-reserve-cashback-1" d="M12,7.8a9,9,0,0,0-9,8.9H21A9,9,0,0,0,12,7.8Z"/><polygon class="cls-reserve-cashback-1" points="22.4 18.6 1.6 18.6 2 17 22 17 22.4 18.6"/><line class="cls-reserve-cashback-1" x1="12" y1="7.8" x2="12" y2="6.1"/><circle class="cls-reserve-cashback-1" cx="12" cy="4.8" r="1.3"/><path class="cls-reserve-cashback-1" d="M14.1,9.4a7.6,7.6,0,0,1,5.3,5.3"/></svg>
                </figure>
            </div>
            <div class="mt-2">
                <h6>Reserve</h6>
                <p>Aproveite o menor preço e faça a sua reserva em nosso site</p>
            </div>
        </li>
        <li>
            <div class="box-rounded">
                <i class="icon-win notranslate"></i>
            </div>
            <div class="mt-2">
                <h6>Ganhe <strong class="client-theme-color">{{ moduleData.discount }}%</strong></h6>
                <p>Suas reservas geram créditos para serem consumidos em outras reservas</p>
            </div>
        </li>
        <li>
            <div class="box-rounded">
                <i class="icon-cashback notranslate"></i>
            </div>
            <div class="mt-2">
                <h6>Resgate</h6>
                <p>Use ou compartilhe os seus créditos com amigos e familiares</p>
            </div>
        </li>
        <li>
            <div class="box-rounded">
                <figure>
                    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-accompany-cashback-1,.cls-accompany-cashback-2{fill:none;stroke:#FFF;stroke-miterlimit:10;}.cls-accompany-cashback-2{stroke-linecap:square;}</style></defs><title>ico-area-cliente</title><g id="_5" data-name="5"><circle class="cls-accompany-cashback-1" cx="12.2" cy="12" r="9.5"/><circle class="cls-accompany-cashback-2" cx="12.2" cy="10.5" r="3.5"/><circle class="cls-accompany-cashback-2" cx="12.2" cy="10.5" r="3.5"/><path class="cls-accompany-cashback-1" d="M6.9,17a6.3,6.3,0,0,1,5.3-3,6.1,6.1,0,0,1,5.3,3"/></g></svg>
                </figure>
            </div>
            <div class="mt-2">
                <h6>Acompanhe</h6>
                <p>Através da área do cliente, você acompanha os créditos disponíveis</p>
<!--                <button>ver meus créditos</button>-->
            </div>
        </li>
    </ul>
</section>
