import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogResourcesComponent} from './dialog/dialog.component';

interface Resource {
    name: string;
}
@Component({
    selector: 'my-portal-resources',
    template: `
        <div class="resources" [ngClass]="{'no-mb': mb, 'with-title': title}">
            <div class="resource" [ngClass]="{'larger-font': largerFont}" *ngFor="let resource of resources | slice: 0:showAllResources(); let i = index">
                {{resource?.name}}
            </div>
            <div *ngIf="moreItems && ( resources && resources.length > 5)" class="resource view-more"
             #tooltip="matTooltip"
             matTooltip="Ver mais" (click)="openResouces(resources)">
                ver mais
            </div>
        </div>
    `,
    styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

    @Input() resources: Array<Resource | null> = [];
    @Input() mb = false;
    @Input() quantity: any | null;
    @Input() title: any | null;
    @Input() moreItems: any | null;
    @Input() largerFont: any | null;
    @Input() showAll: any | null;

    constructor(public dialog: MatDialog) {
    }

    ngOnInit(): void {
        if (!this.quantity) {
            this.quantity = 11;
        }
    }

    openResouces(resources: any): void {
        this.dialog.open(DialogResourcesComponent, {
            data: {
                resourcesData: resources
            },
            maxWidth: '760px',
            autoFocus: false,
            width: '90vw'
        });
    }

    showAllResources(): number {
        if (this.showAll) {
            return this.resources.length + 1;
        } else {
            return 5;
        }
    }
}
