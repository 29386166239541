import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainLayoutComponent} from './layout/main-layout/main-layout.component';


const routes: Routes = [{ path: '', component: MainLayoutComponent }, { path: 'shop', loadChildren: () => import('../features/shop/shop.module').then(m => m.ShopModule) }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
