<section *ngIf="dataHotels" class="mt-5 mb-5">
    <my-portal-highlighter
        [module]="moduleData"></my-portal-highlighter>
    <mat-tab-group mat-stretch-tabsBo>
        <ng-template #listDestinationsHotels *ngFor="let destiny of dataHotels?.destinations; let i = index"
                     [ngTemplateOutlet]="listDestinationsHotels">
            <mat-tab [label]="destiny.name | uppercase">
                <div class="container-hotels" [ngClass]="{'two-items': destiny.hotels.length < 3}">
                    <ng-template #listHotels *ngFor="let hotelId of destiny.hotels; let i = index"
                                 [ngTemplateOutlet]="listHotels">
                        <div [ngClass]="{'two-items': destiny.hotels.length < 3, 'flex-row-reverse': i > 0}"
                             class="hotel">
                            <a [routerLink]="['hotel', dataHotels?.hotels[hotelId].slug, hotelId]">
                                <figure>
                                    <div class="cashback" *ngIf="dataHotels?.hotels[hotelId].cashback">
                                        {{ dataHotels?.hotels[hotelId].cashback.percentage + '%' }}
                                        <i class="icon-cashback notranslate"></i>
                                    </div>
                                    <img
                                        [src]="imgServer + 'width=' + 640 + imgServerUrlEnd + dataHotels?.hotels[hotelId]?.image"
                                        class="img-fluid" alt="image do hotel" width="640" height="auto">
                                </figure>
                            </a>

                            <div class="p-3 hotels-content">
                                <div class="content">
                                    <a [routerLink]="['hotel', dataHotels?.hotels[hotelId].slug, hotelId]">
                                        <div class="title-container">
                                            <h6 class="mt-2">{{dataHotels?.hotels[hotelId]?.name}}</h6>
                                            <div class="star" *ngIf="!data?.hide_stars">
                                                <span *ngFor="let st of [0,1,2,3,4]; let ind = index"
                                                      [ngClass]="{'active': (ind+1) <= dataHotels?.hotels[hotelId]?.stars }"
                                                      class="material-icons-outlined">
                                                    star
                                                </span>
                                            </div>
                                            <div class="d-flex align-items-center stars-and-location" *ngIf="!data?.hide_address">
                                                <div class="d-flex align-items-center location">
                                                    <i class="material-icons-outlined notranslate">location_on</i>
                                                    {{ dataHotels?.hotels[hotelId]?.city }}
                                                    , {{ dataHotels?.hotels[hotelId]?.state }}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="description">
                                        <p [innerHTML]="dataHotels?.hotels[hotelId]?.description"></p>
                                    </div>
                                    <my-portal-resources [resources]="dataHotels?.hotels[hotelId]?.amenities"
                                                         [moreItems]="true"></my-portal-resources>
                                </div>
                                <div class="box-prices">
                                    <a [routerLink]="['hotel', dataHotels?.hotels[hotelId].slug, hotelId]">
                                        <button>mais detalhes</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </mat-tab>
        </ng-template>
    </mat-tab-group>
</section>
