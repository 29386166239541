<div class="coupon-wrapper">
    <div (click)="expandCoupon()" class="coupon-container" [ngClass]="{'rounded': roundedSearch, 'mobile-search': isMobile}">
        <div class="coupon-button">
            <figure>
                <img alt="" class="svg-icon" width="20" height="20" src="assets/images/ico-buscador-cupons.svg">
            </figure>
            <label [ngClass]="{'coupon-label': coupon}">
                {{coupon ? coupon : 'Cupom' }}
            </label>
        </div>
        <span translate="no" class="material-icons">{{ expandedCoupon ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
    </div>

    <div [@.disabled]="!isMobile" @slideUpAndDown *ngIf="expandedCoupon" class="form-field-coupon" [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch}">
        <div class="content">
            <span translate="no" class="material-icons arrow">arrow_drop_up</span>

            <div class="back-button-container">
                <button matRipple class="back-button" (click)="handleBackButton()">
                    <span translate="no" class="material-icons-outlined">chevron_left</span>
                </button>
                <div class="selected-local">
                    <span>{{ selectedLocalAndDates?.name }}</span>
                    <span class="date" *ngIf="selectedLocalAndDates?.selectedDates">
                        {{ selectedLocalAndDates?.selectedDates?.checkin | date: 'dd MMM' }} à {{ selectedLocalAndDates?.selectedDates?.checkout | date: 'dd MMM' }}
                    </span>
                    <span class="model-room" *ngIf="selectedLocalAndDates?.room">
                        {{ selectedLocalAndDates?.room?.pax + ' adulto(s)'}}
                        {{ selectedLocalAndDates?.room?.children ? ' - ' + selectedLocalAndDates?.room?.children + ' criança(s)' : ''}}
                    </span>
                </div>
            </div>
            <div class="title">
                <figure>
                    <svg data-name="Camada 1" id="Camada_1" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <style>.cls-1, .cls-2 {
                                fill: currentColor;
                            }

                            .cls-1 {
                                stroke: currentColor;
                                stroke-miterlimit: 10;
                                stroke-width: 0.25px;
                            }</style>
                        </defs>
                        <title>Icones</title>
                        <path class="cls-1"
                              d="M22.2,1.2A3.3,3.3,0,0,0,20.6.5a3.4,3.4,0,0,0-2.7,1h-.1A2.9,2.9,0,0,0,17,3.5,2.5,2.5,0,0,0,17.2,5a1.6,1.6,0,0,0-.3.9,1.6,1.6,0,0,0,.3.9,1.6,1.6,0,0,0,.9.3,1.4,1.4,0,0,0,.9-.3A1.3,1.3,0,0,0,19,5h0a1.6,1.6,0,0,0-1.3-.3,2.5,2.5,0,0,1,.6-2.8h0a2.7,2.7,0,0,1,2.3-.8,2.4,2.4,0,0,1,1.3.5,2.7,2.7,0,0,1,.2,4.1h0a1.7,1.7,0,0,1-1,.6v-2A1.5,1.5,0,0,0,19.7,3H18.5c-.1,0-.3.1-.3.2h0a.3.3,0,0,0,.2.4h1.2a.9.9,0,0,1,.8.8l.6,6.4h0a1.7,1.7,0,0,1-.6,1.4h0l-10,10a1.9,1.9,0,0,1-2.8,0L1.7,16.5a2,2,0,0,1-.6-1.4,2,2,0,0,1,.6-1.5l10-10A2.2,2.2,0,0,1,13.2,3l2.9.3c.2,0,.3-.1.3-.2h0c.1-.2,0-.4-.2-.4l-2.9-.2a2.4,2.4,0,0,0-2,.7L1.2,13.2A2.9,2.9,0,0,0,.5,15a2.9,2.9,0,0,0,.7,1.8l6,6a2.7,2.7,0,0,0,3.6,0l10-10.1a2.4,2.4,0,0,0,.7-2l-.3-3.8a2.9,2.9,0,0,0,1.3-.8A3.2,3.2,0,0,0,22.2,1.2ZM17.5,5.6l.2.3h.4a.3.3,0,0,0,0-.4V5.2l.6.2a.8.8,0,0,1,.2.5c0,.2-.1.3-.2.5h-1a.9.9,0,0,1-.1-.5Z"
                              id="Tag"/>
                        <g data-name="%" id="_">
                            <path class="cls-2"
                                  d="M5.6,12.8a1.7,1.7,0,0,1,0-1,1.4,1.4,0,0,1,.5-1,2.7,2.7,0,0,1,1-.6h1a1.5,1.5,0,0,1,.9.6l.4.3a4.1,4.1,0,0,1,.5.9,1.7,1.7,0,0,1,0,1,2.1,2.1,0,0,1-.6,1l-.9.6h-1a1.5,1.5,0,0,1-.9-.6l-.4-.3A2,2,0,0,1,5.6,12.8Zm2.4.8.6-.3a.9.9,0,0,0,.3-.7,1.1,1.1,0,0,0-.3-.7l-.4-.3-.7-.4a1.1,1.1,0,0,0-.7.3.6.6,0,0,0-.2.7,1.1,1.1,0,0,0,.3.7l.4.3Zm3.9,3.7-2-8.8.9-.2,2,8.7Zm.8-3.9a2.1,2.1,0,0,1,0-1.1,2.4,2.4,0,0,1,.6-.9,2.1,2.1,0,0,1,1-.6,1.7,1.7,0,0,1,1,0l.9.5.3.4a1.8,1.8,0,0,1,.6.9,1.7,1.7,0,0,1,0,1,2.7,2.7,0,0,1-.6,1,2.1,2.1,0,0,1-1,.6h-1a2,2,0,0,1-.9-.5l-.3-.4A1.6,1.6,0,0,1,12.7,13.4Zm2.2.7h.5l.4-.2a1.3,1.3,0,0,0,.3-.7,1,1,0,0,0-.4-.7l-.3-.4a1.1,1.1,0,0,0-.7-.3,1,1,0,0,0-1,1l.4.6.3.4Z"/>
                        </g>
                    </svg>
                </figure>

                <span>Cupom promocional</span>
            </div>
            <div class="input-container">
                <figure>
                    <svg data-name="Camada 1" id="Camada_1" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <style>.cls-1, .cls-2 {
                                fill: currentColor;
                            }

                            .cls-1 {
                                stroke: currentColor;
                                stroke-miterlimit: 10;
                                stroke-width: 0.25px;
                            }</style>
                        </defs>
                        <title>Icones</title>
                        <path class="cls-1"
                              d="M22.2,1.2A3.3,3.3,0,0,0,20.6.5a3.4,3.4,0,0,0-2.7,1h-.1A2.9,2.9,0,0,0,17,3.5,2.5,2.5,0,0,0,17.2,5a1.6,1.6,0,0,0-.3.9,1.6,1.6,0,0,0,.3.9,1.6,1.6,0,0,0,.9.3,1.4,1.4,0,0,0,.9-.3A1.3,1.3,0,0,0,19,5h0a1.6,1.6,0,0,0-1.3-.3,2.5,2.5,0,0,1,.6-2.8h0a2.7,2.7,0,0,1,2.3-.8,2.4,2.4,0,0,1,1.3.5,2.7,2.7,0,0,1,.2,4.1h0a1.7,1.7,0,0,1-1,.6v-2A1.5,1.5,0,0,0,19.7,3H18.5c-.1,0-.3.1-.3.2h0a.3.3,0,0,0,.2.4h1.2a.9.9,0,0,1,.8.8l.6,6.4h0a1.7,1.7,0,0,1-.6,1.4h0l-10,10a1.9,1.9,0,0,1-2.8,0L1.7,16.5a2,2,0,0,1-.6-1.4,2,2,0,0,1,.6-1.5l10-10A2.2,2.2,0,0,1,13.2,3l2.9.3c.2,0,.3-.1.3-.2h0c.1-.2,0-.4-.2-.4l-2.9-.2a2.4,2.4,0,0,0-2,.7L1.2,13.2A2.9,2.9,0,0,0,.5,15a2.9,2.9,0,0,0,.7,1.8l6,6a2.7,2.7,0,0,0,3.6,0l10-10.1a2.4,2.4,0,0,0,.7-2l-.3-3.8a2.9,2.9,0,0,0,1.3-.8A3.2,3.2,0,0,0,22.2,1.2ZM17.5,5.6l.2.3h.4a.3.3,0,0,0,0-.4V5.2l.6.2a.8.8,0,0,1,.2.5c0,.2-.1.3-.2.5h-1a.9.9,0,0,1-.1-.5Z"
                              id="Tag"/>
                        <g data-name="%" id="_">
                            <path class="cls-2"
                                  d="M5.6,12.8a1.7,1.7,0,0,1,0-1,1.4,1.4,0,0,1,.5-1,2.7,2.7,0,0,1,1-.6h1a1.5,1.5,0,0,1,.9.6l.4.3a4.1,4.1,0,0,1,.5.9,1.7,1.7,0,0,1,0,1,2.1,2.1,0,0,1-.6,1l-.9.6h-1a1.5,1.5,0,0,1-.9-.6l-.4-.3A2,2,0,0,1,5.6,12.8Zm2.4.8.6-.3a.9.9,0,0,0,.3-.7,1.1,1.1,0,0,0-.3-.7l-.4-.3-.7-.4a1.1,1.1,0,0,0-.7.3.6.6,0,0,0-.2.7,1.1,1.1,0,0,0,.3.7l.4.3Zm3.9,3.7-2-8.8.9-.2,2,8.7Zm.8-3.9a2.1,2.1,0,0,1,0-1.1,2.4,2.4,0,0,1,.6-.9,2.1,2.1,0,0,1,1-.6,1.7,1.7,0,0,1,1,0l.9.5.3.4a1.8,1.8,0,0,1,.6.9,1.7,1.7,0,0,1,0,1,2.7,2.7,0,0,1-.6,1,2.1,2.1,0,0,1-1,.6h-1a2,2,0,0,1-.9-.5l-.3-.4A1.6,1.6,0,0,1,12.7,13.4Zm2.2.7h.5l.4-.2a1.3,1.3,0,0,0,.3-.7,1,1,0,0,0-.4-.7l-.3-.4a1.1,1.1,0,0,0-.7-.3,1,1,0,0,0-1,1l.4.6.3.4Z"/>
                        </g>
                    </svg>
                </figure>
                <input class="coupon" [(ngModel)]="coupon" placeholder="Digite seu código" type="text">
            </div>
<!--            <button (click)="expandCoupon()">validar</button>-->
        </div>
        <div class="actions-container">
            <button (click)="expandCoupon()" class="close-button" matRipple>fechar</button>
            <button (click)="handleSearch()" class="search" matRipple>pesquisar</button>
        </div>
    </div>
    <div (click)="expandCoupon()" *ngIf="expandedCoupon" class="backdrop"></div>
</div>
