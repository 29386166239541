<div class="row no-gutters accommodation" *ngFor="let accommodation of accommodations; let i = index" [ngClass]="{'flex-row-reverse': i > 0}">
  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
    <figure [ngClass]="{'loading': load }">
      <img
        [src]="imgServer + 'width=' + 555 + imgServerUrlEnd + accommodation.url" width="555" height="438"
        class="img-fluid">
    </figure>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 content-accommodation">
      <h6 [ngClass]="{'loading': load }">{{accommodation.name}}</h6>
      <div [ngClass]="{'my-3': load }" class="default">
        <p [ngClass]="{'loading': load }">{{accommodation.brief_description}}</p>
      </div>
      <my-portal-resources [resources]="accommodation?.amenities" [showAll]="false" [moreItems]="true"></my-portal-resources>
      <!-- <div class="price text-center" *ngIf="!load && accommodation.price">
        <span class="description">Diárias a partir de: </span>
        <span class="value">{{ accommodation.price | currency }}</span>
      </div> -->

      <a [routerLink]="routerAccommodation('/acomodacao', accommodation.slug, accommodation.id, hotelId)">
          <button>mais detalhes</button>
      </a>
  </div>
</div>
