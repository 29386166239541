<section class="mt-5 mb-5" id="destiny-detail">
    <div *ngIf="loading" class="destiny-detail">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                <div class="highlighter">
                    <div class="box-highlighter" id="box-highlighter">
                        <h1 class="hotel-name">{{ destinations?.title }}</h1>
                        <h2>{{ destinations?.subtitle }}</h2>
                        <div class="divider"></div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <div class="slide">
                    <swiper
                        #galleryTop
                        [config]="configSlide"
                        [initialize]="initializer"
                        class="swiper-container gallery-top"
                    >
                        <div class="swiper-wrapper">
                            <ng-template
                                #accommodationSlideImages
                                *ngFor="let image of [0, 1, 2]"
                                [ngTemplateOutlet]="accommodationSlideImages"
                            >
                                <div class="swiper-slide">
                                    <figure>
                                        <img
                                            src="../../../assets/images/no-image.png"
                                            alt=""
                                            class="img-fluid"
                                        />
                                    </figure>
                                </div>
                            </ng-template>
                        </div>
                    </swiper>

                    <swiper
                        #galleryThumbs
                        [config]="configThumbs"
                        [initialize]="initializer"
                        class="swiper-container gallery-thumbs"
                    >
                        <div class="swiper-wrapper">
                            <ng-template
                                #accommodationThumbsImages
                                *ngFor="let image of [0, 1, 2]"
                                [ngTemplateOutlet]="accommodationThumbsImages"
                            >
                                <div class="swiper-slide">
                                    <figure>
                                        <img
                                            src="../../../assets/images/no-image.png"
                                            alt=""
                                            class="img-fluid"
                                        />
                                    </figure>
                                </div>
                            </ng-template>
                        </div>
                    </swiper>
                </div>

                <p class="mt-5">
                    {{ destinations?.description }}
                </p>

                <div class="tourist_spots" *ngIf="destinations?.tourist_spots.length !== 0">
                    <div class="marker">
                        <div class="box-marker">
                            <div class="inner">
                                <h4>Principais pontos turísticos</h4>
                            </div>
                            <div class="divider"></div>
                        </div>
                    </div>

                    <div class="attractions">
                        <swiper [config]="configInterna">
                            <div class="swiper-wrapper">
                                <div
                                    *ngFor="let destination of destinations"
                                    class="swiper-slide"
                                >
                                    <div class="attractions-content mb-2">
                                        <figure
                                            class=""
                                            [ngClass]="{ loading: load }"
                                        >
                                            <img
                                                src="{{ destination.url }}"
                                                class="img-fluid w-100"
                                            />
                                        </figure>
                                        <div class="content">
                                            <h6
                                                class="mt-2"
                                                [ngClass]="{ loading: load }"
                                            >
                                                Elevador Lacerda
                                            </h6>
                                            <div [ngClass]="{ 'my-2': load }">
                                                <p [ngClass]="{ loading: load }">
                                                    {{
                                                        destination.brief_description
                                                    }}
                                                    <a
                                                        [routerLink]="[
                                                            '/acomodacao',
                                                            destination.slug,
                                                            destination.id
                                                        ]"
                                                        class="btn-ver-mais-destinations"
                                                    >
                                                        ver mais...
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="pagination-accommodation"></div>
                        </swiper>
                    </div>
                </div>

                <div class="pass_promotions" *ngIf="destinations.pass_promotions !== null">
                    <div class="marker">
                        <div class="box-marker">
                            <div
                                class="inner d-flex justify-content-between align-items-center"
                            >
                                <h4>Promoções disponíveis</h4>
                            </div>
                            <div class="divider"></div>
                        </div>
                    </div>

                    <mat-tab-group mat-stretch-tabsBo class="mt-3 mb-3">
                        <mat-tab label="BARES E RESTAURANTES">
                            <div class="row no-gutters mt-5">
                                <div class="col-lg-12">
                                    <div class="available-promotion-list">
                                        <!-- <ng-template class="mt-3 mb-3" #listSupplement *ngFor="let supplement of destinations"
                                            [ngTemplateOutlet]="listSupplement">
                                    <div class="mt-5 mb-5">
                                        <div class="promotion">
                                            <div class="grid">
                                                <figure class="d-flex justify-content-center align-items-center p-3">
                                                    <img src="./../../assets/images/no-image.png" alt="" class="img-fluid">
                                                </figure>
                                                <div class="content-promotion p-3">
                                                    <h6>Elevador Larceda</h6>
                                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit, cupiditate aspernatur in asperiores ad expedita fugiat a cum quasi veniam, eveniet incidunt enim voluptatem voluptatum distinctio sed.</p>
                                                </div>
                                                <div class="discount d-flex justify-content-center align-items-center flex-column p-3">
                                                    <i class="material-icons-outlined notranslate">star</i>
                                                    <span class="d-block font-weight-bold">25%</span>
                                                    <span class="d-block font-weight-bold">OFF</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="promotion-info mt-1">
                                            <div class="d-flex justify-content-between">
                                                <div class="content-time d-flex align-items-center">
                                                    <div class="icon d-flex justify-content-center align-items-center">
                                                        <i class="material-icons-outlined notranslate">schedule</i>
                                                    </div>
                                                    <p class="m-0">Aberto - fechar às 23:00</p>
                                                </div>
                                                <div class="content">
                                                    <ul class="list-unstyled d-flex align-items-center p-0 m-0">
                                                        <li *ngFor="let item of [0,1,2]" class="d-flex justify-content-center align-items-center">
                                                            <i class="material-icons-outlined notranslate">location_on</i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template> -->
                                    </div>
                                </div>
                            </div>
                        </mat-tab>

                        <mat-tab label="SERVIÇOS EM GERAL">
                            <div class="row no-gutters mt-5">
                                <div class="col-lg-12">Mais info aqui</div>
                            </div>
                        </mat-tab>

                        <mat-tab label="PARQUES TEMÁTICOS">
                            <div class="row no-gutters mt-5">
                                <div class="col-lg-12">Mais info aqui</div>
                            </div>
                        </mat-tab>

                        <mat-tab label="SHOWS E EVENTOS">
                            <div class="row no-gutters mt-5">
                                <div class="col-lg-12">Mais info aqui</div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>

            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" *ngIf="destinations?.map">
                <div class="map mb-5" *ngIf="destinations?.map">
                    <iframe
                        loading="lazy"
                        [src]="safeMap"
                        allowfullscreen=""
                        aria-hidden="false"
                        frameborder="0"
                        height="400"
                        style="border: 0"
                        tabindex="0"
                        width="100%"
                    ></iframe>
                </div>
            </div>
        </div>

        <div class="row"></div>
    </div>

    <div *ngIf="!loading && !tours" class="">
        <div class="alert alert-danger text-center" role="alert">
            Houve um erro inesperado, por favor, refaça a sua pesquisa.
        </div>
    </div>
</section>
