import { MatDialog } from '@angular/material/dialog';
import { DialogErrorComponent } from './../../../../../shared/dialog-error/dialog-error.component';
import { DocumentService } from './../../../../../services/document.service';
import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'my-portal-search-type-rounded',
  templateUrl: './search-type-rounded.component.html',
  styleUrls: ['./search-type-rounded.component.scss']
})
export class SearchTypeRoundedComponent implements OnInit {
  @Output() local: any = new EventEmitter();
  @Output() dates: any = new EventEmitter();
  @Output() acomodationType: any = new EventEmitter();
  @Output() guests: any = new EventEmitter();
  @Output() coupon: any = new EventEmitter();

  @Input() searchType: any;
  @Input() dataHeader: any;
  typeSearch: any = this.documentService.getWindow()?.sessionStorage.getItem('typeSearch');
  clearAccommodation: any;
  eventAccommodation: any = false;

  destination: any;

  isMobile: boolean = this.initializerService.isMobileDevice();

  constructor(
    private documentService: DocumentService,
    private initializerService: InitializerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  changeLocal(event: any): void {
    this.local.emit(event);
    this.destination = event;
  }

  changeDates(event: any): void {
    this.dates.emit(event);
  }

  changeAcomodation(event: any): void {
    this.acomodationType.emit(event);
    this.eventAccommodation = event;
  }

  changeGuests(event: any): void {
    this.guests.emit(event);
  }

  changeCoupon(event: any): void {
    this.coupon.emit(event);
  }

  openPanel(event: any): void {
    if (event) {
        setTimeout(() => {
            this.initializerService.changeControlFormSearch({
                type: 'local'
            });
        }, 150);
    }
  }

  openDialog(message: string): void {
    this.dialog.open(DialogErrorComponent, {
        data: {
            error: message,
        },
        minWidth: '250px',
        autoFocus: false,
    });
  }

  onSearch(): void {
    if (!this.eventAccommodation && this.dataHeader.accommodation_type == 3) {
      this.openDialog('Selecione o tipo de hospedagem');
      return
    } else {
      setTimeout(() => {
        this.initializerService.changeControlFormSearch({
            type: 'search',
            clear: true
        });
        this.clearAccommodation = this.eventAccommodation;
      }, 100);
    }
  }
}
