<section class="mt-5 mb-5 tours" *ngIf="tours?.length">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
        </div>
    </div>

    <div class="container-tours">
        <ng-template #listTours [ngTemplateOutlet]="listTours" *ngFor="let tour of tours">
            <div>
                <figure>
                    <img [src]="ImgSrc + tour.images[0].thumb" class="img-fluid">
                </figure>
                <div class="box">
                    <h6>{{tour.name}}</h6>
                    <p *ngIf="tour.brief_description">{{tour.brief_description}}</p>
                    <div class="prices">
                        <div class="value">
                            A partir de: <br>
                            <span>{{tour.lower_price | currency}}</span>
                        </div>
                        <a [href]="dataOtas.url + '/passeio/' + tour.tour_id + '/' + tour.slug" target="_blank">
                            <button>reservar</button>
                        </a>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

</section>
