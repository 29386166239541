import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';
import {DialogErrorComponent} from '../dialog-error/dialog-error.component';
import {DialogMessageCancelationComponent} from './message/message.component';
import {MatDialog} from '@angular/material/dialog';
import {InitializerService} from '../../services/initializer.service';

@Component({
    selector: 'my-portal-tariffs-cancelation',
    template: `
        <div class="cancelation" [ngClass]="{'restricted-area': restrictedArea}" *ngIf="cancelation?.info">
            {{ cancelation?.info }}
            <button *ngIf="cancelation?.description" (click)="openMessage(cancelation?.description)">
                <span translate="no" class="material-icons">info</span>
            </button>
        </div>
    `,
    styleUrls: ['./cancelation.component.scss']
})
export class CancelationComponent implements OnInit {

    @Input() cancelation: any;
    @Input() restrictedArea: any;
    @Input() checkIn?: any;

    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    constructor(private activatedRouter: ActivatedRoute,
                public dialog: MatDialog,
                private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.cancelation.info = 'Política de cancelamento';
    } 

    cancellationMessage(cancellationDate: any): string | null {
        let cancellation = null;

        if ( this.getCancellationDateIsSmall(cancellationDate.advance_days) <= cancellationDate.advance_days ) {
            cancellation = 'Política de cancelamento';
        }

        switch (cancellationDate.penalty.id) {
            case 1:
                if (cancellationDate.advance_days) {
                    if ( this.getCancellationDateIsSmall(cancellationDate.advance_days) >= cancellationDate.advance_days ) {
                        cancellation = this.getCancellationDate(cancellationDate.advance_days);
                    } else {
                        cancellation = 'Tarifa não reembolsável';
                    }
                } else {
                    cancellation = `Cancele quando quiser`;
                }
                break;
            case 2:
                cancellation = 'Política de cancelamento'
                break;
            case 3:
                cancellation = 'Tarifa não reembolsável';
                break;
        }
        return cancellation;
    }

    getCancellationDate(advanceDays: any): string {
        const checkIn = this.checkIn ?? this.activatedRouter.snapshot.params.checkin;
        const dateFormatted = moment(checkIn).subtract(advanceDays, 'days').format('DD/MM/YYYY');
        return `Cancelamento gratuito até ${dateFormatted}`;
    }

    getCancellationDateIsSmall(advanceDays: any): number {
        // const difference = moment(this.activatedRouter.snapshot.params.checkin).subtract(advanceDays, 'days');
        const dateNow = moment();
        const checkIn = this.checkIn ?? this.activatedRouter.snapshot.params.checkin;

        return moment(checkIn).diff(moment(dateNow), 'days');
    }

    openMessage(description: string): void {
        this.dialog.open(DialogMessageCancelationComponent, {
            data: {
                message: description,
                title: 'Política de cancelamento'
            },
            autoFocus: false,
            maxWidth: (this.getScreenWidth) ? '80vw' : '500px',
            maxHeight: (this.getScreenWidth) ? '80vh' : 'auto',
        });
    }
}
