<section class="pt-5 pb-5 payments" id="payments">
    <div class="container">
        <div class="row title_section no-gutters">
            <h6>Pagamento</h6>
            <div class="divider"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-12 mt-5 cards">
                <div class="card-content credits-released rounded" >
                    <div class="icon-container">
                        <div class="content">
                            <i class="material-icons-outlined notranslate">monetization_on</i>
                        </div>
                    </div>
                    <p class="payment-type">Faturado</p>
                    <div class="divider"></div>
                    <p class="payment-type"
                        [ngClass]="{'released': dataPayment?.invoice?.accepted, 'not-released': !dataPayment?.invoice?.accepted}">
                        {{dataPayment?.invoice?.accepted ? 'Liberado' : "Não liberado"}}
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 cards">
                <div class="card-content credits-release rounded" >
                    <div class="icon-container">
                        <div class="content">
                            <i class="material-icons-outlined notranslate">payments</i>
                        </div>
                    </div>
                    <p class="payment-type">Cartão de crédito</p>
                    <div class="divider"></div>
                    <p class="payment-type"
                        [ngClass]="{'released': dataPayment?.credit_card.accepted, 'not-released': !dataPayment?.credit_card.accepted}">
                        {{dataPayment?.credit_card.accepted ? 'Liberado' : "Não liberado"}}
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 cards">
                <div class="card-content credits-used rounded" >
                    <div class="icon-container">
                        <div class="content">
                            <i class="material-icons-outlined notranslate">pix</i>
                        </div>
                    </div>
                    <p class="payment-type">Pix</p>
                    <div class="divider"></div>
                    <p class="payment-type"
                        [ngClass]="{'released': dataPayment?.pix.accepted, 'not-released': !dataPayment?.pix.accepted}">
                        {{dataPayment?.pix.accepted ? 'Liberado' : "Não liberado"}}
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 cards">
                <div class="card-content credits-expired rounded" >
                    <div class="icon-container">
                        <div class="content">
                            <i class="material-icons-outlined notranslate">account_balance</i>
                        </div>
                    </div>
                    <p class="payment-type">Depósito bancário</p>
                    <div class="divider"></div>
                    <p class="payment-type"
                        [ngClass]="{
                            'released': dataPayment?.bank_deposit.accepted,
                            'not-released': !dataPayment?.bank_deposit.accepted}">
                        {{dataPayment?.bank_deposit.accepted ? 'Liberado' : "Não liberado"}}
                    </p>
                </div>
            </div>
        </div>

        <h5 *ngIf="dataPayment?.invoice?.accepted" class="title">Faturado</h5>

        <div class="row" *ngIf="dataPayment?.invoice?.accepted">
            <div class="col-lg-3 col-12 cards">
                <div class="card-content credits-released rounded" >
                    <p class="payment-type">Ciclo de faturamento</p>
                    <div class="divider"></div>
                    <p class="payment-type cycle">{{dataPayment?.invoice?.data.cycle}} dias</p>
                    <p class="comment">Renova em {{dataPayment?.invoice?.data.cycle}} de junho de 2022</p>
                </div>
            </div>

            <div class="col-lg-3 col-12 cards">
                <div class="card-content credits-release rounded" >
                    <p class="payment-type">Limite liberado</p>
                    <div class="divider"></div>
                    <p class="payment-type released">{{dataPayment?.invoice?.data.released | currency}}</p>
                </div>
            </div>

            <div class="col-lg-3 col-12 cards">
                <div class="card-content credits-used rounded" >
                    <p class="payment-type">Utilizados</p>
                    <div class="divider"></div>
                    <p class="payment-type not-released">{{dataPayment?.invoice?.data.used | currency}}</p>
                </div>
            </div>

            <div class="col-lg-3 col-12 cards">
                <div class="card-content credits-expired rounded" >
                    <p class="payment-type">Saldo</p>
                    <div class="divider"></div>
                    <p class="payment-type not-released">{{dataPayment?.invoice?.data.balance | currency}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
