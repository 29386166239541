import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import { InitializerService } from './../../services/initializer.service';

@Component({
    selector: 'my-portal-translate',
    templateUrl: './translate.component.html',
    styleUrls: ['./translate.component.scss'],
})
export class TranslateComponent implements OnInit, AfterViewInit {

    @Input() dataHeader: any;
    @Input() whiteBackground = false;
    @Input() dataColor: any;
    languageIsPt: any;
    wordOriginal: boolean = false;
    wordIdioma: boolean = false;
    initialLanguage: any;

    getScreenWidth: boolean = this.initializerService.isMobileDevice();

    constructor(private elementRef: ElementRef,
                private initializerService: InitializerService) {}

    ngOnInit(): void {
        const tagBody: HTMLElement | any = document.querySelector('html');
        this.initialLanguage = tagBody?.getAttribute('lang');

        const observer = new MutationObserver((mutation : any) => {
            if(mutation){
                this.verifyLanguage(mutation[mutation.length -1].target.lang);
            }
            
        });

        observer.observe(tagBody, {attributes: true})
    }

    verifyLanguage(event: any){
        var select: any = document.getElementById("selectTranslate");

        if(event?.target?.value){
            this.wordIdioma = false;
            this.wordOriginal = true;

            if(select) {
                select = event.target.value
            }
            
        } else {

            if(event == 'pt-Br') {
                this.wordIdioma = true;
                this.wordOriginal = false;

                if(select) {
                    select.value = event;

                    if(select.value == ''){
                        select.value = 'pt-Br'
                        this.wordIdioma = false;
                        this.wordOriginal = true;
                    }
                }

            } else if (event?.target?.value == '') {
                this.wordIdioma = true;
                this.wordOriginal = false;

            } else if (event !== 'pt-br' && event !== 'pt') {
                select.value = event;
                this.wordIdioma = false;
                this.wordOriginal = true;
            }
        }
    }

    ngAfterViewInit(): void {
        const scriptTagContent: HTMLScriptElement | any = document.createElement('script');
        scriptTagContent.type = 'text/javascript';
        scriptTagContent.innerHTML =
            `
            var googleTradutor = null;

            function googleTranslateElementInit() {
                new google.translate.TranslateElement({
                    pageLanguage: 'pt-Br',
                    includedLanguages: 'en,es,it,de,fr',
                    layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL
                }, 'google_translate_element');
        
                googleTradutor = document.getElementById("google_translate_element").querySelector(".goog-te-combo");
            }
        
            function changeEvent(el) {
                if (el.fireEvent) {
                    el.fireEvent('onchange');
                } else {
                    var evObj = document.createEvent("HTMLEvents");
        
                    evObj.initEvent("change", false, true);
                    el.dispatchEvent(evObj);
                }
            }

            function translateLanguage() {
                let select = document.querySelector('#selectTranslate');
                let optionValue = select.options[select.selectedIndex].value;
                
                let valorSelect = optionValue;

            }
        
            function changeLanguage(sigla) {
                if (googleTradutor) {
                    googleTradutor.value = sigla;
                    changeEvent(googleTradutor);
                }

                let select = document.querySelector('#selectTranslate');

                if (select) {

                    let optionValue = select.options[select.selectedIndex].value;

                    let valorSelect = optionValue;

                    if (valorSelect === '') {
                        clearTranslate();
                    }
                    
                    if (valorSelect) {
                        googleTradutor.value = valorSelect;
                        changeEvent(googleTradutor);
                    }
                }                

                
            }

            function clearTranslate() {
                for(let abc = 0; abc < 1; abc++) {
                  var iframe = document.getElementsByClassName('VIpgJd-ZVi9od-ORHb-OEVmcd')[abc];
                  if(!iframe) return;
  
                  var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
                  var restore_el = innerDoc.getElementsByTagName("button");
  
                  for(var i = 0; i < restore_el.length; i++){
                    if(restore_el[i].id.indexOf("restore") >= 0) {
                      restore_el[i].click();
                      var close_el = innerDoc.getElementsByClassName("VIpgJd-ZVi9od-TvD9Pc-hSRGPd");
                      close_el[0].click();
                      return;
                    }
                  }
  
                  continue
                }
              }
            `;
            
        this.elementRef.nativeElement.appendChild(scriptTagContent);

        const scriptTagSource: HTMLScriptElement | any = document.createElement('script');
        scriptTagSource.type = 'text/javascript';
        scriptTagSource.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        this.elementRef.nativeElement.appendChild(scriptTagSource);
        

        const styleTag: HTMLElement = document.createElement('style');
        
        if(this.whiteBackground) {
            styleTag.innerHTML =
            `#google_translate_element{
                font-family:Rubik,sans-serif!important;
            }
            .goog-te-gadget{
                font-size:0!important;
                display:flex;
                align-items:center;
            }
            .goog-te-gadget span{
                display:none;
            }
            .goog-te-gadget select{
                display:flex;
                justify-content:center;
                text-overflow:ellipsis;
                background-color:transparent;
                border:none;
                color: #000;
                font-size: 1rem;
                overflow: hidden;
                height: 30px;
            }
            .goog-te-gadget select option{
                background:#fff;
                color:#484848;
            }
            
            .goog-te-banner-frame.skiptranslate{
                display:none!important;
            }

            .skiptranslate {
                display: none;
            }

            body{
                top:0!important;
            }`;

        this.elementRef.nativeElement.appendChild(styleTag);


        } else {
            styleTag.innerHTML =
            `#google_translate_element{
                padding:0 0.5rem;
                font-family:Rubik,sans-serif!important;
                text-transform:uppercase;
            }
            .goog-te-gadget{
                font-size:0!important;
                display:flex;
                align-items:center;
            }
            .goog-te-gadget span{
                display:none;
            }
            .goog-te-gadget select{
                display:flex;
                justify-content:center;
                text-overflow:ellipsis;
                max-width:70px;
                height:24px;
                background-color:transparent;
                border:none;
                color: #FFF;
                text-transform:uppercase;
                font-size: 0.65rem;
                padding:.25rem
            }
            .goog-te-gadget select option{
                background:#fff;
                color:#484848;
            }
            .goog-text-highlight{
                background-color: transparent !important;
                box-shadow: none;
            }
            .goog-te-banner-frame.skiptranslate{
                display:none!important;
            }
            .skiptranslate {
                display: none;
             }
            body{
                top:0!important;
            }`;

            this.elementRef.nativeElement.appendChild(styleTag);
        }

    }

}
