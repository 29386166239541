import {Component, Input, OnInit} from '@angular/core';
import {SwiperOptions} from 'swiper';
import { InitializerService } from '../../../services/initializer.service';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'my-portal-slide-detail',
    templateUrl: './slide-detail.component.html',
    styleUrls: ['./slide-detail.component.scss']
})
export class SlideDetailComponent implements OnInit {

    @Input() images: any;

    configInterna: SwiperOptions = {
        navigation: {
            nextEl: '.swiper-button-next-cart',
            prevEl: '.swiper-button-prev-cart'
        },
        slidesPerView: 3,
        autoHeight: true,
        autoplay: true,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                centeredSlides: true
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                centeredSlides: true,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 1,
            }
        }
    };

    detail: any = null;
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
    }

}
