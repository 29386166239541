import { Component, Input, OnInit } from '@angular/core';
import { InitializerService } from '../../services/initializer.service';

@Component({
    selector: 'my-portal-highlighter',
    template: `
        <div
            class="hightlighter"
            [ngClass]="{
                'width-full': module?.widthFull,
                'without-pb': pb,
                'session-marker': session_markers === 2
            }">
            <div class="title-content" [ngClass]="{ reserve: reserve }">
                <h1 [ngClass]="{ 'color-client': module?.subtitle }">
                    {{ module?.title }}
                </h1>
                <div *ngIf="module?.tooltip" class="tooltip-content">
                    <i class="material-icons notranslate">info</i>
                </div>
                <div *ngIf="reserve" class="reserve">Pedido: {{ reserve }}</div>
            </div>
            <h2 *ngIf="module?.description && session_markers !== 2">{{ module?.description }}</h2>
            <div class="divider"></div>
        </div>
    `,
    styleUrls: ['./highlighter.component.scss'],
})
export class HighlighterComponent implements OnInit {
    @Input() module: any;
    @Input() pb: any = false;
    @Input() reserve: any;

    session_markers: any;

    constructor(private initializerService: InitializerService) {}

    ngOnInit(): void {
        this.initializerService.currentData.subscribe((data: any) => {
            this.session_markers = data.session_markers;
        });
    }
}
