import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DefaultSnackBarComponent } from '../default-snack-bar/default-snack-bar.component';
import { InitializerService } from '../../services/initializer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentService } from '../../services/document.service';
import { CartService } from '../../services/cart.service';

@Component({
    selector: 'my-portal-dialog-confirm-responsible',
    templateUrl: './dialog-confirm-responsible.component.html',
    styleUrls: ['./dialog-confirm-responsible.component.scss'],
})
export class DialogConfirmResponsibleComponent implements OnInit {
    public responsibleForm!: FormGroup;
    public readonly ng2TelInputOptions = {
        initialCountry: 'br',
        preferredCountries: ['BR', 'UY', 'AR', 'PY', 'BO', 'PE', 'CO', 'VE', 'GY', 'SR', 'GF'],
    };
    public phone: any = { sigla: 'br', ddi: '55' };
    public getScreenWidth: boolean = this.initializerService.isMobileDevice();

    private items: any;
    public cpfErrorMessage: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogConfirmResponsibleComponent>,
        private formBuilder: FormBuilder,
        private initializerService: InitializerService,
        private snackbar: MatSnackBar,
        private documentService: DocumentService,
        private cartService: CartService
    ) {}

    ngOnInit(): void {
        this.items = this.data.items;
        this.initResponsibleForm();
    }

    private initResponsibleForm(): void {
        const hotelKey = Object.keys(this.data.items.hotels)[0];
        const firstEstablishment = this.data.items.hotels[hotelKey];
        const responsible = this.data.budgetData || firstEstablishment?.responsavel;

        this.responsibleForm = this.formBuilder.group({
            nome: new FormControl(responsible ? responsible.nome : null, [Validators.required]),
            sobrenome: new FormControl(responsible ? responsible.sobrenome : null, [Validators.required]),
            data_nascimento: new FormControl(responsible ? responsible.data_nascimento : null),
            telefone: new FormControl(responsible ? responsible.telefone : null, [Validators.required]),
            outro_contato: new FormControl(responsible ? responsible.outro_contato : null),
            email: new FormControl(responsible ? responsible.email : null, [Validators.required, Validators.email]),
            confirme_email: new FormControl(responsible ? responsible.confirme_email : null),
            nacionalidade: new FormControl(responsible ? responsible.nacionalidade : null),
            documento: new FormControl(responsible ? responsible.documento : null, [Validators.required]),
            cpf_passaporte: new FormControl({ value: responsible ? responsible.cpf_passaporte : null, disabled: true }, [
                Validators.required,
            ]),
        });
    }

    public onHandleSubmit(): void {
        if (this.responsibleForm.invalid) {
            this.openSnackBar('Preencha todos os campos obrigatórios', true);
            return;
        }

        const form = this.responsibleForm.value;

        for (const hotel of Object.keys(this.items.hotels)) {
            this.items.hotels[hotel].responsavel = {
                ...form,
                telefone: '+' + this.phone.ddi + form.telefone,
            };
        }

        this.updateCart();

        this.dialogRef.close(this.items);
    }

    public onCountryChange(country: any) {
        this.phone.sigla = country.iso2;
        this.phone.ddi = country.dialCode;
    }

    public confirmDialog(): void {
        this.dialogRef.close('confirm');
    }

    private updateCart(): void {
        this.cartService.changeDataCartItem(this.items);
        this.documentService.getWindow()?.localStorage.setItem('cart', JSON.stringify(this.items));
        this.cartService.updateDataCart(this.items).subscribe();
    }

    public selectTypeDocument(): void {
        if (this.responsibleForm.value.documento) {
            this.responsibleForm.controls.cpf_passaporte.patchValue('');
            this.responsibleForm.controls.cpf_passaporte.enable();


            if (this.responsibleForm.value.documento == 1) {
                this.responsibleForm.get('cpf_passaporte')?.setValidators(this.cpfValidator());
                this.cpfErrorMessage = 'Digite um CPF válido';
            } else {
                this.responsibleForm.get('cpf_passaporte')?.setValidators(Validators.required);
                this.cpfErrorMessage = 'Este campo é obrigatório';
            }
        }
    }

    private openSnackBar(msg: string, flag?: boolean | undefined): void {
        this.snackbar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg,
            },
            horizontalPosition: 'end',
            verticalPosition: this.getScreenWidth ? 'top' : 'bottom',
            panelClass: flag ? 'error-snack-bar' : 'success-snack-bar',
            duration: 5000,
        });
    }

    private cpfValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const cpf = control.value;
            const cpfValidationErrorMessage = { cpfInvalido: true, mensagem: 'Digite um CPF válido' };

            if (!cpf || cpf.length !== 11) {
                return cpfValidationErrorMessage;
            }

            if (/^(\d)\1+$/.test(cpf)) {
                return cpfValidationErrorMessage;
            }

            let sum = 0;
            for (let i = 0; i < 9; i++) {
                sum += parseInt(cpf.charAt(i)) * (10 - i);
            }

            let rest = 11 - (sum % 11);
            const verificationDigit1 = rest >= 10 ? 0 : rest;

            if (verificationDigit1 !== parseInt(cpf.charAt(9))) {
                return cpfValidationErrorMessage;
            }

            sum = 0;
            for (let i = 0; i < 10; i++) {
                sum += parseInt(cpf.charAt(i)) * (11 - i);
            }

            rest = 11 - (sum % 11);
            const verificationDigit2 = rest >= 10 ? 0 : rest;

            if (verificationDigit2 !== parseInt(cpf.charAt(10))) {
                return cpfValidationErrorMessage;
            }

            return null;
        };
    }
}
