import {Component, Input, OnInit} from '@angular/core';
import { SwiperOptions } from 'swiper';
import { InitializerService } from '../../services/initializer.service';

@Component({
  selector: 'my-portal-testimony',
  templateUrl: './testimony.component.html',
  styleUrls: ['./testimony.component.scss']
})
export class TestimonyComponent implements OnInit {

  @Input() testimony: any;
  @Input() load: any;
  @Input() slide: any = false;
  showDescriptionTestimony: any = {};
  getScreenWidth: boolean = this.initializerService.isMobileDevice();

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    spaceBetween: 20,
    slidesPerView: 2,
    autoplay: true,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 20
      }
    }
  };

  constructor(private initializerService: InitializerService) { }

  ngOnInit(): void {
    for (let testimony of this.testimony) {
      this.showDescriptionTestimony[testimony.id] = false;
    }
  }

}
