import {DocumentService} from 'projects/new-portal/src/app/services/document.service';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AppDataService} from '../../services/app-data.service';

@Component({
    selector: 'my-portal-restricted-area-client',
    templateUrl: './restricted-area-client.component.html',
    styleUrls: ['./restricted-area-client.component.scss'],
})
export class RestrictedAreaClientComponent implements OnInit {
    dataReserves: any;
    dataFidelity: any;
    dataPayment: any;

    filterSelect: FormGroup;
    userData: any = JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '');


    constructor(
        private appData: AppDataService,
        private formBuilder: FormBuilder,
        private documentService: DocumentService
    ) {

        this.filterSelect = this.formBuilder.group({
            filter: [''],
        });
    }

    ngOnInit(): void {
        this.appData.getRestrictedAreaReserves(this.userData?.type).subscribe((response: any) => {
                this.dataReserves = response.data.data;
            }
        );

        this.appData.getRestrictedAreaFidelity().subscribe((response: any) => {
                this.dataFidelity = response.data;
            }
        );

        this.appData.getRestrictedAreaPayments().subscribe((response: any) => {
                this.dataPayment = response.data;
            }
        );
    }

    onChangeFilter(form: any): void {
        alert(form);
    }
}
