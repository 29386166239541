<div [ngClass]="{ 'model-2' : dataHeader?.header?.type > 1 && !position }"
    class="search-form" id="search" >

    <my-portal-search-mobile
        (guests)="changeGuests($event)"
        (dates)="changeDates($event)"
        (destiny)="changeDestination($event)"
        (coupon)="couponEvent($event)"
        [dataHeader]="dataHeader"
        [typeSearch]="typeSearch"
        *ngIf="(getScreenWidth && position) && dataHeader.search_engine">
    </my-portal-search-mobile>
    <div class="container" *ngIf="!getScreenWidth || (getScreenWidth && !position)">  
        <div *ngIf="dataHeader?.banner_map && !dataHeader.header.mobile_banner" class="mobile-search">
            <iframe loading="lazy" [src]="dataHeader?.banner_map + '?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&autoplay=1&loop=1&controls=0' | sanitize" frameborder="0" title="Vídeo mobile" width="100%" height="315" aria-hidden="false" tabindex="0"></iframe>
        </div>

        <div class="mobile-search">
            <swiper [config]="config">
                <div class="swiper-wrapper">
                    <figure *ngFor="let item of dataHeader.header.mobile_banner" class="swiper-slide">
                        <a [href]="item.link" *ngIf="item.link" class="redirect-link" target="_blank">
                            <img
                                [src]="imgServer + 'width=' + 768 + imgServerUrlEnd + item.img"
                                alt="logo">
                        </a>
                        <ng-container *ngIf="!item.link">
                            <img
                                [src]="imgServer + 'width=' + 768 + imgServerUrlEnd + item.img"
                                alt="logo">                               
                        </ng-container>
                        </figure>
                </div>
                <div class="swiper-button-next-slide transparent">
                    <mat-icon>east</mat-icon>
                </div>
                <div class="swiper-button-prev-slide transparent">
                    <mat-icon>west</mat-icon>
                </div>
            </swiper>
        </div>

        <!-- <ul *ngIf="dataHeader.header.searchType === 2">
            <li *ngIf="typeSearch == 6">
                <my-portal-search-local (destination)="changeDestination($event)"></my-portal-search-local>
            </li>
            <li>
                <my-portal-datepicker (dates)="changeDates($event)" (panel)="openPanel($event)" [destination]="destination"></my-portal-datepicker>
            </li>
            <li>
                <my-portal-search-guests (guests)="changeGuests($event)" [dataHeader]="dataHeader"></my-portal-search-guests>
            </li>
            <li>
                <my-portal-search-cupom (open)="handleSearchCoupon($event)" (couponData)="couponEvent($event)"></my-portal-search-cupom>
            </li>
            <li>
                <div class="form-field">
                    <button (click)="search()" class="search">Pesquisar</button>
                </div>
            </li>
        </ul> -->

        <my-portal-search-type-rounded
            *ngIf="dataHeader.header.searchType === 1 && dataHeader.search_engine"
            [dataHeader]="dataHeader"
            [searchType]="typeSearch"
            (dates)="changeDates($event)"
            (guests)="changeGuests($event)"
            (acomodationType)="changeAcomodation($event)"
            (local)="changeDestination($event)"
            (coupon)="couponEvent($event)"
        ></my-portal-search-type-rounded>

        <my-portal-search-type-line
            *ngIf="dataHeader.header.searchType === 2 && dataHeader.search_engine"
            [dataHeader]="dataHeader"
            [searchType]="typeSearch"
            (dates)="changeDates($event)"
            (guests)="changeGuests($event)"
            (acomodationType)="changeAcomodation($event)"
            (local)="changeDestination($event)"
            (coupon)="couponEvent($event)"
        ></my-portal-search-type-line>

        <my-portal-search-type-squared
            *ngIf="dataHeader.header.searchType === 3 && dataHeader.search_engine"
            [dataHeader]="dataHeader"
            [searchType]="typeSearch"
            (dates)="changeDates($event)"
            (guests)="changeGuests($event)"
            (local)="changeDestination($event)"
            (acomodationType)="changeAcomodation($event)"
            (coupon)="couponEvent($event)"
        ></my-portal-search-type-squared>
    </div>
</div>
