import { Component, OnInit, Input } from '@angular/core';
import { AppDataService } from '../../../../services/app-data.service';
import { SwiperOptions } from 'swiper';
import { environment } from './../../../../../environments/environment';
import { InitializerService } from './../../../../services/initializer.service';

@Component({
  selector: 'my-portal-packages-header',
  templateUrl: './packages-header.component.html',
  styleUrls: ['./packages-header.component.scss']
})
export class PackagesHeaderComponent implements OnInit {

    @Input() dataHeader: any;
    @Input() personId: any | null;
    packagesHeader: any;
    loading: boolean = true;
    hidden: boolean = true;
    @Input() hotelId: any;

    configInterna: SwiperOptions = {
        slidesPerView: 1,
        autoHeight: true,
        spaceBetween: 10,
        navigation: {
            prevEl: '.swiper-button-prev-cart',
            nextEl: '.swiper-button-next-cart'
        }
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;




    constructor(private appService: AppDataService,
                private initializerService: InitializerService) { }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.appService.getDataModule('promotion', this.personId)
            .subscribe((response: any) => {
                this.packagesHeader = response.data;
                this.loading = false;
            });

    }

    hiddenComponent(): void {
        this.hidden = false;
    }


}
