import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'my-portal-marker',
    templateUrl: './marker.component.html',
    styleUrls: ['./marker.component.scss']
})
export class MarkerComponent implements OnInit {

    @Input() name: any;
    @Input() max: any;
    @Input() bedroomArea: any;

    constructor() {
    }

    ngOnInit(): void {
    }

}
