<div class="search-mobile">
    <div class="search-mobile-container">
        <div class="forms-content">
            <div class="input-container" *ngIf="typeSearch >= 6">
                <my-portal-search-local (destination)="changeDestination($event)" [roundedSearch]="true" [squaredSearch]="true" [mobileSearch]="true"></my-portal-search-local>
            </div>
            <div class="input-container" (click)="openDatepicker()">
                <my-portal-datepicker [destination]="destination" (dates)="changeDates($event)" [mobileSearch]="true"></my-portal-datepicker>
            </div>
            <div class="input-container">
                <my-portal-search-guests (acomodationType)="changeAcomodation($event)" (guests)="changeGuests($event)" [mobileSearch]="true" [dataHeader]="dataHeader"></my-portal-search-guests>
            </div>
            <div class="input-container" *ngIf="!dataHeader.hide_coupon">
                <my-portal-search-cupom (open)="handleSearchCoupon($event)" (couponData)="couponEvent($event)"></my-portal-search-cupom>
            </div>
        </div>
        <div class="search-button">
            <button (click)="onSearch()">
                <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                </figure>
            </button>
        </div>
    </div>
</div>
