<div mat-dialog-content>
    <p>Digite o e-mail e o nome de quem irá receber o cupom.</p>
    <form (ngSubmit)="onSubmit(couponForm)" [formGroup]="couponForm" class="form-container">
      <mat-form-field appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput name="name" formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput name="mail" type="email" formControlName="mail">
      </mat-form-field>
    </form>
  </div>
  
  
  <div class="success-coupon" *ngIf="dataCoupon">
    <p>Cupom enviado com sucesso!</p>
  </div>
  
  <div class="error-coupon" *ngIf="errorMessage && !dataCoupon">
    <p>Não foi possível enviar o cupom, tente novamente!</p>
  </div>
  
  <div mat-dialog-actions class="actions-container">
    <button mat-button (click)="onNoClick()">fechar</button>
    <button mat-button cdkFocusInitial
      (click)="onSubmit(couponForm)"
      [disabled]="!couponForm.valid"
      class="send">{{ loading ? 'enviando' : 'enviar' }}</button>
  </div>