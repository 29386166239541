<div [ngClass]="{'limit': galleryItem?.images.length > 6}" class="grid-image">
    <figure (click)="openModal();currentSlide(i+1)"
        *ngFor="let image of (limitImages ? (galleryItem?.images | slice:0:8) : galleryItem?.images); let i = index">
        <div class="hover-image">
            <span translate="no" class="material-icons">camera_alt</span>
        </div>
        <img class="img-fluid" [src]="imgServer + 'width=' + 270 + imgServerUrlEnd + image" width="270" height="auto" alt="imagem">
    </figure>
</div>

<div class="view-more" *ngIf="galleryItem?.images.length > 6 && limitImages && typeSearch !== 6" (click)="onClickViewMore(galleryItem?.id, galleryItem?.title)">
    <button>ver mais</button>
</div>
<div class="view-more" *ngIf="galleryItem?.images.length > 6 && limitImages && typeSearch === 6" (click)="onClickViewMorePortal()">
    <button>ver mais</button>
</div>

<div class="slide-modal" id="imgModal">
    <span (click)="closeModal()" class="close-modal">
        <i class="material-icons notranslate">close</i>
    </span>
    <div (click)="closeModal()" class="slide-modal-content">
        <div *ngFor="let image of galleryItem?.images; let i = index" class="img-slides">
            <img class="img-fluid" src="{{imgServer + 'width=' + 620 + imgServerUrlEnd + image}}" width="620" height="auto" alt="imagem">
        </div>
    </div>
    <a translate="no" (click)="plusSlides(-1)" class="prev material-icons">arrow_back</a>
    <a translate="no" (click)="plusSlides(1)" class="next material-icons">arrow_forward</a>
</div>
