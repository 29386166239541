import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {DocumentService} from './document.service';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    establishmentName: string = '';

    constructor(public meta: Meta, public title: Title, private documentService: DocumentService) {

        this.documentService.getWindow()?.window.addEventListener('focus', event => {
            this.title.setTitle(this.establishmentName);
        });
        this.documentService.getWindow()?.window.addEventListener('blur', event => {
            this.title.setTitle('Volte aqui!');
        });
    }

    updateSeo(data: any): void {
        if (data) {
            this.establishmentName = data.seo.title;
            const urlEndpointSeoImage = "https://images3.motor-reserva.com.br/cdn-cgi/image/fit=scale-down,format=webp,width=500,quality=90/curl/";

            if (data.favicon) {
                const urlEndpoint = "https://images3.motor-reserva.com.br/cdn-cgi/image/fit=scale-down,format=webp,width=15,quality=75/curl/";
                
                const favicon: HTMLElement | any = this.documentService.getWindow()?.document.getElementById('favicon');
                (favicon as HTMLElement).setAttribute('href', urlEndpoint + data.favicon);
            }

            this.title.setTitle(data.seo.title);
            this.meta.updateTag(
                { name: 'description', content: data.seo.description },
                'name=description',
            );
            this.meta.updateTag(
                { name: 'keywords', content: data.seo.keyWords },
                'name=keywords'
            );

            this.meta.updateTag(
                { property: 'og:title', content: data.seo.title }
            );
            this.meta.updateTag(
                { property: 'og:description', content: data.seo.description }
            );

            this.meta.updateTag(
                { name: 'twitter:title', content: data.seo.title },
            );
            this.meta.updateTag(
                { name: 'twitter:description', content: data.seo.description },
            );
            this.meta.updateTag(
                { name: 'twitter:site', content: this.documentService.getLocation()?.href },
            );

            this.meta.updateTag(
                { name: 'og:url', content: this.documentService.getLocation()?.href },
            );

            this.meta.updateTag(
                { name: 'facebook-domain-verification', content: data?.marketing?.facebook_verification  },
            );

            if (data.seo.image) {
                this.meta.updateTag(
                    { property: 'og:image', content: urlEndpointSeoImage + data.seo.image },
                );
                this.meta.updateTag(
                    { property: 'og:image:secure_url', content: urlEndpointSeoImage + data.seo.image },
                );
            }
        }
    }
}
