import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthRouteClientService {
    userData: any = this.documentService.getWindow()?.sessionStorage?.getItem('userData')
        ? JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '')
        : null;

    constructor(
        private documentService: DocumentService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (this.userData?.type === 'client') {
            return true;
        }
        this.router.navigateByUrl('/');
        return false;
    }
}
