import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogResourcesData {
    resourcesData: any;
}

@Component({
    selector: 'my-portal-dialog',
    template: `
        <mat-dialog-content>
            <h1 mat-dialog-title>Comodidades</h1>
            <div class="resources">
                <div *ngFor="let resource of data?.resourcesData;" class="resource">
                    {{ resource.name }}
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Fechar</button>
        </mat-dialog-actions>`,
    styleUrls: ['./dialog.component.scss']
})
export class DialogResourcesComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DialogResourcesComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogResourcesData) {
    }

    ngOnInit(): void {
    }

}
