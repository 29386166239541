import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { RestrictedAreaLoginComponent } from '../../../shared/restricted-area-login/restricted-area-login.component';

@Component({
  selector: 'my-portal-dialog-client-type',
  templateUrl: './dialog-client-type.component.html',
  styleUrls: ['./dialog-client-type.component.scss']
})
export class DialogClientTypeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogClientTypeComponent>,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  openDialogLogin(loginType: string): void {
    const dialogRef = this.dialog.open(RestrictedAreaLoginComponent, {
        data: {
            loginType
        },
        width: '500px',
    });
    this.closeDialog();
    dialogRef.afterClosed().subscribe(result => {});
}
}
