<div [ngClass]="{'search' : !search}" class="benefits" *ngIf="!load" >
    <swiper [config]="config" [initialize]="initializer" class="mt-3" >
        <div class="swiper-wrapper">
            <div *ngFor="let benefit of benefits" [ngSwitch]="benefit.type" class="benefit swiper-slide">
                <ng-template ngSwitchCase="minimumPrecedence">
                    <figure>
                        <svg stroke="currentColor" id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1,.cls-2{fill:none;stroke-miterlimit:10;stroke-width:0.5px;}.cls-2{stroke-linecap:round;}</style></defs><title>Ico-minimo-dias</title><rect class="cls-1" x="1.4" y="2.6" width="21.2" height="20.25" rx="4"/><line class="cls-2" x1="12" y1="1.7" x2="12" y2="4.4"/><line class="cls-2" x1="18.4" y1="1.7" x2="18.4" y2="4.4"/><line class="cls-2" x1="5.5" y1="1.7" x2="5.5" y2="4.4"/><line class="cls-2" x1="12" y1="1.7" x2="12" y2="4.4"/><line class="cls-2" x1="4.6" y1="7.6" x2="19.3" y2="7.6"/><path class="cls-1" d="M16.9,12.9l-1.4-1.5h-.4L10.5,16,8.8,14.4H8.4L7,15.8a.3.3,0,0,0,0,.4l1.8,1.9h0l1.5,1.4a.3.3,0,0,0,.4,0l6.2-6.3A.2.2,0,0,0,16.9,12.9Z"/></svg>
                    </figure>
                    <h6>Mínimo de dias</h6>
                    <p>{{ benefit.text }}</p>
                </ng-template>
                <ng-template class="benefit" ngSwitchCase="childrenMax">
                    <i class="icon-children notranslate"></i>
                    <h6>Criança não paga</h6>
                    <p>{{ benefit.text }}</p>
                </ng-template>
                <ng-template class="benefit" ngSwitchCase="payment">
                    <i class="icon-installment notranslate"></i>
                    <h6>Parcelamento</h6>
                    <p>{{ benefit.text }}</p>
                </ng-template>
                <ng-template class="benefit" ngSwitchCase="discount">
                    <figure>
                        <img [src]="checkImageIcon(benefit?.formPayment?.id)" alt="icon">
                    </figure>
                    <h6>{{ benefit?.formPayment?.name }}</h6>
                    <p>{{ benefit.text }}</p>
                </ng-template>
                <ng-template class="benefit" ngSwitchCase="depositDiscount">
                    <figure>
                        <svg stroke="currentColor" id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-deposit-1,.cls-deposit-2{fill:none;}.cls-deposit-1,.cls-deposit-2,.cls-deposit-3{stroke-width:0.5px;}.cls-deposit-1{stroke-linejoin:round;}.cls-deposit-2,.cls-deposit-3{stroke-miterlimit:10;}.cls-deposit-3{}</style></defs><title>ico-pagamento-deposito</title><path class="cls-deposit-1" d="M16.9,15l3.4-1.8a1.3,1.3,0,0,1,1.8.4,1.5,1.5,0,0,1-.6,1.8l-7.2,3.8h-.4l-6.7.2a4.5,4.5,0,0,0-1.9,1.4"/><path class="cls-deposit-2" d="M3,17.4l3-2.8a3.9,3.9,0,0,1,3.2-.4,15.2,15.2,0,0,0,2.5.2h2.6a1.3,1.3,0,0,1,1.4,1.3,1.3,1.3,0,0,1-1.4,1.2H10.7"/><path class="cls-deposit-3" d="M9.2,7.3c0,.2-.1.3-.2.3a.3.3,0,0,1-.3-.3c0-.1.1-.2.3-.2Z"/><path class="cls-deposit-3" d="M16.5,7.3a.3.3,0,0,1-.3.3c-.1,0-.2-.1-.2-.3l.2-.2C16.4,7.1,16.5,7.2,16.5,7.3Z"/><rect class="cls-deposit-2" x="5.6" y="3.4" width="14" height="7.97"/><path class="cls-deposit-2" d="M14.6,9.8h2.2A1.2,1.2,0,0,1,18,8.6V6.1a1.2,1.2,0,0,1-1.2-1.2H14.6"/><path class="cls-deposit-2" d="M10.6,4.9H8.4A1.2,1.2,0,0,1,7.2,6.1V8.6A1.2,1.2,0,0,1,8.4,9.8h2.2"/><path class="cls-deposit-2" d="M14.2,7.3c0,1.2-.7,2.2-1.6,2.2S11,8.5,11,7.3s.7-2.1,1.6-2.1S14.2,6.1,14.2,7.3Z"/></svg>
                    </figure>
                    <h6>Pagamento via depósito</h6>
                    <p>{{ benefit.text }}</p>
                </ng-template>
                <ng-template class="benefit" ngSwitchCase="fidelityProgram">
                    <i class="icon-cashback notranslate"></i>
                    <h6>Programa de Fidelidade</h6>
                    <p>{{ benefit.text }}</p>
                </ng-template>
                <ng-template class="benefit" ngSwitchCase="freeNight">
                    <i class="material-icons-outlined notranslate">insert_invitation</i>
                    <h6>Free Night</h6>
                    <p>{{ benefit.text }}</p>
                </ng-template>
            </div>
            <div class="benefit swiper-slide">
                <figure>
                    <svg stroke="#484848" id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1-benefit{fill:none;stroke-linecap:round;stroke-linejoin:round;}</style></defs><title>Icones</title><path class="cls-1-benefit" d="M12,1.5A16.8,16.8,0,0,1,3.2,4.9V8.4c0,6.4,3.5,12.1,8.8,14.1h0c5.3-2,8.8-7.7,8.8-14.1V4.9A16.8,16.8,0,0,1,12,1.5Z"/><polygon class="cls-1-benefit" points="17 7.6 11 13.6 8 10.6 6.2 12.4 9.2 15.4 11 17.2 12.8 15.4 18.8 9.3 17 7.6"/></svg>
                </figure>
                <h6>Certificado de Segurança</h6>
                <p>Faça compras seguras e confiáveis pelo nosso site.</p>
            </div>
            <div class="benefit swiper-slide">
                <figure>
                    <svg stroke="#484848" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.6 21.1"><defs><style>.cls-1-benefit{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.25px;}</style></defs><title>Ativo 16</title><g id="Camada_2" data-name="Camada 2"><g id="Camada_1-2" data-name="Camada 1"><path class="cls-1-benefit" d="M5,7.9A3.1,3.1,0,0,1,2.3,6.2,3.1,3.1,0,0,1,3.7,2.3,3.1,3.1,0,0,1,7.6,3.7a3.3,3.3,0,0,1,.1,2.2"/><path class="cls-1-benefit" d="M5.8,9.7A4.8,4.8,0,0,1,.6,7,4.8,4.8,0,0,1,9.3,2.9a4.5,4.5,0,0,1,.3,3.4"/><path class="cls-1-benefit" d="M13.3,8.6A1.2,1.2,0,0,1,13.9,7a1,1,0,0,1,.9-.1,1.1,1.1,0,0,1,.7.6"/><path class="cls-1-benefit" d="M9.6,12,6.4,5.2a1.6,1.6,0,0,0-.7-.7,1,1,0,0,0-.9.1,1.2,1.2,0,0,0-.6,1.6l3,6.4a1.3,1.3,0,0,0-.7-.6,1.4,1.4,0,0,0-.9.1A1.1,1.1,0,0,0,5,13.6l1.6,3.5a7.6,7.6,0,0,0,1.7,2.3,5.8,5.8,0,0,0,6.4,1l.4-.2a5.7,5.7,0,0,0,3-7.1c-.5-1.4-1.7-3.6-2.6-5.6"/><line class="cls-1-benefit" x1="8.9" y1="16.4" x2="6.2" y2="10.6"/><path class="cls-1-benefit" d="M7.4,7.4A1.2,1.2,0,0,1,8,5.8h.9a1.1,1.1,0,0,1,.7.6L11.8,11"/><path class="cls-1-benefit" d="M10.3,7.9a1.2,1.2,0,0,1,.6-1.6h0a1.2,1.2,0,0,1,1.6.6L14,10"/></g></g></svg>
                </figure>
                <h6>Compra rápida e fácil</h6>
                <p>Em apenas poucos cliques, você finaliza a sua compra.</p>
            </div>
        </div>
    </swiper>
</div>
