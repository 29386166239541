import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppDataService } from '../../../services/app-data.service';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-accommodation-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
})
export class ListAccommodationComponent implements OnInit {
    accommodations: any | null = null;
    load: any = true;
    objRequest: object = {
        qtd_quarto: 1,
        checkin: moment().format('YYYY-MM-DD'),
        checkout: moment().add(1, 'days').format('YYYY-MM-DD'),
        adult: 1,
        ages: null,
    };
    accommodationModuleData: any = {
        title: 'Nossas acomodações',
    };

    constructor(
        private appService: AppDataService,
        private initializerService: InitializerService
    ) {}

    ngOnInit(): void {
        this.appService.getDataModule('rooms').subscribe((response: any) => {
            this.accommodations = response.data;
            for (const item of this.accommodations) {
                item.slug = this.createUrlRoom(item.name);
                item.images = new Array({src: item.url.replace('https://images.focomultimidia.com/curl/', '')});
                item.description = item.brief_description;
            }
            this.load = false;
        });

        this.initializerService.currentData
            .subscribe((data: any) => {
                if (data) {
                    const accommodationModuleData = data.modules.find(
                        (module: any) => module.module_id === 5
                    );

                    if (accommodationModuleData) {
                        this.accommodationModuleData = {
                            title: accommodationModuleData.title,
                            description: accommodationModuleData.description,
                        };
                    }
                }
            })
            .unsubscribe();
    }

    createUrlRoom(title: string): any {
        if (title) {
            return this.appService.createSlugUrl(
                title.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            );
        }
    }
}
