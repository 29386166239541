import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { RestrictedAreaLoginComponent } from './../../../../shared/restricted-area-login/restricted-area-login.component';
import { AuthService } from 'projects/new-portal/src/app/services/auth.service';

@Component({
    selector: 'my-portal-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

    @Input() dataHeader: any;
    hiddenSlider: any = false;
    modalVisibility: boolean = false;
    userData: any =
        this.documentService.getWindow()?.sessionStorage?.getItem('userData')
            ? JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '')
            : null;
    userLogged: any = false;
    showSearches: boolean = false;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();


    constructor(private router: Router,
                private activatedRouter: ActivatedRoute,
                public dialog: MatDialog,
                private documentService: DocumentService,
                private location: Location,
                private authService: AuthService,
                private initializerService: InitializerService
                ) {
        this.activatedRouter.paramMap.subscribe(params => {
            this.hiddenSlider = (location.path() === '') ? false : true;
        });
        router.events.subscribe((val) => {
            this.hiddenSlider = (location.path() === '') ? false : true;
        });
    }

    ngOnInit(): void {
        this.initializerService.currentControlAuthGuard.subscribe(
            (data: any) => {
                this.userLogged = data;
                if (data) {
                    this.userData = JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '');
                }
            }
        );
        this.userLogged = (this.userData?.id) ? true : false;
    }

    openDialog(loginType: string): void {
        const dialogRef = this.dialog.open(RestrictedAreaLoginComponent, {
            data: {
                loginType
            },
            width: '500px',
        });
        dialogRef.afterClosed().subscribe(result => {});
    }

    handleShowSearches(): void {
        this.showSearches = !this.showSearches;
    }
}
