import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {CartService} from '../../../services/cart.service';
import {SwiperOptions} from 'swiper';
import {DocumentService} from '../../../services/document.service';
import { InitializerService } from '../../../services/initializer.service';
import { TagManagerService } from '../../../services/tag-manager.service';

declare var gtag: any;
declare global {
    interface Window { dataLayer: any[]; }
}
@Component({
    selector: 'my-portal-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

    itemCart: any[] = new Array();
    type: string | any = 'hotels';
    ItemsCart: any = this.cartService.getItems();
    @Output() itemEvent = new EventEmitter();
    initialize = false;
    typeSearch: number = Number(this.documentService.getWindow()?.sessionStorage.getItem('typeSearch'));
    marketingData: any;
    productCartEcommerce: any[] = [];

    config: SwiperOptions = {
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next-cart',
            prevEl: '.swiper-button-prev-cart'
        }
    };
    accommodationsInCart: any;

    constructor(private cartService: CartService, 
                private documentService: DocumentService,
                private initializerService: InitializerService,
                private tagManagerService: TagManagerService) {
    }

    ngOnInit(): void {
        this.initializerService.currentData.subscribe((response: any) => {
            this.marketingData = response?.marketing;
        })

        this.cartService.currentDataCartItems.subscribe((response: any) => {
            this.itemCart = new Array();
            this.ItemsCart = response;
            for (const hotelId of Object.keys(this.ItemsCart.hotels)) {
                for (const item of this.ItemsCart.hotels[hotelId].items) {
                    this.itemCart.push(item);
                }
            }
        });
        setTimeout(() => {
            this.initialize = true;
        }, 1500);

        this.cartService.currentAccommodationsInCart.subscribe((response: any) => {
            this.accommodationsInCart = response;
        })
    }

    removeItem(type: string, index: any, personId: number): void {
        let removeCartRoom = this.itemCart[index];

        this.cartService.removeItemCart(type, index, personId, this.accommodationsInCart);
        this.itemEvent.emit(this.itemCart);
        const removeFromCartDataEvent = {
            ...removeCartRoom,
            index
        }
        this.tagManagerService.removeFromCartEvent(removeFromCartDataEvent);

        // if (this.marketingData?.google_tag_manager) {
        //     if((<any> window).dataLayer) {
        //         window.dataLayer.push({
        //             'event': 'removeFromCart',
        //             'ecommerce': {
        //                 'remove': { 
        //                     'products': {
        //                         'name': removeCartRoom.quarto_nome,
        //                         'id': removeCartRoom.quarto_id,
        //                         'price': removeCartRoom.preco,
        //                         'brand': '',
        //                         'category': '',
        //                         'variant': '',
        //                         'quantity': 1
        //                     }
        //                 }
        //             }
        //         })
        //     } 
        // }

        // if (this.marketingData?.google_analytics?.geral) {
        //     if((<any> window).gtag) {
        //         gtag('event', 'removeFromCart', {
        //             'products': {
        //                 'name': removeCartRoom.quarto_nome,
        //                 'id': removeCartRoom.quarto_id,
        //                 'price': removeCartRoom.preco,
        //                 'brand': '',
        //                 'category': '',
        //                 'variant': '',
        //                 'quantity': 1
        //             }
        //         });
        //     }
        // }
    }

}