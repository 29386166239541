import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AppDataService} from '../../services/app-data.service';
import * as moment from 'moment';
import {DocumentService} from '../../services/document.service';
import {DefaultSnackBarComponent} from '../default-snack-bar/default-snack-bar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InitializerService} from '../../services/initializer.service';

@Component({
    selector: 'my-portal-precheckin',
    templateUrl: './precheckin.component.html',
    styleUrls: ['./precheckin.component.scss']
})
export class PrecheckinComponent implements OnInit {

    preCheckinForm: any = FormGroup;
    disabledButton = false;
    disabledRecaptcha = false;
    loading = false;
    typeSearch: any = this.documentService.getWindow()?.sessionStorage.getItem('typeSearch');
    hotels: any = null;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    outputMaskRg = '';
    cell: any = {sigla: 'br', ddi: '55'};
    phone: any = {sigla: 'br', ddi: '55'};
    modelLocalPhone = 1;
    resetPolicyCheckbox: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private appService: AppDataService,
                private documentService: DocumentService,
                private snackbar: MatSnackBar,
                private initializerService: InitializerService) {

        this.preCheckinForm = this.formBuilder.group({
            reserve: new FormControl(null, [Validators.required]),
            origin: new FormControl(null, [Validators.required]),
            checkin: new FormControl(null, [Validators.required]),
            checkout: new FormControl(null, [Validators.required]),
            guest: this.formBuilder.group({
                name: new FormControl(null, [Validators.required]),
                genre: new FormControl(null, [Validators.required]),
                birth_date: new FormControl(null, [Validators.required, this.validDateValidator()]),
                email: new FormControl(null, [Validators.required, Validators.email]),
                job: new FormControl(null, [Validators.required]),
                phone_number: new FormControl(null),
                cell: new FormControl(null, [Validators.required]),
                nationality: new FormControl(null, [Validators.required]),
                pax: new FormControl(null, [Validators.required]),
                documentation: this.formBuilder.group({
                    foreign: new FormControl(null, [Validators.required]),
                    cpf: new FormControl({value: null, disabled: true}, [Validators.required]),
                    type: new FormControl({value: null, disabled: true}, [Validators.required]),
                    number: new FormControl({value: null, disabled: true}, [Validators.required]),
                    issuing_agency: new FormControl({value: null, disabled: true}),
                }),
                address: this.formBuilder.group({
                    zip_code: new FormControl(null, [Validators.required]),
                    public_place: new FormControl({value: null, disabled: true}, [Validators.required]),
                    number: new FormControl({value: null, disabled: true}),
                    complement: new FormControl({value: null, disabled: true}),
                    district: new FormControl({value: null, disabled: true}, [Validators.required]),
                    city: new FormControl({value: null, disabled: true}, [Validators.required]),
                    state: new FormControl({value: null, disabled: true}, [Validators.required]),
                    county: new FormControl({value: null, disabled: true}, [Validators.required]),
                }),
                provenance: this.formBuilder.group({
                    city: new FormControl(null, [Validators.required]),
                    state: new FormControl(null, [Validators.required]),
                    county: new FormControl(null, [Validators.required]),
                }),
                destination: this.formBuilder.group({
                    city: new FormControl(null, [Validators.required]),
                    state: new FormControl(null, [Validators.required]),
                    county: new FormControl(null, [Validators.required]),
                    reason_travel: new FormControl(null, [Validators.required]),
                    transport: new FormControl(null, [Validators.required])
                }),
            }),
        });
    }

    ngOnInit(): void {
        if (this.typeSearch >= 6) {
            this.preCheckinForm.addControl('pessoa_id',
                new FormControl(null, [Validators.required]));

            this.appService.getHotelsPreCheckIn()
                .subscribe((response?: any) => {
                    this.hotels = response.data;
                });
        }
    }

    privacyPolicy($event: any): void {
        this.disabledButton = $event;
    }

    recaptcha($event: any): void {
        this.disabledRecaptcha = $event;
    }

    validDateValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value || typeof value !== 'string') {
                return { invalidDate: true };
            }

            const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
            const match = value.match(dateRegex);

            if (!match) {
                return { invalidDate: true };
            }

            const day = parseInt(match[1], 10);
            const month = parseInt(match[2], 10);
            const year = parseInt(match[3], 10);

            const date = new Date(year, month - 1, day);
            const isValid =
                date.getFullYear() === year &&
                date.getMonth() === month - 1 &&
                date.getDate() === day;

            return isValid ? null : { invalidDate: true };
        };
    }

    selectTypeDocument(): void {
        if (this.preCheckinForm.value.guest.documentation.type === 1) {
            this.preCheckinForm.controls.guest.controls.documentation.controls.issuing_agency.enable();
        }
        this.preCheckinForm.controls.guest.controls.documentation.controls.number.enable();
    }

    selectTypeTourist(): void {
        if (this.preCheckinForm.value.guest.documentation.foreign === 'N') {
            this.preCheckinForm.controls.guest.controls.documentation.controls['cpf'].enable();
            this.preCheckinForm.controls.guest.controls.documentation.controls['issuing_agency'].enable();
            // this.preCheckinForm.controls.guest.controls.documentation.controls.cpf.setValidators([Validators.required]);
            this.preCheckinForm.controls.guest.controls.documentation.controls.issuing_agency.setValidators([Validators.required]);
            this.preCheckinForm.patchValue({
                guest: {
                    documentation: {
                        type: null
                    }
                }
            });

            if (this.preCheckinForm.value.guest.documentation.type) {
                this.preCheckinForm.controls.guest.controls.documentation.controls.number.disabled();
                this.preCheckinForm.patchValue({
                    guest: {
                        documentation: {
                            type: null
                        }
                    }
                });
            }
        } else {
            this.preCheckinForm.controls.guest.controls.documentation.controls['cpf'].disable();
            this.preCheckinForm.controls.guest.controls.documentation.controls['issuing_agency'].disable();
            this.preCheckinForm.controls.guest.controls.documentation.controls['cpf'].patchValue(null);
            this.preCheckinForm.controls.guest.controls.documentation.controls['issuing_agency'].patchValue(null);
            this.preCheckinForm.patchValue({
                guest: {
                    documentation: {
                        type: 3
                    }
                }
            });
            this.preCheckinForm.controls.guest.controls.documentation.controls.number.enable();
        }
        this.preCheckinForm.controls.guest.controls.documentation.controls.type.enable();
    }

    updateCep(cep: string): void {
        if (cep && cep.length === 8) {
            this.preCheckinForm.controls.guest.controls.address.controls.public_place.enable();
            this.preCheckinForm.controls.guest.controls.address.controls.number.enable();
            this.preCheckinForm.controls.guest.controls.address.controls.complement.enable();
            this.preCheckinForm.controls.guest.controls.address.controls.district.enable();
            this.preCheckinForm.controls.guest.controls.address.controls.city.enable();
            this.preCheckinForm.controls.guest.controls.address.controls.state.enable();
            this.preCheckinForm.controls.guest.controls.address.controls.county.enable();
            this.appService.getDataCep(cep).subscribe((response: any) => {
                this.preCheckinForm.patchValue({
                    guest: {
                        address: {
                            public_place: response.street,
                            district: response.neighborhood,
                            city: response.city,
                            state: response.state,
                            county: 'Brasil'
                        },
                        provenance: {
                            city: response.city,
                            state: response.state,
                            county: 'Brasil'
                        },
                        destination: {
                            city: response.city,
                            state: response.state,
                            county: 'Brasil'
                        }
                    }
                });
            });
        }
    }

    submit(): void {
        this.loading = true;
        this.resetPolicyCheckbox = false;

        if (this.preCheckinForm.value.checkin && this.preCheckinForm.value.checkout) {
            this.preCheckinForm.value.checkin = moment(this.preCheckinForm.value.checkin).format('YYYY-DD-MM');
            this.preCheckinForm.value.checkout = moment(this.preCheckinForm.value.checkout).format('YYYY-DD-MM');
        }

        this.preCheckinForm.value.guest.birth_date = moment(this.preCheckinForm.value.guest.birth_date, 'DD/MM/YYYY').format('YYYY-DD-MM');

        this.preCheckinForm.value.guest.cell = this.cell.ddi + this.preCheckinForm.value.guest.cell;
        this.preCheckinForm.value.guest.phone_number = this.phone.ddi + this.preCheckinForm.value.guest.phone_number;

        this.appService.preCheckin(this.preCheckinForm.value)
            .subscribe((response: any) => {
                this.openSnackBar(
                    'Formulário enviado com sucesso!',
                    false
                );
                this.preCheckinForm.reset();
                this.loading = false;
                this.disabledButton = false;
                this.resetPolicyCheckbox = true;
            }, error => {
                this.loading = false;
                this.resetPolicyCheckbox = true;
                this.openSnackBar(
                    'Erro ao enviar formulário!',
                    true
                );
            });
    }

    openSnackBar(msg: string, flag?: boolean | undefined): void {
        this.snackbar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg
            },
            horizontalPosition: 'end',
            verticalPosition: this.getScreenWidth ? 'top' : 'bottom',
            panelClass: (flag) ? 'error-snack-bar' : 'success-snack-bar',
            duration: 5000
        });
    }

    maskRg(): void {
        let mask = '';
        const document = this.preCheckinForm.value.guest.documentation.number;
        if (this.preCheckinForm.value.guest.documentation.type === 1) {
            if (document) {
                if (document.length === 10) {
                    mask = '99.999.999-99';
                } else if (document.length === 9) {
                    mask = '99.999.999-9';
                } else if (document.length === 8) {
                    mask = '9.999.999-9';
                }
            }
        }

        this.outputMaskRg = mask;
    }

    focusMaskRg(): void {
        this.outputMaskRg = '';
    }

    onCountryChange(country: any, input: string) {
        if (input === 'phone') {
            this.phone.sigla = country.iso2;
            this.phone.ddi = country.dialCode;
        } else {
            this.cell.sigla = country.iso2;
            this.cell.ddi = country.dialCode;
        }
    }
}
