import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {AppDataService} from '../../../services/app-data.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogErrorComponent} from '../../../shared/dialog-error/dialog-error.component';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-datepicker-room',
    templateUrl: './datepicker-room.component.html',
    styleUrls: ['./datepicker-room.component.scss']
})
export class DatepickerRoomComponent implements OnInit {

    week: any[] = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'];
    today = moment().format('YYYY-MM-DD');
    datepickerRoom: any;
    calendar: any = null;
    observations: any = {};
    selectedLocal: any;
    month: any = {
        startDate: moment(this.today, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD'),
        endDate: moment(this.today, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
    };
    objectDate: any = {
        checkin: moment().format('YYYY-MM-DD'),
        checkout: moment().add(1, 'days').format('YYYY-MM-DD'),
    };
    objectRange: any = {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(1, 'days').format('YYYY-MM-DD')
    };
    objectRestriction: any = {
        unavailable: true,
        closedCheckIn: true,
        closedCheckOut: false
    };
    @Input() roomId: any;
    @Input() hotelId: any;
    @Input() minPax: any;
    @Output() dates = new EventEmitter();

    constructor(private appService: AppDataService,
                private initializerService: InitializerService,
                public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.datepickerRoom = this.appService.createWeek(this.createMonth(this.today));
    }

    getDataDatepicker(roomId: number, hotelId: number, startDate?: string): void {
        const obj = {
            first_day: (startDate) ? startDate : moment().startOf('month').format('YYYY-MM-DD'),
            pessoa_id: hotelId,
            quarto_id: roomId,
            pax: this.minPax ?? 2
        };
        
        this.appService.getDatepickerRoomJson(obj)
            .subscribe((response: any) => {
                this.calendar = response.data;

                for (const [key, item] of Object.entries(this.calendar)) {
                    if (this.calendar[key].restriction) {
                        this.calendar[key].restriction.restrictions = {
                            1: null,
                            2: null,
                            3: null,
                            4: null,
                            5: null,
                        };

                        if (this.calendar[key].restriction.rules) {
                            for (const ruleItem of this.calendar[key].restriction.rules) {
                                this.calendar[key].restriction.restrictions[ruleItem.id] = ruleItem;
                            }
                        }
                    }
                }
            },
        );
    }

    createMonth(startDate: string): any[] {
        const startMonth = moment(startDate).daysInMonth();
        const startDayMonth = moment(startDate).startOf('month').format('YYYY-MM-DD');
        const endDayMonth = moment(startDate, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD');
        const arrMonth: any[] = new Array();
        const beforeMonth = [];

        for (let i = 1; i <= moment(startDayMonth).isoWeekday(); i++) {
            const subDate = moment(startDayMonth, 'YYYY-MM-DD').subtract(i, 'day').format('YYYY-MM-DD');
            beforeMonth.push({
                weekDay: moment(subDate).isoWeekday(),
                date: subDate
            });
        }

        const quantityAfterDates = 42 - (beforeMonth.length + moment(startMonth).daysInMonth());

        for (let i = 0; i < startMonth + quantityAfterDates; i++) {
            const endDate = moment(startDayMonth, 'YYYY-MM-DD').add(i, 'day').format('YYYY-MM-DD');
            arrMonth.push({
                weekDay: moment(endDate).isoWeekday(),
                date: endDate
            });
        }

        const lastArray = beforeMonth.reverse().concat(arrMonth);

        if (this.roomId && this.hotelId) {
            this.getDataDatepicker(this.roomId, this.hotelId, startDayMonth);
        }

        return lastArray;
    }

    selectDate(date: any, event: any): void {
        let restriction = null;
        if (this.calendar) {
            if (this.calendar[date]) {
                restriction = this.calendar[date].restriction;
            }
        }

        if (!this.objectRange.start) {
            if (this.objectRange.start >= this.today) {
                this.objectDate.checkin = date;
                this.objectRange.start = date;
                this.appService.checkDates(`.date-${date}`, 'start-date');
            }
        } else {
            this.objectDate.end = null
            this.objectDate.checkout = null;

            if (this.objectRange.start < date && !this.objectRange.end) {
                this.objectRestriction.unavailable = true;
                this.objectRestriction.closedCheckIn = true;

                if (this.restriction(restriction, date, true)) {
                    this.objectDate.checkout = date;
                    this.objectRange.end = date;

                    const diff = moment(date).diff(this.objectRange.start, 'days');

                    for (let i = 1; i <= diff - 1; i++) {
                        const newDate = moment(this.objectRange.start,'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD');

                        this.appService.checkDates(`.date-${newDate}`, 'entry');
                    }

                    this.appService.checkDates(`.date-${date}`, 'end-date');
                    this.dates.emit(this.objectRange);
                    this.objectRestriction.closedCheckOut = false;
                }
            } else {
                if (this.restriction(restriction, date, false)) {
                    this.objectRestriction.unavailable = false;
                    this.objectRestriction.closedCheckIn = false;
                    this.objectRestriction.closedCheckOut = true;

                    if (date >= this.today) {
                        this.appService.checkDates('.start-date', 'start-date', true);
                        this.appService.checkDates('.end-date', 'end-date', true);
                        this.appService.checkDates('.entry', 'entry', true);

                        this.objectDate.checkin = date;
                        this.objectRange.start = date;
                        this.objectRange.end = null;

                        this.appService.checkDates(`.date-${date}`,'start-date');
                        this.initializerService.changeControlLocalSelected({
                            ...this.selectedLocal,
                            selectedDates: this.objectDate,
                        });
                    }
                }
            }
        }
    }

    restriction(data: any, date: string, checkout: boolean): boolean {
        const objRestriction: any = this.appService.restriction(data, date, checkout, this.calendar, this.objectRange.start);

        if (objRestriction.message) {
            this.openDialog(objRestriction.message);
        }

        if (objRestriction.dates) {
            this.objectRange = {
                start: moment().format('YYYY-MM-DD'),
                end: moment().add(1, 'days').format('YYYY-MM-DD')
            };
        }

        if (!objRestriction.isValid) {
            this.appService.checkDates('.start-date', 'start-date', true);
            this.appService.checkDates('.end-date', 'end-date', true);
            this.appService.checkDates('.entry', 'entry', true);

            this.objectDate = {
                start: moment().format('YYYY-MM-DD'),
                end: moment().add(1, 'days').format('YYYY-MM-DD'),
                checkin: null,
                checkout: null
            };

            this.objectRange = {
                start: moment().format('YYYY-MM-DD'),
                end: moment().add(1, 'days').format('YYYY-MM-DD')
            };

            this.objectRestriction = {
                unavailable: true,
                closedCheckIn: true,
                closedCheckOut: false
            };
        }

        return objRestriction.isValid;
    }

    changeMonth(type: string): void {
        let startDate: any = null;
        switch (type) {
            case 'prev':
                startDate = moment(this.month.startDate).subtract(1, 'month').format('YYYY-MM-DD');
                this.month.startDate = moment(this.month.startDate).subtract(1, 'month').format('YYYY-MM-DD');
                this.month.endDate = moment(this.month.startDate, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
                break;
            case 'next':
                startDate = moment(this.month.startDate).add(1, 'month').format('YYYY-MM-DD');
                this.month.startDate = moment(this.month.startDate).add(1, 'month').format('YYYY-MM-DD');
                this.month.endDate = moment(this.month.startDate, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD');
                break;
        }
        this.datepickerRoom = this.appService.createWeek(this.createMonth(startDate));
    }

    onMouseHoverDate(event: any, hoverDay: string): void {
        if (this.calendar && this.calendar[hoverDay]) {
            const { start: checkIn, end: checkOut } = this.objectRange;
            const restriction = this.calendar[hoverDay]?.restriction;
            const dateRules = restriction?.rules;
            const isClosedDate = restriction?.closed;
            const hasDates = Boolean(checkIn && checkOut);
            const isValidHoverForCheckOut = Boolean(checkIn && !checkOut && checkIn < hoverDay);
            const retroativeCheckInDate = Boolean(checkIn && !checkOut && checkIn > hoverDay);
            const isCheckInSelectedDate = checkIn === hoverDay;
            const isCheckOutSelectedDate = checkOut === hoverDay;
            const observations: string[] = [];

            if (isCheckInSelectedDate || isCheckOutSelectedDate) {
                if (isCheckInSelectedDate) {
                    observations.push('Check-in');
                }

                if (isCheckOutSelectedDate) {
                    observations.push('Check-out');
                }

                this.observations[hoverDay] = observations.join('\n');

                return;
            }

            if (hasDates || retroativeCheckInDate) {
                observations.push('Check-in');

                if (dateRules) {
                    for (const ruleItem of dateRules) {
                        if ([1, 2, 5].includes(ruleItem.id)) {
                            observations.push(ruleItem.message_full);
                        }

                        if (ruleItem.id === 3) {
                            observations.push('Fechado check-in');
                        }

                        if (isClosedDate && !dateRules) {
                            observations.push('Indisponível');
                        }
                    }
                }
            }

            if (isValidHoverForCheckOut) {
                const differenceBetweenDates = moment(hoverDay).diff(checkIn, 'days');
                const numberOfNights = `${differenceBetweenDates} Noite${differenceBetweenDates > 1 ? 's' : ''}`;
                const isUnavailableDate = isClosedDate && !dateRules;
                const unavailableDates = [];

                for (let i = 1; i <= differenceBetweenDates; i++) {
                    const entryDate = moment(checkIn, 'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD');

                    if (this.calendar[entryDate]?.restriction?.closed && !this.calendar[entryDate]?.restriction?.rules) {
                        unavailableDates.push(entryDate);
                    }
                }

                if (unavailableDates.length > 0 && unavailableDates[0] === hoverDay) {
                    observations.push('Check-out');
                    observations.push(`${numberOfNights}`);

                    this.observations[hoverDay] = observations.join('\n');
                    return;
                }

                if (isUnavailableDate) {
                    observations.push('Indisponível');
                    this.observations[hoverDay] = observations.join('\n');
                    return;
                }

                observations.push('Check-out');
                observations.push(`${numberOfNights}`);

                if (dateRules) {
                    for (const ruleItem of dateRules) {
                        if ([1, 5].includes(ruleItem.id)) {
                            observations.push(ruleItem.message_full);
                        }

                        if (ruleItem.id === 4) {
                            observations.push('Fechado check-out');
                        }
                    }
                }
            }

            this.observations[hoverDay] = observations.join('\n');
        }
    }

    onMouseLeaveDate(event: MouseEvent, hoverDay: string): void {
        this.observations[hoverDay] = null;
    }

    openDialog(message: string): void {
        this.dialog.open(DialogErrorComponent, {
            data: {
                error: message,
            },
            minWidth: '250px',
            autoFocus: false,
        });
    }
}
