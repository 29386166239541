import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CartService} from '../../services/cart.service';
import {Route, Router} from '@angular/router';
import {DocumentService} from '../../services/document.service';
import {InitializerService} from '../../services/initializer.service';
import {Location} from '@angular/common';
import { AppDataService } from '../../services/app-data.service';
import { TagManagerService } from '../../services/tag-manager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'my-portal-dialog-cart',
    templateUrl: './dialog-cart.component.html',
    styleUrls: ['./dialog-cart.component.scss']
})
export class DialogCartComponent implements OnInit,OnDestroy {

    items: any = this.cartService.getItems();
    arrItem: any[] = [
        { key: 'hotels', title: 'Hospedagem', establishments: Object.keys(this.items.hotels) },
    ];
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    checkLocation: boolean = false;
    typeSearch: number = Number(this.documentService.getWindow()?.sessionStorage.getItem('typeSearch'));

    isLoadingFinalization: boolean = false;

    private unsubscribe$ = new Subject();

    constructor(public dialogRef: MatDialogRef<DialogCartComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogCartComponent,
                private cartService: CartService,
                private route: Router,
                private documentService: DocumentService,
                private initializerService: InitializerService,
                private location: Location,
                private appService: AppDataService,
                private tagManagerService: TagManagerService,
    ) {
    }

    ngOnInit(): void {
        const asksuite: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.infochat_floatmain');
        if (asksuite) {
            (asksuite as HTMLElement).classList.add('zIndex');
        }
        this.checkLocation = (
            (this.location.path()  === '/sale/checkout' ||
            this.location.path() === '/sale/finish' ||
            this.location.path() === '/sale')
            && this.getScreenWidth);
        this.cartService.currentDataCartItems.subscribe((response: any) => {
            this.items = response;
            this.arrItem[0].establishments = Object.keys(this.items.hotels);
        });

        this.cartService.currentDataCartItems.
        pipe(takeUntil(this.unsubscribe$))
        .subscribe((response: any) => {

            this.tagManagerService.viewCartEvent(response);

            this.unsubscribe$.next();
            this.unsubscribe$.complete();
    });
        
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    finish(): void {
        this.documentService.getWindow()?.localStorage.setItem('cart', JSON.stringify(this.items));
        // this.route.navigate(['sale/adicionais']);

        this.isLoadingFinalization = true;

        const supplementParams = [];

        for (const item of this.arrItem) {
            for (const id of item.establishments) {
                const hotels = this.items.hotels[id].items;

                for (const hotel of hotels ) {
                    supplementParams.push({
                        hotelId : hotel.pessoa_id,
                        checkIn : hotel.check_in,
                        quartoId : hotel.tarifa_id,
                    })
                }
            }
        }

        this.appService.getDataSupplements(supplementParams)
            .subscribe(
                (res: any) => {
                    if (res.data) {
                        const hotelsId: any = Object.keys(res.data);

                        const haveSupplements = hotelsId.some((hotel: any) => res.data[hotel].some((room: any) => room.length));

                        if (!haveSupplements) {
                            this.route.navigate(['sale']);
                        } else {
                            this.route.navigate(['sale/adicionais']);
                        }
                    } else {
                        this.route.navigate(['sale']);
                    }

                    this.isLoadingFinalization = false;
                    this.onNoClick();
                },
                (err: any) => {
                    this.isLoadingFinalization = false;
                    this.onNoClick();
                }
            );
    }

    itemEventDialog(event: any): void {
        if (!event.count) {
            this.dialogRef.close();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
