import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import { InitializerService } from '../../services/initializer.service';

export interface DialogData {
    banner: null;
}

@Component({
    selector: 'my-portal-floating-banner',
    template: `
        <div class="button-close-floating d-inline-flex align-items-end w-100 justify-content-end mb-1">
            <button mat-icon-button aria-label="Fechar banner" mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <mat-dialog-content>
            <a href="{{this.banner.link}}" target="{{this.banner.target}}">
                <figure>
                    <img loading="lazy" width="700" [src]="imgServer + 'width=' + 700 + imgServerUrlEnd + banner?.img[0].nome_img" class="img-fluid">
                </figure>
            </a>
        </mat-dialog-content>
    `,
    styleUrls: ['./floating-banner.component.scss']
})
export class FloatingBannerComponent implements OnInit {

    banner: any = null;
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(public dialogRef: MatDialogRef<FloatingBannerComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.banner = this.data.banner;
    }

}
