import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'my-portal-config-room',
    templateUrl: './config-room.component.html',
    styleUrls: ['./config-room.component.scss']
})
export class ConfigRoomComponent implements OnInit {

    @Input() max: any;
    @Input() badrooms: any;
    @Input() bedroomArea: any;
    @Input() config: any;

    constructor() {}

    ngOnInit(): void {
    }

}
