<div class="form-search">
    <form name="formSearchAccommodation" #formSearchAccommodation="ngForm" (ngSubmit)="searchAccommodation(formSearchAccommodation)">

        <!-- <div class="fs-header">
            <select [(ngModel)]="room.accommodation" required name="accommodation">
                <option [value]="null">Acomodação</option>
                <option *ngFor="let accommodation of [0,1,2,3,4,5,6,7,9]"
                        [value]="accommodation + 1">
                    {{ accommodation + 1}}
                </option>
            </select>
        </div>

        <div class="fs-body">
            <select [(ngModel)]="room.pax" required name="pax">
                <option [value]="null">Adultos</option>
                <option *ngFor="let adult of pax; let ind = index;"
                        [value]="ind + 1">
                    {{ ind + 1}}
                </option>
            </select>

            <select [(ngModel)]="room.children" (change)="changeAgeChildren(room.children)" name="children">
                <option [value]="null">Crianças</option>
                <option *ngFor="let child of children; let ind = index;"
                        [value]="ind + 1">
                    {{ ind + 1}}
                </option>
            </select>
        </div>

         -->
         <strong>{{ !isMobile ? 'Tipo de hospedagem' : 'Hospedagem'}}</strong>

        <div *ngIf="this.config.accommodation_type == 1 || typeAcomodation == 1">
            <div class="row">
                <div class="col-md-4 group-container">
                    <span>{{ isMobile ? 'Quantidade de acomodações' : 'Acomodação'}}</span>
                    <div class="group">
                        <button [disabled]="room.accommodation === 1" (click)="changeInput($event, 'accommodation', 'decrease')"  color="primary" mat-icon-button mat-stroked-button>
                            <mat-icon>remove</mat-icon>
                        </button>
                        <input [(ngModel)]="room.accommodation" name="accommodation" readonly type="text">
                        <button [disabled]="this.config.max_qtd_accommodation && this.config.max_qtd_accommodation <= room.accommodation" (click)="changeInput($event, 'accommodation', 'plus')"  color="primary" mat-icon-button mat-stroked-button>
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="col-md-4 group-container">
                    <span>{{ isMobile ? 'Quantidade de adulto(s)' : 'Adultos'}}</span>
                    <div class="group">
                        <button [disabled]="room.pax === configRoom.min_pax"
                                (click)="changeInput($event, 'pax', 'decrease')"
                                color="primary" mat-icon-button mat-stroked-button>
                            <mat-icon>remove</mat-icon>
                        </button>
                        <input [(ngModel)]="room.pax" name="pax" readonly type="text">
                        <button (click)="changeInput($event, 'pax', 'plus')"
                                color="primary" mat-icon-button mat-stroked-button
                                [disabled]="room.pax === configRoom.max_pax">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="col-md-4 group-container">
                    <span>{{ isMobile ? 'Quantidade de criança(s)' : 'Crianças'}}</span>
                    <div class="group">
                        <button [disabled]="room.children === 0"
                                (click)="changeInput($event, 'children', 'decrease')" color="primary" mat-icon-button mat-stroked-button>
                            <mat-icon>remove</mat-icon>
                        </button>
                        <input [(ngModel)]="room.children" name="children" readonly type="text">
                        <button (click)="changeInput($event, 'children', 'plus')"
                                color="primary" mat-icon-button mat-stroked-button
                                [disabled]="room.children === configRoom.max_chd">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="fs-body ages" *ngFor="let age of [].constructor(room.children); let ind = index">
                        <div class="title">Idade criança {{ ind + 1 }}</div>
                        <div class="age">
                            <select [(ngModel)]="room.childrenAges[ind]" required [name]="'ageChildren' + (ind + 1)">
                                <option [value]="null">idade</option>
                                <ng-template #listAgeFormSearch [ngTemplateOutlet]="listAgeFormSearch"
                                             *ngFor="let age of [].constructor(18); let ind = index;">
                                    <option
                                        *ngIf="ind >= config?.min_age_chd && ind <= config?.max_age_chd"
                                        [value]="ind">
                                        {{ ind === 0 ? 'até 1 ano' : ind + ' anos' }}
                                    </option>
                                </ng-template>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="this.config.accommodation_type == 2 || typeAcomodation == 2">
            <div class="row">
                <div class="col-md-12 group-container d-flex justify-content-between flex-row">
                    <span>{{ isMobile ? 'Quantidade de hóspedes' : 'Hóspedes'}}</span>
                    <div class="group">
                        <button [disabled]="room.accommodation === 1" (click)="changeInput($event, 'accommodation', 'decrease')"  color="primary" mat-icon-button mat-stroked-button>
                            <mat-icon>remove</mat-icon>
                        </button>
                        <input [(ngModel)]="room.accommodation" name="accommodation" readonly type="text">
                        <button (click)="changeInput($event, 'accommodation', 'plus')"  color="primary" mat-icon-button mat-stroked-button>
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="this.config.accommodation_type == 3 && typeAcomodation == null">
            <div class="row">
                <div class="col-md-12 group-container group-container-acomodation d-flex justify-content-between flex-row">
                    <div class="box-text">
                        <strong>Hospedagem privativa</strong>
                        <span>A sua acomodação será quarto exclusivo</span>
                    </div>
                    <div class="">
                        <button (click)="selectAcomodaction('traditional')" type="button"
                                aria-label="Selecionar" color="primary" class="select-acomodation-type">
                            selecionar
                        </button>
                    </div>
                </div>
                <div class="col-md-12 group-container group-container-acomodation d-flex justify-content-between flex-row">
                    <div class="box-text">
                        <strong>Hospedagem compartilhada</strong>
                        <span>A sua acomodação será em quarto compartilhado</span>
                    </div>
                    <div class="">
                        <button (click)="selectAcomodaction('hostel')" type="button"
                                aria-label="Selecionar" color="primary" class="select-acomodation-type">
                            selecionar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <button *ngIf="typeAcomodation == 1 || typeAcomodation == 2" (click)="typeAcomodation = null" matRipple class="tipe-acomodation">Tipo de acomodação </button>
        <button *ngIf="typeAcomodation == 1 || typeAcomodation == 2 || acomodationType == 1  || acomodationType == 2" matRipple type="submit" class="fs-button">buscar melhores ofertas</button>

    </form>
</div>


