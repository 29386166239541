import { Component, Input, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { AppDataService } from '../../../services/app-data.service';

@Component({
  selector: 'my-portal-destiny',
  templateUrl: './destiny.component.html',
  styleUrls: ['./destiny.component.scss']
})
export class DestinyComponent implements OnInit {

  @Input() initialize: any;
  @Input() load: any;
  @Input() personId: any;
  @Input() moduleData: any;

  destinations: any;


configInterna: SwiperOptions = {
  pagination: {
      el: '.pagination-accommodation',
      clickable: true,
  },
  spaceBetween: 30,
  slidesPerView: 3,
  autoHeight: true,
  // Responsive breakpoints
  breakpoints: {
      // when window width is >= 320px
      320: {
          slidesPerView: 1,
          spaceBetween: 20,
          centeredSlides: true
      },
      // when window width is >= 480px
      480: {
          slidesPerView: 1,
          spaceBetween: 30,
          centeredSlides: true,
      },
      // when window width is >= 640px
      640: {
          slidesPerView: 4,
          spaceBetween: 30
      }
  }
  };

  constructor(private appData: AppDataService) { }

  ngOnInit(): void {
    // const id = 26;
    // this.appData.getDestination()
    //     .subscribe((response: any) => {
    //         console.log(response);
    //         //this.destinations = response;
    //     })
    // console.log(this.moduleData)
  }

}
