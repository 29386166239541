import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppDataService } from '../../../services/app-data.service';
import { Router } from '@angular/router';
import { DocumentService } from '../../../services/document.service';
import { CartService } from '../../../services/cart.service';

@Component({
    selector: 'my-portal-dialog-finish-confirmation',
    templateUrl: './dialog-finish-confirmation.component.html',
    styleUrls: ['./dialog-finish-confirmation.component.scss'],
})
export class DialogFinishConfirmationComponent implements OnInit {

    isLoadingFinalization: boolean = false;
    constructor(
        private dialogRef: MatDialogRef<DialogFinishConfirmationComponent>,
        private appService: AppDataService,
        private cartService: CartService,
        private route: Router,
        private documentService: DocumentService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    selectRemainingAccommodations(): void {
      const id = 'accommodation' + (this.data.isAllAccommodationsSelected.unselected_accomodations[0] - 1);
      const element: HTMLElement | any = this.documentService.getWindow()?.document.getElementById(id);
      if (element) {
          this.appService.scroll(id);
          this.closeDialog();
      } else {
        this.appService.scroll('nextSearchLoad');
        this.closeDialog();
      }
    }

    finish() {
      this.isLoadingFinalization = true;
      this.cartService.saveDataCart(this.data.items).subscribe();

        this.appService.getDataSupplements(this.data.isAllAccommodationsSelected.supplements).subscribe(
            (res: any) => {
                if (res.data) {
                    const hotelsId: any = Object.keys(res.data);

                    const haveSupplements = hotelsId.some((hotel: any) => res.data[hotel].some((room: any) => room.length));
                    
                    if (!haveSupplements) {
                        this.route.navigate(['sale']);
                        this.closeDialog();
                    } else {
                        this.route.navigate(['sale/adicionais']);
                        this.closeDialog();
                    }
                } else {
                    this.route.navigate(['sale']);
                    this.closeDialog();
                }

                this.isLoadingFinalization = false;
            },
            (err: any) => {
                this.isLoadingFinalization = false;
                this.route.navigate(['sale']);
                this.closeDialog();
            }
        );
    }
}
