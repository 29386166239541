<div class="dialog-cart">
    <div class="close-dialog">
<!--            <mat-icon>close</mat-icon>-->
        <button mat-icon-button aria-label="Fechar Carrinho" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="cart-dialog">
        <ng-template #listDialogCartItem *ngFor="let item of arrItem" [ngTemplateOutlet]="listDialogCartItem">
            <ng-template #listDialogCartHotels *ngFor="let hotel of item?.establishments"
                         [ngTemplateOutlet]="listDialogCartHotels">
                <div *ngIf="items[item.key][hotel]?.items?.length">
                    <div class="hightlighter" *ngIf="typeSearch >= 6">
                        <h4>
                            <span translate="no" class="material-icons-outlined">room_service</span>
                            {{ items[item.key][hotel].name }}
                        </h4>
                        <div class="divider"></div>
                    </div>

                    <div class="list-item-container">
                        <my-portal-dialog-cart-item
                            *ngIf="items[item.key][hotel]"
                            [itemCart]="items[item.key][hotel].items"
                            [type]="item.key"
                            (itemEvent)="itemEventDialog($event)">
                        </my-portal-dialog-cart-item>
                    </div>

                </div>
            </ng-template>
        </ng-template>
    </div>
    <div mat-dialog-actions>
        <div class="container-buttons">
            <div class="label-and-total">
                <div class="label">valor total no carrinho</div>
                <div class="total">{{ items.total | currency }}</div>
            </div>
            <button *ngIf="!checkLocation" (click)="onNoClick()" class="default continue" matRipple>continuar comprando</button>
            <button *ngIf="!checkLocation" (click)="finish()" class="default" matRipple [disabled]="isLoadingFinalization">
                {{ checkLocation ? 'fechar' : isLoadingFinalization ? 'carregando' : 'finalizar compra' }}
            </button>
        </div>
    </div>
</div>

