import {HttpClient} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {environment} from 'projects/new-portal/src/environments/environment';
import {AppDataService} from '../../../services/app-data.service';
import {InitializerService} from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-transfers',
    templateUrl: './transfers.component.html',
    styleUrls: ['./transfers.component.scss']
})
export class TransfersComponent implements OnInit {

    @Input() moduleData: any;
    transfers: any;
    ImgSrc = environment.IMAGES_ENDPOINT;
    dataOtas: any = null;

    constructor(
        private appData: AppDataService,
        private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.initializerService.currentData
            .subscribe((data: any) => {
                if (data) {
                    if (data.otas.id && data.otas.pessoa_id) {
                        const obj = {
                            ambience_sale_id: data.otas.id,
                            person_id: data.otas.pessoa_id,
                            featured: 'Y'
                        };
                        this.dataOtas = data.otas;
                        this.appData.getDataTransfers(obj).subscribe((response: any) => {
                            this.transfers = Object.values(response.transfers);
                            for (const transfer of this.transfers) {
                                transfer.transfer.slug = this.appData.createSlugUrl(
                                    transfer.transfer.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                            }
                        });
                    }
                }
            })
            .unsubscribe();
    }

}
