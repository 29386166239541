import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'my-portal-socials',
  templateUrl: './socials.component.html',
  styleUrls: ['./socials.component.scss']
})
export class SocialsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
