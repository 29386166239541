<div class="slide-gallery">
    <div class="slide">
        <div class="slide-wrapper">
            <ng-template #slideGalleryImages *ngFor="let image of images"
                         [ngTemplateOutlet]="slideGalleryImages">
                <figure class="mySlides fade">
                    <img [src]="imgServer + 'width=' + 800 + imgServerUrlEnd + image" class="img-fluid" alt="imagem">
                </figure>
            </ng-template>
            <button class="navigator" matRipple (click)="plusSlides(-1)">
                <span translate="no" class="material-icons">arrow_back_ios_new</span>
            </button>
            <button class="navigator right" matRipple (click)="plusSlides(1)">
                <span translate="no" class="material-icons">arrow_forward_ios</span>
            </button>
        </div>
    </div>
    <div class="thumbs">
        <ng-template #slideGalleryThumbs *ngFor="let image of images; let i = index"
                     [ngTemplateOutlet]="slideGalleryThumbs">
            <button class="img-thumb demo" matRipple [style]="'background: url(' + imgServer + 'width=' + 100 + imgServerUrlEnd +  image + ')'" (click)="currentSlide(i + 1)"></button>
        </ng-template>
    </div>
</div>
