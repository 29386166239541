import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppDataService } from '../../services/app-data.service';
import * as moment from 'moment';
import { InitializerService } from '../../services/initializer.service';
import { environment } from 'projects/new-portal/src/environments/environment';

@Component({
    selector: 'my-portal-card-campaign',
    templateUrl: './card-campaign.component.html',
    styleUrls: ['./card-campaign.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('moveCard', [
            state(
                'original',
                style({
                    transform: 'translateY(0)',
                })
            ),
            state(
                'moved',
                style({
                    transform: 'translateY(calc(100% - 22px))',
                })
            ),
            transition('original => moved', [animate('1s cubic-bezier(0.25, 0.1, 0.25, 1)')]),
            transition('moved => original', [animate('1s cubic-bezier(0.25, 0.1, 0.25, 1)')]),
        ]),
    ],
})
export class CardCampaignComponent implements OnInit {
    @Output() closeCardEvent = new EventEmitter<boolean>();
    private isSafari: any = this.initializerService.isSafari();
    public showTimer!: boolean;
    public widget$!: Observable<any>;
    public moveState: string = 'original';
    public imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    public imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

    constructor(private appData: AppDataService, private initializerService: InitializerService) {}

    ngOnInit(): void {
        this.widget$ = this.appData.getFloatWidgets().pipe(
            tap(response => {
                this.showTimer =
                moment().format('YYYY-MM-DD')
                >=
                moment(response.data?.expiration_at).subtract(response.data?.count_in, 'days').format('YYYY-MM-DD');
            }),
            map(response => response.data)
        );
    }

    public triggerAnimation(): void {
        this.moveState = this.moveState === 'original' ? 'moved' : 'original';
    }

    public closeCard(): void {
        this.closeCardEvent.emit(true);
    }
}
