import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppDataService} from '../../../services/app-data.service';
import {DocumentService} from '../../../services/document.service';

@Component({
    selector: 'my-portal-gallery-item',
    templateUrl: './gallery-item.component.html',
    styleUrls: ['./gallery-item.component.scss']
})
export class GalleryItemComponent implements OnInit {

    galleryName: any;
    galleryDescription: any;
    @Input() limitImages: any;
    galleryItem: any;
    load: boolean = true;

    constructor(private appData: AppDataService,
                private activatedRouter: ActivatedRoute) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.appData.scroll('gallery');
        }, 25);
        this.appData.getDataGallery(this.activatedRouter.snapshot.params.galleryId)
            .subscribe((galleryItem: any) => {
                this.galleryName = galleryItem.data.title;
                this.galleryDescription = galleryItem.data.description;
                // this.galleryItem = galleryItem.data.items;

                // this.galleryItem = galleryItem.data;
                this.galleryItem = {
                    images: galleryItem.data.items,
                };

                this.load = false;
            });
    }

}
