import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AppDataService} from '../../services/app-data.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogPolicyComponent} from '../dialog-policy/dialog-policy.component';
import {DocumentService} from '../../services/document.service';
import { Subscription } from 'rxjs';
import { CartService } from '../../services/cart.service';

@Component({
    selector: 'my-portal-privacy-policy',
    template: `
        <div *ngIf="showPolicyPrivacy">
            <mat-checkbox [(ngModel)]="checked" [color]="'primary'" (click)="openPolicy()">
                Li e aceito política de privacidade
            </mat-checkbox>
        </div>
        `,
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit, OnChanges, OnDestroy {
    currentCartItemsSubscription$!: Subscription;

    items: any;
    policy: any;
    checked: boolean = false;
    showPolicyPrivacy: boolean = true;
    personId: any = this.documentService.getWindow()?.sessionStorage.getItem('person');
    @Output() policyEvent = new EventEmitter();
    typeSearch: number = Number(this.documentService.getWindow()?.sessionStorage.getItem('typeSearch'));
    hotels: any;
    @Input() resetPolicyCheckbox?: boolean = false;
    @Input() isPrivacyPurchaseConfiguration?: boolean;


    constructor(private appService: AppDataService,
                public dialog: MatDialog,
                private cartService: CartService,
                private documentService: DocumentService) {
    }

    ngOnInit(): void {
            if (!this.isPrivacyPurchaseConfiguration) {
                this.getPolicy();
            } 

            if (this.isPrivacyPurchaseConfiguration) {
                this.currentCartItemsSubscription$ = this.cartService.currentDataCartItems.subscribe((response: any) => {
                    if (response) {
                        this.items = response;
                        this.getPolicy(this.generateObjForDataPolicy());
                    }
                });
            }
    }

    generateObjForDataPolicy(): any {
        const obj: any = {};

        for (const hotel of Object.keys(this.items.hotels)) {
            obj[hotel] = {
                items: []
            };
            for (const itemCart of this.items.hotels[hotel].items) {
                obj[hotel].items.push({
                    tariff_id: itemCart.tarifa_id,
                    check_in: itemCart.check_in,
                    check_out: itemCart.check_out,
                });
            }
        }

        return obj;
    }

    getPolicy(data?: any): void {                
        this.appService.getDataPolicy(data)
            .subscribe((response: any) => {
                this.showPolicyPrivacy = true;
                this.policy = response.data;
                this.hotels = (this.typeSearch < 6) ? this.policy : this.policy.privacity;
                if (this.hotels) {
                    for (const hotel of Object.keys(this.hotels)) {
                        if (this.typeSearch < 6) {
                            if (!this.hotels[hotel].policies.privacity) {
                                this.policyEvent.emit(true);
                                this.showPolicyPrivacy = false;
                            }
                        }
                    }
                } else {
                    this.policyEvent.emit(true);
                    this.showPolicyPrivacy = false;
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.resetPolicyCheckbox?.currentValue) {
            this.checked = false;
        }
    }

    openPolicy(): void {
        const checked = !this.checked ? true : false;
        this.policyEvent.emit(checked);
        if (checked) {
            this.dialog.open(DialogPolicyComponent, {
                data: {
                    policyData: this.hotels,
                    policyName: 'Política de privacidade',
                    privacy: false,
                    portal: (this.typeSearch < 6) ? false : true
                },
                autoFocus: false,
            });
        }
    }

    ngOnDestroy(): void {
        if (this.currentCartItemsSubscription$) {
            this.currentCartItemsSubscription$.unsubscribe();
        }
    }

}
