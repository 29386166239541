import {Component, Input, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {AppDataService} from '../../services/app-data.service';
import { DocumentService } from '../../services/document.service';
import { InitializerService } from '../../services/initializer.service';

@Component({
    selector: 'my-portal-testimonies',
    templateUrl: './testimonies.component.html',
    styleUrls: ['./testimonies.component.scss']
})
export class TestimoniesComponent implements OnInit {

    @Input() moduleData: any | null;
    @Input() personId: any | null;
    @Input() typeSlide: boolean = false;
    @Input() formVisible: boolean = true;

    testimonies: any | null;
    load: boolean = true;
    typeSearch: any = Number(this.documentService.getWindow()?.sessionStorage?.getItem('typeSearch'));
    hotelsList: any;
    showTestimoniesComponent: any;

    filterForm = new FormControl();

    constructor(private appData: AppDataService,
                private documentService: DocumentService,
                private initializerService: InitializerService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.initializerService.currentData.subscribe((response: any) => {
            const {only_motor, only_site} = response;

            if (only_motor && !only_site) {
                this.router.navigateByUrl('/');
            }
        }).unsubscribe();

        if (!this.moduleData) {
            this.moduleData = {
                title: 'Depoimentos',
                description: 'Confira o que nossos clientes estão falando de nós'
            };
        }
        this.getTestimonies();

        this.filterForm.valueChanges.subscribe((hotelId:any) => {
            this.getTestimonies(hotelId?.id);
       });

    }

    getTestimonies(hotelId?: any): void {
        const url: string = 'testimony';
        this.load = true;

        this.appData.getDataModule(url, hotelId ? hotelId : this.personId)
            // tslint:disable-next-line:no-any
            .subscribe((response: any) => {
                response.data.testimony == false ? this.showTestimoniesComponent = response.data.testimony : this.showTestimoniesComponent = true;
                this.testimonies = response.data.items;
                if (!this.hotelsList) {
                    this.getHotelsList(response.data.hotels);
                }
                this.load = false;
            });
    }

    getHotelsList(hotels: any): void {
        if (this.typeSearch >= 6) {
            let hotelsList: any = [
                {
                    id: this.personId,
                    name: 'Todos',
                }
            ];

            for(let hotel of hotels) {
                hotelsList.push({
                    id: hotel,
                    name: hotel.nome,
                });
            }

            this.hotelsList = hotelsList;

        }
    }

    filterTestimonies(testimonies: any): void {}
}
