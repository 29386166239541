<div class="campaign-form-container">
    <div class="campaign-header">
        <h3>{{ leadData.form_title }}</h3>
    </div>

    <ng-container *ngIf="!isLoadingForm">
        <form class="campaign-form" [formGroup]="campaignForm" (ngSubmit)="onSubmit()">
            <div class="form-group-container">
                <div class="form-group-item">
                    <!-- <label for="name">*Nome:</label> -->
                    <div class="input-container">
                        <input type="text" id="name" placeholder="Digite seu nome" formControlName="name">
                    </div>
                    <span class="error-message" *ngIf="campaignForm.get('name').hasError('required') && campaignForm.get('name').touched">
                        O nome é obrigatório
                    </span>
                </div>

                <div class="form-group-item">
                    <!-- <label for="phone">*WhatsApp:</label> -->

                    <div class="input-container">
                        <input type="text" id="phone" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'br', preferredCountries: ['BR', 'UY', 'AR', 'PY', 'BO', 'PE', 'CO', 'VE', 'GY', 'SR', 'GF']}"
                                (countryChange)="onCountryChange($event)"
                                [placeholder]="phone.sigla == 'br' ? '(00) 0 0000-0000' :
                                'Digite seu telefone'" [mask]="phone.sigla == 'br' ? '(00) 0000-0000 || (00) 0 0000-0000' : '0000000000000000'"
                                formControlName="whatsapp" [validation]="phone.sigla == 'br'" required >
                    </div>

                    <span class="error-message" *ngIf="campaignForm.get('whatsapp').invalid && campaignForm.get('whatsapp').touched">
                        O WhatsApp é obrigatório
                    </span>
                </div>

                <div class="form-group-item">
                    <!-- <label for="email">*E-mail:</label> -->

                    <div class="input-container">
                        <input type="email" id="email" placeholder="Digite seu e-mail" formControlName="email">
                    </div>

                    <span class="error-message" *ngIf="campaignForm.get('email').hasError('required') && campaignForm.get('email').touched || campaignForm.get('email').hasError('email') && campaignForm.get('email').touched">
                        O e-mail é obrigatório
                    </span>
                </div>

                <ng-container *ngIf="leadData.inputs.travel_period">
                    <div class="form-group-item">
                        <!-- <label for="message">*Período da viagem:</label> -->

                        <div class="input-container" formGroupName="travel_period">
                            <mat-form-field class="hidden-item">
                                <mat-date-range-input [rangePicker]="pickerDateTravel">
                                    <input matStartDate placeholder="Start date" #startDateTravel formControlName="start">
                                    <input matEndDate placeholder="End date" #endDateTravel (dateChange)="dateSelectedTravel(startDateTravel, endDateTravel)" formControlName="end">
                                </mat-date-range-input>
                                <mat-date-range-picker #pickerDateTravel></mat-date-range-picker>
                            </mat-form-field>

                            <button type="button" (click)="openPickerDateTravel()" class="inner-button">
                                <div class="info">
                                    <!-- prettier-ignore  -->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-calendar-days"><path d="M8 2v4" /><path d="M16 2v4" /><rect width="18" height="18" x="3" y="4" rx="2" /><path d="M3 10h18" /><path d="M8 14h.01" /><path d="M12 14h.01" /><path d="M16 14h.01" /><path d="M8 18h.01" /><path d="M12 18h.01" /><path d="M16 18h.01" /></svg>

                                    <span>{{ selectedDateTravel || 'Período da viagem' }}</span>
                                </div>

                                <!-- prettier-ignore  -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6" /></svg>
                            </button>
                        </div>

                        <span class="error-message" *ngIf="campaignForm.controls.travel_period.controls.start.touched && campaignForm.controls.travel_period.controls.start.errors?.required || campaignForm.controls.travel_period.controls.end.touched && campaignForm.controls.travel_period.controls.end.errors?.required">
                            O período da viagem é obrigatório
                        </span>
                    </div>
                </ng-container>
                <ng-container *ngIf="leadData.inputs.event_period">
                    <div class="form-group-item">
                        <!-- <label for="message">*Período do evento:</label> -->

                        <div class="input-container" formGroupName="event_period">
                            <mat-form-field class="hidden-item">
                                <mat-date-range-input [rangePicker]="pickerDateEvent">
                                    <input matStartDate placeholder="Start date" #startDateEvent formControlName="start">
                                    <input matEndDate placeholder="End date" #endDateEvent (dateChange)="dateSelectedEvent(startDateEvent, endDateEvent)" formControlName="end">
                                </mat-date-range-input>
                                <mat-date-range-picker #pickerDateEvent></mat-date-range-picker>
                            </mat-form-field>

                            <button type="button" (click)="openPickerDateEvent()" class="inner-button">
                                <div class="info">
                                    <!-- prettier-ignore  -->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-calendar-days"><path d="M8 2v4" /><path d="M16 2v4" /><rect width="18" height="18" x="3" y="4" rx="2" /><path d="M3 10h18" /><path d="M8 14h.01" /><path d="M12 14h.01" /><path d="M16 14h.01" /><path d="M8 18h.01" /><path d="M12 18h.01" /><path d="M16 18h.01" /></svg>

                                    <span>{{ selectedDateEvent || 'Período do evento' }}</span>
                                </div>

                                <!-- prettier-ignore  -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6" /></svg>
                            </button>
                        </div>

                        <span class="error-message" *ngIf="campaignForm.controls.event_period.controls.start.errors?.required && campaignForm.controls.event_period.controls.start.touched || campaignForm.controls.event_period.controls.end.errors?.required && campaignForm.controls.event_period.controls.end.touched">
                            O período da viagem é obrigatório
                        </span>
                    </div>
                </ng-container>

                <ng-container *ngIf="leadData.inputs.qtd_guest">
                    <div class="form-group-item">
                        <!-- <label for="message">*Hóspedes:</label> -->

                        <div class="input-container">
                            <button  class="inner-button" [matMenuTriggerFor]="guestsMenu" type="button">
                                <!-- prettier-ignore  -->
                                <div class="info">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-round"><circle cx="12" cy="8" r="5" /><path d="M20 21a8 8 0 0 0-16 0" /></svg>

                                    <span [ngPlural]="selectedGuests">
                                        {{ selectedGuests }}
                                        <ng-template ngPluralCase="=1">hóspede</ng-template>
                                        <ng-template ngPluralCase="other">hóspedes</ng-template>
                                    </span>
                                </div>

                                <!-- prettier-ignore  -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6" /></svg>
                            </button>

                            <mat-menu #guestsMenu="matMenu">
                                <ng-container *ngFor="let guest of [].constructor(10); let i = index">
                                    <button mat-menu-item (click)="selectGuests(i + 1)" type="button" class="selection-button">
                                        <span [ngPlural]="i + 1">
                                            {{ i + 1 }}
                                            <ng-template ngPluralCase="=1">hóspede</ng-template>
                                            <ng-template ngPluralCase="other">hóspedes</ng-template>
                                        </span>
                                    </button>
                                </ng-container>
                            </mat-menu>
                        </div>

                        <span class="error-message" *ngIf="campaignForm.get('guests')?.hasError('required') && campaignForm.get('guests').touched">
                            O número de hóspedes é obrigatório
                        </span>
                    </div>
                </ng-container>

                <ng-container *ngIf="leadData.inputs.travel_reason">
                    <div class="form-group-item">
                        <!-- <label for="message">*Motivo da viagem:</label> -->

                        <div class="input-container">
                            <button class="inner-button" [matMenuTriggerFor]="reasonForTheTrip" type="button" >
                                <span>{{ selectedReason || 'Motivo da viagem' }}</span>

                                <!-- prettier-ignore  -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6" /></svg>
                            </button>

                            <mat-menu #reasonForTheTrip="matMenu">
                                <ng-container *ngFor="let reason of reasonForTheTripList">
                                    <button mat-menu-item (click)="selectReason(reason)" type="button" class="selection-button">
                                        <span>{{ reason.label }}</span>
                                    </button>
                                </ng-container>
                            </mat-menu>
                        </div>

                        <span class="error-message" *ngIf="campaignForm.get('travel_reason')?.hasError('required') && campaignForm.get('travel_reason').touched">
                            O motivo da viagem é obrigatório
                        </span>
                    </div>
                </ng-container>
            </div>

            <div class="form-group-container w-full">
                <div class="form-group-item">
                    <!-- <label for="observation">*Observações:</label> -->
                    <div class="input-container">
                        <input type="text" id="observation" placeholder="Digite suas observações" formControlName="observation">
                    </div>

                    <span class="error-message" *ngIf="campaignForm.get('observation')?.hasError('required') && campaignForm.get('observation').touched">
                        O campo observações é obrigatório
                    </span>
                </div>
            </div>

            <div class="actions-container">
                <button type="submit" class="submit-button" [disabled]="isSendingLead">
                    <span *ngIf="isSendingLead">Enviando...</span>
                    <span *ngIf="!isSendingLead">{{ leadData.submit_content }}</span>
                </button>
            </div>
        </form>
    </ng-container>
</div>
