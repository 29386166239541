import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CartService} from '../../services/cart.service';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

import {MatDialog} from '@angular/material/dialog';
import {DialogCartComponent} from '../dialog-cart/dialog-cart.component';
import {InitializerService} from '../../services/initializer.service';
import {DocumentService} from '../../services/document.service';
import { AppDataService } from '../../services/app-data.service';
import { DialogFinishConfirmationComponent } from '../../features/tariffs/dialog-finish-confirmation/dialog-finish-confirmation.component';

@Component({
    selector: 'my-portal-popover-cart',
    animations: [
        trigger('openClose', [
            state('open', style({
                bottom: '0'
            })),
            state('closed', style({
                bottom: '-100px'
            })),
            transition('closed => open', [
                animate('0.25s')
            ]),
        ]),
    ],
    template: `
        <div class="popover-cart" [@openClose]="items.count ? 'open' : 'closed'" [hidden]="!items.count || hiddenCart">
            <div class="container">
                <div class="box-cart">
                    <div class="icon-cart" (click)="openCart()" matRipple>
                        <figure>
                            <div class="budget notranslate">{{ items.count }}</div>
                            <img src="assets/images/ico-carrinho.svg">
                        </figure>
                    </div>
                    <div *ngIf="getScreenWidth">
                        <div class="total-mobile">
                            <strong>Total do pedido </strong>
                            {{ items?.total | currency }}
                        </div>
                    </div>
                    <div class="content">
                        <my-portal-item (itemEvent)="updateItems($event)"></my-portal-item>
                    </div>
                    <div class="footer">
                        <div class="total notranslate" *ngIf="!getScreenWidth">
                            <strong>Total: </strong>
                            {{ items?.total | currency }}
                        </div>
                        <button matRipple (click)="finish()" [disabled]="isLoadingFinalization">
                            {{ getScreenWidth ? '' : isLoadingFinalization ? 'Carregando' : 'Finalizar' }}
                            <mat-icon *ngIf="getScreenWidth">navigate_next</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>`,
    styleUrls: ['./popover-cart.component.scss']
})
export class PopoverCartComponent implements OnInit {

    items: any = this.cartService.getItems();
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    hiddenCart: boolean = false;
    arrItem: any[] = [
        {key: 'hotels', title: 'Hospedagem', establishments: Object.keys(this.items.hotels)},
    ];
    haveSupplements: boolean = true;

    isLoadingFinalization: boolean = false;
    objAccommodationsInCart: any = null;
    isAllAccommodationsSelected: any = {
        unselected_accomodations: [],
        all_selected: false,
    };

    constructor(private route: Router,
                private cartService: CartService,
                private dialog: MatDialog,
                private initializerService: InitializerService,
                private documentService: DocumentService,
                private activatedRouter: ActivatedRoute,
                private appService: AppDataService) {

        this.activatedRouter.paramMap.subscribe(params => {
            this.hiddenCart = (this.route.url.includes('/sale'));
        });

        route.events.subscribe((val) => {
            this.hiddenCart = (this.route.url.includes('/sale'));
        });
    }

    ngOnInit(): void {
        this.cartService.currentDataCartItems.subscribe((response: any) => {
            this.items = response;
            this.arrItem[0].establishments = Object.keys(this.items.hotels);
        });

        this.cartService.currentAccommodationsInCart.subscribe((response: any) => {
            if (response) {
                this.objAccommodationsInCart = response;
            }
        })
    }

    openCart(): void {
        this.dialog.open(DialogCartComponent, {
            maxWidth: (this.getScreenWidth) ? '100vw' : 'auto',
            maxHeight: (this.getScreenWidth) ? '100vh' : 'auto',
            height: (this.getScreenWidth) ? '100%' : 'auto',
            width: (this.getScreenWidth) ? '100%' : '760px',
            autoFocus: false
        });
    }

    finish(): void {
        this.isAllAccommodationsSelected.unselected_accomodations = [];
        this.isAllAccommodationsSelected.all_selected = false;


        this.documentService.getWindow()?.localStorage.setItem('cart', JSON.stringify(this.items));

        const supplementParams = [];

        for (const item of this.arrItem) {
            for (const id of item.establishments) {
                const hotels = this.items.hotels[id].items;
                for (const hotel of hotels ) {
                    supplementParams.push({
                        hotelId : hotel.pessoa_id,
                        checkIn : hotel.check_in,
                        quartoId : hotel.tarifa_id,
                    })
                }
            }
        }
        

        for (const accommodation in this.objAccommodationsInCart) {
            if (!this.objAccommodationsInCart[accommodation]) {
                this.isAllAccommodationsSelected.unselected_accomodations.push(accommodation);
            }
        }

        if (this.isAllAccommodationsSelected.unselected_accomodations.length === 0) {
            this.isAllAccommodationsSelected.all_selected = true;
        }

        this.cartService.handleAccommodationsInCart(this.objAccommodationsInCart);


        if (this.isAllAccommodationsSelected.all_selected) {
            this.isLoadingFinalization = true;
            this.cartService.saveDataCart(this.items).subscribe();

            this.appService.getDataSupplements(supplementParams)
                .subscribe(
                    (res: any) => {
                        if (res.data) {
                            const hotelsId: any = Object.keys(res.data);
    
                            const haveSupplements = hotelsId.some((hotel: any) => res.data[hotel].some((room: any) => room.length));
    
                            if (!haveSupplements) {
                                this.route.navigate(['sale']);
                            } else {
                                this.route.navigate(['sale/adicionais']);
                            }
                        } else {
                            this.route.navigate(['sale']);
                        }

                        this.isLoadingFinalization = false;
                    },
                    (err: any) => {
                        this.isLoadingFinalization = false;
                        this.route.navigate(['sale']);
                    }
                );
        } else {
            this.isAllAccommodationsSelected.supplements = supplementParams;
            this.dialog.open(DialogFinishConfirmationComponent, {
              data: {isAllAccommodationsSelected: this.isAllAccommodationsSelected, items: this.items},
            });
        }
    }

    updateItems($event: any): void {
        this.items = this.cartService.getItems();
        // this.cartItems.emit(this.items);
    }

}
