<section>
    <my-portal-highlighter [module]="{title: 'Nossos estabelecimentos'}"></my-portal-highlighter>

    <div class="row mb-5">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <mat-tab-group mat-stretch-tabsBo (selectedTabChange)="onTabChange()">
                <mat-tab class="client-theme-color" *ngFor="let hotelLabel of data.cities" [label]="hotelLabel | uppercase">
                    <swiper class="mt-3" [config]="config" >
                        <div class="swiper-wrapper">
                            <div *ngFor="let hotel of data.hotels" class="p-1 swiper-slide">
                                <div class="hotels">
                                    <figure class="hotels-image">
                                        <img src="https://images.focomultimidia.com/curl/motor_reserva/images/quarto/cliente_734/201902041549285033CASAKUSUMASRI.jpg" class="img-fluid">
                                    </figure>

                                    <div class="p-3 border border-top-0 hotels-content">
                                        <div class="title-container">
                                            <h6 class="mt-2">{{hotel.name}}</h6>
                                            <div class="star">
                                                <span class="material-icons-outlined" *ngFor="let st of [0,1,2,3,4]; let ind = index" [ngClass]="{'active': (ind+1) <= hotel.stars }">
                                                    star
                                                </span>
                                            </div>
                                            <div class="d-flex align-items-center stars-and-location">
                                                <span>234 avaliações</span>
                                                <div class="d-flex align-items-center location">
                                                    <i class="material-icons-outlined notranslate">location_on</i>
                                                    Salvador, BA
                                                </div>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <div class="description">
                                                <p>{{hotel.description}}</p>
                                            </div>
<!--                                            <my-portal-resources [quantity]="5"></my-portal-resources>-->
                                            <div class="price">
                                                <span>Diárias a partir de <strong>{{hotel.price}}</strong> por pessoa</span>
                                                <div class="d-flex align-items-center payment-message">
                                                    <span>Em até <strong>6x</strong> sem juros</span>
                                                    <i class="material-icons notranslate">info</i>
                                                </div>
                                            </div>
                                            <button>mais detalhes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</section>
