import {Component, Input, OnInit} from '@angular/core';
import {SwiperOptions} from 'swiper';
import {environment} from '../../../environments/environment';
import {DocumentService} from '../../services/document.service';
import {Router} from '@angular/router';
import {AppDataService} from '../../services/app-data.service';
import { InitializerService } from '../../services/initializer.service';

@Component({
    selector: 'my-portal-images',
    templateUrl: './images.component.html',
    styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit {

    @Input() images: any;
    @Input() roomId: any;
    @Input() allotment: any;
    @Input() galleryItem: any;
    @Input() unavailable: any;

    slideIndex = 0;
    config: SwiperOptions = {
        autoplay: true,
        effect: 'fade',
        pagination: {el: '.images-pagination', clickable: true},
        autoHeight: true,
        allowTouchMove: true,
        slidesPerView: 1,
        loop: true
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private documentService: DocumentService,
                private router: Router,
                private appService: AppDataService,
                private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
    }

    openModal(): void {

        if (this.documentService.getWindow()?.document.querySelector('header.fixed')) {
            (this.documentService.getWindow()?.document.querySelector('header.fixed') as HTMLElement).style.zIndex = '1';
        }
        (<any> (this.documentService.getWindow()?.document.getElementById(`imgModal-${this.roomId}`))).style.display = 'block';
    }

    closeModal(): void {
        (<any> (this.documentService.getWindow()?.document.getElementById(`imgModal-${this.roomId}`))).style.display = 'none';
        if (this.documentService.getWindow()?.document.querySelector('header.fixed')) {
            (this.documentService.getWindow()?.document.querySelector('header.fixed') as HTMLElement).style.zIndex = '2';
        }
    }

    plusSlides(n: number): void {
        this.showSlides((this.slideIndex += n));
    }

    currentSlide(n: number): void {
        this.showSlides((this.slideIndex = n));
    }

    showSlides(n: number): void {
        let i;

        const slides = this.documentService.getWindow()?.document.getElementsByClassName(`img-slides-${this.roomId}`) as HTMLCollectionOf<HTMLElement>;

        const dots = this.documentService.getWindow()?.document.getElementsByClassName('images') as HTMLCollectionOf<HTMLElement>;

        if (n > slides.length) {
            this.slideIndex = 1;
        }
        if (n < 1) {
            this.slideIndex = slides.length;
        }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
        }
        slides[this.slideIndex - 1].style.display = 'block';
    }
}
