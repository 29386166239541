import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {DocumentService} from '../../../services/document.service';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {DefaultSnackBarComponent} from '../../../shared/default-snack-bar/default-snack-bar.component';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-form-search',
    templateUrl: './form-search.component.html',
    styleUrls: ['./form-search.component.scss'],
})
export class FormSearchComponent implements OnInit {

    horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    @Input() config: any;
    @Input() configRoom: any;
    @Input() dates: any;
    @Input() roomId: any;
    @Input() hotelId: any;
    pax: any;
    children: any;
    ageChildren: any[] | null = [];
    typeAcomodation: any = null;
    acomodationType: any

    isMobile: boolean = this.initializerService.isMobileDevice();

    room: any = {
        pax: 1,
        children: 0,
        accommodation: 1,
        childrenAges: []
    };

    constructor(
        private router: Router,
        private documentService: DocumentService,
        private snackBar: MatSnackBar,
        private initializerService: InitializerService
    ) {}

    ngOnInit(): void {
        this.children = new Array(this.config.max_qtd_chd);
        this.pax = new Array(!this.config.max_qtd_pax ? 10 : this.config.max_qtd_pax);

        this.initializerService.getInitializer(this.hotelId).subscribe((response: any) => {
            this.acomodationType = response.data.accommodation_type;
        })
    }

    changeAgeChildren(qtd: number): void {
        this.ageChildren = [];
        for (let i = 1; i <= qtd; i++) {
            this.ageChildren.push(null);
        }
        this.room.childrenAges = this.ageChildren;
    }

    changeInput(event: any, model: string, type: string): void {

        event.preventDefault();

        switch (model) {
            case 'accommodation':
                if (type === 'plus') {
                    this.room.accommodation += 1;
                } else {
                    this.room.accommodation -= 1;
                }
                this.room.accommodation = this.room.accommodation < 1 ? 1 : this.room.accommodation;
                break;

            case 'pax':
                if (type === 'plus') {
                    this.room.pax += 1;
                } else {
                    this.room.pax -= 1;
                }
                this.room.pax = this.room.pax < 1 ? 1 : this.room.pax;
                break;

            case 'children':
                if (type === 'plus') {
                    this.room.children += 1;
                } else {
                    if (this.room.children > 0) {
                        this.room.children -= 1;
                    }
                }
                this.changeAgeChildren(this.room.children);
                break;
            default:
                break;
        }
    }

    selectAcomodaction(event: any): void {
        if (event === 'traditional') {
            this.typeAcomodation = 1;
        }
        if (event === 'hostel') {
            this.typeAcomodation = 2;
        }
    }

    searchAccommodation(form: NgForm): void {
        if (form.valid) {
            let urlConfig = '';
            for (let i = 1; i <= Number(this.room.accommodation); i++) {
                urlConfig += this.room.pax;
                if (this.room.children) {
                    const chd = [];

                    for (const age of this.room.childrenAges) {
                        chd.push(age);
                    }

                    urlConfig += `-${chd.join('-')}`;
                }
                if (this.room.accommodation > 1 && i < this.room.accommodation) {
                    urlConfig += '!';
                }
            }

            if (!this.dates) {
                this.dates = {
                    start: moment().format('YYYY-MM-DD'),
                    end: moment().add(1, 'days').format('YYYY-MM-DD'),
                };
            }

            this.router.navigateByUrl(
                `search/${this.dates.start}/${this.dates.end}/${urlConfig}/${this.hotelId}/${this.roomId}`
            );
        } else {
            this.openSnackBar('Informe a idade das criança(s)');
        }
    }

    openSnackBar(msg: string): void {
        this.snackBar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg
            },
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: 'error-snack-bar',
            duration: 2500
        });
    }
}
