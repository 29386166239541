import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {CartService} from '../../../services/cart.service';
import { InitializerService } from '../../../services/initializer.service';
import { DialogCartComponent } from '../dialog-cart.component';
import { MatDialogRef } from '@angular/material/dialog';
import { TagManagerService } from '../../../services/tag-manager.service';

declare var gtag: any;

declare global {
    interface Window { dataLayer: any[]; }
}
@Component({
    selector: 'my-portal-dialog-cart-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class ItemDialogCartComponent implements OnInit {
    showSupplement: boolean = false;

    items: any = this.cartService.getItems();
    isSafari: any = this.initializerService.isSafari();
    @Input() itemCart: any;
    @Input() type: any;
    @Output() itemEvent = new EventEmitter();
    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;
    marketingData: any;
    productCartEcommerce: any[] = [];

    constructor(private cartService: CartService,
                private initializerService: InitializerService,
                public dialogRef: MatDialogRef<DialogCartComponent>,
                private tagManagerService: TagManagerService,) {}

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.initializerService.currentData.subscribe((response: any) => {
            this.marketingData = response?.marketing
        })
    }

    removeItem(type: string, index: any, personId: number): void {
        this.cartService.removeItemCart(type, index, personId);
        let removeCartRoom = this.itemCart[index];

        const removeFromCartDataEvent = {
            ...removeCartRoom,
            index
        }

        this.tagManagerService.removeFromCartEvent(removeFromCartDataEvent);

        if (this.items.count < 1) {
            this.dialogRef.close();
        }

        // for(let hotel of Object.keys(this.items.hotels)) {
        //     this.productCartEcommerce.push({
        //         'name': this.items.hotels[hotel].items[index].quarto_nome,
        //         'id': this.items.hotels[hotel].items[index].quarto_id,
        //         'price': this.items.hotels[hotel].items[index].preco,
        //         'brand': '',
        //         'category': '',
        //         'variant': '',
        //         'quantity': 1
        //     })
        // }

        // if (this.marketingData?.google_tag_manager) {
        //     if((<any> window).dataLayer) {
        //         window.dataLayer.push({
        //             'event': 'removeFromCart',
        //             'ecommerce': {
        //                 'remove': {
        //                 'products': this.productCartEcommerce
        //                 }
        //             }

        //         })
        //     }
        // }

        // if (this.marketingData?.google_analytics?.geral) {

        //     if((<any> window).gtag) {
        //         gtag('event', 'removeFromCart', {
        //             'products': this.productCartEcommerce
        //         });
        //     }
        // }

        this.itemEvent.emit(this.items);
    }

}
