import { Component, Input, OnDestroy, OnInit,  } from '@angular/core';
import { interval} from 'rxjs';

@Component({
  selector: 'my-portal-promotion-counter',
  templateUrl: './promotion-counter.component.html',
  styleUrls: ['./promotion-counter.component.scss']
})
export class PromotionCounterComponent implements OnInit, OnDestroy {

  @Input() date: any;
  @Input() type: any;

  public dateNow = new Date();
  public dDay = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  private subscription: any;

  public timeDifference: any;
  public secondsToDday: any;
  public minutesToDday: any;
  public hoursToDday: any;
  public daysToDday: any;

  constructor() {
  }

  ngOnInit(): void {
    this.dDay = new Date(this.date);

    this.subscription = interval(1000)
        .subscribe(x => {
            this.getTimeDifference();
        });
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  private getTimeDifference(): void {
      this.timeDifference = this.dDay.getTime() - new Date().getTime();
      this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference: any): void {
      this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
      this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
      this.hoursToDday = Math.floor((timeDifference)
          /  (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute)
          % this.hoursInADay);
      this.daysToDday = Math.floor((timeDifference)
          / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }
}
