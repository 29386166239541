import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'my-portal-footer-portal',
    templateUrl: './portal.component.html',
    styleUrls: ['./portal.component.scss']
})
export class PortalFooterComponent implements OnInit {
    @Input() phoneWhatsapp : any;
    @Input() footerData: any;
    @Input() data: any;
    active = 0;
    footerPhones: any

    constructor() {
    }

    ngOnInit(): void {
        this.removeZero(0)
    }

    changeFooter(index: number): void {
        this.active = index;
        this.removeZero(index)
    }

    removeZero(index: any) {

        let novoDiscagem = [];

        for (const phone of this.footerData[index].phone) {
            let numberTollFree = phone.substring(0, 4).includes('0800');

            let number;
            let type;

            number = phone;

            if (!numberTollFree) {
                number = number.replace(/\D+/g, '').replace(/^0+/, '');
            }

            if (numberTollFree) {
                type = 'tollFree'
            } else if (!numberTollFree && number.length > 10) {
                type = 'mobilePhone'
            } else if (!numberTollFree && number.length <=10) {
                type = 'landlinePhone'
            }

            novoDiscagem.push({
                number,
                type
            })

            this.footerPhones = novoDiscagem;
}

        if (!this.footerData[index].whatsapp.number) {
            this.footerData[index].whatsapp.number = ""
        }
        let whatsNewNumber = this.footerData[index].whatsapp.number.replace(/\D+/g, '').replace(/^0+/, '');
        let whatsVerification = whatsNewNumber.substring(0,4).includes('+55');

        this.footerData[index].whatsapp.number = whatsNewNumber

        if(!whatsVerification && this.footerData[index].whatsapp.number.length <= 11 && this.footerData[index].whatsapp.number.length > 0){
            const newWhats = `55${this.footerData[index].whatsapp.number}`
            this.footerData[index].whatsapp.number = newWhats
        }


    }
}
