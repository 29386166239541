import {Component, Input, OnInit} from '@angular/core';
import {AppDataService} from '../../services/app-data.service';
import { InitializerService } from '../../services/initializer.service';
import { environment } from 'projects/new-portal/src/environments/environment';

@Component({
    selector: 'my-portal-list-news',
    templateUrl: './list-news.component.html',
    styleUrls: ['./list-news.component.scss']
})
export class ListNewsComponent implements OnInit {

    @Input() news: any;
    @Input() moduleData: any;
    load = true;
    routerLinkNews: string | null = null;
    showAllNews: boolean = true;
    objNews: any = {
        first: [],
        all: []
    };

    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private appService: AppDataService,
        private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.routerLinkNews = `/categoria/${this.news.slug}/${this.news.id}`;
        if (this.moduleData) {
            this.showAllNews = (this.getScreenWidth) ? false : true;
        }

        const count = (this.getScreenWidth && this.moduleData ? 3 : 2);
        const all = new Array();

        for (const key in this.news.items) {
            if (this.news.items.hasOwnProperty(key)) {
                const item: any | null = this.news.items[key];
                // item.slug = this.appService.createSlugUrl(item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                if (Number(key) <= count) {
                    // @ts-ignore
                    this.objNews.first.push(item);
                } else {
                    // @ts-ignore
                    all.push(item);
                    // this.objNews.all.push(item);
                }
            }
        }
        if (this.moduleData) {
            this.objNews.all = all.slice(0, 6);
        } else {
            this.objNews.all = all;
        }
        this.news = this.objNews;
        setTimeout(() => {
            this.load = false;
        }, 250);
    }

}
