import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DetailDestinationsComponent} from './detail/detail.component';

const routes: Routes = [
    // {path: ':title/:id', component: DetailDestinationsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DestinationsRoutingModule { }
