<div class="slide-detail">
    <swiper [config]="configInterna">
        <div class="swiper-wrapper">
            <div *ngFor="let detailImage of images" class="swiper-slide">
                <div class="accommodation mb-2">
                    <figure class="accomodation">
                        <img src="{{imgServer + 'width=' + 825 + imgServerUrlEnd + detailImage}}" class="img-fluid w-100">
                    </figure>
                </div>
            </div>
        </div>

        <!-- Add Pagination -->
<!--        <div class="pagination-accommodation"></div>-->
        <div class="swiper-button-next-cart slideshow">
            <mat-icon>arrow_forward_ios</mat-icon>
        </div>
        <div class="swiper-button-prev-cart slideshow">
            <mat-icon>arrow_back_ios_new</mat-icon>
        </div>
    </swiper>
</div>

