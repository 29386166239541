import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit, Input, ViewChild, AfterViewInit} from '@angular/core';
import Swiper, {SwiperOptions} from 'swiper';
import {SwiperComponent} from 'ngx-useful-swiper';
import {InitializerService} from '../../../services/initializer.service';
import {DocumentService} from '../../../services/document.service';
import {AppDataService} from '../../../services/app-data.service';
import {SeoService} from '../../../services/seo.service';
import { TagManagerService } from '../../../services/tag-manager.service';

declare var fbq: any;

@Component({
    selector: 'my-portal-accomodation-detail',
    templateUrl: './accomodation-detail.component.html',
    styleUrls: ['./accomodation-detail.component.scss']
})
export class AccomodationDetailComponent implements OnInit, AfterViewInit {

    @Input() moduleData: any;
    accommodationDetail: any;
    load: any = true;
    dataFooter: any | null = this.documentService.getWindow()?.sessionStorage.getItem('footer');
    config: any;
    dateRange: any;
    roomId: any = null;

    constructor(private router: Router,
                private dataService: AppDataService,
                private activatedRouter: ActivatedRoute,
                private initializerService: InitializerService,
                private documentService: DocumentService,
                private seoService: SeoService,
                private appData: AppDataService,
                private tagManagerService: TagManagerService,
               ) {
    }

    ngOnInit(): void {
        if (!this.dataFooter) {
            this.roomId = this.activatedRouter.snapshot.params.id;
        }

        this.initializerService.currentData
            .subscribe((data: any) => {
                if (data) {
                    this.config = data;
                }
            })
            .unsubscribe();
        this.dataService.getAccommodation(this.activatedRouter.snapshot.params.id, this.activatedRouter.snapshot.params.hotelId)
            .subscribe((response: any) => {
                this.accommodationDetail = response.data;

                if (this.dataFooter) {
                    this.dataFooter = JSON.parse(this.dataFooter);
                    this.roomId = this.activatedRouter.snapshot.params.id;
                    this.load = false;
                    const clearDescription = this.accommodationDetail.header.description.replace(/(<([^>]+)>)/gi, '');
                    const objSeo = {
                        seo: {
                            title: this.accommodationDetail.name,
                            description: clearDescription,
                            image: this.accommodationDetail.header.slideshow[0],
                            keyWorks: null
                        }
                    };
                    this.seoService.updateSeo(objSeo);

                } else {
                    this.appData.getDataModule("footer")
                    .subscribe((response: any) => {
                        this.dataFooter = response.data
                        this.load = false;
                    })
                }

                this.accommodationDetail.id = this.activatedRouter.snapshot.params.id;

                this.tagManagerService.viewItemEvent(this.accommodationDetail);
                // this.createDataLayerRoom(response.data)
                this.createFacebookRoom(response.data)
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.documentService.getWindow()?.document.querySelector('#accommodation-detail')?.scrollIntoView({behavior: 'smooth'});
        }, 500);
    }

    getDatesRange(event: any): void {
        this.dateRange = event;
    }

    createDataLayerRoom(room: any): void {
        let marketing: any;

        this.initializerService.currentData.subscribe((response: any) => {
           marketing = response?.marketing
        })

        if (marketing?.google_tag_manager) {
            if((<any> window).dataLayer) {
                window.dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                      'click': {
                        'actionField': {'list': 'accommodationView'},
                        'products': [{
                          'name': room.name,
                          'id': this.roomId,
                          'price': room.price,
                          'quantity': 1
                         }]
                       }
                     },
                })
            }
        }

        if (marketing?.google_analytics.geral) {
            if((<any> window).dataLayer) {
                window.dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                    'click': {
                        'actionField': {'list': 'accommodationView'},
                        'products': [{
                        'name': room.name,
                        'id': this.roomId,
                        'price': room.price,
                        }]
                    }
                    },
                })
            }
        }
    }

    createFacebookRoom(room: any): void {

        let marketingFacebook;

        this.initializerService.currentData.subscribe((response: any) => {
            marketingFacebook = response?.marketing?.facebook_pixelid
         })

        if (marketingFacebook) {
            if((<any> window).fbq) {
                fbq('track', 'ViewContent',
                {
                    id: this.roomId,
                    name: room.name,
                    currency: "BRL",
                    value: room.price,
                    quantity: 1
                });
            }
        }

    }
}
