import {Component, OnInit, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataService} from '../../services/app-data.service';
import {DocumentService} from '../../services/document.service';
import {SwiperOptions} from 'swiper';
import { InitializerService } from '../../services/initializer.service';
import { Router } from '@angular/router';


@Component({
    selector: 'my-portal-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

    videos: any;

    @Input() type: any | null;
    @Input() moduleData: any | null;
    @Input() personId: any | null;
    @Input() removeHighlighter: boolean = true;
    @Input() genericPages: any = null;
    typeSearch: any = Number(this.documentService.getWindow()?.sessionStorage?.getItem('typeSearch'));
    config: SwiperOptions = {
        pagination: { el: '.pagination-video', clickable: true },
        spaceBetween: 20,
        slidesPerView: 1,
        autoHeight: false,
        height: 500
    };
    initalizer: boolean = false;

    constructor(private sanitizer: DomSanitizer,
                private appData: AppDataService,
                private documentService: DocumentService,
                private initializerService: InitializerService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.initializerService.currentData.subscribe((response: any) => {
            const {only_motor, only_site} = response;

            if (only_motor && !only_site) {
                this.router.navigateByUrl('/');
            }
        }).unsubscribe();

        const url = 'video';
        if (!this.moduleData) {
            this.moduleData = {
                title: 'Galeria de vídeos'
            };
        }

        if (!this.genericPages) {
            this.appData.getDataModule(url, this.personId)
                // tslint:disable-next-line:no-any
                .subscribe((response: any) => {
                    this.videos = this.configVideo(response.data);
                    setTimeout(() => {
                        this.initalizer = true;
                    },25);
                });
        } else {
            this.videos = this.configVideo(this.genericPages);
        }
    }

    configVideo(response: any): any[] {
        let videosTrusted;
        if (this.typeSearch < 6) {
            videosTrusted = this.sanitizeVideo(response);
        } else {
            const hotels = [];
            for (const hotel of response) {
                if (hotel.videos) {
                    const obj = {
                        hotel: hotel.hotel,
                        id: hotel.id,
                        videos: this.sanitizeVideo(hotel.videos)
                    };
                    hotels.push(obj);
                }
            }
            videosTrusted = hotels;
        }
        if (!this.type) {
            this.type = {
                type: (response.length) === 1 ? 'single' : 'tabs'
            };
        }
        return videosTrusted;
    }

    sanitizeVideo(data: any): any[] {
        const videoArr = new Array();
        for (const video of data) {
            const videoTrust = {
                title: video.title,
                description: video.description,
                safeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${video.url}?controls=0&showinfo=0`),
                id: video.id,
            };
            videoArr.push(videoTrust);
        }
        return videoArr;
    }
}
