import { AuthRouteCorporateService } from './auth/auth-route-corporate.service';
import { AuthRouteClientService } from './auth/auth-route-client.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientAreaComponent } from './client/client.component';
import { RestrictedAreaComponent } from './restricted-area.component';
import { CorporateAreaComponent } from './corporate/corporate.component';

const routes: Routes = [
    {
        path: '',
        component: RestrictedAreaComponent,
        children: [
          {
            path: 'cliente',
            component: ClientAreaComponent,
            canActivate: [AuthRouteClientService]
          },
          {
            path: 'corporativo',
            component: CorporateAreaComponent,
            canActivate: [AuthRouteCorporateService]
          },
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RestrictedAreaRoutingModule { }
