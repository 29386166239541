import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogResourcesData {
  description: any;
}

@Component({
  selector: 'my-portal-dialog-description',
  templateUrl: './dialog-description.component.html',
  styleUrls: ['./dialog-description.component.scss']
})
export class DialogDescriptionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogDescriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogResourcesData) { }

  ngOnInit(): void {
  }

}
