import { InitializerService } from './../../services/initializer.service';
import {DialogDescriptionComponent} from './dialog-description/dialog-description.component';
import {MatDialog} from '@angular/material/dialog';
import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AppDataService} from '../../services/app-data.service';
import {DocumentService} from '../../services/document.service';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Component({
    selector: 'my-portal-hotels',
    templateUrl: './hotels.component.html',
    styleUrls: ['./hotels.component.scss']
})
export class HotelsComponent implements OnInit {

    @Input() moduleData: any;
    dataHotels: any;
    data: any;
    objRequest: any = {
        qtd_quarto: 1,
        checkin: moment().format('YYYY-MM-DD'),
        checkout: moment().add(1, 'days').format('YYYY-MM-DD'),
        adult: 1,
        ages: null
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private appService: AppDataService,
                private documentService: DocumentService,
                public dialog: MatDialog,
                private initializerService: InitializerService,
                private router: Router
                ) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.initializerService.currentData
            .subscribe((data) => {
                if (data) {
                    this.data = data;
                }
            }).unsubscribe();
        this.appService.getDataModule('hotels').subscribe((response: any) => {
            this.dataHotels = response.data;
            for (const key of Object.keys(this.dataHotels.hotels)) {
                this.dataHotels.hotels[key].slug = this.createUrlHotel(this.dataHotels.hotels[key].name);
            }
        });
    }

    createUrlHotel(title: string): string {
        return this.appService.createSlugUrl(title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    }

    openDescriptionDialog(description: any): void {
        this.dialog.open(DialogDescriptionComponent, {
            data: {
                description
            },
            maxWidth: '760px',
            autoFocus: false,
            width: '90vw'
        });
    }

    handleOpenHotel(hotelData: any, hotelId: any): void {
        this.initializerService.changeControlLocalSelected({
            id: hotelId,
            name: hotelData.name,
            type: 'hotel'
        });

    }
}
