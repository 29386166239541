import { environment } from './../../../../environments/environment';
import {Component, Input, OnInit} from '@angular/core';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-packages-default',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

    @Input() packages: any;
    @Input() load: any;
    @Input() hotelId: any;
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
    }

}
