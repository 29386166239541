import { environment } from './../../../../environments/environment';
import {Component, Input, OnInit} from '@angular/core';
import {AppDataService} from '../../../services/app-data.service';
import {ActivatedRoute} from '@angular/router';
import {SwiperOptions} from 'swiper';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
    initialize: boolean = false;
    @Input() moduleData: any;
    genericPages: any = null;

    config: SwiperOptions = {
        pagination: {
            el: '.pagination-accommodation',
            clickable: true,
        },
        spaceBetween: 30,
        slidesPerView: 3,
        autoHeight: true,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: true
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 30,
                centeredSlides: true,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        }
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;


    constructor(private appData: AppDataService,
                private activatedRoute: ActivatedRoute,
                private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        const landingPageId = (this.moduleData) ? this.moduleData.landing_page_categoria_id : this.activatedRoute.snapshot.params.id;

        this.appData.getGenericPagesList(landingPageId)
            .subscribe((news: any) => {
                this.genericPages = news.data;
                this.genericPages.slug = this.appData.createSlugUrl(this.genericPages.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                for (const item of this.genericPages.items) {
                    item.slug = this.appData.createSlugUrl(item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                }
                this.initialize = true;
            });
    }

}
