import {Component, Input, OnInit} from '@angular/core';
import { SwiperOptions } from 'swiper';
import { environment } from 'projects/new-portal/src/environments/environment';
import { AppDataService } from './../../../services/app-data.service';
import { InitializerService } from '../../../services/initializer.service';

@Component({
  selector: 'my-portal-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {

    @Input() personId: any;

  constructor(private appData: AppDataService,
              private initializerService: InitializerService) { }

  config: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 8,
    slidesPerView: 3,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
      }
    }
  };
  isSafari: any = this.initializerService.isSafari();

  imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
  imgServerUrlEnd: any;

  stamps: any = null;

  ngOnInit(): void {
    this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

    this.appData.getDataStamps(this.personId).subscribe((stamp: any) => {
      this.stamps = Object.values(stamp.data);
    });
  }

}
