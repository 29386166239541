import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';

import {SharedModule} from '../../shared/shared.module';

import { TestimoniesRoutingModule } from './testimonies-routing.module';
import { TestimoniesComponent } from './testimonies.component';


@NgModule({
  declarations: [TestimoniesComponent],
  imports: [
    SharedModule,
    TestimoniesRoutingModule,
    NgxMaskModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  exports: [TestimoniesComponent]
})
export class TestimoniesModule { }
