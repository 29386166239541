<div class="carousel">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <swiper [config]="config" [initialize]="initialize">
      <div class="swiper-wrapper">
        <div *ngFor="let accommodation of accommodations" class="swiper-slide">
          <div class="accommodation mb-2">
            <figure class="accomodation" [ngClass]="{'loading': load }">
              <img [src]="imgServer +  'width=' + 350 + imgServerUrlEnd + accommodation.url" class="img-fluid w-100" width="350" height="231" alt="imagem">
            </figure>
            <div class="content-accommodation p-3 border border-top-0">
              <h6 class="mt-2" [ngClass]="{'loading': load }">{{accommodation.name}}</h6>
              <div [ngClass]="{'my-2': load }">
                <p [ngClass]="{'loading': load }">{{accommodation.brief_description}}</p>
              </div>
              <my-portal-resources [resources]="accommodation?.amenities" [showAll]="false" [moreItems]="true"></my-portal-resources>
              <!-- <div class="price text-center" *ngIf="!load && accommodation.price">
                <span class="description">Diárias a partir de: </span>
                <span class="value">{{ accommodation.price | currency }}</span>
                <span class="price-details-smaller">Menor valor para os próximos 07 dias</span>
              </div> -->
                <a [routerLink]="routerAccommodation('/acomodacao', accommodation.slug, accommodation.id, hotelId)">
                    <button>mais detalhes</button>
                </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="pagination-accommodation mt-3"></div>
    </swiper>
  </div>
</div>
