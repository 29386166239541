import Swiper, {SwiperOptions} from 'swiper';
import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AppDataService} from '../../services/app-data.service';

@Component({
    selector: 'my-portal-our-hotels',
    templateUrl: './our-hotels.component.html',
    styleUrls: ['./our-hotels.component.scss']
})
export class OurHotelsComponent implements OnInit {

    data: any =
        {
            cities: ['Todos os hotéis', 'Salvador', 'Praia do Forte', 'Costa do Sauípe'],
            hotels: [
                {
                    name: 'Hotel Dubai Salvador',
                    description: 'O Dubai Hotel Pituba tem instalações modernas e confortáveis, na medida do seu orçamento. Todas as nossas suítes tem ar-condicionado.',
                    stars: 3,
                    price: 'R$ 1.190,00',
                    image: 'https://www.hotelcostadossauipe.com.br/wp-content/uploads/2019/01/hotel-costa-do-sauipe-1.jpg'
                },
                {
                    name: 'Dubai Business Center',
                    description: 'O Dubai Hotel Pituba tem instalações modernas e confortáveis, na medida do seu orçamento. Todas as nossas suítes tem ar-condicionado.',
                    stars: 5,
                    price: 'R$ 980,00',
                    image: 'https://www.hotelcostadossauipe.com.br/wp-content/uploads/2019/01/hotel-costa-do-sauipe-1.jpg'
                },
                {
                    name: 'Bangalôs Dubai Resort',
                    description: 'O Dubai Hotel Pituba tem instalações modernas e confortáveis, na medida do seu orçamento. Todas as nossas suítes tem ar-condicionado.',
                    stars: 4,
                    price: 'R$ 1.500,00',
                    image: 'https://www.hotelcostadossauipe.com.br/wp-content/uploads/2019/01/hotel-costa-do-sauipe-1.jpg'
                },
            ]
        };

    config: SwiperOptions = {
        spaceBetween: 30,
        slidesPerView: 3,
        autoHeight: true,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: true
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 30,
                centeredSlides: true,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        }
    };

    constructor(private appService: AppDataService) {
    }

    ngOnInit(): void {
        // http://motor-reserva-novo/sales/search?qtd_quarto=1&checkin=2021-11-19&checkout=2021-11-20&pax=1&chd=0
    }

    onTabChange(): void {
        this.initSwiper();
    }

    initSwiper(): void {
        new Swiper('.swiper-wrapper', this.config);
    }
}
