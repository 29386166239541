<section *ngIf="gallerys?.length || gallerys?.hotels?.length" class="mt-5 mb-5 about">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <mat-tab-group disableRipple mat-stretch-tabsBo class="gallery-tabs" *ngIf="portalGallery">
                <ng-template #listGalleryHome [ngTemplateOutlet]="listGalleryHome"
                             *ngFor="let gallery of gallerys?.hotels">
                    <mat-tab [label]="gallery?.name | uppercase" *ngIf="gallery?.galeries">
                        <div class="row no-gutters mt-5">
                            <div class="col-lg-12">
                                <my-portal-galery-modal [limitImages]="limitImages"  [galleryItem]="gallerys?.galeries[gallery?.galeries]"></my-portal-galery-modal>
                            </div>
                        </div>
                    </mat-tab>
                </ng-template>
            </mat-tab-group>


            <mat-tab-group disableRipple mat-stretch-tabsBo *ngIf="!portalGallery">
                <mat-tab *ngFor="let gallery of gallerys" [label]="gallery?.title | uppercase">
                    <div class="row no-gutters mt-5">
                        <div class="col-lg-12">
                            <my-portal-galery-modal [limitImages]="limitImages" [galleryItem]="gallery"></my-portal-galery-modal>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</section>
