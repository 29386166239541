import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContactComponent} from './contact.component';
import {SharedModule} from './../../shared/shared.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ContactRoutingModule} from './contact-routing.module';

import {NgxMaskModule} from 'ngx-mask';
import {Ng2TelInputModule} from 'ng2-tel-input';

@NgModule({
    declarations: [ContactComponent],
    imports: [
        CommonModule,
        SharedModule,
        ContactRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        Ng2TelInputModule,
    ]
})
export class ContactModule {
}
