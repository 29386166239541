import { Component, OnInit } from '@angular/core';
import { AppDataService } from '../../services/app-data.service';

@Component({
    selector: 'my-portal-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {

    faqs: any = [];
    panelOpenState = false;
    isLoading = true;
    errorFaqs = false;
    moduleData = { title: 'FAQ', description: 'Perguntas frequentes' };

    constructor(private appData: AppDataService) {}

    ngOnInit(): void {
        this.appData.getFaqs().subscribe(
            (response: any) => {
                this.faqs = response.data.faqs;
                this.isLoading = false;
                this.errorFaqs = false;
            },
            (err) => {
                this.isLoading = false;
                this.errorFaqs = true;
            }
        );
    }
}
