<section class="mt-5 mb-5" *ngIf="news?.items.length">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
        </div>
    </div>

    <my-portal-list-news *ngIf="news" [news]="news" [moduleData]="moduleData"></my-portal-list-news>

</section>

