import { DialogClientTypeComponent } from './../../../../features/restricted-area/dialog-client-type/dialog-client-type.component';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import { InitializerService } from './../../../../services/initializer.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from './../../../../../environments/environment';

@Component({
    selector: 'my-portal-toolbar-model-two',
    templateUrl: './toolbar-model-two.component.html',
    styleUrls: ['./toolbar-model-two.component.scss'],
})
export class ToolbarModelTwoComponent implements OnInit {
    toggleActive: boolean = false;
    @Input() dataHeader: any;
    @Input() hiddenSlide: any;
    @Input() position: any;
    @Input() transparentLogo: any;
    @ViewChild('sidenav', { static: false }) public sidenav!: MatSidenav;

    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    isSafari: any = this.initializerService.isSafari();

    userData: any = this.documentService.getWindow()?.sessionStorage?.getItem('userData')
        ? JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '')
        : null;
    userLogged: boolean = false;
    showSearches: boolean = false;

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlMaxQuality : any;

    constructor(
        private initializerService: InitializerService,
        private documentService: DocumentService,
        private router: Router,
        public dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.imgServerUrlMaxQuality = this.initializerService.setImageQuality(environment.IMAGES_END_URL_MAX_QUALITY);

        this.initializerService.currentControlAuthGuard.subscribe((data: any) => {
            this.userLogged = data;
        });
        this.userData?.id ? this.userLogged = true : this.userLogged = false;
    }

    toggleRightSidenav(): void {
        // this.toggleActive = !this.toggleActive;
        this.initializerService.toggle();
    }

    handleRouteClick(url: any): void {
        this.router.navigateByUrl(url);
    }

    openDialogLoginType(userLogged: boolean): void {

        if (userLogged) {
            if (this.userData?.type === 'client') {
                this.router.navigateByUrl('/area-restrita/cliente');
            } else {
                this.router.navigateByUrl('/area-restrita/corporativo');
            }
        } else {
            const dialogRef = this.dialog.open(DialogClientTypeComponent, {
                width: '300px',
                data: { },
            });

            dialogRef.afterClosed().subscribe(result => { });
        }
    }
    handleShowSearches(): void {
        this.showSearches = !this.showSearches;
    }
}
