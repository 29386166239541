import {HttpClient} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {AppDataService} from '../../../services/app-data.service';

import {environment} from './../../../../environments/environment';
import {InitializerService} from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-tickets',
    templateUrl: './tickets.component.html',
    styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

    @Input() moduleData: any;
    isSafari: any = this.initializerService.isSafari();
    tickets: any;
    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;
    dataOtas: any = null;

    constructor(private http: HttpClient,
                private appData: AppDataService,
                private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.initializerService.currentData
            .subscribe((data: any) => {
                if (data) {
                    if (data.otas.id && data.otas.pessoa_id) {
                        this.dataOtas = data.otas;
                        const obj = {
                            ambience_sale_id: data.otas.id,
                            person_id: data.otas.pessoa_id,
                            featured: 'Y'
                        };
                        this.appData.getDataTickets(obj).subscribe((response: any) => {
                            this.tickets = Object.values(response.Tickets).slice(0, 6);
                            for (const ticket of this.tickets) {
                                ticket.slug = this.appData.createSlugUrl(
                                    ticket.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                            }
                        }, (error => {
                            this.tickets = [];
                        }));
                    }
                }
            })
            .unsubscribe();
    }
}
