import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'my-portal-dialog-cancellation',
  templateUrl: './dialog-cancellation.component.html',
  styleUrls: ['./dialog-cancellation.component.scss']
})
export class DialogCancellationComponent implements OnInit {
  dataReserve: any;

  constructor(
    public dialogRef: MatDialogRef<DialogCancellationComponent>,
    private appData: AppDataService,
    @Inject(MAT_DIALOG_DATA) public data: DialogCancellationComponent | any
  ) { }

  ngOnInit(): void {
    this.dataReserve = this.data.reserve;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleCancellation(): void {
    this.appData.postRestrictedAreaCancelReserve(this.dataReserve.id).subscribe(
      (response: any) => {
        this.dialogRef.close();
      },
      (error) => {
        this.dialogRef.close();
      }
    );
  }


}
