<div id="cookies">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-9">
                <p class="m-0">Utilizamos cookies para garantir que você tenha a melhor experiência em nosso site.
                    <span *ngIf="privacityContent?.length">Clique <a href="/politica-de-privacidade" target="_blank">aqui</a> para obter mais informações.</span>
                </p>
            </div>
            <div class="col-12 col-md-3 pr-0">
                <button id="aceitar" (click)="handleSetCookies()" class="btn">continuar e fechar</button>
            </div>
        </div>
    </div>
</div>
