<section class="mt-5 mb-5 tariffs" id="hotels">
    <my-portal-highlighter *ngIf="!load" [module]="{title: 'Estabelecimentos disponíveis', description: 'Confira os estabelecimentos disponíveis para a sua viagem'}"></my-portal-highlighter>

    <my-portal-inner-load *ngIf="load"></my-portal-inner-load>

    <ng-template #listHotels [ngTemplateOutlet]="listHotels" *ngFor="let hotel of hotels">
        <div class="available-hotel-item">
            <div class="available-hotel-item-container">
                <figure [ngClass]="{'loading': load }">
                    <div class="regime" *ngIf="hotel.meal_plan">{{ hotel.meal_plan }}</div>
                    <img [src]="imgServer + 'width=' + 387 + imgServerUrlEnd + hotel.url" alt="" class="img-fluid">
                </figure>
                <div class="row-item">
                    <div class="info">
                        <div>
                            <h6 [ngClass]="{'loading': load }">
                                {{ hotel?.name}}
                                <span *ngIf="hotel?.lowest_price?.coupon?.applied">
                                    Cupom aplicado
                                </span>
                            </h6>
                            <div class="details">
                                <div class="star" *ngIf="!data?.hide_stars">
                                    <i translate="no" class="material-icons notranslate" *ngFor="let st of [0,1,2,3,4]; let ind = index" [ngClass]="{'active': (ind+1) <= hotel.stars}">star</i>
                                </div>

                                <div *ngIf="!data?.hide_address" class="local" [ngClass]="{'loading': load }">
                                    <span translate="no" class="material-icons-outlined">room</span>
                                    <span>{{hotel.city}}, {{hotel.state}}</span>
                                </div>
                            </div>
                            <p [innerHTML]="hotel.description"></p>
                        </div>
                    </div>
                    <div class="price-container">
                        <div class="price-content" *ngIf="!hotel.lowest_price?.restrictions?.status">
                            <div>
                                <div *ngIf="!hotel.room_values?.max_parcels && !hotel?.room_values.max_parcels_with_fees">
                                    <div class="current-price" *ngIf="!hotel?.lowest_price?.price.original_value">
                                        <div>A partir de:</div>
                                        <span class="price">{{ hotel.lowest_price?.price?.total | currency }}</span>
                                    </div>
                                    <div class="old-price" *ngIf="hotel.lowest_price?.price?.original_value">
                                        De: {{ hotel.lowest_price?.price?.original_value | currency }}
                                    </div>
                                    <div class="current-price" *ngIf="hotel.lowest_price?.price?.original_value">
                                        Por: <span class="price">{{ hotel.lowest_price?.price?.total | currency }}</span>
                                        <span *ngIf="hotel.room_values?.max_parcels == 1" class="parcels"> sem juros </span>
                                    </div>
                                </div>

                                <!-- SEM JUROS -->
                                <div class="payment-parcels" *ngIf="hotel.room_values?.max_parcels">
                                    <div class="old-price" *ngIf="hotel.lowest_price?.price?.original_value">
                                        De: {{ hotel.lowest_price?.price?.original_value | currency }}
                                    </div>
                                    <div class="from-price" *ngIf="hotel.room_values?.max_parcels > 1"><span>a partir de:</span></div>
                                    <span class="total-price mt-2" *ngIf="hotel.room_values?.max_parcels > 1">{{ hotel.lowest_price?.price?.total | currency }} À vista </span>
                                    <div class="price-with-parcels">
                                        <span class="parcels" *ngIf="hotel.lowest_price?.price?.original_value && hotel.room_values?.max_parcels <= 1"> Por: </span>
                                        <span class="parcels" *ngIf="hotel.room_values?.max_parcels > 1">{{ hotel.room_values?.max_parcels }}x de </span> <span class="price">{{ hotel.room_values?.value_max_parcels_without_fees | currency }}</span>
                                        <span class="parcels"> sem juros </span>
                                    </div>
                                </div>

                                <!-- Com juros  -->
                                <div class="payment-parcels" *ngIf="!hotel.room_values.max_parcels && hotel.room_values.max_parcels_with_fees">
                                    <div class="old-price" *ngIf="hotel.lowest_price?.price?.original_value">
                                        De: {{ hotel.lowest_price?.price?.original_value | currency }}
                                    </div>
                                    <div class="from-price"><span>a partir de:</span></div>
                                    <span class="total-price mt-2">{{ hotel.lowest_price?.price?.total | currency }} À vista </span>
                                    <div class="price-with-parcels">
                                        <span class="parcels"> {{ hotel.room_values.max_parcels_with_fees }}x de </span> <span class="price"> {{ hotel.room_values.value_max_parcels_with_fees | currency }} <span class="parcels"></span></span>
                                    </div>
                                </div>
                                <span class="for-accommodation">por acomodação</span>
                            </div>
                            <a class="choose-button" [routerLink]="[chooseLink, hotel.id]" (click)="search(hotel)">mais detalhes</a>

                        </div>
                        <div class="restriction" *ngIf="hotel.lowest_price?.restrictions?.status">
                            <span translate="no" class="material-icons-outlined">sentiment_dissatisfied</span>
                            Estabelecimento <br> indisponível
                            <div class="text-restriction">
                                {{ hotel.lowest_price?.restrictions?.massages }}
                            </div>
                            <button class="button-datepicker" (click)="openCalendar(hotel)">
                                <figure>
                                    <svg id="Camada_1" data-name="Camada 1" class="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke-linecap:round;}.cls-1,.cls-2{stroke:#484848;stroke-miterlimit:10;}.cls-2{fill:#fff;}</style></defs><title>Icones</title><line class="cls-1" x1="0.5" y1="8.6" x2="23" y2="8.6"/><rect class="cls-1" x="16.4" y="1" width="2.5" height="4.04" rx="1"/><rect class="cls-1" x="5.3" y="1" width="2.5" height="4.04" rx="1"/><path class="cls-1" d="M18.9,3h2.8a2,2,0,0,1,2,2V21.2a2,2,0,0,1-2,2H2.5a2,2,0,0,1-2-2V14.1"/><line class="cls-1" x1="7.8" y1="3" x2="16.4" y2="3"/><path class="cls-1" d="M.5,15.5V5a2,2,0,0,1,2-2H5.3"/><circle class="cls-2" cx="5.7" cy="13.2" r="0.8"/><circle class="cls-2" cx="12.2" cy="13.2" r="0.8"/><circle class="cls-2" cx="18.7" cy="13.2" r="0.8"/><circle class="cls-2" cx="5.7" cy="18.2" r="0.8"/><circle class="cls-2" cx="12.2" cy="18.2" r="0.8"/><circle class="cls-2" cx="18.7" cy="18.2" r="0.8"/></svg>
                                </figure>
                                <span class="consultar-datas">Consultar datas disponíveis</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-item">
                <swiper [config]="config" class="pt-3">
                    <div class="swiper-wrapper">
                        <div class="benefits swiper-slide" *ngFor="let benefit of hotel.carousel" [ngSwitch]="benefit.type">
                            <ng-template ngSwitchCase="minimumPrecedence">
                                <figure>
                                    <svg stroke="currentColor" id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1,.cls-2{fill:none;stroke-miterlimit:10;stroke-width:0.5px;}.cls-2{stroke-linecap:round;}</style></defs><title>Ico-minimo-dias</title><rect class="cls-1" x="1.4" y="2.6" width="21.2" height="20.25" rx="4"/><line class="cls-2" x1="12" y1="1.7" x2="12" y2="4.4"/><line class="cls-2" x1="18.4" y1="1.7" x2="18.4" y2="4.4"/><line class="cls-2" x1="5.5" y1="1.7" x2="5.5" y2="4.4"/><line class="cls-2" x1="12" y1="1.7" x2="12" y2="4.4"/><line class="cls-2" x1="4.6" y1="7.6" x2="19.3" y2="7.6"/><path class="cls-1" d="M16.9,12.9l-1.4-1.5h-.4L10.5,16,8.8,14.4H8.4L7,15.8a.3.3,0,0,0,0,.4l1.8,1.9h0l1.5,1.4a.3.3,0,0,0,.4,0l6.2-6.3A.2.2,0,0,0,16.9,12.9Z"/></svg>
                                </figure>
                                <p>{{ benefit.text }}</p>
                            </ng-template>
                            <ng-template ngSwitchCase="childrenMax">
                                <i class="icon-children notranslate"></i>
                                <p>{{ benefit.text }}</p>
                            </ng-template>
                            <ng-template ngSwitchCase="payment">
                                <i class="icon-installment notranslate"></i>
                                <p>{{ benefit.text }}</p>
                            </ng-template>
                            <ng-template ngSwitchCase="depositDiscount">
                                <figure>
                                    <svg stroke="currentColor" id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1,.cls-2{fill:none;}.cls-1,.cls-2,.cls-3{stroke-width:0.5px;}.cls-1{stroke-linejoin:round;}.cls-2,.cls-3{stroke-miterlimit:10;}.cls-3{}</style></defs><title>ico-pagamento-deposito</title><path class="cls-1" d="M16.9,15l3.4-1.8a1.3,1.3,0,0,1,1.8.4,1.5,1.5,0,0,1-.6,1.8l-7.2,3.8h-.4l-6.7.2a4.5,4.5,0,0,0-1.9,1.4"/><path class="cls-2" d="M3,17.4l3-2.8a3.9,3.9,0,0,1,3.2-.4,15.2,15.2,0,0,0,2.5.2h2.6a1.3,1.3,0,0,1,1.4,1.3,1.3,1.3,0,0,1-1.4,1.2H10.7"/><path class="cls-3" d="M9.2,7.3c0,.2-.1.3-.2.3a.3.3,0,0,1-.3-.3c0-.1.1-.2.3-.2Z"/><path class="cls-3" d="M16.5,7.3a.3.3,0,0,1-.3.3c-.1,0-.2-.1-.2-.3l.2-.2C16.4,7.1,16.5,7.2,16.5,7.3Z"/><rect class="cls-2" x="5.6" y="3.4" width="14" height="7.97"/><path class="cls-2" d="M14.6,9.8h2.2A1.2,1.2,0,0,1,18,8.6V6.1a1.2,1.2,0,0,1-1.2-1.2H14.6"/><path class="cls-2" d="M10.6,4.9H8.4A1.2,1.2,0,0,1,7.2,6.1V8.6A1.2,1.2,0,0,1,8.4,9.8h2.2"/><path class="cls-2" d="M14.2,7.3c0,1.2-.7,2.2-1.6,2.2S11,8.5,11,7.3s.7-2.1,1.6-2.1S14.2,6.1,14.2,7.3Z"/></svg>
                                </figure>
                                <p>{{ benefit.text }}</p>
                            </ng-template>
                            <ng-template ngSwitchCase="fidelityProgram">
                                <i class="icon-cashback notranslate"></i>
                                <p>{{ benefit.text }}</p>
                            </ng-template>
                            <ng-template ngSwitchCase="freeNight">
                                <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" stroke="#6c757d" viewBox="0 0 24.3 23.2" class="calendar-icon"><defs>
                                        <style _ngcontent-serverApp-c17="">.checkin-checkout-icon-1{fill:none;stroke-linecap:round;stroke-miterlimit:10;}.checkin-checkout-icon-2{fill:#6c757d;}</style></defs><title _ngcontent-serverApp-c17="">Ativo 1</title><g _ngcontent-serverApp-c17="" id="Camada_2" data-name="Camada 2"><g _ngcontent-serverApp-c17="" id="Camada_1-2" data-name="Camada 1"><line _ngcontent-serverApp-c17="" x1="0.5" y1="8.1" x2="23" y2="8.1" class="checkin-checkout-icon-1"></line><rect _ngcontent-serverApp-c17="" x="16.4" y="0.5" width="2.5" height="4.04" rx="1" class="checkin-checkout-icon-1"></rect><rect _ngcontent-serverApp-c17="" x="5.3" y="0.5" width="2.5" height="4.04" rx="1" class="checkin-checkout-icon-1"></rect><path _ngcontent-serverApp-c17="" d="M18.9,2.5h2.8a2.1,2.1,0,0,1,2.1,2V20.7a2.1,2.1,0,0,1-2.1,2H2.5a2,2,0,0,1-2-2V13.6" class="checkin-checkout-icon-1"></path><line _ngcontent-serverApp-c17="" x1="7.8" y1="2.5" x2="16.4" y2="2.5" class="checkin-checkout-icon-1"></line><path _ngcontent-serverApp-c17="" d="M.5,15V4.5a2,2,0,0,1,2-2H5.3" class="checkin-checkout-icon-1"></path><circle _ngcontent-serverApp-c17="" cx="5.7" cy="12.7" r="0.8" class="checkin-checkout-icon-2"></circle><circle _ngcontent-serverApp-c17="" cx="12.2" cy="12.7" r="0.8" class="checkin-checkout-icon-2"></circle><circle _ngcontent-serverApp-c17="" cx="18.7" cy="12.7" r="0.8" class="checkin-checkout-icon-2"></circle><circle _ngcontent-serverApp-c17="" cx="5.7" cy="17.7" r="0.8" class="checkin-checkout-icon-2"></circle><circle _ngcontent-serverApp-c17="" cx="12.2" cy="17.7" r="0.8" class="checkin-checkout-icon-2"></circle><circle _ngcontent-serverApp-c17="" cx="18.7" cy="17.7" r="0.8" class="checkin-checkout-icon-2"></circle></g></g></svg>
                                </figure>
                                <p>{{ benefit.text }}</p>
                            </ng-template>

                            <ng-template ngSwitchCase="discount">
                                <figure>
                                    <img [src]="checkImageIcon(benefit?.formPayment?.id)" alt="benefício">
                                </figure>
                                <p>{{ benefit.text }}</p>
                            </ng-template>
                        </div>
                        <div class="benefits swiper-slide" *ngIf="hotel.carousel.length <= 2">
                            <figure>
                                <svg stroke="currentColor" id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;}</style></defs><title>Icones</title><path class="cls-1" d="M12,1.5A16.8,16.8,0,0,1,3.2,4.9V8.4c0,6.4,3.5,12.1,8.8,14.1h0c5.3-2,8.8-7.7,8.8-14.1V4.9A16.8,16.8,0,0,1,12,1.5Z"/><polygon class="cls-1" points="17 7.6 11 13.6 8 10.6 6.2 12.4 9.2 15.4 11 17.2 12.8 15.4 18.8 9.3 17 7.6"/></svg>
                            </figure>
                            <p>Faça compras seguras e confiáveis pelo nosso site.</p>
                        </div>
                        <div class="benefits swiper-slide" *ngIf="hotel.carousel.length === 3">
                            <figure>
                                <svg stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.6 21.1"><defs><style>.cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.25px;}</style></defs><title>Ativo 16</title><g id="Camada_2" data-name="Camada 2"><g id="Camada_1-2" data-name="Camada 1"><path class="cls-1" d="M5,7.9A3.1,3.1,0,0,1,2.3,6.2,3.1,3.1,0,0,1,3.7,2.3,3.1,3.1,0,0,1,7.6,3.7a3.3,3.3,0,0,1,.1,2.2"/><path class="cls-1" d="M5.8,9.7A4.8,4.8,0,0,1,.6,7,4.8,4.8,0,0,1,9.3,2.9a4.5,4.5,0,0,1,.3,3.4"/><path class="cls-1" d="M13.3,8.6A1.2,1.2,0,0,1,13.9,7a1,1,0,0,1,.9-.1,1.1,1.1,0,0,1,.7.6"/><path class="cls-1" d="M9.6,12,6.4,5.2a1.6,1.6,0,0,0-.7-.7,1,1,0,0,0-.9.1,1.2,1.2,0,0,0-.6,1.6l3,6.4a1.3,1.3,0,0,0-.7-.6,1.4,1.4,0,0,0-.9.1A1.1,1.1,0,0,0,5,13.6l1.6,3.5a7.6,7.6,0,0,0,1.7,2.3,5.8,5.8,0,0,0,6.4,1l.4-.2a5.7,5.7,0,0,0,3-7.1c-.5-1.4-1.7-3.6-2.6-5.6"/><line class="cls-1" x1="8.9" y1="16.4" x2="6.2" y2="10.6"/><path class="cls-1" d="M7.4,7.4A1.2,1.2,0,0,1,8,5.8h.9a1.1,1.1,0,0,1,.7.6L11.8,11"/><path class="cls-1" d="M10.3,7.9a1.2,1.2,0,0,1,.6-1.6h0a1.2,1.2,0,0,1,1.6.6L14,10"/></g></g></svg>
                            </figure>
                            <p>Em apenas poucos cliques, você finaliza a sua compra.</p>
                        </div>
                    </div>
                </swiper>
            </div>
        </div>
    </ng-template>
</section>


