import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    error: null;
}

@Component({
  selector: 'my-portal-dialog-error',
  template: `
      <h2>Periodo inválido</h2>
      <mat-dialog-content>
          {{ data.error }}
      </mat-dialog-content>
      <mat-dialog-actions align="end">
          <button mat-button mat-dialog-close>Fechar</button>
      </mat-dialog-actions>
  `,
  styleUrls: ['./dialog-error.component.scss']
})
export class DialogErrorComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogErrorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

}
