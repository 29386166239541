import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {InitializerService} from '../../../../services/initializer.service';
import {MatSidenav} from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { DialogClientTypeComponent } from 'projects/new-portal/src/app/features/restricted-area/dialog-client-type/dialog-client-type.component';
import { Router } from '@angular/router';
import { environment } from 'projects/new-portal/src/environments/environment';

@Component({
    selector: 'my-portal-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

    toggleActive: boolean = false;
    @Input() dataHeader: any;
    @Input() hiddenSlide: any;
    @Input() position: any;
    @ViewChild('sidenav', {static: false}) public sidenav!: MatSidenav;

    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    isSafari: any = this.initializerService.isSafari();

    userData: any = this.documentService.getWindow()?.sessionStorage?.getItem('userData')
        ? JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '')
        : null;
    userLogged: boolean = false;
    showSearches: boolean = false;

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlMaxQuality : any;

    constructor(
        private initializerService: InitializerService,
        private documentService: DocumentService,
        public dialog: MatDialog,
        private router: Router) {
    }

    ngOnInit(): void {
        this.imgServerUrlMaxQuality = this.initializerService.setImageQuality(environment.IMAGES_END_URL_MAX_QUALITY);

        this.initializerService.currentControlAuthGuard.subscribe(
            (data: any) => {
                this.userLogged = data;
            }
        );
        this.userData?.id ? this.userLogged = true : this.userLogged = false;
    }

    toggleRightSidenav(): void {
        // this.toggleActive = !this.toggleActive;
        this.initializerService.toggle();
    }

    openDialogLoginType(userLogged: boolean): void {

        if (userLogged) {
            if (this.userData?.type === 'client') {
                this.router.navigateByUrl('/area-restrita/cliente');
            } else {
                this.router.navigateByUrl('/area-restrita/corporativo');
            }
        } else {
            const dialogRef = this.dialog.open(DialogClientTypeComponent, {
                width: '300px',
                data: { },
            });

            dialogRef.afterClosed().subscribe(result => { });
        }
    }

    handleRouteClick(url: any): void {
        this.router.navigateByUrl(url);
    }

    handleShowSearches(): void {
        this.showSearches = !this.showSearches;
    }
}
