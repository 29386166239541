import { Component, Input, OnInit } from '@angular/core';
import {InitializerService} from '../../services/initializer.service';
import {AppDataService} from '../../services/app-data.service';
import {SwiperOptions} from 'swiper';

@Component({
    selector: 'my-portal-accommodations',
    templateUrl: './accommodations.component.html',
    styleUrls: ['./accommodations.component.scss']
})
export class AccommodationsComponent implements OnInit {

    @Input() moduleData: any;
    @Input() resources: any;
    @Input() personId: any;
    @Input() quantity: any;
    @Input() establishmentType: any;

    load: boolean = true;
    initialize: boolean = false;
    accommodations: any;

    constructor(private initializerService: InitializerService,
                private appData: AppDataService) {
    }

    ngOnInit(): void {
        const url = 'rooms';
        this.appData.getDataAccommodations(url, this.personId)
            // tslint:disable-next-line:no-any
            .subscribe((response: any) => {
                this.accommodations = response.data;
                if (!this.moduleData) {
                    this.moduleData = {
                        title: this.personId ? 'Nossas acomodações' : 'Acomodações'
                    };
                }

                if (this.establishmentType >= 6) {
                    for (const hotel of this.accommodations) {
                        for (const room of hotel.rooms) {
                            room.slug = this.appData.createSlugUrl(room.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                        }
                    }
                } else {
                    for (const room of this.accommodations) {
                        room.slug = this.appData.createSlugUrl(room.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                    }
                }


                setTimeout(() => {
                    this.load = false;
                    this.initialize = true;
                }, 1000);
            });
    }

    myTabFocusChange(event: any) {
        if (event) {
            setTimeout(() => {
                this.initialize = true;
            }, 1000);
        }
    }
}
