<div class="mt-5">
  <my-portal-highlighter class="mt-5" [module]="{title: 'Serviços e lazer', description: 'Confira o que temos a oferecer para a sua diversão e comodidade'}"></my-portal-highlighter>

  <swiper [config]="config">
    <div class="swiper-wrapper">
        <div class="swiper-slide pl-5 pr-5 " *ngFor="let newFooter of [1,2,3,4,5,6]">
            <div class="row border col-12 m-0 p-0">
                <figure class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-0 pr-0">
                    <img src="https://images.focomultimidia.com/curl/motor_reserva/images/galeria_foto/cliente_734/202002051580924441g3.jpg" class="img-fluid">
                </figure>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-2 p-3 description">
                    <h6>Mini Day Spa</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa illo quo exercitationem, aperiam, minima aut error quos est temporibus laudantium quasi molestiae nam natus ab atque rem id nisi repudiandae.
                    <br/>
                    <br/>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat, excepturi? Voluptas obcaecati ex in cupiditate quod reprehenderit ipsam odit quisquam, eaque quo delectus error aspernatur magnam nobis, inventore, veniam natus.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Arrows -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>

    <div class="pagination-accommodation mt-3 mb-5"></div>
  </swiper>
</div>

