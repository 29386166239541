import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {eventListeners} from '@popperjs/core';
import {InitializerService} from '../../../services/initializer.service';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'my-portal-slide-gallery',
    templateUrl: './slide-gallery.component.html',
    styleUrls: ['./slide-gallery.component.scss']
})
export class SlideGalleryComponent implements OnInit, AfterViewInit {
    @Input() images: any;
    slideIndex: number = 1;
    count: number = 1;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        setTimeout(() => {
            this.showSlides(this.slideIndex);
        }, 25);

        let arrayImage: any = [];

        this.images.map((image: any) => {
            const splittedAssetName: string[] = image.split('/');
            const assetName = splittedAssetName.pop()!;
            
            const clearUrl: any = this.clearUrlImage(assetName)

            splittedAssetName.push(clearUrl)
            const finalImage = splittedAssetName.join('/')

            arrayImage.push(finalImage)
        })
        
        this.images = arrayImage

    }

    plusSlides(n: number): void {
        this.showSlides(this.slideIndex += n);
    }

    currentSlide(n: number): void {
        this.showSlides(this.slideIndex = n);
    }

    showSlides(n: number): void {
        let i;
        const slides = document.getElementsByClassName('mySlides');
        const dots = document.getElementsByClassName('demo');
        const thumbs = document.querySelector('.thumbs');
        // const captionText = document.getElementById('caption');
        if (n > slides.length) {
            this.slideIndex = 1;
        }
        if (n < 1) {
            this.slideIndex = slides.length;
        }
        for (i = 0; i < slides.length; i++) {
            (slides[i] as HTMLElement).style.display = 'none';
            (slides[i] as HTMLElement).style.opacity = '0';
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(' active', '');
        }
        (slides[this.slideIndex - 1] as HTMLElement).style.display = 'block';
        (slides[this.slideIndex - 1] as HTMLElement).style.opacity = '1';
        dots[this.slideIndex - 1].className += ' active';

        if (this.slideIndex >= ((this.getScreenWidth) ? 5 : 6)) {
            this.count = this.slideIndex - ((this.getScreenWidth) ? 4 : 5);
            const element: any | HTMLElement = document.querySelector('.img-thumb');
            let value = 0;
            let valueMobile = 0;
            if (this.getScreenWidth) {
                valueMobile = (element.offsetWidth + 8) * this.count;
            } else {
                value = (element.offsetHeight + 8) * this.count;
            }
            (thumbs as HTMLElement).style.transform = `translate3d(-${valueMobile}px, -${value}px, 0px)`;
            (thumbs as HTMLElement).style.transform = `transition-duration: 300ms;`;
        }
        if (this.slideIndex === 1) {
            (thumbs as HTMLElement).style.transform = `translate3d(0px, 0px, 0px)`;
            (thumbs as HTMLElement).style.transform = `transition-duration: 300ms;`;
            this.count = 1;
        }
    }

    ngAfterViewInit(): void {
        const thumbs: HTMLElement | any = document.querySelector('.thumbs');
        let isDown = false;
        let startY: any;
        let scrollDown: any;

        thumbs.addEventListener('mousedown', (e: any) => {
            isDown = true;
            thumbs.classList.add('active');
            startY = e.pageY - thumbs.offsetTop;
            scrollDown = thumbs.scrollDown;
        });

        thumbs.addEventListener('mouseleave', () => {
            isDown = false;
            thumbs.classList.remove('active');
        });

        thumbs.addEventListener('mouseup', () => {
            isDown = false;
            thumbs.classList.remove('active');
        });

        thumbs.addEventListener('mousemove', (e: any) => {
            if (!isDown) {
                return;
            }
            e.preventDefault();
            const x = e.pageX - thumbs.offsetTop;
            const walk = (x - startY) * 3;
            thumbs.scrollLeft = scrollDown - walk;
        });
    }

    clearUrlImage(urlImage: any): void {
        return urlImage.replace(/[!'()]/g, (c: any) => {
            return "%" + c.charCodeAt(0).toString(16);
        })
    }

}
