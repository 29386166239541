import { Component, Input, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import {AppDataService} from '../../services/app-data.service';

@Component({
    selector: 'my-portal-benefits',
    templateUrl: './benefits.component.html',
    styleUrls: ['./benefits.component.scss'],
})
export class BenefitsComponent implements OnInit {
    @Input() benefits: any;
    @Input() search: any;

    initializer = false;
    load: boolean = true;
    benefitsLength: any = 5;

    config: SwiperOptions = {
        spaceBetween: 20,
        slidesPerView: 5,
        autoplay: true,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        },
    };

    constructor(private appService: AppDataService) {}

    ngOnInit(): void {
        this.getBenefit();

        setTimeout(() => {
            this.initializer = true;

            this.config = {
                slidesPerView: this.benefitsLength,
                autoplay: true,
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: this.benefitsLength,
                        spaceBetween: 20,
                    },
                },
            };
        }, 200);

    }

    getBenefit(): void {
        this.appService.getBenefits().subscribe((benefits: any) => {
            this.benefits = benefits.data;
            this.getBenefitArrSize(this.benefits, 4);
            this.load = false;
        });
    }

    getBenefitArrSize(benefits: any, limit: any): void {
        const staticBenefitsLength = 2; // o valor 2 é a quantidade de itens que são estáticos no swiper
        const dynamicBenefitsLength = benefits.length;

        let benefitsLength = staticBenefitsLength + dynamicBenefitsLength;

        if (benefitsLength > limit) {
            benefitsLength = limit;
        }

        this.benefitsLength = benefitsLength;

    }

    checkImageIcon(id: any | string): string {
        return this.appService.checkIconService(id, true);
    }
}
