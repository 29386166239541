import { Component, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'ngx-useful-swiper';
import { SwiperOptions } from 'swiper';
import { environment } from '../../../../environments/environment';
import { AppDataService } from '../../../services/app-data.service';
import { ActivatedRoute } from '@angular/router';
import { InitializerService } from '../../../services/initializer.service';

@Component({
  selector: 'my-portal-receptive-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

    load: any = true;

    @ViewChild('galleryTop', {static: false}) galleryTop?: SwiperComponent | any;
    @ViewChild('galleryThumbs', {static: false}) galleryThumbs?: SwiperComponent | any;

    initializer = true;
    initializer2 = true;
    tickets: any;
    isSafari: any = this.initializerService.isSafari();
    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;

    public configThumbs: SwiperOptions = {
        direction: 'vertical',
        slidesPerView: 5,
        slideToClickedSlide: false,
        spaceBetween: 8,
        autoHeight: false,
        grabCursor: false,
        preloadImages: true,
        slideActiveClass: 'activeSlide',
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 5,
                spaceBetween: 8,
                direction: 'horizontal'
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 5,
                spaceBetween: 8,
                direction: 'horizontal'
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 5,
                spaceBetween: 8,
                direction: 'vertical'
            }
        },
        on: {
            slideChange: () => {
                this.galleryTop.swiper.slideTo(this.galleryThumbs.swiper.clickedIndex);
                this.galleryThumbs.swiper.activeIndexChange = this.galleryThumbs.swiper.clickedIndex;
                this.galleryThumbs.swiper.slides[this.galleryThumbs.swiper.activeIndex].classList.remove('activeSlide');
                this.galleryThumbs.swiper.slides[this.galleryThumbs.swiper.clickedIndex].classList.add('activeSlide');
            },
            click: () => {
                setTimeout(() => {
                    this.galleryTop.swiper.slideTo(this.galleryThumbs.swiper.clickedIndex);
                    this.galleryThumbs.swiper.slideTo(this.galleryThumbs.swiper.clickedIndex);
                    this.galleryThumbs.swiper.activeIndexChange = this.galleryThumbs.swiper.clickedIndex;
                    this.galleryThumbs.swiper.slides[this.galleryThumbs.swiper.activeIndex].classList.remove('activeSlide');
                    this.galleryThumbs.swiper.slides[this.galleryThumbs.swiper.clickedIndex].classList.add('activeSlide');
                }, 250);
            }
        }
    };

    public configSlide: SwiperOptions = {
        autoplay: true,
        effect: 'fade',
        pagination: {el: '.images-pagination', clickable: true},
        autoHeight: false,
        allowTouchMove: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next-cart',
            prevEl: '.swiper-button-prev-cart'
        },
        on: {
            slideChange: () => {
                this.galleryThumbs.swiper.slideTo(this.galleryTop.swiper.activeIndex);
                this.galleryThumbs.swiper.activeIndexChange = this.galleryTop.swiper.activeIndex;
                this.galleryThumbs.swiper.slides[this.galleryThumbs.swiper.activeIndex].classList.remove('activeSlide');
                this.galleryThumbs.swiper.slides[this.galleryTop.swiper.activeIndex].classList.add('activeSlide');
            }
        }
    };

    constructor(private appService: AppDataService,
                private activatedRoute: ActivatedRoute,
                private initializerService: InitializerService) { }

    ngOnInit(): void {

        const idRouter = this.activatedRoute.snapshot.params.id
        this.appService.getReciptiveById('Tickets', idRouter)
            .subscribe((response: any) => {
                if(response.Tickets.length !== 0) {
                    this.tickets = Object.values(response.Tickets).shift()
                } else {
                    this.tickets = null
                }

                this.load = false;
            })
      }
}
