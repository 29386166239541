import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'my-portal-recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent implements OnInit {
    passwordForm: FormGroup;
    loginType: any;
    token: any;
    loading: any;
    loadingVerifyToken: boolean = false;
    invalidToken: boolean = false;
    errorMessage: any;
    recoverySuccess: any;

    constructor(
        private activatedRouter: ActivatedRoute,
        private appData: AppDataService,
        private formBuilder: FormBuilder
        ) {
            this.passwordForm = this.formBuilder.group({
                password: ['', Validators.required],
                passwordConfirm: ['', Validators.required,],
            });
        }
        
        ngOnInit(): void {
            this.activatedRouter.params.subscribe((params) => {
                this.loginType = params.type;
                this.token = params.token;
        });

    }

    onSubmit(form: any): void {
        this.loading = true;
        if (form.value.password === form.value.passwordConfirm) {
            this.handleForgotPassword(form.value.password);
        } else {
            this.loading = false;
            this.errorMessage = 'Senhas não conferem';
        }
    }

    handleForgotPassword(newPassword: any): void {
        this.appData
            .postRestrictedRecoverPassword(this.loginType, this.token, newPassword)
            .subscribe(
                (data: any) => {
                    this.loading = false;
                    this.recoverySuccess = true;
                },
                (error: any) => {
                    if (error.status === 400) {
                        this.errorMessage = 'Token inválido,refaça o processo de recuperação de senha'
                    }

                    if (error.status === 403) {
                        this.errorMessage = 'Token expirado,refaça o processo de recuperação de senha'
                    }
                    this.loading = false;
                }
            );
    }
}
