import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import {Component, OnInit, Output} from '@angular/core';
import {AppDataService} from '../../services/app-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentService} from '../../services/document.service';
import {DefaultSnackBarComponent} from '../default-snack-bar/default-snack-bar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { SwiperOptions } from 'swiper';
import { environment } from './../../../environments/environment';
import { TagManagerService } from '../../services/tag-manager.service';

@Component({
    selector: 'my-portal-available-hotel-item',
    templateUrl: './available-hotel-item.component.html',
    styleUrls: ['./available-hotel-item.component.scss']
})
export class AvailableHotelItemComponent implements OnInit {

    @Output() openDatePickerPortal: boolean = false;

    hotels: any;
    load: any = true;
    data: any = null;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    chooseLink: any

    config: SwiperOptions = {
        spaceBetween: 20,
        slidesPerView: 4,
        autoplay: true,
        loop: true,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        },
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(
        private appService: AppDataService,
        private activatedRouter: ActivatedRoute,
        private router: Router,
        private initializerService: InitializerService,
        private documentService: DocumentService,
        private snackbar: MatSnackBar,
        private tagManagerService: TagManagerService
    ) {

        this.activatedRouter.paramMap.subscribe(params => {
            if (this.hotels) {
                this.load = true;
                this.hotels = null;
                const rooms = this.activatedRouter.snapshot.params.rooms.split('!');
                this.getData(rooms);
            }
        });
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        const rooms = this.activatedRouter.snapshot.params.rooms.split('!');
        this.getData(rooms);
        this.initializerService.currentData
            .subscribe((data) => {
                if (data) {
                    this.data = data;
                }
            }).unsubscribe();

        this.chooseLink = `/search/${this.activatedRouter.snapshot.params.checkin}/${this.activatedRouter.snapshot.params.checkout}/${this.activatedRouter.snapshot.params.rooms}`
    }
    openCalendar(hotelInfo?: any): void {
        this.initializerService.changeControlFormSearch({
            type:'local',
            hotelInfo: {
                name: hotelInfo.name,
                id: hotelInfo.id,
                type: 'hotel'
            }
        })
        this.openDatePickerPortal = true;
    }

    getData(room: string): void {
        const obj: any = {
            adult: 0,
            ages: null,
            checkin: this.activatedRouter.snapshot.params.checkin,
            checkout: this.activatedRouter.snapshot.params.checkout,
            destinationId: this.activatedRouter.snapshot.params.destinationId,
            pessoaId: this.activatedRouter.snapshot.params.pessoaId
        };
        room = room[0];
        if (room.length === 1) {
            obj.adult = room;
        } else {
            const children = room.split('-');
            obj.adult = children.shift();
            obj.ages = new Array();
            for (const age of children) {
                obj.ages.push(Number(age));
            }
        }

        if (this.activatedRouter.snapshot.params.destinationId) {
            obj.destinationId = this.activatedRouter.snapshot.params.destinationId;
        }

        if (this.documentService.getWindow()?.sessionStorage.getItem('coupon')) {
            obj.coupon = this.documentService.getWindow()?.sessionStorage.getItem('coupon');
        }

        this.appService.searchTariffs(obj).subscribe((response: any) => {
            this.hotels = response.data.data_establishments;
            this.appService.scroll('hotels');
            this.load = false;

            const responseMessage = response?.data?.message;

            if (responseMessage) {
                if (responseMessage.error) {
                    this.openSnackBar(responseMessage?.error, true, 3000);
                }

                if (responseMessage.success) {
                    this.openSnackBar(responseMessage?.success, false, 3000);
                }
            }
        });

        const searchDataEvent = {
            hotelInfo: {
                id: obj.id ? obj.id : Number(this.documentService.getWindow()?.sessionStorage.getItem('person')),
                name: obj.id ? obj.id.name : null,
            },
            searchConfig: [obj],
        }

        this.tagManagerService.searchEvent(searchDataEvent, this.router.url);
    }

    search(person: any): void {
        this.initializerService.changeControlLocalSelected({
            id: person.id,
            name: person.name,
            type: 'hotel'
        });

    }

    openSnackBar(msg: string, flag?: boolean | undefined, duration?: number): void {
        this.snackbar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg,
            },
            horizontalPosition: 'end',
            verticalPosition: this.getScreenWidth ? 'top' : 'bottom',
            panelClass: (flag) ? 'error-snack-bar' : 'success-snack-bar',
            duration: duration ?? 1000
        });
    }

    checkImageIcon(id: any | string): string {
        return this.appService.checkIconService(id, true);
    }
}
