import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

import {SharedModule} from '../../shared/shared.module';
import { AccommodationsModule } from '../accommodations/accommodations.module';
import { GalleryModule } from '../gallery/gallery.module';

import { AboutRoutingModule } from './about-routing.module';
import { AboutComponent } from './about.component';
import { ContentComponent } from './content/content.component';
import { DialogAboutComponent } from './dialog-about/dialog-about.component';


@NgModule({
  declarations: [AboutComponent, ContentComponent, DialogAboutComponent],
  imports: [
    SharedModule,
    AboutRoutingModule,
    AccommodationsModule,
    GalleryModule,
    NgxUsefulSwiperModule,
    MatDialogModule
  ],
  exports: [AboutComponent]
})
export class AboutModule { }
