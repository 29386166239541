<section class="mt-5 mb-5">
    <form class="testimony-form" [formGroup]="testimonyForm" (ngSubmit)="onSubmit()">
        <h4>Mande o seu comentário</h4>
        <div class="row mt-3">
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3" *ngIf="typeSearch >= 6 && !personId">
                <mat-form-field appearance="outline">
                    <mat-label>Defina o hotel</mat-label>
                    <mat-select #hotelSelect name="hotel" formControlName="pessoa_id">
                        <mat-option *ngFor="let hotel of hotelsList" [value]="hotel?.id?.id">
                            {{hotel.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div
                [ngClass]="{'col-sm-12 col-md-6 col-lg-3 col-xl-3': typeSearch >= 6 && !personId,
                'col-sm-12 col-md-6 col-lg-4 col-xl-4': typeSearch < 6 || personId}">
                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Digite seu nome" name="name" required formControlName="name">
                    <mat-error>Este campo é obrigatório</mat-error>
                </mat-form-field>
            </div>
            <div [ngClass]="{'col-sm-12 col-md-6 col-lg-3 col-xl-3': typeSearch >= 6 && !personId,
                'col-sm-12 col-md-6 col-lg-4 col-xl-4': typeSearch < 6 || personId}">
                <mat-form-field appearance="outline">
                    <mat-label>E-mail</mat-label>
                    <input matInput placeholder="Digite seu e-mail" type="email" name="email" formControlName="email" required>
                    <mat-error>Este campo é obrigatório</mat-error>
                </mat-form-field>
            </div>
            <div [ngClass]="{'col-sm-12 col-md-6 col-lg-3 col-xl-3': typeSearch >= 6 && !personId,
                'col-sm-12 col-md-6 col-lg-4 col-xl-4': typeSearch < 6 || personId}">
                <mat-form-field appearance="outline">
                    <mat-label>Telefone</mat-label>
                    <input matInput placeholder="Digite seu telefone" [mask]="'(00) 0 0000-0000'" name="phone" formControlName="phone" required>
                    <mat-error>Este campo é obrigatório</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Seu depoimento</mat-label>
                    <textarea matInput placeholder="Digite seu depoimento" formControlName="testimony" rows="7" name="testimony" required></textarea>
                    <mat-error>Este campo é obrigatório</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <my-portal-privacy-policy (policyEvent)="privacyPolicy($event)"></my-portal-privacy-policy>
                <my-portal-recaptcha (recaptchaEvent)="recaptcha($event)"></my-portal-recaptcha>
            </div>
        </div>

        <div class="button-container">
            <button [disabled]="(!testimonyForm.valid || !disabledButton || !disabledRecaptcha)" type="submit">
                <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status"></div>
                <span *ngIf="!loading">enviar</span>
            </button>
        </div>
    </form>
</section>
