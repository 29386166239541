import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';

import { GalleryRoutingModule } from './gallery-routing.module';
import { GalleryComponent } from './gallery.component';
import { ListGalleryComponent } from './list/list.component';
import { GalleryItemComponent } from './gallery-item/gallery-item.component';


@NgModule({
  declarations: [GalleryComponent, ListGalleryComponent, GalleryItemComponent],
  imports: [
    SharedModule,
    GalleryRoutingModule
  ],
  exports: [GalleryComponent]
})
export class GalleryModule { }
