<div class="translate align-items-center d-none" [ngClass]="{'ml-3': getScreenWidth}">
    <div id="google_translate_element"></div>
</div>

<div class="translate-google d-flex align-items-center" [ngClass]="{'ml-4': !getScreenWidth, 'ml-3': getScreenWidth}">
    <div class="d-flex justify-content-center align-items-center" 
        *ngIf="!dataHeader?.translate?.bandeiras_translate">
        <select id="internPageTranslate" onchange="changeLanguage()" (change)="verifyLanguage($event)" 
        [ngClass]="{'mobile-translate': getScreenWidth, 'desktop-translate': !getScreenWidth, 'white-background': whiteBackground}">
            <option value="" *ngIf="wordOriginal" onclick="clearTranslate()">Original</option>
            <option value="" *ngIf="wordIdioma" onclick="clearTranslate()">Idioma</option>
            <option value="de">Alemão</option>
            <option value="es">Espanhol</option>
            <option value="fr">Francês</option>
            <option value="en">Inglês</option>
            <option value="it">Italiano</option>
        </select>    
    </div>
    
    <div class="d-flex justify-content-center align-items-center" *ngIf="dataHeader?.translate?.bandeiras_translate">
        <div class="translate-content d-flex justify-content-center align-items-center">
            <select id="internPageTranslate" class="text-center" 
                [ngClass]="{'flag-translate': dataHeader?.translate?.bandeiras_translate, 'mobile-translate': getScreenWidth, 'desktop-translate': !getScreenWidth, 'white-background': whiteBackground}" onchange="changeLanguage()" (change)="verifyLanguage($event)" >
                <option class="notranslate" value="" *ngIf="wordOriginal || wordIdioma" onclick="clearTranslate()">🇧🇷</option>
                <option class="notranslate" value="de" >🇩🇪</option>
                <option class="notranslate" value="es" >🇪🇸</option>
                <option class="notranslate" value="fr" >🇫🇷</option>
                <option class="notranslate" value="en" >🇺🇸</option>
                <option class="notranslate" value="it" >🇮🇹</option>
            </select>  
        </div>  
    </div>
</div>