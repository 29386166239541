

<section class="reservations pt-3" id="reservations">
    <div class="container-wrapper">
        <div class="row title_section justify-content-between align-items-center no-gutters">
            <h6>Minhas reservas</h6>
            <div class="divider"></div>
            <div class="filter">
                <span>Filtrar por:</span>
                <form (ngSubmit)="onChangeFilter(filterSelect)">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select (valueChange)="onChangeFilter($event)" #filterReserveSelect>
                            <mat-option *ngFor="let status of filterStatusData" [value]="status">{{status.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>

        <div>
            <div class="table mb-1" *ngIf="!loadingData">
                <div class="table-tr title_table">
                    <div class="table-td">
                        <h6>Pedido</h6>
                    </div>
                    <div class="table-td">
                        <h6>Responsável</h6>
                    </div>
                    <div class="table-td">
                        <h6>Estabelecimento</h6>
                    </div>
                    <div class="table-td">
                        <h6>Valor</h6>
                    </div>
                    <div class="table-td">
                        <h6>Período</h6>
                    </div>
                    <div class="table-td">
                        <h6>Status</h6>
                    </div>
                    <div class="table-td"></div>
                </div>

                <div class="reserve-list"  *ngFor="let reserve of dataReserves?.data; let ind = index">
                    <div class="table-tr">
                        <div class="table-td" (click)="objHiddenPopover[ind].orderExpand = !objHiddenPopover[ind].orderExpand">
                            <label>Pedido</label>
                            <button class="btn order">{{reserve.id}}
                                <i class="material-icons-outlined notranslate">
                                    {{ !objHiddenPopover[ind].orderExpand ? 'expand_more' : 'expand_less' }}
                                </i>
                            </button>
                        </div>
                        <div class="table-td">
                            <div class="table-center responsavel">
                                <label>Responsável</label>
                                <div class="d-flex">
                                    <p>{{reserve.responsible.name}}</p>
                                    <div class="popover-content" (click)="objHiddenPopover[ind].showResponsiblePopover = !objHiddenPopover[ind].showResponsiblePopover">
                                        <i class="material-icons notranslate">info</i>
                                        <my-portal-popover [hidden]="!objHiddenPopover[ind].showResponsiblePopover" [type]="'restriction'"
                                            [content]="[
                                                'DADOS DO RESPONSÁVEL',
                                                'Telefone: ' + reserve.responsible.phone,
                                                'Email: ' + reserve.responsible.mail
                                                ]
                                            ">
                                        </my-portal-popover>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-td">
                            <label>Estabelecimento</label>
                            <p>{{reserve.establishment}}</p>
                        </div>


                        <div class="table-td hidden">
                            <label>Valor</label>
                            <p>{{reserve.value | currency}}</p>
                        </div>

                        <div class="table-td hidden">
                            <label>Período</label>
                            <p>De: {{reserve.checkin | date: 'dd/MM/yyyy'}} à {{reserve.checkout | date: 'dd/MM/yyyy'}}</p>
                        </div>

                        <div class="d-block d-md-none valor-periodo">
                            <div class="d-flex">
                                <div class="table-td">
                                    <label>Valor</label>
                                    <p>{{reserve.value | currency}}</p>
                                </div>

                                <div class="table-td">
                                    <label>Período</label>
                                    <p>De: {{reserve.checkin | date: 'dd/MM/yyyy'}} à {{reserve.checkout | date: 'dd/MM/yyyy'}}</p>
                                </div>
                            </div>
                        </div>


                        <div class="table-td">
                            <label>Status</label>
                            <p>{{reserve.status.nome}}</p>
                        </div>
                        <div class="table-td">
                            <button *ngIf="reserve.allow_cancellation" (click)="onHandleCancelReserve(reserve)" class="btn cancel">Cancelar</button>
                            <div *ngIf="!reserve.allow_cancellation" class="table-center">
                                <p class="">Prazo vencido</p>
                                <div class="popover-content" (click)="objHiddenPopover[ind].showCancelationPopover = !objHiddenPopover[ind].showCancelationPopover">
                                    <i class="material-icons notranslate">info</i>
                                    <my-portal-popover
                                        [type]="'restriction'"
                                        [content]="['De acordo com nossa política de cancelamento, após efetuar a reserva, você tem até ' + reserve?.cancel_policy?.advance_days + ' dias para cancelar de forma gratuita.']"
                                        [hidden]="!objHiddenPopover[ind].showCancelationPopover"
                                    ></my-portal-popover>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data_content" *ngIf="objHiddenPopover[ind].orderExpand">
                        <div class="content">
                            <div class="hotel">
                                <div class="thumb">
                                    <img [src]="imgServer + 'width=' + 234 + imgServerUrlEnd + reserve.details.room.image" alt="" class="img-fluid">
                                </div>
                                <div class="content_text">
                                    <ul class="list-unstyled p-0">
                                        <li class="hotel-name">{{reserve.details.hotel}}</li>
                                        <li class="room-name">{{reserve.details.room.name}}</li>
                                        <li class="room-plans">{{reserve.details.room.meal_plans[0]}}</li>
                                        <li class="tariff">
                                            <my-portal-tariffs-cancelation
                                                *ngIf="reserve.cancel_policy"
                                                [restrictedArea]="true"
                                                [cancelation]="reserve.cancel_policy">
                                            </my-portal-tariffs-cancelation>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="check_in">
                                <ul class="list-unstyled p-0">
                                    <li class="check-in">Check-in: {{reserve.checkin | date: 'EEE d MMM YYYY'}}</li>
                                    <li class="check-out">Check-out: {{reserve.checkout | date: 'EEE d MMM YYYY'}}</li>
                                    <li class="amount">Quantidade: {{reserve.details.pax}} adulto(s) {{reserve.details.chd !== 0 ? (reserve.details.chd + ' criança(s)') : '' }}</li>
                                    <li class="value">Valor: <span>{{reserve.details.value | currency}}</span></li>
                                </ul>
                            </div>
                            <div class="names">
                                <ul class="list-unstyled p-0">
                                    <li *ngFor="let guest of reserve.details?.guests | slice:0:3">{{guest}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="empty-reserve" *ngIf="!dataReserves?.data.length">
                    <span>
                        {{ !selectedFilterId ? 'Nenhuma reserva encontrada.' : 'Nenhuma reserva encontrada para este filtro.' }}
                    </span>
                </div>
            </div>

            <div *ngIf="loadingData" class="container text-center mt-5 mb-5">
                <div class="spinner-border mt-5 mb-5">
                    <span class="sr-only">Carregando...</span>
                </div>
            </div>

            <div class="pagination-container">
                <p>Página</p>
                <mat-paginator
                    [length]="dataReserves?.total"
                    [hidePageSize]="true"
                    [pageIndex]="currentPage"
                    [pageSize]="dataReserves?.per_page"
                    (page)="changePage($event)"
                    aria-label="Selecione a página">
                </mat-paginator>
            </div>
        </div>

        <div class="empty-reserve" *ngIf="dataReservesError">
            <span>
                Houve um erro ao carregar as reservas. Por favor, tente novamente mais tarde.
            </span>
        </div>
    </div>
</section>

