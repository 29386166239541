<div class="mt-0 mb-3" *ngIf="transfers?.length">
    <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
    <div class="row transfer">
        <div *ngFor="let transfer of transfers" class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="d-flex border mt-4 transfer-item">
                <div class="col-md-6 col-lg-6 col-sm-12 p-0 image-container">
                    <figure>
                        <img src="{{ImgSrc + transfer.images[0].thumb}}" class="img-fluid" alt="">
                    </figure>

                    <div class="flag client-theme-background">{{transfer.transfer.kind_transfer}}</div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 p-3 info-container">
                    <div class="title">
                        <span class="d-block">{{transfer.transfer.name}}</span>
                        <p>{{transfer.ambience_sale.name}}</p>
                    </div>

                    <div class="d-flex flex-direction-column justify-content-between">
                        <div class="price">
                            <p>A partir de:</p>
                            <span class="d-block">{{transfer.transfer.lower_price | currency:'BRL': 'R$ ' }}</span>
                        </div>
                        <div class="d-flex align-items-center button">
                            <a [href]="dataOtas.url + '/transfer/' + transfer.transfer_id + '/' + transfer.transfer.slug" target="_blank">
                                <button class="client-theme-border client-theme-color">reservar</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

