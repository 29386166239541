<section class="mt-5 mb-5" >
    <div *ngIf="load" class="loading-container">
        <div class="spinner-border client-theme-color" role="status">
            <span class="sr-only">Carregando...</span>
        </div>
    </div>

    <div *ngIf="!load">
        <my-portal-highlighter [module]="{title: 'Galeria de fotos'}"></my-portal-highlighter>

        <div class="gallery" *ngIf="!portalGallery">
            <ng-template #galleryInnerList [ngTemplateOutlet]="galleryInnerList"
                        *ngFor="let gallery of gallerys">
                <a [routerLink]="['/foto', gallery.slug, gallery?.id]" (click)="onClickGallery(gallery?.id, gallery.title)">
                    <div class="item">
                        <figure>
                            <img class="img-fluid" [src]="imgServer + 'width=' + 260 + imgServerUrlEnd + gallery.images[0]" [alt]="gallery.title" width="260" height="auto">
                        </figure>
                        <div class="title">{{ gallery.title }}</div>
                    </div>
                </a>
            </ng-template>
        </div>

        <mat-tab-group disableRipple mat-stretch-tabsBo class="gallery-tabs" *ngIf="portalGallery">
            <ng-template #listGalleryHome [ngTemplateOutlet]="listGalleryHome"
                        *ngFor="let gallery of gallerys?.hotels">
                <mat-tab [label]="gallery?.name | uppercase" *ngIf="gallery?.galeries">
                    <div class="row no-gutters mt-5">
                        <div class="col-lg-12">
                            <my-portal-galery-modal [galleryItem]="gallerys?.galeries[gallery?.galeries]"></my-portal-galery-modal>
                        </div>
                    </div>
                </mat-tab>
            </ng-template>
        </mat-tab-group>
    </div>

    <div *ngIf="!load && !gallerys?.length && !portalGallery">
        <div class="alert alert-secondary text-center" role="alert">
            Não foi encontrado nenhuma galeria para esse hotel.
        </div>
    </div>
</section>
