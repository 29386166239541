import { GalleryModule } from './../gallery/gallery.module';
import {NgModule} from '@angular/core';

import {SharedModule} from '../../shared/shared.module';
import {PackagesRoutingModule} from './packages-routing.module';
import {PackagesComponent} from './packages.component';
import {DefaultComponent} from './default/default.component';
import {CarouselComponent} from './carousel/carousel.component';

import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';
import { PromotionComponent } from './promotion/promotion.component';


@NgModule({
    declarations: [PackagesComponent, DefaultComponent, CarouselComponent, PromotionComponent],
    imports: [
        SharedModule,
        PackagesRoutingModule,
        NgxUsefulSwiperModule,
        GalleryModule
    ],
    exports: [PackagesComponent]
})
export class PackagesModule {
}
