import { Router } from '@angular/router';
import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'my-portal-client-area',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientAreaComponent implements OnInit {
  userData?: any = JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '');

  constructor(
    private documentService: DocumentService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!this.userData.id) {
      this.router.navigateByUrl('/');
    }
  }
}
