import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {SwiperOptions} from 'swiper';
import { InitializerService } from '../../../services/initializer.service';
import { environment } from './../../../../environments/environment';
import { TagManagerService } from '../../../services/tag-manager.service';

@Component({
    selector: 'my-portal-modal-info-room',
    templateUrl: './modal-info-room.component.html',
    styleUrls: ['./modal-info-room.component.scss'],
})
export class ModalInfoRoomComponent implements OnInit {
    //roomImage: any[] = [];
    initialize: boolean = false;

    config: SwiperOptions = {
        pagination: {
            el: '.pagination-accommodation',
            clickable: true,
        },
        spaceBetween: 30,
        slidesPerView: 1,
        autoHeight: true,
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        private initializerService: InitializerService,
        public dialogRef: MatDialogRef<ModalInfoRoomComponent>,
        private tagManagerService: TagManagerService,
    ) {}

    ngOnInit() {
        this.tagManagerService.viewItemEvent(this.data.room);
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
        // for(let item of this.data.room.images){
        //     this.roomImage.push('https://images.focomultimidia.com/curl/' + item.src);

        //     this.roomImage.length ? this.initialize = true : this.initialize = false;
        // }
    }
    closeMoreDatail(){
        this.dialogRef.close()
    }
}
