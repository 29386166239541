import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { InitializerService } from '../../../../../services/initializer.service';

import * as moment from 'moment';
import 'moment/locale/pt-br';


@Component({
    selector: 'my-portal-search-mobile',
    templateUrl: './search-mobile.component.html',
    styleUrls: ['./search-mobile.component.scss'],
})
export class SearchMobileComponent implements OnInit {
    @Output() acomodationType: any = new EventEmitter();
    @Output() guests: any = new EventEmitter();
    @Output() dates: any = new EventEmitter();
    @Output() destiny: any = new EventEmitter();
    @Output() coupon: any = new EventEmitter();
    @Input() dataHeader: any;
    @Input() typeSearch: any;

    destination: any = null;

    constructor(private initializerService: InitializerService) {}

    ngOnInit(): void {}

    openDatepicker(): void {
        setTimeout(() => {
            this.initializerService.changeControlFormSearch(true);
        }, 100);
    }

    changeAcomodation(event: any) {
        this.acomodationType = event;
    }

    changeGuests(event: any): void {
        this.guests.emit(event);
    }

    changeDates(event: any): void {
      this.dates.emit(event);
    }

    onSearch(): void {
        setTimeout(() => {
            this.initializerService.changeControlFormSearch({
                type: 'search',
                clear: true
            });
        }, 100);
    }

    changeDestination(event: any): void {
        this.destination = event;
        this.destiny.emit(event);
        setTimeout(() => {
            this.initializerService.changeControlFormSearch({
                type: 'destination',
            });
        }, 100);
    }
    handleSearchCoupon(event: any): void {
        this.onSearch();
        setTimeout(() => {
            this.initializerService.changeControlFormSearch({
                type: 'coupon'
            });
        }, 100);
    }

    couponEvent(event: any): void {
        // this.coupon = event;
        this.coupon.emit(event);
    }
}
