import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'my-portal-default-snack-bar',
  template: `<span class="example-pizza-party">
          {{ data.message }}
        </span>`,
  styleUrls: ['./default-snack-bar.component.scss']
})
export class DefaultSnackBarComponent implements OnInit {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    }

    ngOnInit(): void {

    }
}
