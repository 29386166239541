<div class="list-searches" [ngClass]="{'veritifactionVert': veritifactionVert}">
    <div class="item" *ngFor="let search of searchList; let ind = index">
        <div class="search-description">
            <a [href]="search.URL" target="_self">
                <span>{{search.destination?.name}}</span>
                <span class="date">
                    ({{search.dates.startDate | date: 'dd MMM'}} -
                    {{search.dates.endDate | date: 'dd MMM'}}) -
                    {{ (search.rooms.pax + ' adulto(s)') + (search.rooms.children ? ' - ' + search.rooms.children + ' criança(s)' : '')}}
                </span>
            </a>
        </div>
        <button translate="no" (click)="clearSearch(ind)" class="clear-search">
            <span class="material-icons-outlined" title="Apagar essa busca">close</span>
        </button>
    </div>

    <div class="item" *ngIf="(!searchList?.length || !searchList) && !loading">
        <div class="search-description">
            <span>Nenhuma pesquisa salva</span>
        </div>
    </div>

    <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
