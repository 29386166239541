import { Component, Input, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'my-portal-rate-comparator',
  templateUrl: './rate-comparator.component.html',
  styleUrls: ['./rate-comparator.component.scss']
})
export class RateComparatorComponent implements OnInit {

  @Input() rates: any;

  config: SwiperOptions = {
    slidesPerView: 1,
    autoplay: true,
    loop: true,
  };

  constructor() { }

  ngOnInit(): void {
  }

}
