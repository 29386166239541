import {Component, Input, OnInit} from '@angular/core';
import {SwiperOptions} from 'swiper';
import { AppDataService } from '../../services/app-data.service';
import { InitializerService } from '../../services/initializer.service';
import { environment } from './../../../environments/environment';

@Component({
    selector: 'my-portal-brands',
    template: `
        <swiper [config]="config" class="mt-4">
            <div class="swiper-wrapper">
                <div *ngFor="let brand of brands" class="benefit swiper-slide">
                    <a [routerLink]="['hotel', createUrlHotel(brand.name), brand.hotel_id]">
                        <figure>
                            <img [src]="imgServer + 'width=' + 150 + imgServerUrlEnd + brand.image" class="img-fluid brand-img" width="150" height="auto" alt="logo">
                        </figure>
                    </a>
                </div>
            </div>
            <div *ngIf="brands.length > 3" class="pagination-accommodation mt-3"></div>
        </swiper>
        `,
    styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

    @Input() brands: any = [];

    config: SwiperOptions = {};

    isSafari: any = this.initializerService.isSafari();
    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private appService: AppDataService,
                private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.config = {
            spaceBetween: 20,
            slidesPerView: 5,
            autoplay: true,
            // Responsive breakpoints
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: this.brands.length >= 5 ? 5 : this.brands.length,
                    spaceBetween: 20
                }
            },
            pagination: {
                el: '.pagination-accommodation',
                clickable: true
            }
        };
    }

    createUrlHotel(title: string): string {
        return this.appService.createSlugUrl(title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    }
}
