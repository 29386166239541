<!-- <div class="d-grid justify-content-center align-items-center mt-5 mb-5" *ngIf="infoData?.has_passs">
    <div class="content">
        <figure>
            <img src="/assets/images/ico-buscador-calendario.svg" alt="">
        </figure>
        <div class="text-content">
            <h4>Web check-in online</h4>
            <p>Faça agora, o seu check-in online e evite filas</p>
        </div>
    </div>
    <form (ngSubmit)="onSubmit(checkinForm)" [formGroup]="checkinForm" class="content">
        <div class="input-grup">
            <label for="reserve" class="sr-only"></label>
            <input type="text" formControlName="reserve" name="reserve"
                   placeholder="Digite seu e-mail ou número da reserva">
        </div>
        <button type="submit">
            <span *ngIf="!loading">Solicitar check-in</span>
            <div *ngIf="loading" class="spinner-border text-light" role="status">
                <span class="sr-only">Enviando...</span>
            </div>
        </button>
    </form>
</div> -->


<div class="mt-5 mb-5 web-checkin-container" *ngIf="infoData?.has_passs">
    <div class="info">
        <div class="icon-container">
            <figure>
                <svg stroke="currentColor" id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-calendar-web-check-in-1,.cls-calendar-web-check-in-2{fill:none;stroke-miterlimit:10;stroke-width:0.5px;}.cls-calendar-web-check-in-2{stroke-linecap:round;}</style></defs><title>Ico-minimo-dias</title><rect class="cls-calendar-web-check-in-1" x="1.4" y="2.6" width="21.2" height="20.25" rx="4"/><line class="cls-calendar-web-check-in-2" x1="12" y1="1.7" x2="12" y2="4.4"/><line class="cls-calendar-web-check-in-2" x1="18.4" y1="1.7" x2="18.4" y2="4.4"/><line class="cls-calendar-web-check-in-2" x1="5.5" y1="1.7" x2="5.5" y2="4.4"/><line class="cls-calendar-web-check-in-2" x1="12" y1="1.7" x2="12" y2="4.4"/><line class="cls-calendar-web-check-in-2" x1="4.6" y1="7.6" x2="19.3" y2="7.6"/><path class="cls-calendar-web-check-in-1" d="M16.9,12.9l-1.4-1.5h-.4L10.5,16,8.8,14.4H8.4L7,15.8a.3.3,0,0,0,0,.4l1.8,1.9h0l1.5,1.4a.3.3,0,0,0,.4,0l6.2-6.3A.2.2,0,0,0,16.9,12.9Z"/></svg>
            </figure>
        </div>
        <div class="content">
            <h4>Web check-in online</h4>
            <p>Faça agora, o seu check-in online e evite filas</p>
        </div>
    </div>
    <form  (ngSubmit)="onSubmit(checkinForm)" [formGroup]="checkinForm" class="content">
        <label for="reserve" class="sr-only"></label>
        <input type="text" formControlName="reserve" name="reserve" placeholder="Digite seu e-mail ou número da reserva">
        <button type="submit">
            <span *ngIf="!loading">Solicitar check-in</span>
            <div *ngIf="loading" class="spinner-border text-light" role="status">
                <span class="sr-only">Enviando...</span>
            </div>
        </button>
    </form>
</div>
