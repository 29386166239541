import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'my-portal-corporate-area',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.scss']
})
export class CorporateAreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
