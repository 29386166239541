<h1 mat-dialog-title class="dialog-price-divergency-title">Atenção, Divergência de preços! </h1>
<div mat-dialog-content>
    <div class="dialog-price-divergency-text-container">
        <span class="dialog-price-divergency-text">Há uma divergência de preços entre a sua pesquisa e a finalização.</span>
        <span class="dialog-price-divergency-text">
            Preço no momento da pesquisa: <span class="dialog-price-divergency-text-currency">{{data.old | currency}}</span>
        </span>
        <span class="dialog-price-divergency-text">Preço nesse momento: 
            <span class="dialog-price-divergency-text-currency">{{data.new | currency}}</span>
        </span>
        <span class="dialog-price-divergency-text">Houve um {{data.variation === 'positive' ? 'acréscimo' : 'desconto'}} de: 

            <span class="dialog-price-divergency-text-currency" [ngClass]="{positive: data.variation === 'positive'}">{{data.diff | currency}}</span>
        </span>
        <span class="dialog-price-divergency-text" >Deseja finalizar a reserva?</span>
    </div>
</div>
<div mat-dialog-actions class="dialog-price-divergency-actions">
  <button mat-button class="dialog-price-divergency-actions-cancel" (click)="cancelDialog()">cancelar</button>
  <button mat-button class="dialog-price-divergency-actions-confirm" (click)="confirmDialog()">finalizar</button>
</div>