import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppDataService} from '../../../services/app-data.service';
import {DocumentService} from '../../../services/document.service';
import { environment } from 'projects/new-portal/src/environments/environment';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-gallery-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListGalleryComponent implements OnInit {

    gallerys: any;
    typeSearch: any = Number(this.documentService.getWindow()?.sessionStorage?.getItem('typeSearch'));
    portalGallery = false;
    load: boolean = true;
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private appData: AppDataService,
                private appService: AppDataService,
                private router: Router,
                private documentService: DocumentService,
                private initializerService: InitializerService
                ) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.initializerService.currentData.subscribe((response: any) => {
            const {only_motor, only_site} = response;

            if (only_motor && !only_site) {
                this.router.navigateByUrl('/');
            }
        }).unsubscribe();

        this.portalGallery = (this.typeSearch >= 6);
        this.appData.getDataGallery(null, null, 0).subscribe((gallery: any) => {
            this.gallerys = gallery.data;
            const allGallerys = (this.portalGallery) ? Object.values(this.gallerys.galeries) : this.gallerys;

            for (const galleryData of allGallerys) {
                galleryData.slug = this.appService.createSlugUrl(galleryData.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
            }

            this.load = false;
        });
    }

    onClickGallery(galleryId: any, galleryName: any): void {
        this.documentService.getWindow()?.sessionStorage.setItem('galleryData', JSON.stringify({
            galleryId,
            galleryName
        }));
    }
}
