

<section>

    <div *ngIf="loadingVerifyToken" class="container text-center mt-5 mb-5">
        <div class="spinner-border mt-5 mb-5">
            <span class="sr-only">Carregando...</span>
        </div>
    </div>

    <div *ngIf="invalidToken" class="container text-center mt-5 mb-5">
        <p>Token inválido!</p>
        <p>Caso não tenha recebido o e-mail, tente novamente.</p>
    </div>

    <form *ngIf="!loadingVerifyToken && !invalidToken && !recoverySuccess" (ngSubmit)="onSubmit(passwordForm)" [formGroup]="passwordForm" class="form-container">
        <h6>
            Para configurar uma nova senha preencha o formulário abaixo.
        </h6>
        <mat-form-field appearance="outline">
            <mat-label>Digite a nova senha</mat-label>
            <input matInput name="password" type="password" formControlName="password">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Digite novamente a nova senha</mat-label>
            <input matInput name="passwordConfirm" type="password" formControlName="passwordConfirm">
        </mat-form-field>

        <p class="error" *ngIf="errorMessage">{{ errorMessage }}</p>

        <button [disabled]="!passwordForm.valid">
            <span *ngIf="!loading">enviar</span>
            <div *ngIf="loading" class="spinner-border">
                <span class="sr-only">Carregando...</span>
            </div>
        </button>
    </form>

    <div *ngIf="recoverySuccess" class="container text-center mt-5 mb-5">
        <p>Senha alterada com sucesso!</p>
        <p>Faça um novo login para entrar na sua conta.</p>
    </div>
</section>