
<section class="pt-5 pb-5 nav">
    <div class="container p-0">
        <div class="row justify-content-between no-gutters">
            <p>
                <i class="material-icons-outlined notranslate">person</i>
                <span>Olá {{', ' + userData.name}}</span>
            </p>
            <ul class="d-flex list-unstyled">
                <li (click)="scrollToView('reservations')">
                    <a>Minhas reservas</a>
                </li>
                <li (click)="scrollToView('faithfulness')" *ngIf="userData.type === 'client'">
                    <a>Fidelidade</a>
                </li>
                <li (click)="scrollToView('payments')" *ngIf="userData.type === 'corporate'">
                    <a>Pagamento</a>
                </li>
                <li (click)="handleLogout()">
                    <a>Sair</a>
                </li>
            </ul>
        </div>
    </div>
</section>

<router-outlet></router-outlet>
