import { Component, OnInit } from '@angular/core';
import { AppDataService } from '../../services/app-data.service';
import { DocumentService } from '../../services/document.service';

@Component({
    selector: 'my-portal-privacy-policy-landing-page',
    templateUrl: './privacy-policy-landing-page.component.html',
    styleUrls: ['./privacy-policy-landing-page.component.scss'],
})
export class PrivacyPolicyLandingPageComponent implements OnInit {
    policy: any;
    typeSearch: number = Number(
        this.documentService.getWindow()?.sessionStorage.getItem('typeSearch')
    );
    hotels: any;
    isLoading: boolean = true;

    constructor(
        private appService: AppDataService,
        private documentService: DocumentService
    ) {}

    ngOnInit(): void {
        this.appService.getDataPolicy().subscribe(
            (response: any) => {
                this.policy = response.data;

                this.hotels = Object.values(
                    this.typeSearch == 6 ? this.policy.hotels : this.policy
                );
                this.isLoading = false;
            },
            (error: any) => {
                this.hotels = [];
                this.isLoading = false;
            }
        );
    }
}