<swiper [config]="config" [initialize]="true" #usefulSwiper>
    <div class="swiper-wrapper">
        <ng-template #tariffImages [ngTemplateOutlet]="tariffImages" *ngFor="let image of images; let i = index">
            <figure class="swiper-slide">
                <div class="allotement" *ngIf="allotment <= 5 && !unavailable">
                    <span translate="no" class="material-icons">whatshot</span>
                    Resta(m) {{allotment < 0 ? 0 : allotment}} {{allotment === 1 ? 'unidade' : 'unidade(s)'}}
                </div>

                <img [src]="imgServer + 'width=' + 350 + imgServerUrlEnd + image.src" class="img-fluid" (click)="openModal();currentSlide(i+1)">
            </figure>
        </ng-template>

    </div>
    <!-- Add Arrows -->
<!--    <div class="swiper-pagination"></div>-->
    <div class="images-pagination swiper-pagination"></div>
</swiper>

<div class="slide-modal" [id]="'imgModal-' + roomId">
    <span (click)="closeModal()" class="close-modal">
        <i class="material-icons notranslate">close</i>
    </span>
    <div (click)="closeModal()" class="slide-modal-content">

        <div *ngFor="let image of images; let i = index" [class]="'img-slides-' + roomId">
            <img [src]="imgServer + 'width=' + 620 + imgServerUrlEnd +  image.src" class="w-90">
        </div>
    </div>
    <a (click)="plusSlides(-1)" class="prev material-icons">arrow_back</a>
    <a (click)="plusSlides(1)" class="next material-icons">arrow_forward</a>
</div>

