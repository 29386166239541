import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import { DefaultSnackBarComponent } from 'projects/new-portal/src/app/shared/default-snack-bar/default-snack-bar.component';
import {AppDataService} from '../../../../../services/app-data.service';

@Component({
    selector: 'my-portal-search-guests',
    templateUrl: './search-guests.component.html',
    styleUrls: ['./search-guests.component.scss'],

    animations: [
        trigger('slideUpAndDown', [
            transition(
                ':enter', [
                style({
                    transform: 'translateY(100%)'
                }),
                animate(200)]
            ),
            transition(':leave', [
                animate(200,
                    style({
                        transform: 'translateY(100%)'
                    })
                )
            ]),
        ]),
    ],
})
export class SearchGuestsComponent implements OnInit, OnChanges {
    @Output() guests: any = new EventEmitter();
    @Output() acomodationType: any = new EventEmitter();
    @Input() dataHeader: any;
    @Input() mobileSearch: any;
    @Input() roundedSearch: boolean = false;
    @Input() squaredSearch: any = false;
    @Input() lineSearch: any = false;
    @Input() openConfig: any;
    @Input() otherForm: any = false;
    @Input() searchType: any = false;
    @Input() resetTypeAcomodation: any;

    isMobile: boolean = this.initializerService.isMobileDevice();

    arrChildren: string[] = new Array(18);
    openCouponSearch: boolean = false;
    modelRoom: any = {
        pax: 2,
        children: 0,
        accommodation: 1,
    };
    openConfigSearch = false;
    rooms: any[] = [
        {
            pax: 2,
            children: 0,
            childrenAges: [],
        },
    ];

    selectedLocalAndDates: any;
    typeAcomodation: any = null;
    info: any;

    constructor(
        private initializerService: InitializerService,
        private documentService: DocumentService,
        private snackBar: MatSnackBar,
        private activatedRouter: ActivatedRoute,
        private router: Router,
        private dataService: AppDataService
    ) {
        activatedRouter.paramMap.subscribe((params) => {
            const initUrl = router.url;
            if (initUrl.includes('/search/')) {
                const url = initUrl.replace('/search/', '').split('/');

                let allConfig = null;
                if (url[2].split('?').length > 1) {
                    allConfig = url[2].split('?')[0];
                } else {
                    allConfig = url[2];
                }

                const config = allConfig.split('!');
                // const config = url[2].split('!');
                const endRoom = [];
                for (const room of config) {
                    const obj: any = {
                        pax: null,
                        children: 0,
                        childrenAges: [],
                    };
                    if (room.length === 1) {
                        obj.pax = Number(room);
                    } else {
                        const children = room.split('-');
                        obj.pax = Number(children.shift());
                        for (const age of children) {
                            obj.childrenAges.push(Number(age));
                        }
                        obj.children = obj.childrenAges.length;
                    }
                    endRoom.push(obj);
                }
                this.rooms = endRoom;
                this.modelRoom.pax = this.countTotal(this.rooms).pax;
                this.modelRoom.children = this.countTotal(this.rooms).children;
                this.modelRoom.accommodation = this.countTotal(this.rooms).accommodation;
            }
        });
    }

    ngOnInit(): void {
        this.initializerService.currentControlFormSearch.subscribe(
            (value: any) => {
                if (value) {
                    if (value.type === 'guests') {
                        this.openConfigSearch = true;
                        if (this.isMobile && this.info.whatsapp.active) {
                            const btnWhatsapp: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.btn-whats-app');
                            if (btnWhatsapp) {
                                btnWhatsapp.style.zIndex = -1;
                            }
                        }
                    }
                }
            }
        );

        this.initializerService.currentControlLocalSelected.subscribe(
            (value: any) => {
                if (value) {
                    this.selectedLocalAndDates = value;
                }
            }
        );

        if (!this.dataHeader.max_qtd_chd) {
            this.dataHeader.max_qtd_chd = 5;
        }

        if (!this.dataHeader.max_age_chd) {
            this.dataHeader.max_age_chd = 17;
        }

        if(this.dataHeader.accommodation_type == 2) {
            this.rooms[0].pax = this.modelRoom.accommodation
        }

        if (this.rooms.length > this.dataHeader.max_qtd_accommodation) {
            this.rooms = [
                {
                    pax: 2,
                    children: 0,
                    childrenAges: [],
                },
            ];
            this.openConfigSearch = false;
        }
        
        this.initializerService.currentData.subscribe((data: any) => {
            this.info = data;
        });
    
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.initializerService.currentControlFormSearch.subscribe((value: any) => {
            if(value.clear) {
                this.typeAcomodation = null;
            }
        });

        if (this.resetTypeAcomodation) {
            this.typeAcomodation = null
        }
    }

    openModal(): void {
        if (this.isMobile && this.info.whatsapp.active) {
            const btnWhatsapp: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.btn-whats-app');
            if (btnWhatsapp) {
                btnWhatsapp.style.zIndex = -1;
            }
        }

        const gallery: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.mat-tab-body.mat-tab-body-active');


        if (this.mobileSearch) {
            this.openConfigSearch = !this.openConfigSearch;
        } else {
            const body: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.mat-drawer-content');
            this.openConfigSearch = !this.openConfigSearch;
            if (this.openConfigSearch) {
                this.dataService.showAskSuites(true);
                const element: HTMLElement | any = this.documentService.getWindow()?.document.getElementById('search');
                (element as HTMLElement).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                });

                body.style = 'overflow: hidden';

                if (gallery) {
                    gallery.style.zIndex = 0;
                }
            } else {
                this.dataService.showAskSuites(false);
                body.style = 'overflow: auto';
                if (gallery) {
                    gallery.style.zIndex = 1;
                }

                if (this.isMobile && this.info.whatsapp.active) {
                    const btnWhatsapp: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.btn-whats-app');
                    if (btnWhatsapp) {
                        btnWhatsapp.style.zIndex = 3;
                    }
                }
            }
        }

        this.emitGuestsConfig();
    }

    openCoupon(event: any): void {
        const valid = this.checkChildrenAge();
        this.emitGuestsConfig();
        if (valid) {
            setTimeout(() => {
                if (this.dataHeader.hide_coupon) {
                    this.initializerService.changeControlFormSearch({
                        type: 'search',
                        clear: true
                    });
                }
                if (!this.dataHeader.hide_coupon) {
                    this.initializerService.changeControlFormSearch({
                        type: 'coupon',
                    });
                }

                this.initializerService.changeControlLocalSelected({
                    ...this.selectedLocalAndDates,
                    room: this.modelRoom
                });
            }, 100);

            this.openModal();
            this.openCouponSearch = true;
        } else {
            this.openSnackBar('Informe a idade das criança(s)');
        }
    }

    newAccommodation(): void {
        if (this.rooms.length == this.dataHeader.max_qtd_accommodation){
            return
        } else{
        this.rooms.push({
            pax: 2,
            children: 0,
            childrenAges: [],
        });
        this.modelRoom.accommodation = this.rooms.length;
    }}

    removeAccommodation(key: number): void {
        this.rooms.splice(key, 1);
        this.modelRoom.pax = this.countTotal(this.rooms).pax;
        this.modelRoom.children = this.countTotal(this.rooms).children;
        this.modelRoom.accommodation = this.rooms.length;
    }

    newGuests(): void {
        this.rooms[0].pax = this.rooms[0].pax + 1;
        this.modelRoom.accommodation = this.rooms[0].pax;
    }

    removeGuests(): void {
        this.rooms[0].pax = this.rooms[0].pax - 1;
        this.modelRoom.accommodation = this.rooms[0].pax;
    }

    selectAcomodaction(type: any): void {
        this.acomodationType.emit({
            type: type
        });

        if (type === 'traditional') {
            this.typeAcomodation = 1
        }

        if (type === 'hostel') {
            this.typeAcomodation = 2;
            this.modelRoom.accommodation = this.rooms[0].pax;
        }
    }

    actionRooms(flag: string, type: string, key: number): void {
        switch (flag) {
            case 'add':
                this.rooms[key][type] += 1;
                if (type === 'children') {
                    this.rooms[key].childrenAges.push(null);
                }
                break;
            case 'remove':
                this.rooms[key][type] -= 1;
                if (type === 'children') {
                    this.rooms[key].childrenAges.pop();
                }
                break;
        }
        this.modelRoom.pax = this.countTotal(this.rooms).pax;
        this.modelRoom.children = this.countTotal(this.rooms).children;
        this.modelRoom.accommodation = this.countTotal(
            this.rooms
        ).accommodation;
    }

    countTotal(rooms: any): any {
        const obj = {
            pax: 0,
            children: 0,
            accommodation: rooms.length,
        };
        for (const room of rooms) {
            obj.pax += Number(room.pax);
            obj.children += room.children;
        }
        return obj;
    }

    checkChildrenAge(): boolean {
        let valid = true;
        const selects: HTMLElement | any = this.documentService.getWindow()?.document.querySelectorAll('.select-children');
        for (const accom of this.rooms) {
            if (accom.children) {
                for (const age of accom.childrenAges) {
                    const el: HTMLElement | any = selects[accom.childrenAges.indexOf(null)] || selects[accom.childrenAges.indexOf('null')];
                    if (el) {
                        el.classList.add('required');
                    }
                    if (age === null || age === 'null') {
                        valid = false;
                    }
                }
            }
        }
        return valid;
    }

    openSnackBar(msg: string): void {
        this.snackBar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg,
            },
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: 'error-snack-bar',
            duration: 2500,
        });
    }

    emitGuestsConfig(): void {
        this.guests.emit({
            arrChildren: this.arrChildren,
            openCouponSearch: this.openCouponSearch,
            modelRoom: this.modelRoom,
            openConfigSearch: this.openConfigSearch,
            rooms: this.rooms,
        });
    }

    handleSearch(): void {
        setTimeout(() => {
            this.emitGuestsConfig();

            this.openConfigSearch = false;
            this.initializerService.changeControlFormSearch({
                type: 'search',
            });
        }, 100);
    }

    handleBackButton(): void {
        this.openModal();

        setTimeout(() => {
            this.initializerService.changeControlFormSearch({
                type: 'local',
            });
        }, 100);
    }

    handleModelChange(event: any) {
        if (event !== 'null') {
        const selects: HTMLElement | any = this.documentService.getWindow()?.document.querySelectorAll('.select-children');
            for (const accom of this.rooms) {
                const el: HTMLElement | any = selects[accom.childrenAges.indexOf(event)] ?? selects[accom.childrenAges.indexOf('null')];
                if (el) {
                    el.classList.remove('required');
                }
            }
        }
    }

}

