import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogMessageCancelationData {
    message: null;
    title: null;
}

@Component({
    selector: 'my-portal-message',
    template: `
        <h2 *ngIf="data.title">{{data.title}}</h2>
        <mat-dialog-content>
            <div [innerHTML]="data.message"></div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Fechar</button>
        </mat-dialog-actions>`,
    styleUrls: ['./message.component.scss']
})
export class DialogMessageCancelationComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DialogMessageCancelationComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogMessageCancelationData) {
    }

    ngOnInit(): void {
    }

}
