<!-- NEWSLETTER -->
<my-portal-cookie *ngIf="showBoxCookies && info.accept_cookie"></my-portal-cookie>

<footer>
    <div class="map" *ngIf="loadMap">
        <iframe loading="lazy" defer="defer" title="Mapa de localização" *ngIf="urlMap" [src]="urlMap | sanitize" allowfullscreen="" aria-hidden="false" frameborder="0" height="400"
                style="border:0;" tabindex="0" width="100%"></iframe>
    </div>
    <div *ngIf="footerData" class="container" translate="no">
        <my-portal-btn-whats-app *ngIf="whatsappInfoNumber" [infoData]="info" [footerData]="footerData"></my-portal-btn-whats-app>
        <my-portal-footer-establishment *ngIf="footerData?.length <= 1" [footerData]="footerData" [phoneWhatsapp]="info?.whatsapp" [data]="data"></my-portal-footer-establishment>
        <my-portal-footer-portal *ngIf="footerData?.length > 1" [footerData]="footerData" [phoneWhatsapp]="info?.whatsapp" [data]="data"></my-portal-footer-portal>
    </div>

    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col d-flex align-items-center copy">
                    <a class="d-flex align-items-center" href="https://focomultimidia.com/" target="_blank">
                        Desenvolvido por <img class="mx-1" src="assets/images/ico_logo.png" alt="imagem" width="25" height="25">
                        <span class="notranslate">
                            Foco Multimídia
                        </span>
                    </a>
                    
                    <div class="d-flex align-items-center copy">
                        <span class="ml-1 mr-1"> | </span>
                        <a class="privacy" href="/politica-de-privacidade" target="_blank">
                            Política de Privacidade
                        </a>
                    </div>
                </div>
                <div class="col-sm certificate">
                    <img src="assets/images/certificate.png" alt="imagem" width="239" height="52">
                </div>
            </div>
        </div>
    </div>
</footer>


<my-portal-popover-cart></my-portal-popover-cart>
