<my-portal-inner-load *ngIf="load"></my-portal-inner-load>
<div class="mt-4 mb-4" id="hotels-detail" [hidden]="load">
    <my-portal-about [personId]="hotelId" [expanded]="true"></my-portal-about>
    <my-portal-accommodations [personId]="hotelId"></my-portal-accommodations>
    <my-portal-gallery [personId]="hotelId"></my-portal-gallery>
    <my-portal-video-player [personId]="hotelId"></my-portal-video-player>
    <my-portal-packages [personId]="hotelId"></my-portal-packages>
    <my-portal-testimonies [personId]="hotelId" [typeSlide]="true"></my-portal-testimonies>
    <my-portal-certificates [personId]="hotelId"></my-portal-certificates>
</div>
