<div class="modal-info-room">
    <button translate="no" mat-dialog-close class="button-close-modal-room" (click)="closeMoreDatail()">
        <i class="material-icons"> close </i>
    </button>
    <h4 class="pb-1 title-info-room">{{ data.room.name }}</h4>
    <div class="content-box">
        <div class="row">
            <div class="col-md-12">
                <swiper [config]="config" [initialize]="initialize">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            *ngFor="let image of data.room.images"
                        >
                            <img [src]="imgServer + 'width=' + 448 + imgServerUrlEnd + image.src" class="img-fluid" />
                        </div>
                    </div>
                    <!-- Add Pagination -->
                    <div class="pagination-accommodation mt-4"></div>
                </swiper>
            </div>
        </div>
        <div class="mt-5 mb-5 info-content">
            <div class="box-content">
                <div class="d-flex justify-content-between align-items-start mb-1 title-info">
                    <h4>Descrição do quarto</h4>
                    <div class="content-info d-flex">
                        <div class="guest d-flex align-items-center">
                            <figure>
                                <img
                                    class="svg-icon"
                                    src="assets/images/ico-buscador-hospedes.svg"
                                    alt=""
                                />
                            </figure>
                            <p class="m-0">
                                {{ data.room.max }} max de hóspedes
                            </p>
                        </div>
                        <div class="max-area d-flex align-items-center" *ngIf="data.room.bedroom_area">
                            <figure>
                                <img
                                    class="svg-icon"
                                    src="assets/images/ico-tarifario-dimensao.svg"
                                    alt=""
                                />
                            </figure>
                            <p class="m-0">{{ data.room.bedroom_area }}m²</p>
                        </div>
                    </div>
                </div>
                <div class="divider-box"></div>
            </div>
            <p class="mt-4 description-info-room">{{ data.room.description }}</p>
        </div>
        <div *ngIf="data.room.amenities.length">
            <div class="box-content">
                <div class="d-flex justify-content-between align-items-start mb-1">
                    <h4>Comodidades</h4>
                </div>
                <div class="divider-box"></div>
            </div>
            <div class="list-amenities mt-4">
                <ul>
                    <li *ngFor="let item of data.room.amenities">
                        {{ item.name }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
