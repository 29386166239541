<div class="datepicker-room">
    <div class="dt-header">
        <div>
            <button matRipple (click)="changeMonth('prev')"
                    *ngIf="month.startDate > today">
                <span class="material-icons notranslate">arrow_back_ios</span>
            </button>
        </div>
        <div class="title" translate="no">{{month.startDate | date: 'MMMM | yyyy'}}</div>
        <div>
            <button matRipple (click)="changeMonth('next')">
                <span class="material-icons notranslate">arrow_forward_ios</span>
            </button>
        </div>
    </div>
    <div class="dt-week notranslate">
        <ul>
            <li *ngFor="let dayWeek of week">
                {{dayWeek}}
            </li>
        </ul>
    </div>
    <div class="dt-body">
        <ng-template #listDatePickerWeeks *ngFor="let week of datepickerRoom"
                     [ngTemplateOutlet]="listDatePickerWeeks">
            <ul class="days">
                <ng-template #listDatePickerDays *ngFor="let day of week"
                             [ngTemplateOutlet]="listDatePickerDays">

                    <li>
                        <div [matTooltip]="observations[day?.date]"
                            [matTooltipPosition]="'above'"
                            [matTooltipClass]="'my-tooltip-min-days'">
                            <button translate="no" (click)="selectDate(day?.date, $event)"
                                (mouseover)="onMouseHoverDate($event, day?.date)"
                                (mouseleave)="onMouseLeaveDate($event, day?.date)"
                                [ngClass]="{'disabled-date': day?.date < month.startDate || day?.date > month.endDate }"
                                [class]="'button-height date-'+day?.date"
                                [disabled]="
                                    day?.date < month.startDate ||
                                    day?.date < today ||
                                    day?.date > month.endDate ||
                                    (calendar && calendar[day?.date]?.restriction?.closed) && !objectRestriction.closedCheckOut ||
                                    !day"
                                matRipple>
                                {{ day?.date | date: 'd' }}
                                <span *ngIf="calendar && !calendar[day?.date]?.restriction?.closed"
                                    class="price">{{ calendar[day?.date]?.price | currency:'':'' }}
                                </span>

                                <span translate="yes" class="restriction" *ngIf="calendar && calendar[day?.date]?.restriction?.id === 2 || calendar && calendar[day?.date]?.restriction?.id === 3">
                                    {{calendar[day?.date]?.restriction?.message}}
                                </span>

                                <span translate="yes" class="restriction" *ngIf="day?.date >= today && (calendar && calendar[day?.date]?.restriction?.restrictions[3] && objectRestriction.closedCheckIn)">
                                    {{calendar[day?.date]?.restriction?.restrictions[3].message}}
                                </span>

                                <span translate="yes" class="restriction" *ngIf="day?.date >= today && (calendar && calendar[day?.date]?.restriction?.restrictions[4] && objectRestriction.closedCheckOut)">
                                    {{calendar[day?.date]?.restriction?.restrictions[4].message}}
                                </span>

                                <span
                                    class="observation search"
                                    [matTooltip]="'Mínimo de ' + calendar[day?.date]?.restriction?.observations[2].min_nights + ' noites'"
                                    [matTooltipPosition]="'above'"
                                    [matTooltipClass]="'my-tooltip-min-days'"
                                    *ngIf="day?.date >= today && (calendar && calendar[day?.date]?.restriction?.observations && calendar[day?.date]?.restriction?.observations[2])">
                                    {{calendar[day?.date]?.restriction?.observations[2].min_nights}}
                                </span>

                                <span
                                    class="observation closed"
                                    *ngIf="day?.date >= today && (calendar && calendar[day?.date]?.restriction?.closed)"
                                    [matTooltip]="'Esgotado ou Indisponível'"
                                    [matTooltipPosition]="'above'"
                                    [matTooltipClass]="'my-tooltip-min-days'">
                                    x
                                </span>
                            </button>
                        </div>
                    </li>
                </ng-template>
            </ul>
        </ng-template>
    </div>
</div>
