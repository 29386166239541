<div *ngIf="load" class="loading-container">
    <div class="spinner-border client-theme-color" role="status">
        <span class="sr-only">Carregando...</span>
    </div>
</div>

<section class="mt-5 mb-5 about" *ngIf="about">

  <div *ngIf="!load">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
          <my-portal-highlighter [pb]="true" [module]="marker"></my-portal-highlighter>
          <div class="hotel-info">
            <div class="info" *ngIf="!infoData?.hide_stars">
              <div class="star">
                <span translate="no" class="material-icons-outlined" *ngFor="let st of [0,1,2,3,4]; let ind = index"
                  [ngClass]="{'active': (ind+1) <= about?.data_establishment?.stars}">star</span>
              </div>
              <div class="local" *ngIf="!infoData?.hide_address">
                <span translate="no" class="material-icons-outlined">location_on</span>
                <span>{{about?.data_establishment?.city + ', ' + about?.data_establishment?.state}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  <my-portal-benefits [benefits]="about['carousel']"></my-portal-benefits>-->

      <div class="row no-gutters mt-5">
        <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
            <swiper [config]="configInterna" [ngClass]="{'loading': load }">
                <div class="swiper-wrapper">
                    <div *ngFor="let image of  about?.data_establishment?.url" class="benefit swiper-slide">
                        <figure>
                            <img class="img-fluid" [src]="imgServer + 'width=' + 833 + imgServerUrlEnd +  image">
                        </figure>
                    </div>
                </div>
                <div class="swiper-pagination pagination-accommodation pagination-images"></div>
            </swiper>

          <my-portal-benefits [benefits]="about['carousel']"></my-portal-benefits>

          <my-portal-highlighter [module]="hotel" *ngIf="typeEstablishment != 6 "></my-portal-highlighter>
          <p [innerHTML]="about.data_establishment.description | sanitizeHtml"></p>

          <my-portal-highlighter *ngIf="typeEstablishment !== 6" [module]="amenitiesMarker"></my-portal-highlighter>
          <my-portal-resources *ngIf="typeEstablishment !== 6" [showAll]="true" [resources]="about?.data_establishment?.amenities" [largerFont]="true"></my-portal-resources>

          <div *ngIf="typeEstablishment !== 6">
            <my-portal-accommodations [quantity]="2"></my-portal-accommodations>
          </div>

          <my-portal-gallery [moduleData]="{title: 'Nossas fotos'}"></my-portal-gallery>

        </div>

        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <my-portal-triggers [infoData]="infoData"></my-portal-triggers>
        </div>
      </div>
  </div>
</section>
