import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewsComponent} from './news/news.component';
import {SharedModule} from '../../shared/shared.module';
import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';
import {EntertainmentComponent} from './entertainment/entertainment.component';
import {CertificatesComponent} from './certificates/certificates.component';
import {TransfersComponent} from './transfers/transfers.component';
import {TicketsComponent} from './tickets/tickets.component';
import {GenericPagesRoutingModule} from './generic-pages-routing.module';
import {DetailComponent} from './detail/detail.component';
import {GenericPagesListComponent} from './list/list.component';
import {PagesComponent} from './pages/pages.component';
import { SlideDetailComponent } from './slide-detail/slide-detail.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        NewsComponent,
        EntertainmentComponent,
        CertificatesComponent,
        TransfersComponent,
        TicketsComponent,
        DetailComponent,
        GenericPagesListComponent,
        PagesComponent,
        SlideDetailComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgxUsefulSwiperModule,
        GenericPagesRoutingModule,
        MatIconModule,
    ],
    exports: [
        NewsComponent,
        EntertainmentComponent,
        CertificatesComponent,
        TransfersComponent,
        TicketsComponent,
        GenericPagesListComponent,
        PagesComponent
    ]
})
export class GenericPagesModule {
}
