<header [ngClass]="{'fixed': hiddenSlide, 'mobile-search-fixed': hiddenSlide && isMobile}">

  <div *ngIf="data?.header.type_menu == 1" >
    <!-- <my-portal-toolbar [hidden]="hiddenMenu" [dataHeader]="data" [hiddenSlide]="hiddenSlide" [position]="hiddenSlide"></my-portal-toolbar> -->
    <my-portal-toolbar *ngIf="hiddenSlide" [hidden]="hiddenMenu" [dataHeader]="data" [hiddenSlide]="hiddenSlide" [position]="hiddenSlide"></my-portal-toolbar>
    <my-portal-toolbar-model-two *ngIf="!hiddenSlide" [dataHeader]="data"></my-portal-toolbar-model-two>
  </div>

  <div *ngIf="data?.header.type_menu == 2">
    <my-portal-top-bar *ngIf="!data?.only_motor" [dataHeader]="data" [hidden]="hiddenSlide"></my-portal-top-bar>
    <my-portal-toolbar  [hidden]="hiddenMenu" [dataHeader]="data" [hiddenSlide]="hiddenSlide" [position]="hiddenSlide"></my-portal-toolbar>
  </div>

  <div *ngIf="data?.header.type_menu == 3" >
    <my-portal-toolbar *ngIf="hiddenSlide" [hidden]="hiddenMenu" [dataHeader]="data" [hiddenSlide]="hiddenSlide" [position]="hiddenSlide"></my-portal-toolbar>
    <my-portal-toolbar-model-two *ngIf="!hiddenSlide" [dataHeader]="data" [transparentLogo]="true"></my-portal-toolbar-model-two>
  </div>



  <iframe loading="lazy" title="Vídeo" [style]="youtubeTvFull ? 'height: 100vh' : ''" *ngIf="data?.banner_map && !isMobile" [hidden]="hiddenSlide" [src]="data?.banner_map + '?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&autoplay=1&loop=1' | sanitize" aria-hidden="false" frameborder="0" height="450"
            style="border:0; margin-bottom: -7px;" tabindex="0" width="100%"></iframe>

  <my-portal-slideshow *ngIf="!hiddenSlide && !data?.banner_map" [dataHeader]="data?.header?.slideshow" [type]="data?.header.type" [typeMenu]="data?.header.type_menu" [typeEstablishment]="data?.type" [effectType]="data?.header.effect"></my-portal-slideshow>
  <my-portal-search-form [dataHeader]="data" [position]="hiddenSlide"></my-portal-search-form>
  <!-- <my-portal-slideshow *ngIf="!hiddenSlide && data?.header.type > 1 && !data?.banner_map" [dataHeader]="data?.header?.slideshow" [type]="data?.header.type" [typeMenu]="data?.header.type_menu" [typeEstablishment]="data?.type" [effectType]="data?.header.effect"></my-portal-slideshow> -->

  <!-- <div class="msg-reserve-online" *ngIf="!hiddenSlide && data?.search_engine && !isMobile" [ngClass]="{'typeTwo': data?.header.type == 2, 'typeThree': data?.header.type == 3}">
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-reserve-cashback-1{fill:none;stroke:#FFF;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.75px;}</style></defs><title>ico-hospedagem</title><path class="cls-reserve-cashback-1" d="M12,7.8a9,9,0,0,0-9,8.9H21A9,9,0,0,0,12,7.8Z"/><polygon class="cls-reserve-cashback-1" points="22.4 18.6 1.6 18.6 2 17 22 17 22.4 18.6"/><line class="cls-reserve-cashback-1" x1="12" y1="7.8" x2="12" y2="6.1"/><circle class="cls-reserve-cashback-1" cx="12" cy="4.8" r="1.3"/><path class="cls-reserve-cashback-1" d="M14.1,9.4a7.6,7.6,0,0,1,5.3,5.3"/></svg>
    <h5>Faça a sua reserva online!</h5>
  </div> -->

<!--   <my-portal-packages-header *ngIf="typeSearch < 6" [dataHeader]="data" [hidden]="hiddenSlide"></my-portal-packages-header>-->
  <!-- <my-portal-search-form [dataHeader]="data" [position]="hiddenSlide"></my-portal-search-form> -->
</header>
<div class="container" *ngIf="data?.only_motor && hiddenSlide && hasBenefits">
  <my-portal-benefits [benefits]="data?.carousel"></my-portal-benefits>
</div>
