import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'my-portal-toolbar-model-three',
  templateUrl: './toolbar-model-three.component.html',
  styleUrls: ['./toolbar-model-three.component.scss']
})
export class ToolbarModelThreeComponent implements OnInit {
  @Input() dataHeader: any;
  @Input() hiddenSlide: any;
  @Input() position: any;
  constructor() { }

  ngOnInit(): void {
  }

}
