import { DefaultSnackBarComponent } from './../default-snack-bar/default-snack-bar.component';
import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import { DialogForgotPasswordComponent } from './../dialog-forgot-password/dialog-forgot-password.component';
import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppDataService } from '../../services/app-data.service';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'my-portal-restricted-area-login',
    templateUrl: './restricted-area-login.component.html',
    styleUrls: ['./restricted-area-login.component.scss'],
})
export class RestrictedAreaLoginComponent implements OnInit {
    @Input() visibility: boolean = false;

    user: any = {
        mail: '',
        password: '',
    };

    loading: boolean = false;
    loginType: string = '';
    disabledRecaptcha: boolean = false;
    loginError: boolean = false;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();

    constructor(
        private appData: AppDataService,
        public dialogRef: MatDialogRef<RestrictedAreaLoginComponent>,
        private router: Router,
        public dialog: MatDialog,
        private documentService: DocumentService,
        private initializerService: InitializerService,
        private snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.loginType = this.data.loginType;
    }

    ngOnInit(): void {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    recaptcha($event: any): void {
        this.disabledRecaptcha = $event;
    }

    onSubmit(formLogin: NgForm, loginType?: string): void {
        this.loading = true;
        const { mail, password } = formLogin.value;

        if (formLogin.valid) {
            this.appData
                .postRestrictedArea(loginType, mail, password)
                .subscribe(
                    (response: any) => {
                        this.loginError = false;
                        this.loading = false;
                        if (response.data.user) {
                            this.documentService.getWindow()
                                ?.sessionStorage?.setItem(
                                    'userData',
                                    JSON.stringify(response.data.user)
                                );
                            this.dialogRef.close();

                            if (this.router.url === '/' || this.router.url.includes('/?pessoa_id')) {
                                this.router.navigateByUrl(
                                    loginType === 'client'
                                        ? 'area-restrita/cliente'
                                        : 'area-restrita/corporativo'
                                );
                            } else {
                                this.openSnackBar('Logado com sucesso!');
                            }

                            this.updateUser(true);
                        }
                    },
                    (error) => {
                        this.loading = false;
                        this.loginError = true;
                    }
                );
        }
    }

    updateUser(isLogged: boolean): void {
        this.initializerService.changeControlAuth(isLogged);
    }

    handleForgotPassword(): void {
        this.dialogRef.close();
        const dialogRef = this.dialog.open(DialogForgotPasswordComponent, {
          width: '500px',
          data: {
            loginType: this.loginType,
          },
        });

        dialogRef.afterClosed().subscribe(result => {});
    }

    openSnackBar(msg: string): void {
        this.snackbar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg
            },
            horizontalPosition: 'end',
            verticalPosition: this.getScreenWidth ? 'top' : 'bottom',
            panelClass: ['success-snack-bar', 'open-cart'],
            duration: 1500
        });
    }
}
