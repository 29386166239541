

<section class="reservations pt-3">
    <div class="container">
        <div class="row title_section justify-content-between align-items-center no-gutters">
            <h6>Minhas reservas</h6>
            <div class="divider"></div>
            <div class="filter">
                <span>Filtrar por:</span>
                <form (ngSubmit)="onChangeFilter(filterSelect)" [formGroup]="filterSelect">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select #filterSelectRef multiple>
                            <mat-option *ngFor="let status of [1,2,3,4]" [value]="status">Status</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div class="table mt-3 mb-5">
            <div class="table-tr title_table">
                <div class="table-td">
                    <h6>Pedido</h6>
                </div>
                <div class="table-td">
                    <h6>Responsável pela reserva</h6>
                </div>
                <div class="table-td">
                    <h6>Estabelecimento</h6>
                </div>
                <div class="table-td">
                    <h6>Valor</h6>
                </div>
                <div class="table-td">
                    <h6>Período</h6>
                </div>
                <div class="table-td">
                    <h6>Status</h6>
                </div>
                <div class="table-td">

                </div>
            </div>

            <div class="table-tr" *ngFor="let reserve of dataReserves; let ind = index">
                <div class="table-td">
                    <button class="btn order">{{reserve.id}}
                        <i class="material-icons-outlined notranslate">
                            expand_more
                        </i>
                    </button>
                </div>

                <div class="table-td">
                    <p>
                        {{reserve.responsible.name}}
                        <i class="material-icons notranslate">info</i>
                    </p>
                </div>

                <div class="table-td">
                    <p>{{reserve.establishment}}</p>
                </div>

                <div class="table-td">
                    <p>{{reserve.value | currency}}</p>
                </div>

                <div class="table-td">
                    <p>De: {{reserve.checkin | date: 'dd/MM/yyyy'}} à {{reserve.checkout | date: 'dd/MM/yyyy'}}</p>
                </div>

                <div class="table-td">
                    <p>{{reserve.status.nome}}</p>
                </div>
                <div class="table-td">
                    <button *ngIf="reserve.allow_cancellation" class="btn cancel">Cancelar</button>
                </div>
            </div>

            <!-- <div class="data_content">
                <div class="content">
                    <div class="hotel">
                        <div class="thumb">
                            <img src="https://images.focomultimidia.com/curl/motor_reserva/images/quarto/cliente_734/201902041549285172CASAVARUNA.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="content_text">
                            <ul class="list-unstyled p-0">
                                <li class="hotel-name">Hotel Sky</li>
                                <li class="room-name">Apartamento Triplo Casal Luxo</li>
                                <li class="room-plans">Café da manhã Colonial</li>
                                <li class="tariff">Tarifa não reembolsável <i class="notranslate">&#38;</i> </li>
                            </ul>
                        </div>
                    </div>
                    <div class="check_in">
                        <ul class="list-unstyled p-0">
                            <li class="check-in">Check-in: Qua, 09 fev, 2022</li>
                            <li class="check-out">Check-out: Qui, 10 fev, 2022</li>
                            <li class="amount">Quantidade: 1 adulto(s) 1 criança(s)</li>
                            <li class="value">Valor: <span>R$: 1.587,00</span></li>
                        </ul>
                    </div>
                    <div class="names">
                        <ul class="list-unstyled p-0">
                            <li>Pago</li>
                        </ul>
                    </div>
                </div>
            </div> -->


            <!-- <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Pedido</th>
                    <th scope="col">Responsável pela reserva</th>
                    <th scope="col">Estabelecimento</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Período</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let reserve of [0,1,2,3]">
                    <td>
                        <span class="btn">34589
                            <i class="material-icons-outlined notranslate">
                                expand_more
                            </i>
                    </span>
                    </td>
                    <td>Fabrício Calmon dos Santos</td>
                    <td>Pousada Chales da Montanha</td>
                    <td>R$: 2.800,00</td>
                    <td>De: 26/10/2023 à 29/10/2023</td>
                    <td>Pago</td>
                    <td><button class="btn cancelar">Cancelar</button></td>
                  </tr>
                </tbody>
              </table> -->
        </div>
    </div>
</section>

<section class="faithfulness pt-5 pb-5">
    <div class="container">
        <div class="row title_section no-gutters">
            <h6>Fidelidade</h6>
            <div class="divider"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-12 mt-5 mb-5 cards ">
                <div class="card-content credits-released rounded" >
                    <p class="text-light">Créditos liberados</p>
                    <div class="divider"></div>
                    <p class="value text-light">{{dataFidelity?.cards.available | currency}}</p>
                    <button class="btn">Gerar voucher</button>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 mb-5 cards ">
                <div class="card-content credits-release rounded" >
                    <p class="text-dark">Créditos a liberar</p>
                    <div class="divider"></div>
                    <p class="value">{{dataFidelity?.cards?.released | currency}}</p>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 mb-5 cards ">
                <div class="card-content credits-used rounded" >
                    <p class="text-dark">Créditos utilizados</p>
                    <div class="divider"></div>
                    <p class="value text-danger">{{dataFidelity?.cards.used | currency}}</p>

                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 mb-5 cards ">
                <div class="card-content credits-expired rounded" >
                    <p class="text-dark">Créditos Expirados</p>
                    <div class="divider"></div>
                    <p class="value text-danger">{{dataFidelity?.cards?.expired | currency}}</p>
                </div>
            </div>

        </div>

        <div class="table-list">
            <h5 class="title">Meus cupons</h5>
            <div class="table">
                <div class="table-tr title_table">
                    <div class="table-td">
                        <h6>Código dos cupons</h6>
                    </div>
                    <div class="table-td">
                        <h6>Expiração</h6>
                    </div>
                    <div class="table-td">
                        <h6>Valor</h6>
                    </div>
                    <div class="table-td">
                        <h6>Observação</h6>
                    </div>
                    <div class="table-td share">
                        <h6>Compartilhar</h6>
                    </div>
                </div>

                <div class="table-tr title_table"  *ngFor="let voucher of dataFidelity?.vouchers">
                    <div class="table-td">
                        <p>{{voucher.codigo}}</p>
                    </div>
                    <div class="table-td">
                        <p>{{voucher.codigo}}</p>
                    </div>
                    <div class="table-td">
                        <p>{{voucher.valor}}</p>
                    </div>
                    <div class="table-td">
                        <p>{{voucher.codigo}}</p>
                    </div>
                    <div class="table-td share">
                        <p>
                            <a href="#">
                                <i class="material-icons-outlined notranslate">share</i>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-list">
            <h5 class="title">Extrato</h5>
            <div class="table">
                <div class="table-tr title_table">
                    <div class="table-td">
                        <h6>Data</h6>
                    </div>
                    <div class="table-td">
                        <h6>Expiração</h6>
                    </div>
                    <div class="table-td">
                        <h6>Valor</h6>
                    </div>
                    <div class="table-td">
                        <h6>Id</h6>
                    </div>
                    <div class="table-td">
                        <h6>Reserva</h6>
                    </div>
                    <div class="table-td">
                        <h6>Estabelecimento</h6>
                    </div>
                    <div class="table-td">
                        <h6>Produto</h6>
                    </div>
                    <div class="table-td">
                        <h6>Status</h6>
                    </div>
                    <div class="table-td">
                        <h6>Observação</h6>
                    </div>
                </div>

                <div class="table-tr title_table" *ngFor="let extract of dataFidelity?.extract">
                    <div class="table-td">
                        <p>{{extract.date | date: 'dd/MM/yy'}}</p>
                    </div>
                    <div class="table-td">
                        <p>{{extract.date | date: 'dd/MM/yy'}}</p>
                    </div>
                    <div class="table-td">
                        <p>{{extract.value | currency}}</p>
                    </div>
                    <div class="table-td">
                        <p>{{extract.id}}</p>
                    </div>
                    <div class="table-td">
                        <p>{{extract.reserve}}</p>
                    </div>
                    <div class="table-td">
                        <p>{{extract.establishment}}</p>
                    </div>
                    <div class="table-td">
                        <p>Hospedagem</p>
                    </div>
                    <div class="table-td">
                        <p>Crédito</p>
                    </div>
                    <div class="table-td">
                        <p>Crédito de hospedagem ({{extract.reserve}})</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pt-5 pb-5 payments">
    <div class="container">
        <div class="row title_section no-gutters">
            <h6>Formas de pagamento</h6>
            <div class="divider"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-12 mt-5 cards">
                <div class="card-content credits-released rounded" >
                    <div class="icon-container">
                        <div class="content">
                            <i class="material-icons-outlined notranslate">monetization_on</i>
                        </div>
                    </div>
                    <p class="payment-type">Faturado</p>
                    <div class="divider"></div>
                    <p class="payment-type"
                        [ngClass]="{'released': dataPayment.pix?.accepted, 'not-released': !dataPayment.pix.accepted}">
                        {{dataPayment.pix?.accepted ? 'Liberado' : "Não liberado"}}
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 cards">
                <div class="card-content credits-release rounded" >
                    <div class="icon-container">
                        <div class="content">
                            <i class="material-icons-outlined notranslate">payments</i>
                        </div>
                    </div>
                    <p class="payment-type">Cartão de crédito</p>
                    <div class="divider"></div>
                    <p class="payment-type"
                        [ngClass]="{'released': dataPayment.credit_card.accepted, 'not-released': !dataPayment.credit_card.accepted}">
                        {{dataPayment.credit_card.accepted ? 'Liberado' : "Não liberado"}}
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 cards">
                <div class="card-content credits-used rounded" >
                    <div class="icon-container">
                        <div class="content">
                            <i class="material-icons-outlined notranslate">pix</i>
                        </div>
                    </div>
                    <p class="payment-type">Pix</p>
                    <div class="divider"></div>
                    <p class="payment-type"
                        [ngClass]="{'released': dataPayment.pix.accepted, 'not-released': !dataPayment.pix.accepted}">
                        {{dataPayment.pix.accepted ? 'Liberado' : "Não liberado"}}
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-12 mt-5 cards">
                <div class="card-content credits-expired rounded" >
                    <div class="icon-container">
                        <div class="content">
                            <i class="material-icons-outlined notranslate">account_balance</i>
                        </div>
                    </div>
                    <p class="payment-type">Depósito bancário</p>
                    <div class="divider"></div>
                    <p class="payment-type"
                        [ngClass]="{'released': dataPayment.bank_deposit.accepted, 'not-released': !dataPayment.bank_deposit.accepted}">
                        {{dataPayment.bank_deposit.accepted ? 'Liberado' : "Não liberado"}}
                    </p>
                </div>
            </div>
        </div>

        <h5 class="title">Meus vouchers gerados</h5>

        <div class="row">
            <div class="col-lg-3 col-12 cards">
                <div class="card-content credits-released rounded" >
                    <p class="payment-type">Ciclo de faturamento</p>
                    <div class="divider"></div>
                    <p class="payment-type cycle">{{dataPayment.invoice.data.cycle}} dias</p>
                    <p class="comment">Renova em {{dataPayment.invoice.data.cycle}} de junho de 2022</p>
                </div>
            </div>

            <div class="col-lg-3 col-12 cards">
                <div class="card-content credits-release rounded" >
                    <p class="payment-type">Limite liberado</p>
                    <div class="divider"></div>
                    <p class="payment-type released">{{dataPayment.invoice.data.released | currency}}</p>
                </div>
            </div>

            <div class="col-lg-3 col-12 cards">
                <div class="card-content credits-used rounded" >
                    <p class="payment-type">Utilizados</p>
                    <div class="divider"></div>
                    <p class="payment-type not-released">{{dataPayment.invoice.data.used | currency}}</p>
                </div>
            </div>

            <div class="col-lg-3 col-12 cards">
                <div class="card-content credits-expired rounded" >
                    <p class="payment-type">Saldo</p>
                    <div class="divider"></div>
                    <p class="payment-type not-released">{{dataPayment.invoice.data.balance | currency}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pb-5 payments">
    <div class="container">
        <div class="row title_section no-gutters">
            <h6>Meus dados</h6>
            <div class="divider"></div>
        </div>

        <div class="row"></div>
    </div>
</section>
