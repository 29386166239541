<div class="line-search">
    <ul class="mb-0" [ngClass]="{'portal-ul': +searchType === 6, 'hidden-coupon-establishment': dataHeader.hide_coupon <= 6 && dataHeader.hide_coupon, 'hidden-coupon-portal': typeSearch >= 6 && dataHeader.hide_coupon}">
        <li *ngIf="searchType == 6">
            <my-portal-search-local (destination)="changeLocal($event)" [lineSearch]="'true'"></my-portal-search-local>
        </li>
        <li>
            <my-portal-datepicker (dates)="changeDates($event)" (panel)="openPanel($event)" [destination]="destination" [lineSearch]="true" [otherForm]="true"></my-portal-datepicker>
        </li>
        <li [ngClass]="{'portal-li': +searchType === 6}">
            <my-portal-search-guests [dataHeader]="dataHeader" (acomodationType)="changeAcomodation($event)" (guests)="changeGuests($event)" [lineSearch]="true" [otherForm]="true" [searchType]="searchType"></my-portal-search-guests>
        </li>
        <li [ngClass]="{'portal-li': +searchType === 6}" *ngIf="!dataHeader.hide_coupon">
            <my-portal-search-cupom [dataHeader]="dataHeader" (couponData)="changeCoupon($event)" (open)="onSearch()" [lineSearch]="true"></my-portal-search-cupom>
        </li>
        <li (click)="onSearch()" class="search">
            <button>
                Pesquisar
            </button>
        </li>
    </ul>
</div>
