import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { Router } from '@angular/router';
import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import { Component, OnInit } from '@angular/core';
import { runInThisContext } from 'vm';

@Component({
  selector: 'my-portal-restricted-area',
  templateUrl: './restricted-area.component.html',
  styleUrls: ['./restricted-area.component.scss']
})
export class RestrictedAreaComponent implements OnInit {

  userData?: any = JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '');

  constructor(
    private documentService: DocumentService,
    private appData: AppDataService,
    private router: Router,
    private initializerService: InitializerService
  ) { }

  ngOnInit(): void {
    if (!this.userData) {
      this.router.navigate(['/']);
    }

    if (this.userData?.type === 'client') {
        this.router.navigate(['/area-restrita/cliente']);
    }

    if (this.userData?.type === 'corporate') {
        this.router.navigate(['/area-restrita/corporativo']);
    }
  }
  handleLogout(): void {
    this.appData.postRestrictedAreaLogout(this.userData?.type).subscribe(
      (response: any) => {
        this.updateUser(false);
        this.router.navigateByUrl('/');
        this.documentService.getWindow()?.sessionStorage?.removeItem('userData');
      },
      (error) => {
        this.updateUser(false);
        this.router.navigateByUrl('/');
        this.documentService.getWindow()?.sessionStorage?.removeItem('userData');
      }
    );
  }

  updateUser(isLogged: boolean): void {
    this.initializerService.changeControlAuth(isLogged);
  }

  scrollToView(id: string): void {
    const element: HTMLElement | any = this.documentService.getWindow()?.document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
}
