<section class="container">
    <div class="content-trigger contact" *ngIf="checkinOut">
        <div class="icon-container">
            <figure>
                <svg xmlns="http://www.w3.org/2000/svg" stroke="#fff" viewBox="0 0 24.3 23.2"><defs><style>.checkin-checkout-icon-1{fill:none;stroke-linecap:round;stroke-miterlimit:10;}.checkin-checkout-icon-2{fill:#fff;}</style></defs><title>Ativo 1</title><g id="Camada_2" data-name="Camada 2"><g id="Camada_1-2" data-name="Camada 1"><line class="checkin-checkout-icon-1" x1="0.5" y1="8.1" x2="23" y2="8.1"/><rect class="checkin-checkout-icon-1" x="16.4" y="0.5" width="2.5" height="4.04" rx="1"/><rect class="checkin-checkout-icon-1" x="5.3" y="0.5" width="2.5" height="4.04" rx="1"/><path class="checkin-checkout-icon-1" d="M18.9,2.5h2.8a2.1,2.1,0,0,1,2.1,2V20.7a2.1,2.1,0,0,1-2.1,2H2.5a2,2,0,0,1-2-2V13.6"/><line class="checkin-checkout-icon-1" x1="7.8" y1="2.5" x2="16.4" y2="2.5"/><path class="checkin-checkout-icon-1" d="M.5,15V4.5a2,2,0,0,1,2-2H5.3"/><circle class="checkin-checkout-icon-2" cx="5.7" cy="12.7" r="0.8"/><circle class="checkin-checkout-icon-2" cx="12.2" cy="12.7" r="0.8"/><circle class="checkin-checkout-icon-2" cx="18.7" cy="12.7" r="0.8"/><circle class="checkin-checkout-icon-2" cx="5.7" cy="17.7" r="0.8"/><circle class="checkin-checkout-icon-2" cx="12.2" cy="17.7" r="0.8"/><circle class="checkin-checkout-icon-2" cx="18.7" cy="17.7" r="0.8"/></g></g></svg>
            </figure>
        </div>
        <h2>Check-in/out</h2>
        <p>Checkin: <strong>{{checkinOut.checkin}}</strong> | Check-out: <strong>{{checkinOut.checkout}}</strong></p>
    </div>
    <my-portal-info *ngIf="data?.phone?.length" [phone]="data?.phone" [whatsapp]="data?.whatsapp" [data]="infoData"></my-portal-info>
    <a *ngIf="infoData?.whatsapp?.active" href="https://api.whatsapp.com/send?phone={{data.whatsapp?.number}}{{data.whatsapp?.message ? '&text='+infoData.whatsapp.message : ' '}}" target="_blank">
    <div class="content-trigger whatsapp" *ngIf="data?.whatsapp && (!pageAbout || infoData.type != 6)">
        <div class="icon-container">
            <figure>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="24" height="24" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
            </figure>
        </div>
        <h2>Atendimento via WhatsApp</h2>
        <p><a href="https://api.whatsapp.com/send?phone={{data.whatsapp.number}}{{data.whatsapp.message ? '&text='+infoData.whatsapp.message : ' '}}" target="_blank">Clique aqui</a> para dúvidas, sugestões ou reservas.</p>
    </div>
    </a>

    <div class="content-trigger client-area" *ngIf="!userLogged && infoData?.restrited_area">
        <div class="icon-container">
            <!-- <i class="icon-cashback notranslate"></i> -->
            <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.user-icon-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}</style></defs><title>Icones</title><circle class="user-icon-1" cx="12" cy="6.3" r="4.7" transform="translate(-1.5 7.3) rotate(-32.1)"/><path class="user-icon-1" d="M4.7,22.4a1.5,1.5,0,0,1-1.5-1.9c.9-4.1.5-7.1,8.8-7.1s7.9,3,8.8,7.1a1.5,1.5,0,0,1-1.5,1.9Z"/></svg>
        </div>
        <h2>Área do cliente</h2>
        <p>Se você já é cliente, acesse o nosso portal para acompanhar reservas. </p>

        <form (ngSubmit)="onLoginSubmit(loginForm)" [formGroup]="loginForm" class="form-content">
            <input type="email" formControlName="mail" name="mail" placeholder="E-mail">
            <input type="password" formControlName="password" name="password" placeholder="Senha">
            <button type="submit" [disabled]="loginForm.invalid">
                <span *ngIf="!loadingLogin">acessar</span>
                <div *ngIf="loadingLogin" class="spinner-border spinner-grow-sm text-light" role="status">
                    <span class="sr-only">Entrando...</span>
                </div>
            </button>
            <div class="forgot-password">
                <button type="button" (click)="handleForgotPassword()">esqueci minha senha</button>
            </div>
        </form>
    </div>

    <div class="content-trigger web-checkin" *ngIf="infoData?.has_passs">
        <div class="icon-container">
            <figure>
                <svg id="Camada_1" stroke="#fff" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.web-checkin-icon-1,.web-checkin-icon-2{fill:none;stroke-width:0.5px;}.web-checkin-icon-1{stroke-linejoin:round;}.web-checkin-icon-2{stroke-miterlimit:10;}</style></defs><title>ico-web-check-in</title><path class="web-checkin-icon-1" d="M17,7.1h0a4.8,4.8,0,0,0-4.8-4.7A4.4,4.4,0,0,0,7.6,6.3a.9.9,0,0,0,.9,1,1.7,1.7,0,0,0,1.4-.5l.4-.6a1.7,1.7,0,0,1,2-1.3,1.6,1.6,0,0,1,1.3.8h0a2.2,2.2,0,0,1-.9,3l-3.8,2-.6.4a2,2,0,0,0-.8,1.8V19a2.6,2.6,0,0,0,2.6,2.6h4.3A2.6,2.6,0,0,0,17,19V7.1Z"/><path class="web-checkin-icon-2" d="M15.2,15.7a3,3,0,0,1-3,3,3.1,3.1,0,0,1-3-3,3,3,0,0,1,3-3A2.9,2.9,0,0,1,15.2,15.7Z"/><polyline class="web-checkin-icon-2" points="10.9 15.6 12.1 16.6 13.6 14.5"/></svg>
            </figure>
        </div>
        <h2>Web Check-in</h2>
        <p>Desfrute de toda comodidade e praticidade que nós oferecemos para fazer o seu check-in, evitando filas no dia da hospedagem.</p>

        <div class="form-content">
            <form (ngSubmit)="onCheckinSubmit(checkinForm)" [formGroup]="checkinForm" class="form-container">
                <input type="text" formControlName="reserve" name="reserve" placeholder="Nº da reserva ou e-mail">
                <button type="submit" [disabled]="checkinForm.invalid">
                    <span *ngIf="!loading">iniciar check-in</span>
                    <div *ngIf="loading" class="spinner-border text-light" role="status">
                        <span class="sr-only">Enviando...</span>
                    </div>
                </button>

            </form>
        </div>
    </div>
</section>
