<div class="room">
    <my-portal-images [allotment]="room?.allotment" [images]="room?.images" [roomId]="room?.id" [unavailable]="room?.restriction"></my-portal-images>
    <div class="box">
        <div class="info">
            <div>
                <h6 [ngClass]="{'loading': load }">{{room?.name}}</h6>
                <my-portal-config-room
                    [badrooms]="room.bed_rooms"
                    [bedroomArea]="room.bedroom_area"
                    [config]="room.config"
                    [max]="room?.max">
                </my-portal-config-room>
                <p [ngClass]="{'loading': load }">{{room?.description}}</p>
                <a (click)="openDialog()" class="more-details">Mais detalhes</a>
            </div>

            <div></div>

            <my-portal-resources [resources]="room?.amenities" [mb]="true" [moreItems]="true"></my-portal-resources>
        </div>
        <div *ngIf="!room?.restriction" class="prices">
            <div *ngIf="room?.price && !type" class="nights" [ngClass]="{'two-prices' : room?.alter_price}">
                <span translate="no" class="material-icons-outlined">nights_stay</span>
                {{ room?.nights === 1 ? room?.nights + ' noite' : room?.nights + ' noites' }}
                <div class="popover-content" (mouseover)="objHiddenPopover = true" (mouseleave)="objHiddenPopover = false">
                    <i class="material-icons notranslate pop">info</i>
                    <my-portal-popover [type]="'room'" [hidden]="!objHiddenPopover" 
                    [content]="[
                            'Total para: ' +
                            room?.nights + (room?.nights == 1 ? ' noite' : ' noites'),
                            config?.adult + (config?.adult == 1 ? ' adulto' : ' adultos') +
                            (config?.ages?.length ? ' e ' + config?.ages.length + ' criança' + (config?.ages.length === 1 ? '' : 's') : '')
                            ]">
                    </my-portal-popover>
                </div>
            </div>
            <div class="box-prices">
<!--                <div *ngIf="room?.alter_price" class="price discount">-->
<!--                    <div-->
<!--                        class="value client-theme-color">{{ room.alter_price.price | currency }}</div>-->
<!--                    <div class="desc-value client-theme-color">-->
<!--                        {{ room.alter_price.payment_form }}-->
<!--                        <div class="popover-content">-->
<!--                            <span class="material-icons" (click)="openMessage(room.alter_price.payment_description)">info</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
                <div *ngIf="room?.price === 0 && !type" class="price">
                    <div class="value">{{ room?.price | currency }}</div>
                </div>
                <div *ngIf="room?.price && !type" class="price">
                    <!--A VISTA-->
                    <div *ngIf="!room.max_parcels && !room.max_parcels_with_fees" class="d-flex flex-column">
                        <span class="to">a partir de:</span>
                        <div class="value">
                            <span class="value" [matTooltip]="(room.entry_percentage?.value ? (room.entry_percentage?.type == 2 ? 'Percentual de entrada ' + room.entry_percentage?.value + '%' : 'Entrada de R$' +  this.room.entry_percentage?.value.toFixed(2).replace('.', ',')) : '')" matTooltipPosition="right">{{ room?.price | currency }}</span>
                        </div>
                    </div>
                    <!--SEM JUROS-->
                    <div *ngIf="room.max_parcels">
                        <div class="desc-value discount" *ngIf="room.max_parcels > 1">
                            <strong class="getnet">{{ room?.price | currency }} À vista</strong>
                        </div>
                        <div class="value">
                            <span [matTooltip]="(room.entry_percentage?.value ? (room.entry_percentage?.type == 2 ? 'Percentual de entrada ' + room.entry_percentage?.value + '%' : 'Entrada de R$' +  this.room.entry_percentage?.value.toFixed(2).replace('.', ',')) : '')" matTooltipPosition="right">
                                <span *ngIf="room.max_parcels > 1">{{room.max_parcels}}x de</span> {{ room?.price_getnet | currency }} <span>sem juros</span>
                            </span>
                            <div *ngIf="room.entry_percentage?.value && getScreenWidth" class="popover-controller" (click)="showEntryPercentagePopover = !showEntryPercentagePopover">
                                <i class="material-icons notranslate">info</i>
                                <span class="popover" *ngIf="!showEntryPercentagePopover">{{(room.entry_percentage?.value ? (room.entry_percentage?.type == 2 ? 'Percentual de entrada ' + room.entry_percentage?.value + '%' : 'Entrada de R$' +  this.room.entry_percentage?.value.toFixed(2).replace('.', ',')) : '')}}</span>
                             </div>
                        </div>
                    </div>
                    <!--COM JUROS-->
                    <div *ngIf="room.max_parcels_with_fees && !room.max_parcels">
                        <div class="desc-value discount" *ngIf="room.price_with_max_parcels_fees && !room.max_parcels">
                            <strong class="getnet">{{room?.price | currency}} À vista</strong>
                        </div>
                        <div class="value" >
                           <span [matTooltip]="(room.entry_percentage?.value ? (room.entry_percentage?.type == 2 ? 'Percentual de entrada ' + room.entry_percentage?.value + '%' : 'Entrada de R$' +  this.room.entry_percentage?.value.toFixed(2).replace('.', ',')) : '')" matTooltipPosition="right"><span>{{room.max_parcels_with_fees}}x de</span> {{ room?.value_max_parcel_with_fees | currency }}</span>
                        </div>
                    </div>


                    <!-- <span class="to" *ngIf="room.max_parcels">a partir de:</span>
                    <div class="value" *ngIf="(!room.max_parcels && !room.max_parcels_with_fees)">{{ room?.price | currency }}</div>
                    <div class="desc-value discount" *ngIf="room.price_with_max_parcels_fees && !room.max_parcels">
                        <strong class="getnet">{{room.price_with_max_parcels_fees | currency}} em até</strong>
                    </div> -->

<!--                    Com Desconto GetNet-->

                    <!-- <div class="desc-value discount" *ngIf="room?.price_getnet && room.max_parcels >= 1">
                        <strong class="getnet">{{ room?.price | currency }} em até</strong>
                    </div>
                    <div class="value" *ngIf="room?.price_getnet && room.max_parcels >= 1">
                        <span>{{room.max_parcels}}x de</span> {{ room?.price_getnet | currency }} <span>sem juros</span>
                    </div>
                    <div class="value" *ngIf="room.price_with_max_parcels_fees && !room.max_parcels">
                        <span>{{room.max_parcels_with_fees}}x de</span> {{ room?.value_max_parcel_with_fees | currency }}
                    </div> -->
                    <!-- <div class="value" *ngIf="room?.price_getnet && room.max_parcels <= 1 && !room.max_parcels_with_fees">
                        {{ room?.price_getnet | currency }}
                    </div> -->
                    <!-- <div class="max-parcels-fees" *ngIf="room.max_parcels_with_fees && room.max_parcels > 1">
                        <strong>{{room.max_parcels_with_fees + 'x de '}} {{room.value_max_parcel_with_fees | currency}}</strong>
                    </div> -->
                    <!--                    Sem Desconto GetNet-->
                    <!-- <span *ngIf="showPriceDetails" class="price-details-smaller">Menor valor para os próximos 07 dias</span> -->
                    <!-- <div *ngIf="room?.max_parcels > 1 && !room?.price_getnet" class="desc-value">Em
                        até {{room.max_parcels}}x nos cartões
                    </div> -->
                    <div *ngIf="room?.alter_price" class="desc-value discount">
                        <div><strong>{{ room.alter_price.price | currency }}</strong></div>
                        <div> <span>no</span> {{ room.alter_price.payment_form }}</div>
                    </div>
                </div>
            </div>

            <div *ngIf="room.cashback" class="price cashback">
                <i *ngIf="room.cashback.id === 1" class="icon-cashback notranslate"></i>
                <figure *ngIf="room.cashback.id === 2">
                    <img class="ame-logo" src="assets/images/logo-ame.png" alt="Cashback Ame Digital">
                </figure>
                <span>Reserve e receba<br> <span class="cashback-value">{{ room.cashback.value | currency }}</span> de crédito<br> para sua próxima<br> reserva.</span>
            </div>

            <div *ngIf="type">
                <a [routerLink]="[room.slug, room.id]">
                    <button class="default-button">ver detalhes</button>
                </a>
            </div>
        </div>
        <div *ngIf="room?.restriction" class="restriction">

            <div class="unavailable-accommodation">
                <!-- <figure>
                    <img alt="" class="svg-icon"
                        src="../../../assets/images/ico-buscador-calendario.svg">
                </figure> -->
                <span translate="no" class="material-icons-outlined">sentiment_dissatisfied</span>
                <p> Acomodação <br> indisponível</p>
            </div>
            <button class="button-datepicker " (click)="openCalendar()">
                <figure>
                    <svg id="Camada_1" data-name="Camada 1" class="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke-linecap:round;}.cls-1,.cls-2{stroke:#484848;stroke-miterlimit:10;}.cls-2{fill:#fff;}</style></defs><title>Icones</title><line class="cls-1" x1="0.5" y1="8.6" x2="23" y2="8.6"/><rect class="cls-1" x="16.4" y="1" width="2.5" height="4.04" rx="1"/><rect class="cls-1" x="5.3" y="1" width="2.5" height="4.04" rx="1"/><path class="cls-1" d="M18.9,3h2.8a2,2,0,0,1,2,2V21.2a2,2,0,0,1-2,2H2.5a2,2,0,0,1-2-2V14.1"/><line class="cls-1" x1="7.8" y1="3" x2="16.4" y2="3"/><path class="cls-1" d="M.5,15.5V5a2,2,0,0,1,2-2H5.3"/><circle class="cls-2" cx="5.7" cy="13.2" r="0.8"/><circle class="cls-2" cx="12.2" cy="13.2" r="0.8"/><circle class="cls-2" cx="18.7" cy="13.2" r="0.8"/><circle class="cls-2" cx="5.7" cy="18.2" r="0.8"/><circle class="cls-2" cx="12.2" cy="18.2" r="0.8"/><circle class="cls-2" cx="18.7" cy="18.2" r="0.8"/></svg>
                </figure>
                <span >Consultar datas disponíveis</span>
            </button>
        </div>
    </div>
</div>
