<ng-template #listDialogCartItems [ngTemplateOutlet]="listDialogCartItems" *ngFor="let item of itemCart; let ind = index;">
    <div class="container-item">
        <figure>
            <img [src]="(item.image) ? imgServer +  'width=' + 237 + imgServerUrlEnd + item?.image : 'assets/images/no-image.png'" class="img-fluid" width="237" height="176">
        </figure>
        <div class="content">
            <div class="infos">
                <div class="header-info">
                    <div class="title-and-action">
                        <h6>{{ item.quarto_nome }}</h6>
                        <button mat-icon-button aria-label="Remover item do carrinho" (click)="removeItem(type, ind, item.pessoa_id)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <div class="price-item free DropDown" (click)="showSupplement=!showSupplement">
                        <span>Incluso</span>
                        <i class="material-icons-outlined client-theme-color notranslate">{{ showSupplement ? 'expand_less' : 'expand_more' }}</i>
                    </div>
                    <ul class="supplements" *ngIf="showSupplement">
                        <li *ngFor="let supplement of item.regime">
                            {{ supplement.name }}
                        </li>
                    </ul>
                    <my-portal-tariffs-cancelation *ngIf="item?.cancelamento" [cancelation]="item.cancelamento" [checkIn]="item.check_in"></my-portal-tariffs-cancelation>
    <!--                <pre>{{item | json }}</pre>-->
                </div>

                <div class="info-and-price">
                    <div class="info-container">
                        <div class="config">
                            <div class="items-container">
                                <div class="info">
                                    <figure>
                                        <img src="assets/images/ico-buscador-calendario.svg">
                                    </figure>
                                    De {{ item.check_in | date: 'EEE dd MMM' }} à {{ item.check_out | date: 'EEE dd MMM' }}
                                </div>
                                <div class="info">
                                    <figure>
                                        <img src="assets/images/ico-buscador-hospedes.svg">
                                    </figure>
                                    {{ item.quantidade_adulto }} adulto(s)  {{ item.quantidade_crianca }} criança(s)
                                </div>
                            </div>
                            <div class="price" *ngIf="item?.suplementos?.items?.length">
                                {{ item.diarias | currency }}
                            </div>
                        </div>

                        <div class="supplements-container">
                            <div class="mt-2" *ngIf="item?.taxas">Taxas</div>
                            <ul class="supplements optional w-100">
                                <li class="items w-100" *ngFor="let tax of item?.taxas?.tax">
                                    <div class="name w-100">{{ tax.name }}</div>
                                    <div class="value">{{ tax.value | currency }}</div>
                                </li>
                            </ul>
                        </div>

                        <div class="supplements-container">
                            <div class="mt-2" *ngIf="item?.suplementos?.items?.length">Opcionais</div>
                            <ul class="supplements optional w-100">
                                <li class="items w-100" *ngFor="let supplement of item.suplementos.items">
                                    <div class="name w-100">{{ supplement.nome }}</div>
                                    <div class="value">{{ supplement.valor | currency }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="total-price" [ngClass]="{'with-daily': item?.suplementos?.items?.length}">
                        <div class="price" *ngIf="item?.suplementos?.items?.length">
                            {{ item.diarias | currency }}
                        </div>

                        <div class="total">
                            <div class="label">Valor parcial</div>
                            <div class="value">{{ item.preco | currency }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
