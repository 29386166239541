<div class="mt-3 mb-3">
    <div class="tabela">
        <div class="tabela-tr title">
            <div class="tabela-td">
                <p>Sala</p>
            </div>
            <div class="tabela-td">
                <p>Área</p>
            </div>
            <div class="tabela-td">
                <p>Pé direito</p>
            </div>
            <div class="tabela-td">
                <p>Auditório</p>
            </div>
            <div class="tabela-td">
                <p>Escolar</p>
            </div>
            <div class="tabela-td">
                <p>Mesa"u"</p>
            </div>
            <div class="tabela-td">
                <p>Espinha de peixe</p>
            </div>
            <div class="tabela-td">
                <p>Baguete</p>
            </div>
            <div class="tabela-td">
                <p>Cooktail</p>
            </div>
        </div>

        <div class="tabela-tr data" *ngFor="let event of roomEvents">
            <div class="tabela-td">
                <p>{{event.nome}}</p>
            </div>
            <div class="tabela-td">
                <p>{{event.area}}</p>
            </div>
            <div class="tabela-td">
                <p>{{event.pe_direito}}</p>
            </div>
            <div class="tabela-td">
                <p>{{event.auditorio}}</p>
            </div>
            <div class="tabela-td">
                <p>{{event.escolar}}</p>
            </div>
            <div class="tabela-td">
                <p>{{event.mesa}}</p>
            </div>
            <div class="tabela-td">
                <p>{{event.espinha_peixe}}</p>
            </div>
            <div class="tabela-td">
                <p>{{event.banquete}}</p>
            </div>
            <div class="tabela-td">
                <p>{{event.cocktail}}</p>
            </div>
        </div>

    </div>
</div>