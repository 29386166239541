<section class="mt-5 mb-5" id="tickets-detail">
    <my-portal-inner-load *ngIf="load"></my-portal-inner-load>
    <div *ngIf="!load && tickets" class="tickets-detail">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                <div class="highlighter">
                    <div class="box-highlighter" id="box-highlighter">
                        <h1 class="hotel-name">{{ tickets?.name }}</h1>
                        <h2>{{ tickets?.brief_description }}</h2>
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row section-tickets-detail">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 frist-column">
                <div class="slide">
                    <swiper #galleryTop [config]="configSlide" [initialize]="initializer"
                    class="swiper-container gallery-top">
                        <div class="swiper-wrapper">
                            <ng-template #accommodationSlideImages *ngFor="let image of tickets?.images"
                                        [ngTemplateOutlet]="accommodationSlideImages">
                                <div class="swiper-slide">
                                    <figure>
                                        <img src="{{ imgServer + image.larger }}" alt="" class="img-fluid">
                                    </figure>
                                </div>
                            </ng-template>
                        </div>

                        <!-- Add Arrows -->
                        <div class="swiper-button-next-cart slideshow">
                            <mat-icon>arrow_forward_ios</mat-icon>
                        </div>
                        <div class="swiper-button-prev-cart slideshow">
                            <mat-icon>arrow_back_ios_new</mat-icon>
                        </div>
                    </swiper>

                    <swiper #galleryThumbs [config]="configThumbs" [initialize]="initializer"
                            class="swiper-container gallery-thumbs">
                        <div class="swiper-wrapper">
                            <ng-template #accommodationThumbsImages *ngFor="let image of tickets.images"
                                         [ngTemplateOutlet]="accommodationThumbsImages">
                                <div class="swiper-slide">
                                    <figure>
                                        <img src="{{ imgServer + image?.larger }}"
                                        alt=""
                                        class="img-fluid">
                                    </figure>
                                </div>
                            </ng-template>
                        </div>
                    </swiper>
                </div>


                <mat-tab-group mat-stretch-tabs class="mt-3 mb-3">
                    <mat-tab label="DETALHES" *ngIf="tickets?.description">
                        <div class="row no-gutters mt-5">
                            <div class="col-lg-12" [innerHtml]="tickets?.description"></div>
                        </div>
                    </mat-tab>

                    <mat-tab label="OUTRAS INFORMAÇÕES" *ngIf="tickets?.other_informations">
                        <div class="row no-gutters mt-5">
                            <div class="col-lg-12" [innerHtml]="tickets?.other_informations"></div>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <div class="shared mt-5 mb-5">
                    <ul class="d-flex list-unstyled p-0">
                        <li *ngFor="let item of [0,1,2]">
                            <a href="#" class="btn d-flex justify-content-center align-items-center">
                                <i class="material-icons-outlined notranslate">face</i>
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- <my-portal-highlighter [module]="{title: 'Compre também:'}"></my-portal-highlighter>
                <mat-tab-group mat-stretch-tabs class="mt-3 mb-3">
                    <mat-tab label="PASSEIOS" class="next">
                        <div class="row no-gutters">
                            <div class="col-lg-12">
                               <div class="tickets-list">
                                    <ng-template #listSupplement *ngFor="let supplement of [0,1,2]"
                                        [ngTemplateOutlet]="listSupplement">
                                        <div class="tickets">
                                            <div class="img-container">
                                                <figure>
                                                    <img src="../../../assets/images/no-image.png"
                                                        alt=""
                                                        class="img-fluid">
                                                </figure>
                                            </div>
                                            <div class="content compre-tambem d-flex justify-content-between p-3">
                                                <div class="title">
                                                    <span>{{supplement.name}}</span>
                                                </div>
                                                <div class="action mt-3 d-flex justify-content-between align-items-end">
                                                    <div class="info d-flex justify-content-between flex-column">
                                                        <p>A partir de:</p>
                                                        <span class="m-0 price"> R$: 250,00</span>
                                                    </div>

                                                    <button>Reservar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="TRANSFERS" class="next">
                        <div class="row no-gutters">
                            <div class="col-lg-12">
                               <div class="tickets-list">
                                    <ng-template #listSupplement *ngFor="let supplement of destinations"
                                                [ngTemplateOutlet]="listSupplement">
                                        <div class="tickets">
                                            <div class="img-container">
                                                <figure>
                                                    <img src="../../../assets/images/no-image.png"
                                                        alt=""
                                                        class="img-fluid">
                                                </figure>
                                            </div>
                                            <div class="content compre-tambem d-flex justify-content-between p-3">
                                                <div class="title">
                                                    <span>{{supplement.name}}</span>
                                                </div>
                                                <div class="action mt-3 d-flex justify-content-between align-items-end">
                                                    <div class="info d-flex justify-content-between flex-column">
                                                        <p>A partir de:</p>
                                                        <span class="m-0 price">{{ tickets.lower_price | currency  }}</span>
                                                    </div>

                                                    <button>Reservar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="TICKETS" class="next">
                        <div class="row no-gutters">
                            <div class="col-lg-12">
                               <div class="tickets-list">
                                    <ng-template #listSupplement *ngFor="let supplement of destinations"
                                                [ngTemplateOutlet]="listSupplement">
                                        <div class="tickets">
                                            <div class="img-container">
                                                <figure>
                                                    <img src="../../../assets/images/no-image.png"
                                                        alt=""
                                                        class="img-fluid">
                                                </figure>
                                            </div>
                                            <div class="content compre-tambem d-flex justify-content-between p-3">
                                                <div class="title">
                                                    <span>{{supplement.name}}</span>
                                                </div>
                                                <div class="action mt-3 d-flex justify-content-between align-items-end">
                                                    <div class="info d-flex justify-content-between flex-column">
                                                        <p>A partir de:</p>
                                                        <span class="m-0 price"> R$: 250,00</span>
                                                    </div>

                                                    <button>Reservar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group> -->

                <!-- <div [innerHTML]="accommodationDetail?.header?.description"></div> -->

                <!-- <my-portal-marker *ngIf="accommodationDetail?.amenities.length" [name]="'Comodidades'"></my-portal-marker> -->

                <!-- <my-portal-resources *ngIf="accommodationDetail?.amenities.length" [resources]="accommodationDetail?.amenities" [largerFont]="true" [showAll]="true"></my-portal-resources> -->
                <!-- <my-portal-marker [name]="'Opcionais'"></my-portal-marker>
                <my-portal-supplement></my-portal-supplement> -->
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 second-column">
                <div class="price">
                    <div class="box text-center">
                        <div>A partir de: </div>
                        <div class="value">{{ tickets.lower_price | currency }}</div>
                    </div>
                </div>

                <div class="info-room text-center">
                    <div class="title font-weight-bold">Gostou do passeio?</div>
                    <div class="subtitle">Faça já a sua reserva!</div>
                </div>

                <!-- <my-portal-datepicker-room (dates)="getDatesRange($event)" [roomId]="roomId" [hotelId]="accommodationDetail?.hotel_id"></my-portal-datepicker-room> -->

                <!-- <my-portal-form-search [config]="config" [configRoom]="accommodationDetail" [dates]="dateRange" [roomId]="roomId" [hotelId]="accommodationDetail?.hotel_id"></my-portal-form-search> -->

                <!-- <my-portal-info [phone]="dataFooter.phone"></my-portal-info> -->
            </div>
        </div>
    </div>
    <div *ngIf="!load && !tickets" class="">
        <div class="alert alert-danger text-center" role="alert">
            Houve um erro inesperado, por favor, refaça a sua pesquisa.
        </div>
    </div>
</section>
