<div class="price-compare" *ngIf="rates">
    <div class="title">
        <i class="material-icons-outlined notranslate">attach_money</i>
        <span>Compare os preços</span>
    </div>
    <div class="prices">
        <div class="our-site">
            <div class="our-site-price">
                <span>Nosso site </span>
                <span><strong>{{ rates[0]?.price | currency}}</strong></span>
            </div>
        </div>
        <div class="channel">
            <swiper [config]="config">
                <div class="swiper-wrapper">
                    <ng-template #listRate [ngTemplateOutlet]="listRate" *ngFor="let channel of rates; let i = index">
                        <div class="swiper-slide" *ngIf="i > 0">
                            <div class="channel-price">
                                <span class="name">{{channel.name}} </span>
                                <span><strong>{{channel.price | currency}}</strong></span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </swiper>
        </div>
    </div>
</div>
