<div class="local-wrapper">
    <div class="local-container">
        <div class="local-content" [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch}">
            <label [ngClass]="{ 'mobile-search' : mobileSearch }"
                   *ngIf="((!roundedSearch && !squaredSearch) || isMobile ) || lineSearch">Onde quer ir?</label>
            <div class="form-field">
                <div class="icon">
                    <figure>
                        <img alt="" class="svg-icon" [ngClass]="{ 'mobile-search' : mobileSearch }"
                             src="assets/images/ico-buscador-localizacao.svg" width="20" height="20">
                    </figure>
                </div>

                <button class="button-area" (click)="openModal()">
                    <p [ngClass]="{ 'mobile-search' : mobileSearch }">{{ local.model ? local.model : 'Destino' }}</p>
                    <span translate="no" *ngIf="!mobileSearch" class="material-icons">{{ local.hidden ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</span>
                </button>
            </div>
            <div class="local-select">
                <div [@.disabled]="!isMobile" @slideUpAndDown *ngIf="!local.hidden" class="dropdown-local" [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch}">
                    <span translate="no" class="material-icons arrow">arrow_drop_up</span>

                    <div class="header-fixed" [@.disabled]="!isMobile" @slideUpAndDown>
                        <div class="back-button-container">
                            <button matRipple class="back-button" (click)="handleBackButton()">
                                <span translate="no" class="material-icons-outlined">chevron_left</span>
                            </button>

                            <div *ngIf="selectedLocal" class="selected-local">
                                <span>{{ selectedLocal?.name }}</span>
                                <span class="date" *ngIf="selectedLocal?.selectedDates">
                                    {{ selectedLocal?.selectedDates?.checkin | date: 'dd MMM' }} à {{ selectedLocal?.selectedDates?.checkout | date: 'dd MMM' }}
                                </span>
                            </div>
                        </div>

                        <div class="clear-local" *ngIf="selectedLocal?.name" (click)="handleClearLocal()">
                            <span class="text">limpar seleção</span>
                            <span translate="no" class="material-icons-outlined icon">clear</span>
                        </div>
                    </div>
                    <ul class="destiny">
                        <ng-template #listCities
                                     *ngFor="let city of cities"
                                     [ngTemplateOutlet]="listCities">
                            <li translate="no" class="destiny-item">
                                <div class="title" (click)="selectLocal('local', city)" matRipple>
                                    <figure>
                                        <img alt="" class="svg-icon"
                                             src="assets/images/ico-buscador-localizacao.svg" width="20" height="20">
                                    </figure>
                                    {{ city.name }} - {{ city.state }}
                                </div>

                                <ul>
                                    <li *ngFor="let hotel of city.hotels" class="hotel" (click)="selectLocal('hotel', hotel)" matRipple>
                                        {{ hotel.name }}
                                    </li>
                                </ul>
                            </li>
                        </ng-template>
                    </ul>
                    <div class="footer-local">
                        <button (click)="openModal()" class="close-button" matRipple>fechar</button>
                        <button (click)="handleSelectDates()" class="next-button" matRipple>confirmar destino</button>
                    </div>
                </div>
            </div>

            <div (click)="openModal()" *ngIf="!local.hidden" class="backdrop"></div>
        </div>

    </div>
</div>
