<div mat-dialog-content>
  <p>Digite o valor que você deseja resgatar</p>
  <form (ngSubmit)="onSubmit(couponForm)" [formGroup]="couponForm" class="form-container">
    <mat-form-field appearance="outline">
      <mat-label>Resgate seu cupom</mat-label>
      <input
        matInput name="coupon"
        formControlName="coupon"
        mask="separator.2"
        [thousandSeparator]="'.'"
        prefix="R$ "
        [dropSpecialCharacters]="true"
      >
    </mat-form-field>
  </form>
</div>


<div class="success-coupon" *ngIf="dataCoupon">
  <h6>Cupom resgatado com sucesso!</h6>

  <p>
    O código do seu cupom com o valor de <strong>{{dataCoupon.valor | currency}}</strong> é: <strong>{{dataCoupon.codigo}}</strong>.
  </p>
  <p>
    O prazo de expiração do seu cupom é de <strong>{{dataCoupon.expiracao}} </strong> dias.
  </p>
</div>

<div class="error-coupon" *ngIf="errorMessage && !dataCoupon">
  <h6>Não foi possível resgatar o seu cupom!</h6>
  <p>
    {{errorMessage}}
  </p>
</div>

<div mat-dialog-actions class="actions-container">
  <button mat-button (click)="onNoClick()">fechar</button>
  <button mat-button cdkFocusInitial
    (click)="onSubmit(couponForm)"
    [disabled]="!couponForm.valid"
    class="rescue">{{ loading ? 'resgatando' : 'resgatar' }}</button>
</div>
