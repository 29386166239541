<div mat-dialog-title class="title">
    Itens pendentes
    <div class="close">
        <button class="close" (click)="closeDialog()">
            <i class="material-icons-outlined notranslate">close</i>
        </button>
    </div>
</div>
<div mat-dialog-content class="content">
    <div class="dialog-content">
        <p>
            Baseado na sua pesquisa, ainda existem acomodações para serem escolhidas. <br>
            Deseja continuar escolhendo acomodações?
        </p>
    </div>

</div>
<div mat-dialog-actions class="actions-container">
    <button class="finish" mat-button (click)="finish()" [disabled]="isLoadingFinalization">
        {{ isLoadingFinalization ? 'Finalizando' : 'Não' }}
    </button>
    <button class="select-accommodations" mat-button (click)="selectRemainingAccommodations()">Sim</button>
</div>
