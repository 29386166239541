import {Component, Input, OnInit} from '@angular/core';
import {SwiperOptions} from 'swiper';
import { environment } from 'projects/new-portal/src/environments/environment';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-packages-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

    @Input() packages: any;
    @Input() initialize: any;
    @Input() load: any;
    @Input() hotelId: any;

    config: SwiperOptions = {
        pagination: {
            el: '.pagination-accommodation',
            clickable: true,
        },
        spaceBetween: 30,
        slidesPerView: 3,
        autoHeight: true,
        observer: true,
        observeParents: true,
        autoplay: {
            delay: 3000,
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: true
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 30,
                centeredSlides: true,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        }
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
    }

}
