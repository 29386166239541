import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpRequest,
    HttpInterceptor,
    HttpHandler,
    HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheResolverService } from './cache-resolver.service';

const TIME_TO_LIVE = 3600; // in seconds => 1 hour

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(private cacheResolver: CacheResolverService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            request.method !== 'GET' ||
            request.url.includes('search') ||
            request.url.includes('supplements') ||
            request.url.includes('policy-terms') ||
            request.url.includes('datepicker') ||
            request.url.includes('pet')
        ) {
            return next.handle(request);
        }

        const cachedResponse = this.cacheResolver.get(request.url);

        return cachedResponse ? of(cachedResponse) : this.sendRequest(request, next);
    }

    sendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    this.cacheResolver.set(request.url, event, TIME_TO_LIVE);
                }
            })
        );
    }
}
