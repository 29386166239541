<section class="mt-5 mb-5">
    <div *ngIf="moduleData" class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 d-flex justify-content-between">
            <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
        </div>
    </div>

    <my-portal-inner-load *ngIf="isLoading"></my-portal-inner-load>

    <div *ngIf="!isLoading && !errorFaqs">
        <div *ngIf="faqs.length > 0" class="row no-gutters d-flex justify-content-between">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let faq of faqs" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ faq.question }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div [innerHTML]="faq.answer | sanitizeHtml"></div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div *ngIf="!isLoading && faqs.length == 0">
            <h5>Este estabelecimento não possui FAQs</h5>
        </div>
    </div>

    <div *ngIf="!isLoading && errorFaqs">
        <h5>Houve um erro ao consultar as FAQs. Tente novamente em alguns instantes.</h5>
    </div>
</section>