<section class="mt-5 mb-5" *ngIf="genericPages?.items?.length">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
        </div>
    </div>


<div class="row generic-pages">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <swiper [config]="config" [initialize]="initialize">

                <div class="swiper-wrapper">
                    <ng-template #listGenericPages [ngTemplateOutlet]="listGenericPages"
                        *ngFor="let page of genericPages?.items">
                        <a [routerLink]="['/conteudo', page.slug, page.id]" class="swiper-slide">
                        <div>
                            <figure>
                                <img [src]="imgServer + 'width=' + 350 + imgServerUrlEnd + page.image" class="img-fluid" width="350" height="auto" alt="imagem">
                            </figure>
                            <div class="box-page">
                                <div>
                                    <h4>{{ page.title }}</h4>
                                    <p [innerText]="page.sub_title"></p>
                                </div>
                                <a [routerLink]="['/conteudo', page.slug, page.id]">
                                    <div class="box-button">
                                        <button>saiba mais</button>
                                    </div>
                                </a>
                            </div>
                        </div>
                        </a>
                    </ng-template>
                </div>
                        <!-- Add Pagination -->
                <div class="pagination-accommodation mt-4"></div>
        </swiper>
    </div>
</div>

<my-portal-show-all [route]="'/categoria/'+ genericPages.slug + '/' + genericPages.id"></my-portal-show-all>


    <!-- <div class="row row-cols-1 row-cols-lg-3 generic-pages">
        <ng-template #listGenericPages [ngTemplateOutlet]="listGenericPages"
            *ngFor="let page of genericPages?.items">
            <a [routerLink]="['/conteudo', page.slug, page.id]">
                <div class="col mb-4">
                    <figure>
                        <img [src]="page.image" class="img-fluid">
                    </figure>
                    <div class="box-page">
                        <div>
                            <h4>{{ page.title }}</h4>
                            <p [innerText]="page.sub_title"></p>
                        </div>
                        <a [routerLink]="['/conteudo', page.slug, page.id]">
                            <div class="box-button">
                                <button>saiba mais</button>
                            </div>
                        </a>
                    </div>
                </div>
            </a>
        </ng-template>
    </div> -->
</section>
