import {NgModule, DEFAULT_CURRENCY_CODE} from '@angular/core';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth.interceptors';

import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';

import { registerLocaleData } from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import { CacheInterceptor } from './cache/cache-interceptor';
import { CacheResolverService } from './cache/cache-resolver.service';

registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserAnimationsModule,
        BrowserTransferStateModule,
        CoreModule,
        AppRoutingModule,
        NgxMaskModule.forRoot()
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: 'pt'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL'},
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        CacheResolverService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
