import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'my-portal-entertainment',
  templateUrl: './entertainment.component.html',
  styleUrls: ['./entertainment.component.scss']
})
export class EntertainmentComponent implements OnInit {

  constructor() { }

  config: SwiperOptions = {
    pagination: {
      el: '.pagination-accommodation',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    effect: 'slide',
    spaceBetween: 30,
    slidesPerView: 1,
    autoHeight: true,
  };

  ngOnInit(): void {
  }
}
