<div mat-dialog-content class="content">
    <div class="close">
        <button class="close" (click)="closeDialog()">
            <i class="material-icons-outlined notranslate">close</i>
        </button>
    </div>
    <div class="dialog-content">
        <p>
            Selecione o tipo de login
        </p>
    </div>

    <div mat-dialog-actions class="actions-container">
        <button mat-button (click)="openDialogLogin('client')">cliente</button>
        <button mat-button (click)="openDialogLogin('corporate')">corporativo</button>
    </div>
</div>
