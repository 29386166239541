<section class="mt-5 mb-5">
    <my-portal-inner-load *ngIf="load"></my-portal-inner-load>
    <div class="row" *ngIf="!load" id="list-accommodations">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <my-portal-highlighter [module]="accommodationModuleData"></my-portal-highlighter>
        </div>
    </div>
    <div class="row no-gutters accommodation" *ngIf="!load">
        <div class="col">
            <ng-template #templateListAccommodations *ngFor="let room of accommodations; let i = index"
                         [ngTemplateOutlet]="templateListAccommodations">
                <div class="mb-5">
                    <my-portal-room [showPriceDetails]="true" [room]="room" [type]="true"></my-portal-room>
                </div>
            </ng-template>
        </div>
    </div>
</section>
