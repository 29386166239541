<div class="marker">
    <div class="box-marker">
        <div class="inner">
            <h4>{{ name }}</h4>

            <div class="box-info">
                <div class="info" *ngIf="max">
                    <figure>
                        <img class="svg-icon" src="assets/images/ico-buscador-hospedes.svg" alt="">
                    </figure>
                    {{ max }} max de hóspedes
                </div>

                <div class="info" *ngIf="bedroomArea">
                    <figure>
                        <img class="svg-icon" src="assets/images/ico-tarifario-dimensao.svg" alt="">
                    </figure>
                    {{ bedroomArea }}m²
                </div>
            </div>
        </div>
        <div class="divider"></div>
    </div>
</div>
