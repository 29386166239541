<h1 mat-dialog-title class="dialog-confirm-responsible-title">Atenção!</h1>

<form name="formCheckout" [formGroup]="responsibleForm" (ngSubmit)="onHandleSubmit()">
    <div mat-dialog-content>
        <div class="dialog-confirm-responsible-container">
            <span class="dialog-confirm-responsible-text">Não conseguimos salvar os dados do responsável. Informe-os novamente por
                favor!</span>

            <div class="row mb-2 mt-3">
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput placeholder="José" formControlName="nome">
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Sobrenome</mat-label>
                        <input matInput placeholder="Silva" formControlName="sobrenome">
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                    <mat-form-field appearance="outline">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="seunome@email.com" formControlName="email">
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Celular</mat-label>
                        <input matInput ng2TelInput [ng2TelInputOptions]="ng2TelInputOptions" (countryChange)="onCountryChange($event)"
                            [placeholder]="phone.sigla == 'br' ? '(00) 0 0000-0000' : 
                            'Celular'" [mask]="phone.sigla == 'br' ? '(00) 0000-0000 || (00) 0 0000-0000' : '0000000000000000'"
                            formControlName="telefone" [validation]="phone.sigla == 'br'">
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de documento</mat-label>
                        <mat-select [formControlName]="'documento'" (selectionChange)="selectTypeDocument()">
                            <mat-option value="1">CPF</mat-option>
                            <mat-option value="2">Passaporte</mat-option>
                            <mat-option value="3">Outro documento</mat-option>
                        </mat-select>
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Documento</mat-label>
                        <input
                            matInput
                            placeholder="000.000.000-00"
                            [mask]="responsibleForm.value.documento == 1 ? '000.000.000-00' : '00000000000000000'"
                            formControlName="cpf_passaporte">
                        <mat-error>{{ cpfErrorMessage }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="action-form-purchase">
        <button mat-button class="dialog-confirm-responsible-actions-confirm">finalizar</button>
    </div>
</form>