import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import {DocumentService} from '../../../../../services/document.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {AppDataService} from '../../../../../services/app-data.service';

@Component({
    selector: 'my-portal-search-cupom',
    templateUrl: './cupom.component.html',
    styleUrls: ['./cupom.component.scss'],

    //animate slide from bottom
    animations: [
        trigger('slideUpAndDown', [
            transition(
                ':enter', [
                style({
                    transform: 'translateY(100%)'
                }),
                animate(200)]
            ),
            transition(':leave', [
                animate(200,
                    style({
                        transform: 'translateY(100%)'
                    })
                )
            ]),
        ]),
    ],
})


export class CupomComponent implements OnInit {

    @Output() open = new EventEmitter();
    @Output() couponData = new EventEmitter();
    @Input() roundedSearch: any = false;
    @Input() squaredSearch: any = false;
    @Input() lineSearch: any = false;
    @Input() dataHeader: any;
    @Input() resetTypeAcomodation: any

    isMobile: boolean = this.initializerService.isMobileDevice();
    sessionCoupon: any = this.documentService.getWindow()?.sessionStorage.getItem('coupon');
    coupon: string | null = (this.sessionCoupon) ? this.sessionCoupon : null;
    body: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.mat-drawer-content');
    couponContainer: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.form-field-coupon');
    expandedCoupon = false;
    selectedLocalAndDates: any;
    info: any;

    constructor(private initializerService: InitializerService,
                private documentService: DocumentService,
                private dataService: AppDataService) {
    }

    ngOnInit(): void {
        this.initializerService.currentControlFormSearch.subscribe((value: any) => {
            if (value) {
                if (value.type === 'coupon') {
                    this.expandCoupon();
                }
            }
         });

        this.initializerService.currentControlLocalSelected.subscribe(
            (value: any) => {
                if (value) {
                    this.selectedLocalAndDates = value;
                }
            }
        );

        this.initializerService.currentData.subscribe((data: any) => {
            this.info = data;
        });
    }

    expandCoupon(): void {
        if (this.isMobile && this.info.whatsapp.active) {
            const btnWhatsapp: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.btn-whats-app');
            if (btnWhatsapp) {
                btnWhatsapp.style.zIndex = -1;
            }

        }
        this.expandedCoupon = !this.expandedCoupon;
        const gallery: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.mat-tab-body.mat-tab-body-active');
        if (this.expandedCoupon) {
            this.dataService.showAskSuites(true);
            const element: HTMLElement | any = this.documentService.getWindow()?.document.getElementById('search');
            (element as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
            this.body.style = 'overflow: hidden';
            if (gallery) {
                gallery.style.zIndex = 0;
            }
        } else {
            this.dataService.showAskSuites(false);
            this.body.style = 'overflow: auto';
            this.couponContainer?.addClass('slide-out-top');
            if (gallery) {
                gallery.style.zIndex = 1;
            }

            if (this.isMobile && this.info.whatsapp.active) {
                const btnWhatsapp: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.btn-whats-app');
                if (btnWhatsapp) {
                    btnWhatsapp.style.zIndex = 3;
                }
            }
        }
        this.couponEvent();
    }

    handleSearch(): void {
        // this.open.emit(true);
        this.body.style = 'overflow: auto';
        this.couponEvent();
        this.expandedCoupon = false;

        this.initializerService.changeControlFormSearch({
            type: 'search',
            clear: true
        });
        if (this.isMobile && this.info.whatsapp.active) {
            const btnWhatsapp: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.btn-whats-app');
            if (btnWhatsapp) {
                btnWhatsapp.style.zIndex = 3;
            }
        }
    }

    couponEvent(): void {
        this.couponData.emit(this.coupon);
    }

    handleBackButton(): void {
        this.expandedCoupon = false;

        setTimeout(() => {
            this.initializerService.changeControlFormSearch({
                type: 'guests',
            });
        }, 100);
    }
}
