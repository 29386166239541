import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DocumentService } from './document.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    userData: any = this.documentService.getWindow()?.sessionStorage?.getItem('userData')
        ? JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '')
        : null;

    constructor(
        private documentService: DocumentService,
        private router: Router,
        private initializerService: InitializerService
    ) {
        this.initializerService.currentControlAuthGuard.subscribe(
            (data: any) => {
                if (data) {
                    this.userData = JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '');
                }
            }
        );
    }

    isAuthenticated(): Observable<boolean> | boolean {
        if (this.userData?.id) {
            return true;
        } else {
            this.router.navigateByUrl('/');
            return false;
        }
    }
}
