import {AccomodationDetailComponent} from './accomodation-detail/accomodation-detail.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AccommodationsComponent} from './accommodations.component';
import {ListAccommodationComponent} from './list/list.component';

const routes: Routes = [
    {
        path: 'acomodacao', component: ListAccommodationComponent
    },
    {path: 'acomodacao/:title/:id', component: AccomodationDetailComponent},
    {path: 'acomodacao/:title/:id/:hotelId', component: AccomodationDetailComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccommodationsRoutingModule {
}
