import {Component, Input, OnInit} from '@angular/core';
import {AppDataService} from '../../services/app-data.service';
import {environment} from '../../../environments/environment';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {FloatingBannerComponent} from '../floating-banner/floating-banner.component';
import { SwiperOptions } from 'swiper';
import {InitializerService} from '../../services/initializer.service';


@Component({
  selector: 'my-portal-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {

    @Input() moduleData: any;
    isSafari: any = this.initializerService.isSafari();
    banners: any;
    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;
    arrBanners : any;
    initializer = false;
    isMobile: boolean = this.initializerService.isMobileDevice();

    configBanner: SwiperOptions = {
        pagination: {
            el: '.pagination-accommodation',
            clickable: true,
        },
        autoplay: {
            delay: 3000,
        },
        spaceBetween: 8,
        slidesPerView: 3,
      };

  constructor(private initializerService: InitializerService,
              private appData: AppDataService,
              private sanitize: DomSanitizer,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

    setTimeout(() => {
        this.initializer = true;

        if(this.isMobile){
            this.configBanner = {
                slidesPerView: 1,
                spaceBetween: 8,
                pagination: {
                    el: '.pagination-accommodation',
                    clickable: true,
                },
                autoplay: {
                    delay: 3000,
                },
            };
        }

    }, 200);

      this.appData.getDataModuleBanner(this.moduleData.category, this.moduleData.max)
          .subscribe((response: any) => {
              if (this.moduleData.category !== 5 && this.moduleData.category !== 9) {
                  this.banners = response.data;
                  this.arrBanners = response.data;
                  this.initializer = true;
                  for (const banner of this.banners) {
                      if (banner.html) {
                        const scriptTag = banner.description.match(/<script[^>]*>([\s\S]*?)<\/script>/gi);
                        let scriptContent = '';

                        if (scriptTag) {
                            for (const script of scriptTag) {
                                const replacedTag = script.replace(/<script[^>]*>/gi, '').replace(/<\/script>/gi, '');
                                scriptContent += replacedTag + '\n';
                            }
                        }

                        const scriptDOM = document.createElement('script');
                        scriptDOM.innerHTML = scriptContent;
                        document.body.appendChild(scriptDOM);

                        banner.sanitize = this.sanitizeUrl(banner.description);
                      }
                  }
              } else {
                  if (response.data.length) {
                      this.dialog.open(FloatingBannerComponent, {
                          data: {
                              banner: response.data[0]
                          },
                          panelClass: 'floating-banner',
                          autoFocus: false
                      });
                  }
              }
          });
  }

  sanitizeUrl(url: string): SafeUrl {
      return this.sanitize.bypassSecurityTrustHtml(url);
  }

}
