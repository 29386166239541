import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { DocumentService } from './document.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class GuardService implements CanActivate {
    constructor(
        private documentService: DocumentService,
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (this.authService.isAuthenticated()) {
            return true;
        }
        this.router.navigateByUrl('/');
        return false;
    }
}
