<div class="cart-container">
    <div class="cart-header">
        <div class="header">
            <span>Diárias a partir de:</span>
            <span class="price">R$ 285,50</span>
            <span>Em até 10x sem juros.</span>
        </div>
    </div>

    <div class="cart-content">
        <div class="header">
            <h3>Gostou do quarto?</h3>
            <span>Faça já a sua reserva!</span>
        </div>

        <div class="input-area">
            <div class="input-calendar">
                <div>
                    <label for="">Check-in</label>
                    <input placeholder="26-10-2020" type="text">
                </div>
                <i class="material-icons client-theme-color notranslate">calendar_today</i>
            </div>
            <div class="input-calendar">
                <div>
                    <label for="">Check-out</label>
                    <input placeholder="29-10-2020" type="text">
                </div>
                <i class="material-icons client-theme-color notranslate">calendar_today</i>
            </div>

            <div class="input-calendar">
                <div>
                    <label for="">Quantidade de quartos</label>
                    <input placeholder="01" type="text">
                </div>
                <i class="material-icons client-theme-color notranslate">expand_more</i>
            </div>

            <div class="input-calendar-group">
                <div class="input-calendar group">
                    <div>
                        <label for="">Adultos</label>
                        <input placeholder="01" type="text">
                    </div>
                    <i class="material-icons client-theme-color notranslate">expand_more</i>
                </div>
                <div class="input-calendar group">
                    <div>
                        <label for="">Crianças</label>
                        <input placeholder="0" type="text">
                    </div>
                    <i class="material-icons client-theme-color notranslate">expand_more</i>
                </div>
            </div>

            <button>Adicionar ao carrinho</button>
        </div>
    </div>
</div>
