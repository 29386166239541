<div class="container-items">
    <swiper #swiperItem [config]="config" [initialize]="initialize">
        <div class="swiper-wrapper">
            <ng-template #itemCartTemplate *ngFor="let item of itemCart; let ind = index"
                         [ngTemplateOutlet]="itemCartTemplate">
                <div class="swiper-slide">
                    <div class="items">
                        <div class="box-item">
                            <div>
                                <h4 *ngIf="typeSearch >= 6">{{item.estabelecimento}}</h4>
                                <h6>{{item.quarto_nome}}</h6>
                                <p>{{item.tarifa_nome}}</p>
                            </div>
                        </div>
                        <div class="box-item">
                            <div class="item">
                                <figure>
                                    <img src="assets/images/ico-buscador-calendario.svg">
                                </figure>
                                <div class="date">
                                    <div class="day">{{ item.check_in | date: 'dd' }}</div>
                                    <div class="month">
                                        {{ item.check_in | date: 'EEE' }}<br>
                                        {{ item.check_in | date: 'MMM' }}
                                    </div>
                                </div>
                                <div class="date">
                                    <div class="day">{{ item.check_out | date: 'dd' }}</div>
                                    <div class="month">
                                        {{ item.check_out | date: 'EEE' }}<br>
                                        {{ item.check_out | date: 'MMM' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-item">
                            <div class="item">
                                <figure>
                                    <img src="assets/images/ico-buscador-hospedes.svg">
                                </figure>
                                {{ item.quantidade_adulto + ' Adulto(s)' }}<br>
                                {{ item.quantidade_crianca + ' Criança(s)' }}
                            </div>
                        </div>
                        <div class="box-item">
                            <div class="total">
                                <div class="label-item">Total</div>
                                <div>{{ item?.preco | currency }}</div>
                            </div>
                            <div (click)="removeItem(type, ind, item.pessoa_id)" class="remove">
                                <span translate="no" class="material-icons">delete</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next-cart">
            <mat-icon>arrow_forward_ios</mat-icon>
        </div>
        <div class="swiper-button-prev-cart">
            <mat-icon>arrow_back_ios_new</mat-icon>
        </div>
    </swiper>
</div>


