import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {InitializerService} from './../../services/initializer.service';
import {Component, OnInit, Input, Inject, AfterViewInit} from '@angular/core';
import {DocumentService} from '../../services/document.service';
import {AppDataService} from '../../services/app-data.service';
import {DefaultSnackBarComponent} from '../default-snack-bar/default-snack-bar.component';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogForgotPasswordComponent} from '../dialog-forgot-password/dialog-forgot-password.component';
import {Location} from '@angular/common';

@Component({
    selector: 'my-portal-triggers',
    templateUrl: './triggers.component.html',
    styleUrls: ['./triggers.component.scss'],
})
export class TriggersComponent implements OnInit, AfterViewInit {
    @Input() infoData: any;
    loginError: boolean = false;
    userLogged: boolean = false;

    pageAbout: any;

    checkinOut: any;
    data: any | null = this.documentService
        .getWindow()
        ?.sessionStorage.getItem('footer');
    checkinForm: FormGroup;
    loginForm: FormGroup;
    loading: any;
    loadingLogin: any;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    userData: any = this.documentService.getWindow()?.sessionStorage?.getItem('userData')
        ? JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '')
        : null;

    constructor(
        private initializerService: InitializerService,
        private documentService: DocumentService,
        private formBuilder: FormBuilder,
        private appData: AppDataService,
        private snackbar: MatSnackBar,
        public dialog: MatDialog,
        private location: Location
    ) {
        this.checkinForm = this.formBuilder.group({
            reserve: ['', Validators.required],
        });

        this.loginForm = this.formBuilder.group({
            mail: ['', Validators.required],
            password: ['', Validators.required],
        });

        this.pageAbout = this.location.path() == '/sobre' ? true : false
    }

    ngOnInit(): void {
        this.initializerService.currentData
            .subscribe((data: any) => {
                if (data) {
                    if (data.checkin && data.checkout) {
                        this.checkinOut = {
                            checkin: data.checkin,
                            checkout: data.checkout,
                        };
                    }
                }
            })
            .unsubscribe();

        this.initializerService.currentFooterData
            .subscribe((data: any) => {
                if (data) {
                    this.data = data;

                    if (this.data.whatsapp) {
                        const whatsNewNumber = this.data.whatsapp.number
                            .replace(/\D+/g, '')
                            .replace(/^0+/, '');
                        const whatsVerification = whatsNewNumber.substring(0, 3).includes('55');

                        this.data.whatsapp.number = whatsNewNumber;

                        if (!whatsVerification && this.data.whatsapp.number.length <= 11) {
                            const newWhats = `55${this.data.whatsapp.number}`;
                            this.data.whatsapp.number = newWhats;
                        }
                    }
                }
            });

        this.userData?.id ? this.userLogged = true : this.userLogged = false;
    }

    onCheckinSubmit(form: any): void {
        this.loading = true;

        this.appData
            .postWebCheckinPass(form.value.reserve, this.infoData.id)
            .subscribe(
                (reserve: any) => {
                    this.loading = false;
                    this.checkinForm.reset();
                    this.openSnackBar('E-mail enviado com sucesso!', false);
                },
                (exception: any) => {
                    this.loading = false;
                    this.checkinForm.reset();
                    this.openSnackBar(exception.error.errors.message, true);
                }
            );
    }

    onLoginSubmit(formLogin: any): void {
        this.loadingLogin = true;
        const {mail, password} = formLogin.value;

        if (formLogin.valid) {
            this.appData
                .postRestrictedArea('client', mail, password)
                .subscribe(
                    (response: any) => {
                        this.loginError = false;
                        this.loadingLogin = false;
                        if (response.data.user) {
                            this.documentService
                                .getWindow()
                                ?.sessionStorage?.setItem(
                                'userData',
                                JSON.stringify(response.data.user)
                            );

                            this.openSnackBar('Logado com sucesso!');
                            this.updateUser(true);
                            location.reload();
                        }
                    },
                    (error) => {
                        this.loadingLogin = false;
                        this.loginError = true;
                    }
                );
        }
    }

    updateUser(isLogged: boolean): void {
        this.initializerService.changeControlAuth(isLogged);
    }

    handleForgotPassword(): void {
        const dialogRef = this.dialog.open(DialogForgotPasswordComponent, {
            width: '500px',
            data: {
                loginType: 'client',
            },
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    openSnackBar(msg: string, flag?: boolean | undefined): void {
        this.snackbar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg,
            },
            horizontalPosition: 'end',
            verticalPosition: this.getScreenWidth ? 'top' : 'bottom',
            panelClass: flag ? 'error-snack-bar' : 'success-snack-bar',
            duration: 5000,
        });
    }


    ngAfterViewInit(): void {
        this.initializerService.currentFooterData
            .subscribe((data: any) => {
                if (data) {
                    this.data = data;
                }
            }).unsubscribe();
    }
}
