import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {AppDataService} from '../../services/app-data.service';
import {DocumentService} from '../../services/document.service';
import { InitializerService } from '../../services/initializer.service';

@Component({
    selector: 'my-portal-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

    @Input() personId: any;
    @Input() moduleData: any;
    @Input() limitImages: any = false;
    gallerys: any;
    typeSearch: any = Number(this.documentService.getWindow()?.sessionStorage?.getItem('typeSearch'));
    portalGallery = false;

    constructor(private appData: AppDataService, 
                private documentService: DocumentService,
                private initializerService: InitializerService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.initializerService.currentData.subscribe((response: any) => {
            const {only_motor, only_site} = response;

            if (only_motor && !only_site) {
                this.router.navigateByUrl('/');
            }
        }).unsubscribe();


        if (!this.moduleData) {
            this.moduleData = {
                title: 'Galeria de fotos'
            };
        }
        this.portalGallery = (this.typeSearch >= 6 && !this.personId);

        this.appData.getDataGallery(null, this.personId, 1).subscribe((gallerys: any) => {
            this.gallerys = gallerys.data;
        });
    }
}
