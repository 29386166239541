import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {InitializerService} from '../../services/initializer.service';
import {AppDataService} from '../../services/app-data.service';
import {response} from 'express';

@Component({
    selector: 'my-portal-recaptcha',
    templateUrl: './recaptcha.component.html',
    styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {

    recaptcha: any = null;
    secretKey: any = null;
    @Output() recaptchaEvent = new EventEmitter();

    constructor(private initializerService: InitializerService,
                private appService: AppDataService) {
    }

    ngOnInit(): void {
        this.initializerService.currentData
            .subscribe((data: any) => {
                if (data) {
                    if (data.recaptcha?.secret_key && data.recaptcha?.site_key) {
                        this.secretKey = data.recaptcha.secret_key;
                        this.recaptcha = data.recaptcha.site_key;
                    } else {
                        this.recaptchaEvent.emit(true);
                    }
                }
            })
            .unsubscribe();
    }

    resolved(captchaResponse: string): void {
        if (captchaResponse) {
            this.appService.recaptchaValidador(this.secretKey, captchaResponse)
                .subscribe(() => {
                    this.recaptchaEvent.emit(true);
                }, error => {
                    this.recaptchaEvent.emit(false);
                });
        } else {
            this.recaptchaEvent.emit(false);
        }
    }

    errored(event: any): void {
        console.warn(`reCAPTCHA error encountered: ${event}`);
    }

}
