<!-- ROW -->
<div class="mt-5 mb-5" *ngIf="tickets?.length">
    <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
    <div>
        <div class="row tickets">
            <div *ngFor="let ticket of tickets; let i = index" class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
              <div class="border ticket-item">
                    <div class="p-0 image-container">
                        <figure>
                            <img src="{{imgServer + 'width=' + 350 + imgServerUrlEnd + ticket.images[0]?.thumb}}" width="350" class="img-fluid" alt="Imagem ingresso">
                        </figure>

                        {{ticket.CategoryTicketItems[0]}}
                        <div class="flag">
                            <span class="d-block text-center day">{{ticket.first_date | date: 'dd'}}</span>
                            <span class="d-block text-center month">{{ticket.first_date | date: 'MMM': 'pt'}}</span>
                            <span class="d-block text-center year">{{ticket.first_date | date: 'YYYY'}}</span>
                        </div>
                    </div>
                    <div class="p-3 info-container">
                        <div class="title">
                            <span class="d-block">{{ticket.name}}</span>
                            <p>{{ticket.category_ticket.name}}</p>
                        </div>
                        <div class="availability">
                            <div class="d-flex align-items-center hour">
                                <div class="d-flex align-items-center icon clock-icon">
                                    <figure>
                                        <svg stroke="currentColor" id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke:#000;stroke-miterlimit:10;}</style></defs><title>Icones</title><circle class="cls-1" cx="12" cy="12" r="11"/><polyline class="cls-1" points="17.8 12.6 11.4 12.6 11.4 4.5"/></svg>
                                    </figure>
                                    <span class="horary">{{ticket.horary}}</span>
                                </div>
                                <div class="d-flex align-items-center ml-2 icon">
                                    <figure>
                                        <svg stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#fff;}</style></defs><title>Ativo 13</title><g id="Camada_2" data-name="Camada 2"><g id="Camada_1-2" data-name="Camada 1"><path class="cls-1" d="M8,0A8,8,0,0,0,0,8c0,5,5.1,10.6,7.4,12.7A.7.7,0,0,0,8,21a.9.9,0,0,0,.7-.3C10.9,18.6,16,13,16,8A8,8,0,0,0,8,0Zm.1,20.1H7.9C6.3,18.5.8,12.8.8,8A7.3,7.3,0,0,1,8,.8,7.2,7.2,0,0,1,15.2,8C15.2,12.7,10.2,18,8.1,20.1Z"/><path class="cls-1" d="M8,4.2A3.8,3.8,0,1,0,11.8,8,3.8,3.8,0,0,0,8,4.2ZM8,11A3,3,0,0,1,5.1,8,2.9,2.9,0,0,1,8,5.1,3,3,0,0,1,11,8,3.1,3.1,0,0,1,8,11Z"/></g></g></svg>
                                    </figure>
                                    <span class="place">{{ticket.Place.city.name}}, {{ticket.Place.city.state.name}}</span>
                                </div>
                            </div>
                            <div class="mt-3 date">
                                <div class="d-flex align-items-center icon calendar-icon">
                                    <figure>
                                        <svg stroke="currentColor" id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke-linecap:round;}.cls-1,.cls-2{stroke:#000;stroke-miterlimit:10;}.cls-2{fill:#fff;}</style></defs><title>Icones</title><line class="cls-1" x1="0.5" y1="8.6" x2="23" y2="8.6"/><rect class="cls-1" x="16.4" y="1" width="2.5" height="4.04" rx="1"/><rect class="cls-1" x="5.3" y="1" width="2.5" height="4.04" rx="1"/><path class="cls-1" d="M18.9,3h2.8a2,2,0,0,1,2,2V21.2a2,2,0,0,1-2,2H2.5a2,2,0,0,1-2-2V14.1"/><line class="cls-1" x1="7.8" y1="3" x2="16.4" y2="3"/><path class="cls-1" d="M.5,15.5V5a2,2,0,0,1,2-2H5.3"/><circle class="cls-2" cx="5.7" cy="13.2" r="0.8"/><circle class="cls-2" cx="12.2" cy="13.2" r="0.8"/><circle class="cls-2" cx="18.7" cy="13.2" r="0.8"/><circle class="cls-2" cx="5.7" cy="18.2" r="0.8"/><circle class="cls-2" cx="12.2" cy="18.2" r="0.8"/><circle class="cls-2" cx="18.7" cy="18.2" r="0.8"/></svg>
                                    </figure>
                                    <span class="day" *ngFor="let day of ['sex', 'sab', 'dom']">{{day}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-direction-column justify-content-between mt-3 actions">
                            <div class="price">
                                <p>A partir de:</p>
                                <span>{{ticket.lower_price | currency:'BRL': 'R$ '}}</span>
                            </div>
                            <div class="d-flex align-items-center button">
                                <a [href]="dataOtas.url + '/ingresso/' + ticket.id + '/' + ticket.slug" target="_blank">
                                    <button>reservar</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- COLUMN -->
<!--        <div class="mt-3 tickets-column">-->
<!--            <div *ngFor="let ticket of tickets" class="d-flex border ticket-item mt-3">-->
<!--                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0 pb-0 pl-0 image-container">-->
<!--                    <figure>-->
<!--                        <img src="{{ImgSrc + ticket.images[0].thumb}}" class="img-fluid" alt="">-->
<!--                    </figure>-->

<!--                    <div class="flag client-theme-background">-->
<!--                        <span class="d-block text-center day">{{ticket.first_date | date: 'dd'}}</span>-->
<!--                        <span class="d-block text-center month">{{ticket.first_date | date: 'MMM': 'pt_BR'}}</span>-->
<!--                        <span class="d-block text-center year">{{ticket.first_date | date: 'YYYY'}}</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="p-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center info-container">-->
<!--                    <div class="title">-->
<!--                        <span class="d-block">{{ticket.name}}</span>-->
<!--                    </div>-->

<!--                    <div class="availability">-->
<!--                        <div class="d-flex align-items-center hour">-->
<!--                            <div class="d-flex align-items-center">-->
<!--                                <i class="material-icons-outlined mr-1 notranslate">schedule</i>-->
<!--                                {{ticket.horary}}-->
<!--                            </div>-->
<!--                            <div class="d-flex align-items-center ml-2">-->
<!--                                <i class="material-icons-outlined mr-1 notranslate">location_on</i>-->
<!--                                {{ticket.Place.city.name}}, {{ticket.Place.city.state.name}}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="date">-->
<!--                            <div class="d-flex align-items-center">-->
<!--                                <i class="material-icons-outlined mr-1 notranslate">date_range</i>-->
<!--                                <span class="day" *ngFor="let day of ['sex', 'sab', 'dom']">{{day}}</span>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="description">-->
<!--                            <p class="mt-2 mb-0" [innerHTML]="ticket.description"></p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mt-3 mb-3 actions">-->
<!--                    <div class="d-flex flex-column align-items-center price-container">-->
<!--                        <span class="provider">Fornecedor</span>-->
<!--                        <span class="provider-name">Veromundo</span>-->
<!--                        <p>A partir de:</p>-->
<!--                        <span class="client-theme-color price">{{ticket.lower_price | currency:'BRL': 'R$ '}}</span>-->
<!--                    </div>-->
<!--                    <div class="d-flex align-items-center button">-->
<!--                        <button class="client-theme-border client-theme-color">mais detalhes</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</div>
