<div mat-dialog-content>
    
    <div class="dialog-content">
        <p>
            <strong>{{dataReserve.cancel_policy.name}}</strong>
        </p>
        <p [innerHtml]="dataReserve.cancel_policy.description"></p>
    </div>

    <div mat-dialog-actions class="actions-container">  
        <button mat-button (click)="onNoClick()">fechar</button>
        <button mat-button class="close"cdkFocusInitial (click)="handleCancellation()">cancelar reserva</button>
    </div>
</div>
  