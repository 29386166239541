<div class="config" [ngClass]="{'two-items': badrooms?.length === 0}">
<!--    <div class="item">-->
<!--        <figure>-->
<!--            <img class="svg-icon" src="assets/images/ico-buscador-hospedes.svg" alt="">-->
<!--        </figure>-->
<!--        {{ max }} max de hóspedes-->
<!--    </div>-->
    <div *ngIf="bedroomArea" class="item">
        <figure>
            <img class="svg-icon" src="assets/images/ico-tarifario-dimensao.svg" alt="">
        </figure>
        {{ bedroomArea }}m²
    </div>

<!--    <ng-template #listBad [ngTemplateOutlet]="listBad" *ngFor="let bad of badrooms">-->
<!--        <div class="item" *ngIf="bad.pax <= config.adult">-->
<!--            <figure>-->
<!--                <img class="svg-icon" [src]="bad.single_beds ? 'assets/images/ico-tarifario-cama-solteiro.svg' : 'assets/images/ico-tarifario-cama-casal.svg'" alt="">-->
<!--            </figure>-->
<!--            <span *ngIf="bad.single_beds > 0">{{bad.single_beds}} cama(s) Solteiro</span>-->
<!--            <span *ngIf="bad.couple_beds > 0">{{bad.couple_beds}} cama(s) Casal</span>-->
<!--        </div>-->
<!--    </ng-template>-->
</div>
