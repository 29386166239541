import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { InitializerService } from '../../../services/initializer.service';
import { AppDataService } from '../../../services/app-data.service';
import { DocumentService } from '../../../services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperComponent } from 'ngx-useful-swiper';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'my-portal-destinations-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
})
export class DetailDestinationsComponent implements OnInit {
    // @Input() accommodations: any;
    // @Input() initialize: any;
    safeMap: any;
    tours: any;
    loading: boolean = false;
    @Input() moduleData: any;
    @Input() load: any;
    dataFooter: any | null = this.documentService
        .getWindow()
        ?.sessionStorage.getItem('footer');
    portal: boolean | null = null;
    data: any;
    footerData: any;
    urlMap: any = null;

    initializer = true;
    initializer2 = true;

    @ViewChild('galleryTop', { static: false }) galleryTop?:
        | SwiperComponent
        | any;
    @ViewChild('galleryThumbs', { static: false }) galleryThumbs?:
        | SwiperComponent
        | any;

    destinations: any;

    config: SwiperOptions = {
        pagination: {
            el: '.pagination-accommodation',
            clickable: true,
        },
        spaceBetween: 30,
        slidesPerView: 3,
        autoHeight: true,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: true,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 30,
                centeredSlides: true,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    };

    configInterna: SwiperOptions = {
        pagination: {
            el: '.pagination-accommodation',
            clickable: true,
        },
        spaceBetween: 30,
        slidesPerView: 3,
        autoHeight: true,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: true,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 30,
                centeredSlides: true,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    };

    public configThumbs: SwiperOptions = {
        direction: 'vertical',
        slidesPerView: 5,
        slideToClickedSlide: false,
        spaceBetween: 8,
        autoHeight: false,
        grabCursor: false,
        preloadImages: true,
        slideActiveClass: 'activeSlide',
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 5,
                spaceBetween: 8,
                direction: 'horizontal',
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 5,
                spaceBetween: 8,
                direction: 'horizontal',
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 5,
                spaceBetween: 8,
                direction: 'vertical',
            },
        },
        on: {
            slideChange: () => {
                this.galleryTop.swiper.slideTo(
                    this.galleryThumbs.swiper.clickedIndex
                );
                this.galleryThumbs.swiper.activeIndexChange =
                    this.galleryThumbs.swiper.clickedIndex;
                this.galleryThumbs.swiper.slides[
                    this.galleryThumbs.swiper.activeIndex
                ].classList.remove('activeSlide');
                this.galleryThumbs.swiper.slides[
                    this.galleryThumbs.swiper.clickedIndex
                ].classList.add('activeSlide');
            },
            click: () => {
                setTimeout(() => {
                    this.galleryTop.swiper.slideTo(
                        this.galleryThumbs.swiper.clickedIndex
                    );
                    this.galleryThumbs.swiper.slideTo(
                        this.galleryThumbs.swiper.clickedIndex
                    );
                    this.galleryThumbs.swiper.activeIndexChange =
                        this.galleryThumbs.swiper.clickedIndex;
                    this.galleryThumbs.swiper.slides[
                        this.galleryThumbs.swiper.activeIndex
                    ].classList.remove('activeSlide');
                    this.galleryThumbs.swiper.slides[
                        this.galleryThumbs.swiper.clickedIndex
                    ].classList.add('activeSlide');
                }, 250);
            },
        },
    };

    public configSlide: SwiperOptions = {
        autoplay: true,
        effect: 'fade',
        pagination: { el: '.images-pagination', clickable: true },
        autoHeight: false,
        allowTouchMove: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next-cart',
            prevEl: '.swiper-button-prev-cart',
        },
        on: {
            slideChange: () => {
                this.galleryThumbs.swiper.slideTo(
                    this.galleryTop.swiper.activeIndex
                );
                this.galleryThumbs.swiper.activeIndexChange =
                    this.galleryTop.swiper.activeIndex;
                this.galleryThumbs.swiper.slides[
                    this.galleryThumbs.swiper.activeIndex
                ].classList.remove('activeSlide');
                this.galleryThumbs.swiper.slides[
                    this.galleryTop.swiper.activeIndex
                ].classList.add('activeSlide');
            },
        },
    };

    constructor(
        private documentService: DocumentService,
        private appData: AppDataService,
        private sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute

    ) {}

    ngOnInit(): void {
        const idRouter = this.activatedRoute.snapshot.params.id;

        this.appData.getDestinations(idRouter).subscribe((response: any) => {
            if (response.data.length !== 0) {
                this.destinations = response.data;
                this.safeMap = this.sanitizeMap(response.data.map);
                this.loading = true;
            }
            else{
                this.loading = false;
            }
        });

    }

    sanitizeMap(url: any): any[] {
        const safeUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return safeUrl;
    }
}
