import {Component, Input, OnInit} from '@angular/core';
import {AppDataService} from '../../services/app-data.service';
import {environment} from '../../../environments/environment';
import {InitializerService} from '../../services/initializer.service';

@Component({
    selector: 'my-portal-tours',
    templateUrl: './tours.component.html',
    styleUrls: ['./tours.component.scss']
})
export class ToursComponent implements OnInit {

    @Input() moduleData: any;
    tours: any;
    ImgSrc = environment.IMAGES_ENDPOINT;
    dataOtas: any = null;

    constructor(private appData: AppDataService,
                private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.initializerService.currentData
            .subscribe((data: any) => {
                if (data) {
                    if (data.otas.id && data.otas.pessoa_id) {
                        const obj = {
                            ambience_sale_id: data.otas.id,
                            person_id: data.otas.pessoa_id,
                            featured: 'Y'
                        };
                        this.dataOtas = data.otas;
                        this.appData.getDataTours(obj).subscribe((response: any) => {
                            this.tours = Object.values(response.tours).slice(0, 8);
                            for (const tour of this.tours) {
                                tour.slug = this.appData.createSlugUrl(tour.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                            }
                        });
                    }
                }
            })
            .unsubscribe();
    }

}
