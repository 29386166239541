import {Component, OnInit} from '@angular/core';
import {CartService} from '../../services/cart.service';
import {InitializerService} from '../../services/initializer.service';

@Component({
    selector: 'my-portal-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

    items: any = this.cartService.getItems();
    showCart = false;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    arrItem: any[] = [
        { key: 'hotels', title: 'Hospedagem', establishments: Object.keys(this.items.hotels) },
    ];

    constructor(private cartService: CartService, private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.arrItem[0].establishments = Object.keys(this.items.hotels);
        if (this.items.count) {
            setTimeout(() => {
                // this.showCart = true;
            }, 100);
        }
        // this.showCart = false;
        // this.cartService.currentDataCartItems.subscribe(data => {
        //     console.log(data);
        //     this.items = data;
        // });
    }

    updateCart($event: any): void {
        this.items = this.cartService.getItems();
    }

}
