import { Component, Input, OnInit, Output } from '@angular/core';
import {DialogMessageCancelationComponent} from '../cancelation/message/message.component';
import {MatDialog} from '@angular/material/dialog';
import {AppDataService} from '../../services/app-data.service';
import * as moment from 'moment';
import { ModalInfoRoomComponent } from './modal-info-room/modal-info-room.component';
import { InitializerService } from './../../services/initializer.service';
import { NONE_TYPE } from '@angular/compiler';

@Component({
    selector: 'my-portal-room',
    templateUrl: './room.component.html',
    styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {

    @Input() room: any;
    @Input() load: any;
    @Input() type: any = false;
    @Input() showPriceDetails: boolean = false;
    @Input() config: any;
    @Input() ind: any;
    objHiddenPopover: any = false;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    typeHotel : any ;

    @Output() openDatePickerRoom: boolean = false;

    dialogOpen: boolean = false;
    showEntryPercentagePopover: boolean = true;

    constructor(public dialog: MatDialog,
        private initializerService: InitializerService) {
    }

    ngOnInit(): void {
        this.initializerService.currentData.subscribe((response : any ) => {
            this.typeHotel = response.type
        })
    }

    openCalendar(close?: boolean): void {
        this.initializerService.changeControlFormSearch({
            type:'local'
        })
        this.openDatePickerRoom = true;
    }

    openMessage(description: string): void {
        this.dialog.open(DialogMessageCancelationComponent, {
            data: {
                message: description
            },
            autoFocus: false
        });
    }

    openDialog() {
        const chatBot = document.querySelector('.infochat_floatmain');
        chatBot?.classList.add('zIndexZero');

        const dialogRef = this.dialog.open(ModalInfoRoomComponent, {
            data: {
                room: this.room
            },
            maxWidth: (this.getScreenWidth) ? '100vw' : 'auto',
            maxHeight: (this.getScreenWidth) ? '100vh' : 'auto',
            height: (this.getScreenWidth) ? '100%' : 'auto',
            width: (this.getScreenWidth) ? '100%' : '500px',
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {

        });
      }
}
