import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppDataService } from '../../services/app-data.service';
import { DocumentService } from '../../services/document.service';
import { environment } from './../../../environments/environment';
import { InitializerService } from '../../services/initializer.service';

@Component({
    selector: 'my-portal-galery-modal',
    templateUrl: './galery-modal.component.html',
    styleUrls: ['./galery-modal.component.scss'],
})
export class GaleryModalComponent implements OnInit {
    @Input() galleryItem: any;
    @Input() limitImages: any;
    slideIndex = 0;
    isSafari: any = this.initializerService.isSafari();
    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;
    typeSearch: number = Number(this.documentService.getWindow()?.sessionStorage.getItem('typeSearch'));

    constructor(
        private documentService: DocumentService,
        private router: Router,
        private appService: AppDataService,
        private initializerService: InitializerService
    ) { }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
    }

    openModal(): void {
        if (
            this.documentService
                .getWindow()
                ?.document.querySelector('header.fixed')
        ) {
            (
                this.documentService
                    .getWindow()
                    ?.document.querySelector('header.fixed') as HTMLElement
            ).style.zIndex = '1';
        }
        (<any>(
            this.documentService
                .getWindow()
                ?.document.getElementById('imgModal')
        )).style.display = 'block';
    }

    closeModal(): void {
        (<any>(
            this.documentService
                .getWindow()
                ?.document.getElementById('imgModal')
        )).style.display = 'none';
        if (
            this.documentService
                .getWindow()
                ?.document.querySelector('header.fixed')
        ) {
            (
                this.documentService
                    .getWindow()
                    ?.document.querySelector('header.fixed') as HTMLElement
            ).style.zIndex = '2';
        }
    }

    plusSlides(n: number): void {
        this.showSlides((this.slideIndex += n));
    }

    currentSlide(n: number): void {
        this.showSlides((this.slideIndex = n));
    }

    showSlides(slideIndex: any): any;

    showSlides(n: number): void {
        let i;
        const slides = this.documentService
            .getWindow()
            ?.document.getElementsByClassName(
                'img-slides'
            ) as HTMLCollectionOf<HTMLElement>;
        const dots = this.documentService
            .getWindow()
            ?.document.getElementsByClassName(
                'images'
            ) as HTMLCollectionOf<HTMLElement>;
        if (n > slides.length) {
            this.slideIndex = 1;
        }
        if (n < 1) {
            this.slideIndex = slides.length;
        }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(' active', '');
        }
        slides[this.slideIndex - 1].style.display = 'block';
        if (dots && dots.length > 0) {
            dots[this.slideIndex - 1].className += ' active';
        }
    }

    onClickViewMore(galleryId: any, galleryName: any): void {

        this.documentService.getWindow()?.sessionStorage.setItem(
            'galleryData',
            JSON.stringify({
                galleryId,
                galleryName,
            })
        );

        const gallerySlug = this.appService.createSlugUrl(
            galleryName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        );
        this.router.navigate(['/foto', gallerySlug, galleryId]);

    }

    onClickViewMorePortal() {
        this.router.navigate(['/galerias']);
    }
}
