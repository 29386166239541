<div *ngFor="let footer of footerData" class="hotels">
    <div class="row no-gutters">
        <div class="col mb-2 mt-3">
            <h6>{{ footer.name | uppercase }}</h6>
            <p *ngIf="footer?.doc.type == 'cnpj' && footer.doc.number">CNPJ: {{ footer.doc.number }}</p>
            <span *ngIf="!data?.hide_stars">
                <i *ngFor="let star of [0,1,2,3,4]; let i = index" [ngClass]="{'star': (i+1) <= footer.star}"
               class="material-icons notranslate">star</i>
            </span>
        </div>
    </div>

    <div class="row no-gutters mb-4 container-footer ">
        <div class="col-sm" *ngIf="footer?.phone?.length || footer?.whatsapp">
            <div class="container-footer whatsapp">
                <div class="icon-footer" *ngIf="footer?.phone?.length || footer?.whatsapp?.number?.length">
                    <i class="material-icons notranslate">phone</i>
                </div>
                <div>
                    <div class="box">
                        <a *ngFor="let phone of footer.phone" href="tel:{{phone.number}}">
                            <p>
                                <span *ngIf="phone.type === 'landlinePhone'">
                                    Telefone: {{ phone.number | mask: '(00) 0000-0000'}}
                                </span>
                                <span *ngIf="phone.type === 'mobilePhone' ">
                                    Telefone: {{ phone.number | mask: '(00) 0 0000-0000'}}
                                </span>
                                <span *ngIf="phone.type === 'tollFree'">
                                    Telefone: {{ phone.number | mask: '0000 000 0000'}}
                                </span>
                            </p>
                        </a>
                        <p *ngIf="footer.whatsapp">
                            <a href="https://api.whatsapp.com/send?phone={{footerData[0].whatsapp.number}}{{footerData[0].whatsapp.message ? '&text='+footerData[0].whatsapp.message : ''}}" target="_blank">
                                <span *ngIf="footer?.whatsapp.number.length >=13 "> Whatsapp: {{ footer.whatsapp.number | mask: '+00 (00) 0 0000-0000' }}</span>
                                <span *ngIf="footer?.whatsapp.number.length < 13 && footer?.whatsapp.number.length > 5"> Whatsapp: {{ footer.whatsapp.number | mask: '+00 (00) 0000-0000' }}</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm" *ngIf="footer?.email">
            <div class="container-footer">
                <div class="icon-footer">
                    <i class="material-icons notranslate">mail_outline</i>
                </div>
                <div class="box">
                    <p>
                        <a href="mailto:{{ footer.email }}" target="_blank">
                            {{ footer.email }}
                        </a>
                    </p>
                </div>
            </div>
        </div>

        <div class="col-sm" *ngIf="!data?.hide_address">
            <div class="container-footer">
                <div class="icon-footer">
                    <i class="material-icons notranslate">location_on</i>
                </div>
                <div class="box">
                    <a [href]="footer.location_map" target="_black">
                        <p>
                            {{ footer.address }}, {{ footer?.district ? footer?.district + ' | ' : '' }}
                            {{ footer.city }} | {{ footer.state }} | {{ footer.country }} {{ footer.cep ? '| CEP: ' + footer.cep : '' }}
                        </p>
                    </a>
                </div>
            </div>
        </div>

        <div class="col-sm">
            <div class="container-footer">
                <div class="social-networking">
                    <a *ngFor="let social of footer.social_network" [href]="social.url" target="_blank">
                        <button [className]="social.name | lowercase" [attr.aria-label]="social.name">
                            <i class="{{'icon-' + social.name | lowercase }} notranslate"></i>
                        </button>
                    </a>
                </div>
            </div>
        </div>

        <div #htmlContainer [innerHTML]="html"></div>
    </div>

    <ng-template #listScriptFooter [ngTemplateOutlet]="listScriptFooter" *ngFor="let script of arrScripts">
        <div class="row" *ngIf="footer.footer_scripts[script]">
            <div class="col" [innerHTML]="footer.footer_scripts[script]"></div>
        </div>
    </ng-template>

</div>
