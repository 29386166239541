import { Component, Input, OnInit } from '@angular/core';
import { InitializerService } from '../../services/initializer.service';
import { environment } from './../../../environments/environment';

@Component({
    selector: 'my-portal-banner-search',
    templateUrl: './banner-search.component.html',
    styleUrls: ['./banner-search.component.scss'],
})
export class BannerSearchComponent implements OnInit {
    @Input() bannerSearch: any;
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private initializerService: InitializerService) {}

    ngOnInit() {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
    }
}
