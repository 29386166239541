import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'my-portal-popover',
    template: `
        <div class="my-popover" [ngClass]="{'restriction': type === 'restriction', 'finish': finish, 'room-popover': type === 'room'}">
            <p *ngFor="let item of content">{{item}}</p>
            <p *ngIf="messages" [innerText]="messages"></p>
            <ng-content></ng-content>
        </div>`,
    styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {

    @Input() type?: any;
    @Input() messages?: any;
    @Input() content?: any;
    @Input() finish: any;

    constructor() {
    }

    ngOnInit(): void {
    }
}
