
<div class="row">
    <div *ngFor="let new of objNews?.first"
         class="col-sm-6 col-md-4 col-lg-4 col-xl-4 news-header mb-3"
        [ngClass]="{ 'col-6' : this.moduleData, 'col-12': !this.moduleData}">
        <a [routerLink]="['/conteudo', new?.slug, new.id]">
            <figure>
                <img class="img-fluid" [src]="imgServer + 'width=' + 350 + imgServerUrlEnd + new.image" width="350" height="auto" alt="imagem">
            </figure>

            <span class="d-block mt-2 mb-2">{{new.title}}</span>
        </a>
    </div>
</div>

<div class="row mt-3" *ngIf="showAllNews">
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 news-footer mt-2" *ngFor="let new of objNews?.all">
        <a [routerLink]="['/conteudo', new?.slug, new.id]">
            <div class="row">
                <figure class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <img class="img-fluid" [src]="imgServer + 'width=' + 160 + imgServerUrlEnd + new.image" width="160" height="auto" alt="imagem">
                </figure>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-3 pr-3 pt-3 description">
                    <p>{{new.title}}</p>
                </div>
            </div>
        </a>
    </div>
</div>

<my-portal-show-all *ngIf="moduleData" [route]="routerLinkNews"></my-portal-show-all>


