import { NgModule } from '@angular/core';

import {SharedModule} from '../../shared/shared.module';
import { PackagesModule } from '../packages/packages.module';

import { HotelsRoutingModule } from './hotels-routing.module';
import { HotelsComponent } from './hotels.component';
import {DetailHotelComponent} from './detail/detail.component';
import {AboutModule} from '../about/about.module';
import {AccommodationsModule} from '../accommodations/accommodations.module';
import {GalleryModule} from '../gallery/gallery.module';
import {TestimoniesModule} from '../testimonies/testimonies.module';
import { DialogDescriptionComponent } from './dialog-description/dialog-description.component';
import { MatDialogModule } from '@angular/material/dialog';
import {GenericPagesModule} from '../generic-pages/generic-pages.module';


@NgModule({
  declarations: [HotelsComponent, DetailHotelComponent, DialogDescriptionComponent],
    imports: [
        SharedModule,
        HotelsRoutingModule,
        PackagesModule,
        AboutModule,
        AccommodationsModule,
        GalleryModule,
        TestimoniesModule,
        MatDialogModule,
        GenericPagesModule
    ],
  exports: [HotelsComponent, DetailHotelComponent]
})
export class HotelsModule { }
