<section class="mt-5 mb-5">

    <div *ngIf="!load && !dataHotels" class="loading-container">
        <div class="spinner-border client-theme-color" role="status">
            <span class="sr-only">Carregando...</span>
        </div>
    </div>
    
    <div *ngIf="loading">
        <my-portal-highlighter
            [module]="{title: 'Canais de atendimento', description: 'Você pode entrar em contato conosco de várias formas'}"></my-portal-highlighter>
            <mat-tab-group disableRipple mat-stretch-tabsBo (selectedIndexChange)="tabSelecionada($event)">
                <ng-template #contactHotels [ngTemplateOutlet]="contactHotels" *ngFor="let item of dataHotels">
                    <mat-tab [label]="item?.name | uppercase">
                        <div class="row no-gutters contact mt-4">
                            <div class="col-sm-12 col-md">
                                <!-- <div class="contact-card">
                                    <div class="icon-container" >
                                    <figure>
                                        <svg [attr.fill]="clientColor" data-name="Camada 1" id="Camada_1"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>ico-telefone</title>
                                            <path
                                                d="M20,22h0c-1.8,0-6.6-.6-11.4-5.4S3.3,7.1,3.2,5.2A1.2,1.2,0,0,1,4,4.1l3.3-1a1.1,1.1,0,0,1,1.4.5l1.7,3.6a.9.9,0,0,1-.2,1.3L8.5,10.3A10.9,10.9,0,0,0,11.2,14,13.8,13.8,0,0,0,15,16.8L16.7,15a1.2,1.2,0,0,1,1.3-.2l3.6,1.8a1.1,1.1,0,0,1,.6,1.4l-1.1,3.2A1.2,1.2,0,0,1,20,22ZM7.6,3.8H7.5L4.3,4.9a.3.3,0,0,0-.3.3c0,1.8.6,6.3,5.2,10.9s9.1,5.1,10.8,5.1l.4-.2,1.1-3.3-.2-.4-3.6-1.8h-.5l-1.9,2h-.4a15.6,15.6,0,0,1-4.2-3,11.2,11.2,0,0,1-3-4.3c-.1-.1,0-.3.1-.4L9.7,8a.6.6,0,0,0,.1-.4L8,4C7.9,3.8,7.8,3.8,7.6,3.8Z"/>
                                        </svg>
                                    </figure>
                                </div>
                                    <div class="info" *ngIf="item?.phone">
                                    <span class="title">Central de vendas</span>
                                    <a target="_blank" *ngFor="let phone of item.phone" href="tel:{{phone.number}}">
                                        <p class="text" >
                                            <span *ngIf="phone.type === 'landlinePhone'">
                                                {{ phone.number | mask: '(00) 0000-0000'}}
                                            </span>
                                            <span *ngIf="phone.type === 'mobilePhone' ">
                                                {{ phone.number | mask: '(00) 0 0000-0000'}}
                                            </span>
                                            <span *ngIf="phone.type === 'tollFree'">
                                                {{ phone.number | mask: '0000 000 0000'}}
                                            </span>
                                        <br/></p>
                                    </a>
                                </div>
                                </div> -->

                                <div *ngIf="item.whatsapp?.number && item?.whatsapp?.number.length > 10" class="contact-card">
                                    <div class="icon-container">
                                        <figure>
                                            <svg [attr.fill]="clientColor" data-name="Camada 1" id="Camada_1"
                                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <title>ico-whatsapp</title>
                                                <path
                                                    d="M15.3,12.5l-.6-.2-.6.2-.5.6-.5.2a2.1,2.1,0,0,1-1.4-.9,2.9,2.9,0,0,1-.9-1.3.8.8,0,0,1,.2-.5l.6-.6c.2-.1.2-.3.2-.6s0-.4-.2-.5L9.9,7.1a.9.9,0,0,0-1.2,0A3.8,3.8,0,0,0,7.5,9.9c0,2.3,2.1,4.2,2.3,4.4s2.2,2.3,4.4,2.3h.1A3.7,3.7,0,0,0,17,15.4h0a.8.8,0,0,0,0-1.1Zm1.2,2.4a3,3,0,0,1-2.3,1c-2,0-3.8-2.1-3.9-2.1h0S8.2,11.9,8.2,9.9a3,3,0,0,1,1-2.3h.2l1.7,1.8h0l-.6.5a1.5,1.5,0,0,0-.4,1.1,3.5,3.5,0,0,0,1.1,1.8A3,3,0,0,0,13,14a1.5,1.5,0,0,0,1.1-.4l.5-.6h.2l1.7,1.8Z" />
                                                <path
                                                    d="M12.2,2.7a8.8,8.8,0,0,0-8.9,8.8,8.3,8.3,0,0,0,1.5,4.8L3,21.1a.8.8,0,0,0,.3.9l.5.2h.4l4.9-2.3a10.6,10.6,0,0,0,3.1.5,8.8,8.8,0,1,0,0-17.6Zm0,16.9a10.5,10.5,0,0,1-3-.5c0-.1-.1-.1-.2,0H8.9l-5,2.4H3.7v-.2l1.8-4.9h0a7.8,7.8,0,0,1-1.4-4.6,8.1,8.1,0,1,1,8.1,8.1Z" />
                                            </svg>
                                        </figure>
                                    </div>
                                    <div class="info">
                                        <span class="title">WhatsApp</span>
                                        <a *ngIf="item?.whatsapp.number.length >= 13"
                                            href="https://api.whatsapp.com/send?phone={{ item.whatsapp.number }}{{phoneMsgWhatsApp ? '&text=' + phoneMsgWhatsApp : ''}}"
                                            target="_black">
                                            <p class="text">
                                                {{item.whatsapp.number | mask: '+00 (00) 0 0000-0000'}}</p>
                                        </a>

                                        <a *ngIf="item?.whatsapp?.number?.length < 13"
                                            href="https://api.whatsapp.com/send?phone={{ item.whatsapp.number }}{{phoneMsgWhatsApp ? '&text=' + phoneMsgWhatsApp : ''}}"
                                            target="_black">
                                            <p class="text">
                                                {{item?.whatsapp?.number | mask: '+00 (00) 0000-0000'}}</p>
                                        </a>

                                    </div>
                                </div>

                                <!-- <div *ngIf="checkinOut" class="contact-card">
                                <div class="icon-container">
                                    <figure>
                                        <svg [attr.stroke]="clientColor" data-name="Camada 1" id="Camada_1"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <defs>
                                                <style>.icon-clock-1 {
                                                    fill: none;
                                                    stroke-miterlimit: 10;
                                                }</style>
                                            </defs>
                                            <title>ico-relogio</title>
                                            <circle class="icon-clock-1" cx="12" cy="12" r="11"/>
                                            <polyline class="icon-clock-1" points="17.8 12.6 11.4 12.6 11.4 4.5"/>
                                        </svg>
                                    </figure>
                                </div>
                                <div class="info">
                                    <span class="title">Horários</span>
                                    <p class="text">
                                        Check-in: {{checkinOut.checkin}}<br/>
                                        Check-out: {{checkinOut.checkout}}
                                    </p>
                                </div>
                            </div> -->

                                <div *ngIf="item.email" class="contact-card">
                                    <div class="icon-container">
                                        <figure>
                                            <svg [attr.fill]="clientColor" data-name="Camada 1" id="Camada_1"
                                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <title>ico-email</title>
                                                <path
                                                    d="M19.9,5.7H4.4a1.1,1.1,0,0,0-1,1.1V18.1a1.1,1.1,0,0,0,1,1H19.9a1.1,1.1,0,0,0,1.1-1V6.8A1.1,1.1,0,0,0,19.9,5.7Zm-6.7,7.4a1.5,1.5,0,0,1-2,0L4.6,6.4H19.8Zm-3.6-.7L4.1,17.9V7Zm.5.5.6.7a2.2,2.2,0,0,0,3,0l.6-.7,5.5,5.5H4.6Zm4.7-.5L20.3,7V17.9Z" />
                                            </svg>
                                        </figure>
                                    </div>
                                    <div class="info">
                                        <span class="title">E-mail</span>
                                        <a [href]="'mailto:'+item.email" target="_black">
                                            <p class="text">{{item.email}}</p>
                                        </a>
                                    </div>
                                </div>

                                <div *ngIf="item.address && !hiddenAdress" class="contact-card">
                                    <div class="icon-container">
                                        <figure>
                                            <svg [attr.fill]="clientColor" viewBox="0 0 16 21"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <defs>
                                                    <style>
                                                        .icon-location-1 {}
                                                    </style>
                                                </defs>
                                                <title>Ativo 13</title>
                                                <g data-name="Camada 2" id="Camada_2">
                                                    <g data-name="Camada 1" id="Camada_1-2">
                                                        <path class="icon-location-1"
                                                            d="M8,0A8,8,0,0,0,0,8c0,5,5.1,10.6,7.4,12.7A.7.7,0,0,0,8,21a.9.9,0,0,0,.7-.3C10.9,18.6,16,13,16,8A8,8,0,0,0,8,0Zm.1,20.1H7.9C6.3,18.5.8,12.8.8,8A7.3,7.3,0,0,1,8,.8,7.2,7.2,0,0,1,15.2,8C15.2,12.7,10.2,18,8.1,20.1Z" />
                                                        <path class="icon-location-1"
                                                            d="M8,4.2A3.8,3.8,0,1,0,11.8,8,3.8,3.8,0,0,0,8,4.2ZM8,11A3,3,0,0,1,5.1,8,2.9,2.9,0,0,1,8,5.1,3,3,0,0,1,11,8,3.1,3.1,0,0,1,8,11Z" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </figure>
                                    </div>
                                    <div class="info">
                                        <span class="title">Endereço</span>
                                        <a [href]="item.location_map" target="_black">
                                            <p class="text">
                                                {{item.address}}, {{ item?.district ? item?.district + ' | ' : ''
                                                }}<br />
                                                {{item.city}} | {{item.state}}<br />
                                                {{ item.cep ? '| CEP: ' + item.cep : '' }}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md form">
                                <div class="title-container">
                                    <span>Formulário de contato</span>
                                    <p>Como podemos te ajudar?</p>
                                </div>
                                <form (ngSubmit)="onSubmit(contactForm)" [formGroup]="contactForm"
                                    class="form-container">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nome</mat-label>
                                        <input formControlName="name" matInput name="name" placeholder="José Silva"
                                            required>
                                        <mat-error>Este campo é obrigatório</mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>E-mail</mat-label>
                                        <input formControlName="mail" matInput name="mail"
                                            placeholder="seunome@email.com" required>
                                        <mat-error>Este campo é obrigatório</mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" floatLabel="always">
                                        <mat-label>Telefone</mat-label>
                                        <input matInput ng2TelInput [ng2TelInputOptions]="{initialCountry: 'br', preferredCountries: ['BR', 'UY', 'AR', 'PY', 'BO', 'PE', 'CO', 'VE', 'GY', 'SR', 'GF']}"
                                            (countryChange)="onCountryChange($event)"
                                            [placeholder]="telefone.sigla == 'br' ? '(00) 0 0000-0000' : 
                                            'Celular'" [mask]="telefone.sigla == 'br' ? '(00) 0000-0000 || (00) 0 0000-0000' : '0000000000000000'" 
                                            formControlName="phone" [validation]="telefone.sigla == 'br'" required >
                                        <mat-error>Este campo é obrigatório</mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Assunto</mat-label>
                                        <input formControlName="subject" matInput name="subject"
                                            placeholder="Assunto da mensagem" required>
                                        <mat-error>Este campo é obrigatório</mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Mensagem</mat-label>
                                        <textarea formControlName="message" matInput name="message"
                                            placeholder="Digite a sua mensagem" required rows="7"></textarea>
                                        <mat-error>Este campo é obrigatório</mat-error>
                                    </mat-form-field>

                                    <div class="captcha">
                                        <my-portal-privacy-policy
                                            (policyEvent)="privacyPolicy($event)"></my-portal-privacy-policy>
                                        <my-portal-recaptcha (recaptchaEvent)="recaptcha($event)"></my-portal-recaptcha>
                                    </div>

                                    <button type="submit"
                                        [disabled]="(!contactForm.valid || !disabledButton || !disabledRecaptcha)">
                                        <div *ngIf="!loading" class="spinner-border spinner-border-sm" role="status">
                                        </div>
                                        <span *ngIf="loading">enviar mensagem</span>
                                    </button>
                                </form>
                            </div>

                            <div class="col-sm-12 col-md location" *ngIf="item?.location_map">
                                <div class="title-container">
                                    <span>Localização</span>
                                    <p>Saiba como chegar ao nosso estabelecimento.</p>
                                </div>

                                <iframe loading="lazy" title="Mapa de Localização" *ngIf="item?.location_map" [src]="item.location_map | sanitize"
                                    allowfullscreen="" aria-hidden="false" frameborder="0" height="462"
                                    style="border:0;" tabindex="0" width="100%"></iframe>

                                <div class="col-sm mt-3">
                                    <div class="container-footer">
                                        <div class="social-networking">
                                            <a *ngFor="let social of item.social_network" [href]="social.url"
                                                target="_blank">
                                                <button [className]="social.name | lowercase">
                                                    <i class="{{'icon-' + social.name | lowercase }} notranslate"></i>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </ng-template>
            </mat-tab-group>

        <!-- <div class="row contact">
            <div class="col-sm-12 col-md">
                <div *ngIf="data?.phone.length >= 1 " class="contact-card">

                    <div class="icon-container" *ngIf="data?.phone">
                        <figure>
                            <svg [attr.fill]="clientColor" data-name="Camada 1" id="Camada_1"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>ico-telefone</title>
                                <path
                                    d="M20,22h0c-1.8,0-6.6-.6-11.4-5.4S3.3,7.1,3.2,5.2A1.2,1.2,0,0,1,4,4.1l3.3-1a1.1,1.1,0,0,1,1.4.5l1.7,3.6a.9.9,0,0,1-.2,1.3L8.5,10.3A10.9,10.9,0,0,0,11.2,14,13.8,13.8,0,0,0,15,16.8L16.7,15a1.2,1.2,0,0,1,1.3-.2l3.6,1.8a1.1,1.1,0,0,1,.6,1.4l-1.1,3.2A1.2,1.2,0,0,1,20,22ZM7.6,3.8H7.5L4.3,4.9a.3.3,0,0,0-.3.3c0,1.8.6,6.3,5.2,10.9s9.1,5.1,10.8,5.1l.4-.2,1.1-3.3-.2-.4-3.6-1.8h-.5l-1.9,2h-.4a15.6,15.6,0,0,1-4.2-3,11.2,11.2,0,0,1-3-4.3c-.1-.1,0-.3.1-.4L9.7,8a.6.6,0,0,0,.1-.4L8,4C7.9,3.8,7.8,3.8,7.6,3.8Z"/>
                            </svg>
                        </figure>
                    </div>
                    <div class="info" *ngIf="data?.phone">
                        <span class="title">Central de vendas</span>
                        <a target="_blank" *ngFor="let phone of data.phone" href="tel:{{phone.number}}">
                            <p class="text" >
                                <span *ngIf="phone.type === 'landlinePhone'">
                                    {{ phone.number | mask: '(00) 0000-0000'}}
                                </span>
                                <span *ngIf="phone.type === 'mobilePhone' ">
                                    {{ phone.number | mask: '(00) 0 0000-0000'}}
                                </span>
                                <span *ngIf="phone.type === 'tollFree'">
                                    {{ phone.number | mask: '0000 000 0000'}}
                                </span>
                            <br/></p>
                        </a>
                    </div>
                </div>

                <div *ngIf="data.whatsapp" class="contact-card">
                    <div class="icon-container">
                        <figure>
                            <svg [attr.fill]="clientColor" data-name="Camada 1" id="Camada_1"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>ico-whatsapp</title>
                                <path
                                    d="M15.3,12.5l-.6-.2-.6.2-.5.6-.5.2a2.1,2.1,0,0,1-1.4-.9,2.9,2.9,0,0,1-.9-1.3.8.8,0,0,1,.2-.5l.6-.6c.2-.1.2-.3.2-.6s0-.4-.2-.5L9.9,7.1a.9.9,0,0,0-1.2,0A3.8,3.8,0,0,0,7.5,9.9c0,2.3,2.1,4.2,2.3,4.4s2.2,2.3,4.4,2.3h.1A3.7,3.7,0,0,0,17,15.4h0a.8.8,0,0,0,0-1.1Zm1.2,2.4a3,3,0,0,1-2.3,1c-2,0-3.8-2.1-3.9-2.1h0S8.2,11.9,8.2,9.9a3,3,0,0,1,1-2.3h.2l1.7,1.8h0l-.6.5a1.5,1.5,0,0,0-.4,1.1,3.5,3.5,0,0,0,1.1,1.8A3,3,0,0,0,13,14a1.5,1.5,0,0,0,1.1-.4l.5-.6h.2l1.7,1.8Z"/>
                                <path
                                    d="M12.2,2.7a8.8,8.8,0,0,0-8.9,8.8,8.3,8.3,0,0,0,1.5,4.8L3,21.1a.8.8,0,0,0,.3.9l.5.2h.4l4.9-2.3a10.6,10.6,0,0,0,3.1.5,8.8,8.8,0,1,0,0-17.6Zm0,16.9a10.5,10.5,0,0,1-3-.5c0-.1-.1-.1-.2,0H8.9l-5,2.4H3.7v-.2l1.8-4.9h0a7.8,7.8,0,0,1-1.4-4.6,8.1,8.1,0,1,1,8.1,8.1Z"/>
                            </svg>
                        </figure>
                    </div>
                    <div class="info">
                        <span class="title">WhatsApp</span>
                            <a *ngIf="data?.whatsapp.length >= 13" href="https://api.whatsapp.com/send?phone={{ data.whatsapp }}{{phoneMsgWhatsApp ? '&text=' + phoneMsgWhatsApp : ''}}" target="_black"><p class="text">
                            {{data.whatsapp | mask: '+00 (00) 0 0000-0000'}}</p>
                            </a>

                            <a *ngIf="data?.whatsapp.length < 13" href="https://api.whatsapp.com/send?phone={{ data.whatsapp }}{{phoneMsgWhatsApp ? '&text=' + phoneMsgWhatsApp : ''}}" target="_black"><p class="text">
                                {{data.whatsapp | mask: '+00 (00) 0000-0000'}}</p>
                                </a>

                    </div>
                </div>

                <div *ngIf="checkinOut" class="contact-card">
                    <div class="icon-container">
                        <figure>
                            <svg [attr.stroke]="clientColor" data-name="Camada 1" id="Camada_1"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <style>.icon-clock-1 {
                                        fill: none;
                                        stroke-miterlimit: 10;
                                    }</style>
                                </defs>
                                <title>ico-relogio</title>
                                <circle class="icon-clock-1" cx="12" cy="12" r="11"/>
                                <polyline class="icon-clock-1" points="17.8 12.6 11.4 12.6 11.4 4.5"/>
                            </svg>
                        </figure>
                    </div>
                    <div class="info">
                        <span class="title">Horários</span>
                        <p class="text">
                            Check-in: {{checkinOut.checkin}}<br/>
                            Check-out: {{checkinOut.checkout}}
                        </p>
                    </div>
                </div>

                <div *ngIf="data.email" class="contact-card">
                    <div class="icon-container">
                        <figure>
                            <svg [attr.fill]="clientColor" data-name="Camada 1" id="Camada_1"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>ico-email</title>
                                <path
                                    d="M19.9,5.7H4.4a1.1,1.1,0,0,0-1,1.1V18.1a1.1,1.1,0,0,0,1,1H19.9a1.1,1.1,0,0,0,1.1-1V6.8A1.1,1.1,0,0,0,19.9,5.7Zm-6.7,7.4a1.5,1.5,0,0,1-2,0L4.6,6.4H19.8Zm-3.6-.7L4.1,17.9V7Zm.5.5.6.7a2.2,2.2,0,0,0,3,0l.6-.7,5.5,5.5H4.6Zm4.7-.5L20.3,7V17.9Z"/>
                            </svg>
                        </figure>
                    </div>
                    <div class="info">
                        <span class="title">E-mail</span>
                        <a [href]="'mailto:'+data.email" target="_black">
                            <p class="text">{{data.email}}</p>
                        </a>
                    </div>
                </div>

                <div *ngIf="data.address && !hiddenAdress" class="contact-card">
                    <div class="icon-container">
                        <figure>
                            <svg [attr.fill]="clientColor" viewBox="0 0 16 21" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <style>.icon-location-1 {
                                    }</style>
                                </defs>
                                <title>Ativo 13</title>
                                <g data-name="Camada 2" id="Camada_2">
                                    <g data-name="Camada 1" id="Camada_1-2">
                                        <path class="icon-location-1"
                                            d="M8,0A8,8,0,0,0,0,8c0,5,5.1,10.6,7.4,12.7A.7.7,0,0,0,8,21a.9.9,0,0,0,.7-.3C10.9,18.6,16,13,16,8A8,8,0,0,0,8,0Zm.1,20.1H7.9C6.3,18.5.8,12.8.8,8A7.3,7.3,0,0,1,8,.8,7.2,7.2,0,0,1,15.2,8C15.2,12.7,10.2,18,8.1,20.1Z"/>
                                        <path class="icon-location-1"
                                            d="M8,4.2A3.8,3.8,0,1,0,11.8,8,3.8,3.8,0,0,0,8,4.2ZM8,11A3,3,0,0,1,5.1,8,2.9,2.9,0,0,1,8,5.1,3,3,0,0,1,11,8,3.1,3.1,0,0,1,8,11Z"/>
                                    </g>
                                </g>
                            </svg>
                        </figure>
                    </div>
                    <div class="info">
                        <span class="title">Endereço</span>
                        <a [href]="data.location_map" target="_black">
                            <p class="text">
                                {{data.address}}, {{ data?.district ? data?.district + ' | '  : '' }}<br />
                                {{data.city}} | {{data.state}}<br/>
                                {{ data.cep ? '| CEP: ' + data.cep : '' }}
                            </p>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md form">
                <div class="title-container">
                    <span>Formulário de contato</span>
                    <p>Como podemos te ajudar?</p>
                </div>

                <form (ngSubmit)="onSubmit(contactForm)" [formGroup]="contactForm" class="form-container">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input formControlName="name" matInput name="name" placeholder="José Silva" required>
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>E-mail</mat-label>
                        <input formControlName="mail" matInput name="mail" placeholder="seunome@email.com" required>
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Telefone</mat-label>
                        <input formControlName="phone" matInput name="phone" placeholder="(99) 99999-9999" [mask]="'(00) 0 0000-0000'" required>
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Assunto</mat-label>
                        <input formControlName="subject" matInput name="subject" placeholder="Assunto da mensagem" required>
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Mensagem</mat-label>
                        <textarea formControlName="message" matInput name="message" placeholder="Digite a sua mensagem"
                                required rows="7"></textarea>
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>

                    <div class="captcha">
                        <my-portal-privacy-policy (policyEvent)="privacyPolicy($event)"></my-portal-privacy-policy>
                        <my-portal-recaptcha (recaptchaEvent)="recaptcha($event)"></my-portal-recaptcha>
                    </div>

                    <button type="submit" [disabled]="(!contactForm.valid || !disabledButton || !disabledRecaptcha)">
                        <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status"></div>
                        <span *ngIf="!loading">enviar mensagem</span>
                    </button>
                </form>
            </div>

            <div class="col-sm-12 col-md location" *ngIf="data?.location_map">
                <div class="title-container">
                    <span>Localização</span>
                    <p>Saiba como chegar ao nosso estabelecimento.</p>
                </div>

                <iframe loading="lazy" title="Mapa de Localização" *ngIf="urlMap" [src]="urlMap" allowfullscreen="" aria-hidden="false" frameborder="0" height="462"
                        style="border:0;" tabindex="0" width="100%"></iframe>

                <div class="col-sm mt-3">
                    <div class="container-footer">
                        <div class="social-networking">
                            <a *ngFor="let social of data.social_network" [href]="social.url" target="_blank">
                                <button [className]="social.name | lowercase">
                                    <i class="{{'icon-' + social.name | lowercase }} notranslate"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <div *ngIf="!loading && !data && !displayErrorMessage">
        <div class="alert alert-danger text-center" role="alert">
            Houve um erro inesperado.
        </div>
    </div>
</section>