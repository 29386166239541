import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'my-portal-show-all',
    template: `
        <div class="show-all">
            <a [routerLink]="[route]">ver todos</a>
        </div>`,
    styleUrls: ['./show-all.component.scss']
})
export class ShowAllComponent implements OnInit {

    @Input() route: string | any;

    constructor() {
    }

    ngOnInit(): void {
    }

}
