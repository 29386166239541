<section class="mt-5 mb-5" id="destiny-detail">
  <div *ngIf="!load" class="destiny-detail">
    <div class="row">

      <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
        <my-portal-highlighter
        [module]="{title: 'Nossos destinos'}"></my-portal-highlighter>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <swiper [config]="configInterna">
          <div class="swiper-wrapper">
            <div *ngFor="let destination of destinations" class="swiper-slide">
              <div class="accommodation mb-2">
                <figure class="accomodation" [ngClass]="{'loading': load }">
                  <img src="{{destination.url}}" class="img-fluid w-100">
                </figure>
                <div class="content-accommodation p-3 border border-top-0">
                  <h6 class="mt-2" [ngClass]="{'loading': load }">{{destination.name}}</h6>
                  <div [ngClass]="{'my-2': load }">
                    <p [ngClass]="{'loading': load }">
                      {{destination.brief_description}}
                    </p>
                  </div>

                  <a [routerLink]="['/acomodacao', destination.slug, destination.id]" class="btn-mais-detalhes">
                    <button>Mais detalhes</button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="pagination-accommodation"></div>
        </swiper>
      </div>
    </div>
  </div>
</section>
