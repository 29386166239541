import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

import { AccommodationsRoutingModule } from './accommodations-routing.module';
import { AccommodationsComponent } from './accommodations.component';
import { DefaultComponent } from './default/default.component';
import { CarouselComponent } from './carousel/carousel.component';
import { AccomodationDetailComponent } from './accomodation-detail/accomodation-detail.component';
import { CartComponent } from './cart/cart.component';
import {ListAccommodationComponent} from './list/list.component';
import {MatIconModule} from '@angular/material/icon';
import { MarkerComponent } from './marker/marker.component';
import { DatepickerRoomComponent } from './datepicker-room/datepicker-room.component';
import { FormSearchComponent } from './form-search/form-search.component';
import {FormsModule} from '@angular/forms';
import {MatRippleModule} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        AccommodationsComponent,
        DefaultComponent,
        CarouselComponent,
        AccomodationDetailComponent,
        CartComponent,
        ListAccommodationComponent,
        MarkerComponent,
        DatepickerRoomComponent,
        FormSearchComponent
    ],
    exports: [
        AccommodationsComponent
    ],
    imports: [
        SharedModule,
        AccommodationsRoutingModule,
        NgxUsefulSwiperModule,
        MatIconModule,
        FormsModule,
        MatRippleModule,
        MatSnackBarModule,
        MatTooltipModule
    ]
})
export class AccommodationsModule { }
