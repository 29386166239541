import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'my-portal-accommodations-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
