import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'my-portal-inner-load',
    template: `
        <div class="loader-container">
            <div class="spinner-border" role="status"></div>
        </div>`,
    styleUrls: ['./inner-load.component.scss']
})
export class InnerLoadComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
