import { Component, OnInit, Input } from '@angular/core';
import { AppDataService } from '../../../services/app-data.service';
import { DocumentService } from '../../../services/document.service';
import { SeoService } from '../../../services/seo.service';
import { SwiperOptions } from 'swiper';
import { InitializerService } from '../../../services/initializer.service';
import { environment } from 'projects/new-portal/src/environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'my-portal-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {

    infoData: any;
    initialize: boolean = false;
    load: boolean = true;
    about: any;
    typeEstablishment: any = (Number(this.documentService.getWindow()?.sessionStorage.getItem('typeSearch')));
    isSafari: any = this.initializerService.isSafari();
    marker: any = {
        title: null,
        description: null,
    };
    hotel: any = {
        title: 'Sobre nós',
        description: null,
    };

    portal: any = {
        title: 'A rede',
        description: null,
    };

    amenitiesMarker: any = {
        title: 'Comodidades',
        description: null,
    };

    accomodations: any = {
        title: 'Acomodações',
        description: null,
    };

    configInterna: SwiperOptions = {
        pagination: {
            el: '.pagination-accommodation',
            clickable: true,
        },
        slidesPerView: 1,
        autoHeight: true,
    };

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(
        private appData: AppDataService,
        private documentService: DocumentService,
        private seoService: SeoService,
        private initializerService: InitializerService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.initializerService.currentData.subscribe((response: any) => {
            const {only_motor, only_site} = response;
            this.infoData = response;
            if (only_motor && !only_site) {
                this.router.navigateByUrl('/');
            }
        }).unsubscribe();

        this.appData.getDataAbout('about', null, true).subscribe((response: any) => {
            this.about = response.data;
            this.marker.title = this.about.data_establishment.name;
            this.initialize = true;
            // this.marker.description = `Check-in: 14:00 | Check-out: 12:00`;
            const objSeo = {
                seo: {
                    title: this.about.data_establishment.name,
                    description: this.about.data_establishment.description,
                    keyWords: null,
                    image: this.about.data_establishment.url[0]
                }
            };
            this.seoService.updateSeo(objSeo);
            setTimeout(() => {
                this.load = false;
            }, 500);
        });

    }
}
