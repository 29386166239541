<section class="mt-5 mb-5" id="accommodation-detail">
    <my-portal-inner-load *ngIf="load"></my-portal-inner-load>
    <div *ngIf="!load" class="accommodation-detail">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                <div class="highlighter">
                    <div class="box-highlighter" id="box-highlighter">
                        <h1 class="hotel-name">{{accommodationDetail?.name}}</h1>
                        <h2>Veja de forma rápida as nossas vagas disponíveis</h2>
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" [ngClass]="{
                'col-md-12 col-lg-12 col-xl-12': !config.search_engine,
                'col-md-8 col-lg-8 col-xl-8': config.search_engine && config.search_engine.id == 3
            }">

                <my-portal-slide-gallery [images]="accommodationDetail?.header?.slideshow"></my-portal-slide-gallery>

                <my-portal-marker [bedroomArea]="accommodationDetail?.bedroom_area" [max]="accommodationDetail?.max"
                                  [name]="'Descrição da acomodação'"></my-portal-marker>

                <div [innerHTML]="accommodationDetail.header.description | sanitizeHtml"></div>

                <my-portal-marker *ngIf="accommodationDetail?.amenities.length" [name]="'Comodidades'"></my-portal-marker>

                <my-portal-resources *ngIf="accommodationDetail?.amenities.length" [resources]="accommodationDetail?.amenities" [largerFont]="true" [showAll]="true"></my-portal-resources>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" *ngIf="config.search_engine && config.search_engine.id == 3">
                <div class="info-room" *ngIf="accommodationDetail?.date_picker">
                    <div class="title">Gostou da acomodação?</div>
                    <div class="subtitle">Escolha as opções para sua hospedagem!</div>
                </div>

                <my-portal-datepicker-room *ngIf="accommodationDetail?.date_picker" (dates)="getDatesRange($event)" [roomId]="roomId" [hotelId]="accommodationDetail?.hotel_id" [minPax]="accommodationDetail?.min_pax"></my-portal-datepicker-room>

                <my-portal-form-search *ngIf="accommodationDetail?.date_picker" [config]="config" [configRoom]="accommodationDetail" [dates]="dateRange" [roomId]="roomId" [hotelId]="accommodationDetail?.hotel_id"></my-portal-form-search>

                <my-portal-info [phone]="dataFooter.phone" [whatsapp]="dataFooter.whatsapp"></my-portal-info>
            </div>
        </div>
    </div>
</section>
