import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'my-portal-restricted-area-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class RestrictedAreaPaymentComponent implements OnInit {
  dataPayment: any;

  constructor(private appData: AppDataService) { }

  ngOnInit(): void {
    this.appData.getRestrictedAreaPayments().subscribe((response: any) => {
      this.dataPayment = response.data;
    });
  }
}
