import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface IPriceDivergencyDialogData {
  old: number,
  new: number,
  diff: number,
  variation: 'positive' | 'negative',
}

@Component({
  selector: 'my-portal-dialog-error-price-divergency',
  templateUrl: './dialog-error-price-divergency.component.html',
  styleUrls: ['./dialog-error-price-divergency.component.scss']
})
export class DialogErrorPriceDivergencyComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: IPriceDivergencyDialogData, private dialogRef: MatDialogRef<DialogErrorPriceDivergencyComponent>) { }

  ngOnInit(): void {
  }

  cancelDialog(): void {
    this.dialogRef.close('cancel');
  }

  confirmDialog(): void {
    this.dialogRef.close('confirm');
  }
}
