import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ClipboardModule} from '@angular/cdk/clipboard';

import { RestrictedAreaRoutingModule } from './restricted-area-routing.module';
import { RestrictedAreaComponent } from './restricted-area.component';
import { RestrictedAreaReserveComponent } from './reserve/reserve.component';
import { ClientAreaComponent } from './client/client.component';
import { CorporateAreaComponent } from './corporate/corporate.component';
import { RestrictedAreaFidelityComponent } from './fidelity/fidelity.component';
import { RestrictedAreaPaymentComponent } from './payment/payment.component';
import { SharedModule } from '../../shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { DialogVoucherComponent } from './fidelity/dialog-voucher/dialog-voucher.component';
import { NgxMaskModule } from 'ngx-mask';
import { MatPaginatorModule} from '@angular/material/paginator';
import { DialogCancellationComponent } from './reserve/dialog-cancellation/dialog-cancellation.component';
import { DialogShareComponent } from './fidelity/dialog-share/dialog-share.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { DialogClientTypeComponent } from './dialog-client-type/dialog-client-type.component';


@NgModule({
  declarations: [
    RestrictedAreaComponent,
    ClientAreaComponent,
    CorporateAreaComponent,
    RestrictedAreaReserveComponent,
    RestrictedAreaFidelityComponent,
    RestrictedAreaPaymentComponent,
    DialogVoucherComponent,
    DialogCancellationComponent,
    DialogShareComponent,
    RecoverPasswordComponent,
    DialogClientTypeComponent,
  ],
  imports: [
    CommonModule,
    RestrictedAreaRoutingModule,
    MatSelectModule,
    SharedModule,
    MatMenuModule,
    ClipboardModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule,
    MatPaginatorModule,
  ]
})
export class RestrictedAreaModule { }
