import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {InitializerService} from '../../../services/initializer.service';
import {Location} from '@angular/common';
import {DocumentService} from '../../../services/document.service';

@Component({
    selector: 'my-portal-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input() dataHeader: any;
    hiddenSlide = false;
    hiddenMenu = false;
    data: any;
    isMobile: boolean = this.initializerService.isMobileDevice();
    typeSearch: any = this.documentService.getWindow()?.sessionStorage.getItem('typeSearch');
    youtubeTvFull: any;
    historyBack: any;
    hasBenefits: boolean = false

    constructor(private initializerService: InitializerService,
                private router: Router,
                private activatedRouter: ActivatedRoute,
                private location: Location,
                private documentService: DocumentService) {
        this.activatedRouter.paramMap.subscribe(params => {
            const urlSearchParams = new URLSearchParams(this.documentService.getWindow()?.location.search);
            const isHomePage = this.documentService.getWindow()?.location.pathname === '/';

            this.hiddenSlide = !isHomePage;

            const canalId = (urlSearchParams.get('canal_id')) ? urlSearchParams.get('canal_id') : null;
            const uuid_ask = (urlSearchParams.get('quotationIdentifier')) ? urlSearchParams.get('quotationIdentifier') : null;
            const trivagoId = (urlSearchParams.get('trv_reference')) ? urlSearchParams.get('trv_reference') : null;

            if (canalId) {
                this.documentService.getWindow()?.sessionStorage.setItem('canal_id', canalId)
            }

            if (uuid_ask) {
                this.documentService.getWindow()?.sessionStorage.setItem('quotationIdentifier', uuid_ask)
            }

            if (trivagoId) {
                this.documentService.getWindow()?.sessionStorage.setItem('trv_reference', trivagoId)
            }


        });
        router.events.subscribe((val) => {
            const isHomePage = this.documentService.getWindow()?.location.pathname === '/';
            this.hiddenSlide = !isHomePage;

            this.hiddenAskSuites();
        });
    }

    ngOnInit(): void {
        this.documentService.getWindow()?.window.addEventListener('scroll', this.scrollEvent, true);
        this.initializerService.currentData
            .subscribe((data) => {
                if (data) {
                    this.data = data;
                    this.hasBenefits = this.checkBenefits()
                    this.youtubeTvFull = this.data.header?.type === 3;
                    if (this.data.banner_map) {
                        // console.log(this.data.banner_map);
                    }
                }
            }).unsubscribe();
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnDestroy(): void {
        this.documentService.getWindow()?.window.removeEventListener('scroll', this.scrollEvent, true);
    }

    scrollEvent = (event: any): void => {
        const n = event.target.scrollTop;
        const form: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.search-form');
        const body: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('body');

        !this.hiddenSlide ? this.hiddenMenu = false : this.hiddenMenu = (n > 0);

        if (body.style.top) {
            if (body.style.top === '40px') {
                body.style.top = 0;
            }
        }
    }

    hiddenAskSuites(): void {
        const ask = this.documentService.getWindow()?.document.querySelector('.infochat_floatmain');
        if (ask) {
            if (this.hiddenSlide) {
                (ask as Element).classList.add('d-none');
            } else {
                (ask as Element).classList.remove('d-none');
            }
        }
    }

    checkBenefits(): boolean {
        return  this.data?.modules.some( (module: any) => module.module_id === 33)
    }
}
