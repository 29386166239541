import { MatDialog } from '@angular/material/dialog';
import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DialogPolicyComponent } from '../dialog-policy/dialog-policy.component';

@Component({
    selector: 'my-portal-cookie',
    templateUrl: './cookie.component.html',
    styleUrls: ['./cookie.component.scss'],
})
export class CookieComponent implements OnInit {
    policy: any;
    privacityContent: any;
    typeSearch: number = Number(this.documentService.getWindow()?.sessionStorage.getItem('typeSearch'));

    constructor(
        private appService: AppDataService,
        private initializerService: InitializerService,
        private documentService: DocumentService,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.appService.getDataPolicy().subscribe((response: any) => {
            
            if (this.typeSearch === 6) {
                this.policy = response.data.privacity
            } else {
                this.policy = this.typeSearch < 6 ? response.data : response.data.hotels;
            }

            let privacityMessageArr: any = [];

            if (this.typeSearch < 6) {
                Object.values(this.policy).forEach((item: any) => {
                    if (item.policies.privacity?.content) {
                        privacityMessageArr.push(item.policies.privacity.content);
                    }
                });
            } else {
                privacityMessageArr.push(response.data.privacity.content);
            }

            this.privacityContent = privacityMessageArr.length > 0 ? privacityMessageArr : null;
        });
    }

    handleSetCookies(): void {
        let expire = new Date('2050-01-01');
        let cookie = 'CookieActived=1; expires=' + expire.toUTCString();
        document.cookie = cookie;

        this.initializerService.changeControlCookie({
            showBoxCookie: false,
        });
    }

    openTerms(): void {
        this.dialog.open(DialogPolicyComponent, {
            data: {
                policyData: Object.values(this.policy),
                policyName: 'Politica de privacidade',
                privacy: true,
                fromCookie: true,
            },
            autoFocus: false,
        });
    }
}
