import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subscription, interval} from 'rxjs';

@Component({
    selector: 'my-portal-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit, OnDestroy {

    @Input() date: any;
    @Input() type: any;

    public dateNow = new Date();
    public dDay = new Date();
    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    SecondsInAMinute = 60;
    private subscription: any;

    public timeDifference: any;
    public secondsToDday: any;
    public minutesToDday: any;
    public hoursToDday: any;
    public daysToDday: any;


    constructor() {
    }

    ngOnInit(): void {
        this.date = this.date.replace(' ', 'T');
        this.dDay = new Date(this.date);
        this.subscription = interval(1000)
            .subscribe(x => { this.getTimeDifference(); });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private getTimeDifference(): void {
        this.timeDifference = this.dDay.getTime() - new Date().getTime();
        
        if(this.timeDifference <= 0) {
            this.daysToDday = 0;
            this.hoursToDday = 0;
            this.minutesToDday = 0;
            this.secondsToDday = 0;
        } else {
            this.allocateTimeUnits(this.timeDifference);
        }
    }
    
    private allocateTimeUnits(timeDifference: any): void {
        this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
        this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
        this.hoursToDday = Math.floor((timeDifference)
            /  (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute)
            % this.hoursInADay);
        this.daysToDday = Math.floor((timeDifference)
            / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));

    }

}
