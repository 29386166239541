<section class="mt-5 mb-5" *ngIf="showTestimoniesComponent">
    <div *ngIf="load" class="loading-container">
        <div class="spinner-border client-theme-color" role="status">
            <span class="sr-only">Carregando...</span>
        </div>
    </div>

    <div *ngIf="!load">
        <div class="row no-gutters d-flex justify-content-between">
            <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
            <div *ngIf="typeSearch >= 6 && !moduleData?.module_id && !personId" class="filter">
                <mat-form-field appearance="outline">
                    <mat-label>Filtrar por hotel</mat-label>
                    <mat-select #filterSelect [formControl]="filterForm" [disabled]="load">
                        <mat-option *ngFor="let hotel of hotelsList" [value]="hotel.id">{{hotel.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div *ngIf="!load">
        <my-portal-testimony *ngIf="testimonies?.length" [load]="load" [testimony]="testimonies" [slide]="typeSlide"></my-portal-testimony>
        <div *ngIf="!testimonies?.length" class="empty-testimonies">
            <div class="alert alert-danger text-center" role="alert">
                Não foi encontrado nenhum depoimento para esse hotel.
            </div>
        </div>
        <my-portal-testimony-form *ngIf="formVisible" [typeSearch]="typeSearch" [hotelsList]="hotelsList" [personId]="personId"></my-portal-testimony-form>
    </div>

    <my-portal-show-all *ngIf="moduleData?.module_id" [route]="'/depoimento'"></my-portal-show-all>

</section>
