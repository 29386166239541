import {Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {environment} from '../../../../environments/environment';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-default',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

    @Input() accommodations: any;
    @Input() load: any;
    @Input() hotelId: any;
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(
        private activatedRouter: ActivatedRoute,
        private initializerService: InitializerService
    ) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
    }

    routerAccommodation(staticRoute: string, slug: string, id: number, hotelId: number): any[] {
        return hotelId ? [staticRoute, slug, id, hotelId] : [staticRoute, slug, id];
    }
}
