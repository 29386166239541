import {environment} from 'projects/new-portal/src/environments/environment';
import {Component, Input, OnInit} from '@angular/core';
import {SwiperOptions} from 'swiper';
import {AppDataService} from './../../../services/app-data.service';
import {ActivatedRoute} from '@angular/router';


@Component({
    selector: 'my-portal-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

    @Input() moduleData: any;
    news: any = null;
    ImgSrc = environment.IMAGES_ENDPOINT;
    config: SwiperOptions = {
        pagination: {clickable: true, dynamicBullets: true},
    };

    constructor(private appData: AppDataService, private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        const landingPageId = (this.moduleData) ? this.moduleData.landing_page_categoria_id : this.activatedRoute.snapshot.params.id;
        if (!this.moduleData) {
            this.moduleData = {
                title: 'Notícias',
                description: 'Confira as notícias sobre nosso hotel e o turismo local'
            };
        }
        this.appData.getGenericPagesList(landingPageId)
            .subscribe((news: any) => {
                news.data.slug = this.appData.createSlugUrl(news.data.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                this.news = news.data;
            });
    }
}
