<div class="counter">
    <div class="date">
        <div class="month">{{date | date: 'MMM'}}</div>
        <div class="day">{{date | date: 'dd'}}</div>
    </div>
    <div class="message">restam<br />apenas</div>
    <div class="clock">
        <div class="step">
            <div class="description">dia(s)</div>
            <div class="count">{{daysToDday}}</div>
        </div>
        <div class="step">
            <div class="description">hrs</div>
            <div class="count">{{hoursToDday}}:</div>
        </div>
        <div class="step">
            <div class="description">min</div>
            <div class="count">{{minutesToDday}}:</div>
        </div>
        <div class="step">
            <div class="description">seg</div>
            <div class="count">{{secondsToDday}}</div>
        </div>
    </div>
  </div>
  