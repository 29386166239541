import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/pt-br';

import {InitializerService} from '../../../../services/initializer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentService} from '../../../../services/document.service';
import {SwiperOptions} from 'swiper';
import {Subject} from 'rxjs';
import {DefaultSnackBarComponent} from '../../../../shared/default-snack-bar/default-snack-bar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { environment } from './../../../../../environments/environment';

@Component({
    selector: 'my-portal-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

    @Input() dataHeader: any;
    @Input() position: any;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    typeSearch: any = this.documentService.getWindow()?.sessionStorage.getItem('typeSearch');
    expandMore = true;
    openCouponSearch: boolean = false;
    coupon: any;
    couponFromUrl: any = this.documentService.getWindow()?.sessionStorage.getItem('coupon')
    typeFormSearch = 2;
    typeAcomodation: any;
    hotelInfo: any;


    eventsSubject: Subject<void> = new Subject<void>();

    selected: any = {startDate: moment().add(0, 'days'), endDate: moment().add(1, 'days')};
    minDate = moment();
    rooms: any[] = [
        {
            pax: 2,
            children: 0,
            childrenAges: []
        }
    ];
    arrChildren: string[] = new Array(18);
    modelRoom: any = {
        pax: 2,
        children: 0,
        accommodation: 1
    };
    destination: any;
    openConfigSearch = false;

    config: SwiperOptions = {
        slidesPerView: 1,
        autoplay: {
            delay: 3500,
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            nextEl: '.swiper-button-next-slide',
            prevEl: '.swiper-button-prev-slide'
        },
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private initializerService: InitializerService,
                private router: Router,
                private activatedRouter: ActivatedRoute,
                private documentService: DocumentService,
                private snackBar: MatSnackBar) {
        moment.updateLocale('pt-br', {
            weekdaysMin: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB']
        });
        activatedRouter.paramMap.subscribe(params => {
            const initUrl = router.url;
            if (initUrl.includes('/search/')) {
                const url = initUrl.replace('/search/', '').split('/');
                this.selected = {startDate: moment(url[0]), endDate: moment(url[1])};

                let allConfig = null;
                if (url[2].split('?').length > 1) {
                    allConfig = url[2].split('?')[0];
                } else {
                    allConfig = url[2];
                }

                const config = allConfig.split('!');
                const endRoom = [];
                for (const room of config) {
                    const obj: any = {
                        pax: null,
                        children: 0,
                        childrenAges: []
                    };
                    if (room.length === 1) {
                        obj.pax = Number(room);
                    } else {
                        const children = room.split('-');
                        obj.pax = Number(children.shift());
                        for (const age of children) {
                            obj.childrenAges.push(Number(age));
                        }
                        obj.children = obj.childrenAges.length;
                    }
                    endRoom.push(obj);
                }
                this.rooms = endRoom;
                this.modelRoom.pax = this.countTotal(this.rooms).pax;
                this.modelRoom.children = this.countTotal(this.rooms).children;
                this.modelRoom.accommodation = this.countTotal(this.rooms).accommodation;
            }
        });
    }

    ngOnInit(): void {
        this.config.loop = this.dataHeader.header.mobile_banner?.length > 1;
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL_MAX_QUALITY);

        this.initializerService.currentControlFormSearch.subscribe(
            (value: any) => {
                if (value) {
                    if (value.type === 'search') {
                        this.search();
                    }

                    if (value.hotelInfo) {
                        this.hotelInfo = value.hotelInfo
                    }
                }
            }
        );
    }

    openDatepicker(): void {
        // this.pickerDirective.open();
    }

    emitEventToChild(): void {
        this.eventsSubject.next();
    }

    actionRooms(flag: string, type: string, key: number): void {
        switch (flag) {
            case 'add':
                this.rooms[key][type] += 1;
                if (type === 'children') {
                    this.rooms[key].childrenAges.push(null);
                }
                break;
            case 'remove':
                this.rooms[key][type] -= 1;
                if (type === 'children') {
                    this.rooms[key].childrenAges.pop();
                }
                break;
        }
        this.modelRoom.pax = this.countTotal(this.rooms).pax;
        this.modelRoom.children = this.countTotal(this.rooms).children;
        this.modelRoom.accommodation = this.countTotal(this.rooms).accommodation;
    }

    countTotal(rooms: any): any {
        const obj = {
            pax: 0,
            children: 0,
            accommodation: rooms.length
        };
        for (const room of rooms) {
            obj.pax += Number(room.pax);
            obj.children += room.children;
        }
        return obj;
    }

    newAccommodation(): void {
        this.rooms.push({
            pax: 1,
            children: 0,
            childrenAges: []
        });
        this.modelRoom.accommodation = this.rooms.length;
    }

    removeAccommodation(key: number): void {
        this.rooms.splice(key, 1);
        this.modelRoom.pax = this.countTotal(this.rooms).pax;
        this.modelRoom.children = this.countTotal(this.rooms).children;
        this.modelRoom.accommodation = this.rooms.length;
    }

    couponEvent(event: any): void {
        this.coupon = event;
    }

    changeAcomodation(event: any): void {
        this.typeAcomodation = event.type
    }

    search(): void {
        if (this.dataHeader.search_engine.id === 3) {
            const typeSearch: any = this.documentService.getWindow()?.sessionStorage.getItem('typeSearch');
            let type = typeSearch < 6 ? 'search' : 'hotels';
            if (this.destination) {
                type = (this.destination.type === 'hotel' || this.destination.type === 'localHotel') ? 'search' : 'hotels';
            }
            let URL = `${type}/${moment(this.selected.startDate).format('YYYY-MM-DD')}/${moment(this.selected.endDate).format('YYYY-MM-DD')}/`;
            let searchRoom = '';
            let count = 1;

            if (this.dataHeader?.accommodation_type == 2 || this.typeAcomodation == 'hostel') {
                let countRoom = ''
                for (let i = 0; i < this.rooms[0].pax; i++) {
                    countRoom += 1;
                }

                URL = URL + countRoom.split('').join('!');

            } else {
                for (const room of this.rooms) {
                    searchRoom += room.pax;
                    if (room.children > 0) {
                        for (const age of room.childrenAges) {
                            searchRoom += `-${age}`;
                        }
                    }
                    if (this.rooms.length > 1 && count !== this.rooms.length) {
                        searchRoom += '!';
                    }
                    count++;

                    //URL += searchRoom;
                    URL = `${type}/${moment(this.selected.startDate).format('YYYY-MM-DD')}/${moment(this.selected.endDate).format('YYYY-MM-DD')}/${searchRoom}`
                }
            }

            if (this.destination) {
                switch (this.destination.type) {
                    case 'localHotel':
                        URL += `/${this.destination.id}`;
                        break;
                    case 'local':
                        URL += `/${this.initializerService.generateSlug(this.destination.name)}/${this.destination.id}`;
                        break;
                    case 'hotel':
                        URL += `/${this.destination.id}`;
                        break;
                }
            }

            const valid = this.checkChildrenAge();

            if (this.coupon || this.couponFromUrl) {
                this.documentService.getWindow()?.sessionStorage.setItem('coupon', this.coupon ?? this.couponFromUrl);
            } else {
                if (this.documentService.getWindow()?.sessionStorage.getItem('coupon')) {
                    this.documentService.getWindow()?.sessionStorage.removeItem('coupon');
                }
            }

            if (valid) {
                this.openConfigSearch = false;
                this.router.navigateByUrl(URL);
            } else {
                this.openSnackBar('Informe a idade das criança(s)');
                this.openModal();
            }

            const searches: any = this.documentService.getWindow()?.localStorage.getItem('searches');

            if (searches) {
                const searchesArray = JSON.parse(searches);

                for (const search of searchesArray) {
                    if (search.URL === URL) {
                        searchesArray.splice(searchesArray.indexOf(search), 1);
                    }
                }

                searchesArray.unshift(this.auxSearch(type, URL));

                this.documentService.getWindow()?.localStorage.setItem('searches', JSON.stringify(searchesArray));
            } else {
                const searchesArray = [];
                searchesArray.unshift(this.auxSearch(type, URL));
                this.documentService.getWindow()?.localStorage.setItem('searches', JSON.stringify(searchesArray));
            }
        } else {
            this.searchEngine(this.dataHeader.search_engine.id, this.dataHeader.search_engine);
        }
    }

    auxSearch(type: string, URL: string): any {
        return {
            dates: {
                startDate: moment(this.selected.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.selected.endDate).format('YYYY-MM-DD'),
            },
            rooms: {
                pax: this.countTotal(this.rooms).pax,
                children: this.countTotal(this.rooms).children,
            },
            destination: this.destination,
            destinationType: this.destination?.type ?? null,
            typeSearch: type ?? null,
            URL: URL ?? null
        };
    }

    changeDates(event: any): void {
        if (event) {
            this.selected = {
                startDate: event.start, endDate: event.end
            };
        }
    }

    changeDestination(event: any): void {
        this.destination = event;

        if (this.hotelInfo) {
            this.destination = this.hotelInfo
        }

        setTimeout(() => {
            this.initializerService.changeControlFormSearch({
                type: 'destination',
            });
        }, 100);
    }

    openPanel(event: any): void {
        if (event) {
            setTimeout(() => {
                this.initializerService.changeControlFormSearch({
                    type: 'local'
                });
            }, 150);
        }
    }

    openCoupon(event: any): void {
        const valid = this.checkChildrenAge();
        if (valid) {
            setTimeout(() => {
                this.initializerService.changeControlFormSearch({
                    type: 'coupon'
                });
            }, 100);

            this.openConfigSearch = false;
            this.openCouponSearch = true;
        } else {
            this.openSnackBar('Informe a idade das criança(s)');
        }
    }

    checkChildrenAge(): boolean {
        let valid = true;
        const selects: HTMLElement | any = this.documentService.getWindow()?.document.querySelectorAll('.select-children');
        for (const accom of this.rooms) {
            if (accom.children) {
                for (const age of accom.childrenAges) {
                    const el: HTMLElement | any = selects[accom.childrenAges.indexOf(null)];
                    if (el) {
                        el.classList.add('required');
                    }
                    if (age === null || age === 'null') {
                        valid = false;
                    }
                }
            }
        }
        return valid;
    }

    handleSearchCoupon(event: any): void {
        this.search();
        setTimeout(() => {
            this.initializerService.changeControlFormSearch({
                type: 'coupon'
            });
        }, 100);
    }

    openModal(): void {
        const body: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.mat-drawer-content');
        this.openConfigSearch = !this.openConfigSearch;
        if (this.openConfigSearch) {
            const element: HTMLElement | any = this.documentService.getWindow()?.document.getElementById('search');
            (element as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

            body.style = 'overflow: hidden';
        } else {
            body.style = 'overflow: auto';
        }
    }

    openSnackBar(msg: string): void {
        this.snackBar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg
            },
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: 'error-snack-bar',
            duration: 2500
        });
    }

    changeGuests(event: any): void {
        this.rooms = event.rooms;
        this.arrChildren = event.arrChildren;
        this.openCouponSearch = event.openCouponSearch;
        this.modelRoom = event.modelRoom;
        this.openConfigSearch = event.openConfigSearch;
    }

    searchEngine(id: number, data: any): void {
        let url: string = '';

        let childenAgesNew: any;
        for (const childrenAges of this.rooms) {
            childenAgesNew = childrenAges.childrenAges;
        }

        switch (id) {
            case 5:
                url = `${data.link}&diff=false&CheckIn=${moment(this.selected.startDate).format('DDMMYYYY')}&CheckOut=${moment(this.selected.endDate).format('DDMMYYYY')}&NRooms=${this.modelRoom.accommodation}`;

                if (this.coupon) {
                    url += `&Code=${this.coupon}`;
                }

                let adults = '';
                let children = '';
                let ages = '';
                let count = 1;
                for (const room of this.rooms) {

                    if (room.pax) {
                        adults += (count) === 1 ? `&ad=${room.pax}` : `,${room.pax}`;
                    }

                    if (room.children > 0) {
                        children += (count) === 1 ? `&ch=${room.children}` : `,${room.children}`;
                    } else {
                        children += (count) === 1 ? '&ch=0' : ',0';
                    }

                    if (room.childrenAges.length > 0) {
                        let countAges = 1;
                        for (const age of room.childrenAges) {
                            if (count === 1) {
                                ages += (countAges) === 1 ? `&ag=${age}` : `;${age}`;
                            } else {
                                ages += (countAges) === 1 ? `,${age}` : `;${age}`;
                            }

                            countAges++;
                        }
                    } else {
                        ages += (count) === 1 ? '&ag=-' : ',-';
                    }
                    count++;
                }
                url = `${url}${adults}${children}${ages}`;
                this.documentService.getWindow()?.window.open(url, '_blank');
                break;
            case 8:

                let childrenAges: any = '';

                if (this.modelRoom?.children > 1) {
                    for (let idades of childenAgesNew) {
                        childrenAges += "&childrenage=" + idades;
                    }

                } else {
                    childrenAges = "&childrenage=" + childenAgesNew;
                }

                url = `https://hbook.hsystem.com.br/booking?companyId=${this.dataHeader?.codes?.hsystem}&adults=${this.modelRoom.pax}&children=${this.modelRoom?.children}&checkin=${moment(this.selected.startDate).format('YYYY-MM-DD')}&checkout=${moment(this.selected.endDate).format('YYYY-MM-DD')}${childrenAges}${this.coupon ? "&promocode="+this.coupon : "" }`;

                this.documentService.getWindow()?.window.open(url, '_blank');

                break;
            case 9:
                url = `https://hotels.cloudbeds.com/pt-br/reservas/${this.dataHeader?.codes?.cloudbeds}#checkin=${moment(this.selected.startDate).format('YYYY-MM-DD')}&checkout=${moment(this.selected.endDate).format('YYYY-MM-DD')}`;
                this.documentService.getWindow()?.window.open(url, '_blank');
                break;
            case 13:
                url = `https://minihotelpms.net/frame/reservas/getAvailability?from=${moment(this.selected.startDate).format('YYYY-MM-DD')}&to=${moment(this.selected.startDate).format('YYYY-MM-DD')}hotelToken=224669fc0b69e1a51ee829260cbd6262&nAdults=1&nChilds=${this.modelRoom?.children}&nBabies=0&`;
                this.documentService.getWindow()?.window.open(url, '_blank');
                break;
            case 14:
                let freeChildCount = 0;
                let payingChildCount = 0;

                for (const room of this.rooms) {
                    if (room.children > 0) {
                        for (const childrenAge of room.childrenAges) {
                            childrenAge <= data.free_chd_max_age ? freeChildCount++ : payingChildCount++;
                        }
                    }
                }

                url = `${data.link}?checkin=${moment(this.selected.startDate).format('YYYY-MM-DD')}&checkout=${moment(this.selected.endDate).format('YYYY-MM-DD')}&adults=${this.modelRoom.pax}&child1=${freeChildCount}&child2=${payingChildCount}`;

                if (this.coupon) {
                    url+= `&voucher=${this.coupon}`;
                }
                this.documentService.getWindow()?.window.open(url, '_blank');
                break;
            case 15:
                url = `https://sbreserva.silbeck.com.br/${this.dataHeader?.codes?.silbeck}/pt-br/reserva/busca/checkin/${moment(this.selected.startDate).format('DD-MM-YYYY')}/checkout/${moment(this.selected.endDate).format('DD-MM-YYYY')}/adultos-000001/0${this.modelRoom?.pax}${this.coupon ? "/promo/"+this.coupon : "" }`;
                this.documentService.getWindow()?.window.open(url, '_blank');
                break;
        }
    }
}
